/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAgentPageView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAgentPageController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAgentPageView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAgentPageView ? transformProxies(this.props.children) : {
      'af-breadcrumb-agent': [],
      'af-header-agent': [],
      'af-logo-image-agent': [],
      'af-header-agent-name': [],
      'af-top-100-agent': [],
      'af-agent-name': [],
      'af-agent-title': [],
      'af-marketing-name-agent': [],
      'af-stars-average-agent': [],
      'af-stars-agent': [],
      'af-link-reviews-agent': [],
      'af-review-agent-button': [],
      'af-description-agent': [],
      'af-instagram-agent': [],
      'af-facebook-agent': [],
      'af-linkedin-agent': [],
      'af-info-agent': [],
      'af-territories-wrap-agent': [],
      'af-territory-city-agent': [],
      'af-territory-city-agent-link': [],
      'af-territory-zip-code-area-agent': [],
      'af-territory-zip-code-area-agent-link': [],
      'af-admin-wrap-agent': [],
      'af-admin-link-agent': [],
      'af-admin-offer-request-link-agent': [],
      'af-data-wrapper': [],
      'af-average-response-time': [],
      'af-asking-price-ratio': [],
      'af-would-recommend': [],
      'af-reviews-wrapper': [],
      'af-review': [],
      'af-review-stars': [],
      'af-review-author': [],
      'af-review-date': [],
      'af-recommend-agent': [],
      'af-review-description': [],
      'af-review-show-more': [],
      'af-map-agent': [],
      'af-offer-request-link': [],
      'af-social-media-wrapper': [],
      'af-facebook-agent': [],
      'af-linkedin-agent': [],
      'af-twitter-agent': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-view-wrapper">
              <div className="af-class-container-4 w-container">
                <div className="af-class-breadcrumb-wrapper">
                  <div className="af-class-breadcrumb-link">
                    <a href="/" className="af-class-breadcrumb-link">Asuntoarvio.fi</a>
                  </div>
                  <div className="af-class-breadcrumb-arrow-right">-</div>
                  <div className="af-class-breadcrumb-link">
                    <a href="/kiinteistonvalittaja" className="af-class-breadcrumb-link">Etsi kiinteistönvälittäjä</a>
                  </div>
                  <div className="af-class-breadcrumb-arrow-right">-</div>
                  {map(proxies['af-breadcrumb-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-breadcrumb-link af-class-breadcrumb-link-active ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Matti Meikäläinen</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                </div>
              </div>
              <div className="af-class-container-5 w-container">
                {map(proxies['af-header-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-header ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  {map(proxies['af-logo-image-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-86-agencypage af-class-lkv-placeholder ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {map(proxies['af-header-agent-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h1 {...{...props, className: `af-class-heading-3 af-class-for-heading ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Matti Meikäläinen</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </h1>
                ))}
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                <div className="af-class-div-block-33" />
                <div className="af-class-agency-left-content-wrapper">
                  <div className="af-class-agency-info-wrapper af-class-alt-padding w-clearfix">{map(proxies['af-top-100-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="images/top100.svg" width={53} alt="top-100" {...{...props, className: `af-class-image-5 ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </img>
                ))}
                    {map(proxies['af-agent-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h1 {...{...props, className: `af-class-heading-3 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Matti Meikäläinen</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </h1>
                ))}
                    {map(proxies['af-agent-title'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h2 {...{...props, className: `af-class-agent-title ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Yrittäjä, toimitusjohtaja, LKV, LVV</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </h2>
                ))}
                    {map(proxies['af-marketing-name-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[city]/[company]" {...{...props, className: `af-class-lkv-company-agent ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Helsingin kiinteistönvälitys LKV</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    <div className="af-class-agency-page-rating-wrapper-agent w-clearfix">
                      {map(proxies['af-stars-average-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-rating-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>4.6</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      {map(proxies['af-stars-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-star-full ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      {map(proxies['af-link-reviews-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#reviews" {...{...props, className: `af-class-text-link-small-review-anchor ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Lue arvosteluja</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    </div>
                    {map(proxies['af-review-agent-button'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalittaja/profiili/[agent]/arvostele" {...{...props, className: `af-class-button-medium af-class-static-button af-class-no-float af-class-review-button-topmargin w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Arvostele kiinteistönvälittäjä tästä<span className="af-class-arrow-right af-class-arrow-button-medium af-class-arrow-padding-left"> </span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-description-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                    <div className="af-class-div-block-some-icon-wrapper">
                      <p className="af-class-lkv-result-list-some-icons">
                        {map(proxies['af-instagram-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="http://[instagram]" rel="nofollow" target="_blank" {...{...props, className: `af-class-lkv-result-list-some-icons ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-agent-info-icon-large"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      </p>
                      <p className="af-class-lkv-result-list-some-icons">
                        {map(proxies['af-facebook-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="http://[facebook]" rel="nofollow" target="_blank" {...{...props, className: `af-class-lkv-result-list-some-icons ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-agent-info-icon-large"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      </p>
                      <p className="af-class-lkv-result-list-some-icons">
                        {map(proxies['af-linkedin-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="http://[linkedin]" rel="nofollow" target="_blank" {...{...props, className: `af-class-lkv-result-list-some-icons ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-agent-info-icon-large"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      </p>
                    </div>
                    {map(proxies['af-info-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kokemusvuodet: 15<br />Kielitaito: Suomi, Ruotsi, Englanti<br />‍Erityisosaaminen: Kerrostalot, Omakotitalot, Sijoitusasunnot<br /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                    {map(proxies['af-territories-wrap-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <p className="af-class-p2 af-class-p2-margin">Välittäjän toimialueet</p>
                      {map(proxies['af-territory-city-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h3 {...{...props, className: `af-class-postcode-tag af-class-postcode-tag-small af-class-postcode-tag-dark ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        {map(proxies['af-territory-city-agent-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalittaja/[city]" {...{...props, className: `af-class-postcode-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Helsinki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </h3>
                ))}
                      {map(proxies['af-territory-zip-code-area-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h3 {...{...props, className: `af-class-postcode-tag ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        {map(proxies['af-territory-zip-code-area-agent-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalittaja/[postcode]" {...{...props, className: `af-class-postcode-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>00100 Helsinki Keskusta</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </h3>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  </div>
                  {map(proxies['af-admin-wrap-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-admin-link-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]/[agentId]" {...{...props, className: `af-class-button-medium af-class-static-button af-class-no-float af-class-admin-button w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Muokkaa välittäjän tietoja<span className="af-class-arrow-right af-class-arrow-button-medium"> </span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-admin-offer-request-link-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]/tarjouspyynnot/avoimet" {...{...props, className: `af-class-button-medium af-class-static-button af-class-no-float af-class-admin-button w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Avoimet tarjouspyynnöt<span className="af-class-arrow-right af-class-arrow-button-medium"> </span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-data-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-agency-info-wrapper-extra-margin w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-div-block-58">
                      <div className="af-class-agency-statistic-ball">
                        {map(proxies['af-average-response-time'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-statistic-big-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>2</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-agency-statistic-text">Päivää</div>
                      </div>
                      <div className="af-class-agency-statistic-label">Vastausnopeus <br />tarjouspyyntöön <br />keskimäärin</div>
                    </div>
                    <div className="af-class-div-block-58">
                      <div className="af-class-agency-statistic-ball">
                        {map(proxies['af-asking-price-ratio'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-statistic-big-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>31</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-agency-statistic-text">Prosenttisesti</div>
                      </div>
                      <div className="af-class-agency-statistic-label">Hintapyyntö<br />toteutunut<br />keskimäärin</div>
                    </div>
                    <div className="af-class-div-block-58">
                      <div className="af-class-agency-statistic-ball">
                        {map(proxies['af-would-recommend'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-statistic-big-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>16</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-agency-statistic-text">Kpl</div>
                      </div>
                      <div className="af-class-agency-statistic-label">Käyttäjää <br />suosittelee <br />yritystä </div>
                    </div>
                    <div className="af-class-div-block-57" />
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-reviews-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div id="reviews" className="af-class-h4">Arvostelut</div>
                    {map(proxies['af-review'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <div className="af-class-div-block-60" />
                      <div className="af-class-div-block-59 w-clearfix">
                        {map(proxies['af-review-stars'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-star-full ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-103">
                        <div className="af-class-div-block-resultpage-reviewer-name w-clearfix">
                          {map(proxies['af-review-author'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-rating-number af-class-alt-size ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Etunimi Sukunimi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        </div>
                        {map(proxies['af-review-date'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-date ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>24.8.2019</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      {map(proxies['af-recommend-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-user-recommendations ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Suosittelee välittäjää <span className="af-class-thumb"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      {map(proxies['af-review-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-lkv-result-list-address ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  <div className="af-class-div-block-76">
                    {map(proxies['af-review-show-more'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-medium-show-more af-class-static-button af-class-no-float w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Näytä lisää arvosteluita (12/32)</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  </div>
                </div>
                <div className="af-class-agency-right-content-wrapper">
                  {map(proxies['af-map-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-53 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                    <div className="af-class-map-2 w-widget w-widget-map" data-widget-style="roadmap" data-widget-latlng="60.1683731,24.9301702" data-enable-scroll="true" role title data-enable-touch="true" data-widget-zoom={12} data-widget-tooltip />
                  </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  <div className="af-class-div-block-54">
                    <div className="w-clearfix">
                      <div className="af-class-text-block-24"></div>
                      <p className="af-class-lkv-result-list-address">Välittäjävertailu on 100 % maksuton palvelu asunnon myyjille ja myyntiä suunnitteleville.</p>
                    </div>
                    <div className="w-clearfix">
                      <div className="af-class-text-block-24"></div>
                      <p className="af-class-lkv-result-list-address">Luotettava valinta elämäsi tärkeimpään kauppaan. Paikkakuntasi parhaat kiinteistönvälittäjät nopeasti ja vaivattomasti.</p>
                    </div>
                    <div className="w-clearfix">
                      <div className="af-class-text-block-24"></div>
                      <p className="af-class-lkv-result-list-address">Palvelun käyttö ei sido sinua mihinkään. Voit vapaasti valita sopivimman välittäjän asuntosi kauppaan.</p>
                    </div>
                    {map(proxies['af-offer-request-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kilpailutuspalvelu/kiinteistonvalittaja/[agent]" {...{...props, className: `af-class-button-medium w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Pyydä tarjous välittäjältä<span className="af-class-arrow-right af-class-arrow-button-medium af-class-arrow-no-margin"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-social-media-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <p className="af-class-lkv-result-list-address af-class-margin-top">Jaa yrityksen sivu sosiaalisessa mediassa</p>
                      <div className="af-class-footer-some-icons af-class-share">{map(proxies['af-facebook-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-footer-some-icons af-class-small ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}{map(proxies['af-linkedin-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-footer-some-icons af-class-small ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}{map(proxies['af-twitter-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-footer-some-icons af-class-small ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}</div>
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  </div>
                </div>
              </div>
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAgentPageView

/* eslint-enable */