import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client, { Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { connect } from 'react-redux';
import { logger } from './lib/debug';
import { UserService } from './services/user';
import { userActions } from './store/user';
import history from './history';

const log = logger('auth');

const DEFAULT_REDIRECT_CALLBACK = (appState: any) => {
  const target = (appState && appState.targetUrl
    ? appState.targetUrl
    : 'hallinta').replace(/^\//, '');

  setTimeout(() => history.replace(`/${target}`), 100);
};

function mapDispatchToProps(dispatch: any) {
  const actions = {
    onUserUpdate: (person: any) => dispatch(userActions.update(person)),
    onUserClear: () => dispatch(userActions.clear()),
    onUserAccessTokenUpdate: (token: string) => dispatch(userActions.updateAccessToken(token)),
  };
  return actions;
}

interface Props {
    children: JSX.Element[] | JSX.Element;
    onRedirectCallback?: (appstate?: any) => void;
    domain?: string;
    client_id?: string;
    redirect_uri?: string;
    audience?: string;
    scope?: string;
    onUserUpdate: (profile: any) => void;
    onUserClear: () => void;
    onUserAccessTokenUpdate: (token: string) => void;
}

export interface User {
  name: string;
  email: string;
}

export interface AuthHelper {
  isAuthenticated: boolean;
  loading: boolean;
  loginWithRedirect: (props?: any) => void;
  logout: () => void;
  user: User|null;
  getTokenSilently: () => string|null;
}

export const Auth0Context = React.createContext({});
export const useAuth0 = (): AuthHelper => useContext(Auth0Context) as AuthHelper;
export const Auth0Provider: React.FC<Props> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  onUserUpdate,
  onUserClear,
  onUserAccessTokenUpdate,
  ...initOptions
}: Props): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0FromHook = await createAuth0Client(initOptions as Auth0ClientOptions);
        setAuth0(auth0FromHook as any);

        if (window.location.search.includes('code=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const isAuthenticatedOk = await auth0FromHook.isAuthenticated();

        setIsAuthenticated(isAuthenticatedOk as any);

        if (isAuthenticatedOk) {
          const userProfile = await auth0FromHook.getUser();
          const service = new UserService();
          const accessToken = await auth0FromHook.getTokenSilently();
          onUserAccessTokenUpdate(accessToken);
          onUserUpdate({
            ...userProfile,
            ...(await service.me().get()),
          });
          log('userProfile', userProfile);
          setUser(userProfile as any);
        } else {
          onUserClear();
        }

        setLoading(false);
      } catch (error) {
        log('initAuth failure', error);
        setLoading(false);
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    if (!auth0Client) {
      throw new Error('Auth0 client missing');
    }
    setPopupOpen(true);
    try {
      await (auth0Client as any).loginWithPopup(params);
    } catch (error) {
      log(error);
    } finally {
      setPopupOpen(false);
    }
    setUser(await (auth0Client as any).getUser());
    setIsAuthenticated(true as any);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await (auth0Client as any).handleRedirectCallback();
    const currentUser = await (auth0Client as any).getUser();
    setLoading(false);
    setIsAuthenticated(true as any);
    setUser(currentUser);
  };

  const provider = {
      isAuthenticated,
      user,
      loading,
      popupOpen,
      loginWithPopup,
      handleRedirectCallback,
      getIdTokenClaims: (...p: any) => (auth0Client as any).getIdTokenClaims(...p),
      loginWithRedirect: (options: any = {}) => {
        (auth0Client as any).loginWithRedirect({
          ...options,
          appState: {
            ...options?.appState,
            targetUrl: history.location.pathname
          },
        });
      },
      getTokenSilently: (...p: any) => (auth0Client as any).getTokenSilently(...p),
      getTokenWithPopup: (...p: any) => (auth0Client as any).getTokenWithPopup(...p),
      logout: () => (auth0Client as any).logout({ returnTo: window.location.origin }),
  };

  return (
    <Auth0Context.Provider value={provider as any}>
      {children}
    </Auth0Context.Provider>
  );
};

export const Auth0ProviderConnectedBindActionCreators = connect(
  null,
  mapDispatchToProps,
)(Auth0Provider);
