import React from 'react';
import { toast } from 'react-toastify';
import { logger } from '../lib/debug';
import { CompanyService } from '../services/company';
import { AfReviewsLandingController } from '../controllers/AfReviewsLandingController';
import { FormInitialState } from '../components/Form';
import { loadingProps } from '../lib/helpers';
import { AgentService } from '../services/agent';
import { WriteReview, State } from './WriteReview';
import mapValues from 'lodash/mapValues';
import { StringSignatureAny } from '../shared/types';

const log = logger('WriteReview');

export class WriteReviewDirect extends WriteReview {
  state = {
    ...FormInitialState,
    section: 'section-review-open',
  } as State;

  getRedirectPath = (key: string) => {
    const { location } = this.props;
    let redirect = location.pathname;
    redirect = redirect.replace(key, '');
    return redirect;
  }

  componentDidMount() {
    super.componentDidMount();
    const { match, location, history } = this.props;
    const { agentSlug, companySlug, reviewSlug } = match.params;

    const redirect = this.getRedirectPath('/arvostele');
    if (typeof location.state === 'undefined' && typeof reviewSlug === 'undefined') {
      history.push(redirect, {openLoginModal: true});
    }
    if (!!location.state && !!(location as any).state.status && (location as any).state.status === 'unknown') {
      history.push(redirect, {openLoginModal: true});
    }
    if (typeof reviewSlug !== 'undefined') {
      this.loadReviewRequest();
    } else if (typeof companySlug !== 'undefined' && typeof reviewSlug === 'undefined') {
      this.loadCompanyDetails();
    } else if (typeof agentSlug !== 'undefined') {
      this.loadAgentDetails();
    }
  }

  getCustomValidators(elemName: string | null = null) {
    const { location } = this.props;
    const { formData } = this.state;
    const validators = {
      description: () => {
        if (typeof location.state === 'undefined') {
          return [];
        }
        if (!!formData.description && formData.description.trim() !== '') {
          return [];
        }

        return ['Empty description'];
      },
    } as StringSignatureAny;

    const elemValidators = mapValues(validators, (validator, name) => ({
      name,
      checkValidity: () => validator().length === 0,
      validity: null,
      getErrors: validator,
    })) as StringSignatureAny;

    if (elemName) {
      return elemValidators[elemName];
    }

    return elemValidators;
  }
  componentWillUnmount() {
    if (this.timer !== 0) {
      clearTimeout(this.timer);
      this.timer = 0;
    }
  }

  timer: any = 0;

  get companyService() {
    const { match } = this.props;
    const { companySlug } = match.params;
    const service = new CompanyService(companySlug);
    return service;
  }

  get agentService() {
    const { match } = this.props;
    const { agentSlug } = match.params;
    const service = new AgentService(agentSlug);
    return service;
  }

  async loadCompanyDetails() {
    const { history } = this.props;
    try {
      const company = await this.companyService.get();
      const formData = {};
      this.setState({
        company,
        formData,
      });
    } catch (error) {
      if ((error as any).json && (error as any).json.statusCode === 405 && (error as any).json.message === 'Locked') {
        toast.error('Tämä linkki ei ole enää voimassa.');
        history.push('/');
        return;
      }
      log('ERROR', (error as any).json);
      toast.error('Jotain meni pieleen. Ole hyvä ja yritä uudelleen.');
    }
  }
  async loadAgentDetails() {
    const { history } = this.props;
    try {
      const agent = await this.agentService.get();
      const formData = {};
      this.setState({
        agent,
        formData,
      });
    } catch (error) {
      if ((error as any).json && (error as any).json.statusCode === 405 && (error as any).json.message === 'Locked') {
        toast.error('Tämä linkki ei ole enää voimassa.');
        history.push('/');
        return;
      }
      log('ERROR', (error as any).json);
      toast.error('Jotain meni pieleen. Ole hyvä ja yritä uudelleen.');
    }
  }
  async loadReviewRequest() {
    const { match, history } = this.props;

    const { reviewSlug, numberOfStars } = match.params;

    try {
      let reviewRequest = await this.companyService.child('review').child(`${reviewSlug}`).get();
      const company = reviewRequest.company;
      if (!reviewRequest.review.numberOfStars && numberOfStars !== undefined) {
        const stars = parseInt(numberOfStars, 10);
        this.updateNumberOfStars(stars);
        reviewRequest = { ...reviewRequest, review: { ...reviewRequest.review, numberOfStars: stars } };
      }
      const review = reviewRequest.review;
      this.setState({
        formData: review,
        company,
        reviewRequest,
      });
    } catch (error) {
      if ((error as any).json && (error as any).json.statusCode === 405 && (error as any).json.message === 'Locked') {
        toast.error('Tämä linkki ei ole enää voimassa.');
        history.push('/');
        return;
      }
      log('ERROR', (error as any).json);
      toast.error('Jotain meni pieleen. Ole hyvä ja yritä uudelleen.');
    }
  }
  async saveformData(): Promise<boolean> {
    const { reviewRequest } = this.state;
    const { match, location, history } = this.props;
    const { companySlug, agentSlug, reviewSlug } = match.params;
    try {
      const oldFormData = this.state.formData;
      const customerName = typeof location.state !== 'undefined'
        // social login
        ? (!!(location as any).state.profileObj ? (location as any).state.profileObj.name : (location as any).state.name)
        : reviewRequest.review.customerName;

      const newState = {
        formData: {
          ...oldFormData,
          customerName,
        },
      };

      this.setState(newState);

      if (typeof companySlug !== 'undefined' && typeof reviewSlug === 'undefined') {
        await this.companyService.child('review').patch(newState.formData);
      } else if (typeof agentSlug !== 'undefined') {
        // use agent main company for review request
        await this.agentService.child('review').patch(newState.formData);
      } else {
        await this.companyService.child('request').child(`${reviewRequest.slug}`).patch(newState.formData);
      }
      toast.success('Kiitos.');
      if (!reviewSlug) {
        this.timer = setTimeout(() => {
          const redirect = this.getRedirectPath('/arvostele');
          history.push(redirect);
        }, 3000);
      }
      return true;
    } catch (error) {
      toast.error('Tietojen päivittäminen epäonnistui');
      return false;
    }
  }

  handleCancel = () => {
    const { history } = this.props;
    const { company, agent } = this.state;
    if (typeof company !== 'undefined') {
      history.push(`/kiinteistonvalitys/${company.city}/${company.slug}`);
    } else if (typeof agent !== 'undefined') {
      history.push(`/kiinteistonvalittaja/profiili/${agent.slug}`);
    }
  }

  renderFormChildren(): any {
    if (this.state.savedSections.includes(this.sectionName)) {
      return <af-section-submit disabled>Kiitos, arvostelusi on tallennettu</af-section-submit>;
    }

    return [
      <af-section-submit-cancel onClick={this.handleCancel} key='cancel' />,
      <af-section-submit onClick={this.handleSubmit} key='submit'  {...loadingProps(this.isSectionSaving())} />,
    ];
  }

  render() {
    const { company, agent } = this.state;

    if (!company && !agent) {
      return null;
    }

    return (
      <AfReviewsLandingController
        {...this.props}
      >
        {this.renderForm()}
      </AfReviewsLandingController>
    );
  }
}

export default WriteReviewDirect;
