import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AgentService } from '../services/agent';
import { Agent } from './Agent.interface';
import { userSelectors } from '../store/user';
import { AfAgentPageController } from '../controllers/AfAgentPageController';
import { Review } from './Review.interface';
import { BaseComponent, BaseState } from '../components/BaseComponent';
import { AuthHelper } from '../AuthProvider';
import { UserProfile } from '../store/user/reducer';
import ReviewLogin from './ReviewLogin';

interface State extends BaseState {
  agent: Agent;
  reviews: Review[];
  reviewPage: number;
  reviewPageCount: number;
  reviewsCount: number;
  modalIsOpen: boolean;
  openLoginModal: boolean;
}

interface TParams {
  agentSlug: string;
}

interface Props {
  isAuthenticated: boolean;
  hasCompany: (companyId: number) => boolean;
  isAdmin: boolean;
  profile: UserProfile | null;
}

export class AgentDetails extends BaseComponent<RouteComponentProps<TParams> & Props & AuthHelper, State> {
  state = {
    reviewPage: 1,
  } as State;

  componentDidMount() {
    super.componentDidMount();
    this.loadAgentDetails();

    const { location } = this.props;

    if (!!location.state && !!(location as any).state.openLoginModal && (location as any).state.openLoginModal) {
      this.setState({
        openLoginModal: true,
      });
    }
  }

  async loadAgentDetails() {
    const { match, history } = this.props;
    const { agentSlug } = match.params;

    try {
      const service = new AgentService(agentSlug);
      const [agent, reviewsPage] = await Promise.all([
        service.get(),
        service.child('review').get(),
      ]);

      const { items: reviews, meta: { totalItems: reviewsCount, totalPages: reviewPageCount } } = reviewsPage;

      this.setStateAfterLoad({ agent, reviews, reviewsCount, reviewPageCount });
    } catch (err) {
      console.error('Failed to load agent details', err);
      history.push('/kiinteistonvalittaja/');
    }
  }

  loadReviewsNextPage = async () => {
    const { match } = this.props;
    const { agentSlug } = match.params;

    this.startLoadingData('page');

    const { reviewPage, reviews } = this.state;

    const page = reviewPage + 1;
    const service = new AgentService(agentSlug);
    const { items, meta: { totalItems: reviewsCount, totalPages: reviewPageCount } } = await service.review().query({ page }).get();

    this.endLoadingData('page', { reviews: reviews.concat(items), reviewsCount, reviewPageCount, reviewPage: page });
  }

  onCloseModalLogin = (e: any = null) => {
    const { location, history } = this.props;
    const redirect = location.pathname;

    if (!!location.state && !!(location as any).state.openLoginModal && (location as any).state.openLoginModal) {
      history.push(redirect, {openLoginModal: false});
    }

    if (e) {
      e.preventDefault();
    }
    this.setStateAfterLoad({ openLoginModal: false });
  }

  onReviewClick = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setStateAfterLoad({
      openLoginModal: true,
    });
  }

  onSocialLogin = (e: any) => {
    const { agent } = this.state;
    const { history } = this.props;

    history.push(`/kiinteistonvalittaja/profiili/${agent.slug}/arvostele`, e);
  }

  render() {
    const { agent, reviews, reviewPage, reviewPageCount, reviewsCount, openLoginModal } = this.state;

    if (this.isLoading) {
      return this.renderLoading();
    }

    return (
      <>
        <AfAgentPageController
          agent={agent}
          reviews={reviews}
          reviewPage={reviewPage}
          reviewPageCount={reviewPageCount}
          reviewsCount={reviewsCount}
          loadReviewsNextPage={this.loadReviewsNextPage}
          onReviewClick={this.onReviewClick}
          {...this.props}
        />
        <ReviewLogin
          isOpen={openLoginModal}
          onCloseModalLogin={this.onCloseModalLogin}
          onSocialLogin={this.onSocialLogin}
          {...this.props}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isAdmin: userSelectors.isAdmin(state),
  isAuthenticated: userSelectors.isAuthenticated(state),
  profile: userSelectors.getProfile(state),
  hasCompany: userSelectors.hasCompany(state),
});

export default connect(
  mapStateToProps,
  {
  },
)(AgentDetails);
