/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAdminModalsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAdminModalsController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAdminModalsView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAdminModalsView ? transformProxies(this.props.children) : {
      'af-modal-contacted': [],
      'af-form-modal-contacted': [],
      'af-modal-contacted-cancel-btn': [],
      'af-modal-contacted-confirm-btn': [],
      'af-modal-reclamation': [],
      'af-form-modal-reclamation': [],
      'af-modal-reclamation-cancel-btn': [],
      'af-modal-rejected': [],
      'af-form-modal-rejected': [],
      'af-link-agent-container': [],
      'af-input-reason-rejected-open': [],
      'af-modal-rejected-cancel-btn': [],
      'af-modal-rejected-confirm-btn': [],
      'af-modal-rejected-2': [],
      'af-form-modal-rejected-2': [],
      'af-link-agent-container': [],
      'af-input-reason-rejected-contacted': [],
      'af-modal-rejected-2-cancel-btn': [],
      'af-modal-rejected-2-confirm-btn': [],
      'af-modal-contract': [],
      'af-form-modal-contract': [],
      'af-input-contract-at': [],
      'af-input-asking-price': [],
      'af-is-offer-sale-checkbox': [],
      'af-modal-contract-cancel-btn': [],
      'af-modal-contract-confirm-btn': [],
      'af-modal-fulfilled': [],
      'af-form-modal-fulfilled': [],
      'af-input-fulfilled-at': [],
      'af-input-selling-price': [],
      'af-input-commission': [],
      'af-input-commission-receipt': [],
      'af-link-agent-container': [],
      'af-input-agent-link': [],
      'af-modal-fulfilled-cancel-btn': [],
      'af-modal-fulfilled-confirm-btn': [],
      'af-modal-not-fulfilled': [],
      'af-form-modal-not-fulfilled': [],
      'af-input-not-fulfilled-at': [],
      'af-link-agent-container': [],
      'af-input-reason-rejected-contract': [],
      'af-modal-not-fulfilled-cancel-btn': [],
      'af-modal-not-fulfilled-confirm-btn': [],
      'af-modal-claim-company': [],
      'af-marketing-name': [],
      'af-require-login': [],
      'af-modal-cancel-btn': [],
      'af-modal-login-btn': [],
      'af-authenticated': [],
      'af-form-authenticated': [],
      'af-email': [],
      'af-modal-cancel-btn': [],
      'af-modal-claim-company-btn': [],
      'af-modal-terms': [],
      'af-form-terms': [],
      'af-modal-reject-btn': [],
      'af-modal-accept-btn': [],
      'af-modal-review-login': [],
      'af-form-reviews-login': [],
      'af-modal-login-facebook-wrapper': [],
      'af-modal-login-faceook': [],
      'af-modal-login-google-wrapper': [],
      'af-modal-login-google': [],
      'af-exit-some-modal': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-modal-body">
            {map(proxies['af-modal-contacted'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-modal-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-modal-content">
                <div className="af-class-div-block-16">
                  <div className="af-class-admin-modal-heading">Vahvista asiakkaan yhteystietojen haku</div>
                  <p className="af-class-paragraph af-class-bottom-extra-margin">Yhteystietojen katsominen muodostaa sopimussuhteen Asuntoarvion.fi:n kanssa myyntikohteeseen ja siirtää sen Avoimet asiakkaat -välilehdelle.<br /><br />Myyntikohteen yhteystietojen katsominen maksaa 20 euroa (alv 0 %). Tarkemmat ehdot <a href="/app/documents/Asuntoarvio-fi-sopimusehdot_230101.pdf" target="_blank" className="af-class-admin-modals-link-text">palvelusopimuksessa</a>.<br />‍<br />Yhteydenoton nopeus asiakkaaseen on yksi vaikuttava tekijä välitysliikkeen sijoittumisessa palvelussa. Olethan siis asiakkaaseen pikimmiten yhteydessä ja merkitse toteutunut toimeksiantosopimus järjestelmän tietoihin kun saatte kohteen myyntiin.<br />‍<br /></p>
                  <div className="w-form">
                    {map(proxies['af-form-modal-contacted'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-modal-contacted" name="wf-form-af-form-modal-contacted" data-name="af-form-modal-contacted" method="get" data-wf-page-id="5d9c6c5957629d84db83f5b6" data-wf-element-id="f5ab3239-3a25-192d-14e6-8c0aa10cc461" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>{map(proxies['af-modal-contacted-cancel-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Peruuta" {...{...props, className: `af-class-button-admin-modals-top w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Peruuta')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{map(proxies['af-modal-contacted-confirm-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Näytä yhteystiedot" {...{...props, className: `af-class-button-admin-modals w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Näytä yhteystiedot')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-modal-reclamation'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-modal-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-modal-content">
                <div className="af-class-div-block-16">
                  <div className="af-class-admin-modal-heading">Reklamaatio virheellisestä vinkistä</div>
                  <p className="af-class-paragraph af-class-bottom-extra-margin">Välittäjällä on oikeus kirjallisella reklamaatiolla vapautua vinkkimaksun maksamisesta seuraavissa tapauksissa:<br /><br />1. Myyntikohteeseen liittyvät myyjän yhteystiedot ovat virheelliset.<br /><br />2. Myyjä ilmoittaa välittäjän yhteydenoton jälkeen, että ei ole myymässä kohdetta 12 kuukauden sisällä vinkin vastaanottamisesta.<br /><br />3. Välittäjä on vahingossa tai virheellisesti ottanut vastaan vinkin myyntikohteesta, jonka tiedot ovat todistettavasti välittäjän tiedossa ennen myyjän yhteystietojen katsomista palvelusta.<br />‍<br /><strong>Reklamaatio tulee tehdä 24 tunnin kuluessa myyjän yhteystietojen vastaanottamisesta. Jos reklamaatiota ei tehdä em. määräajassa, oikeus reklamaatioon raukeaa.</strong><br /><br />Reklamaatiot otetaan vastaan sähköpostilla osoitteeseen <a href="mailto:reklamaatio@asuntoarvio.fi?subject=Reklamaatio%20Asuntoarvio.fi">reklamaatio@asuntoarvio.fi</a>. Reklamaatiosta tulee käydä esille reklamoitavan myyntikohteen täydellinen osoite siinä muodossa missä asunnon myyjä on sen kirjoittanut sekä tieto reklamaation perusteesta.<br /></p>
                  <p className="af-class-paragraph af-class-bottom-extra-margin af-class-claim-modal-paragraph">Asuntoarvio.fi -palvelun <a href="/app/documents/Asuntoarvio-fi-sopimusehdot_230101.pdf" target="_blank" className="af-class-admin-modals-link-text">sopimusehdot.</a><br /></p>
                  <div className="w-form">
                    {map(proxies['af-form-modal-reclamation'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-modal-reclamation" name="wf-form-af-form-modal-reclamation" data-name="af-form-modal-reclamation" method="get" data-wf-page-id="5d9c6c5957629d84db83f5b6" data-wf-element-id="5ecfffb9-e9ce-0ec5-2217-3a7c4834ca59" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>{map(proxies['af-modal-reclamation-cancel-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Sulje" {...{...props, className: `af-class-button-admin-modals-top w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Sulje')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-modal-rejected'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-modal-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-modal-content">
                <div className="af-class-div-block-16">
                  <div className="af-class-admin-modal-heading">Vahvista tarjouspyynnön hylkäys</div>
                  <p className="af-class-paragraph af-class-bottom-extra-margin-modals">Haluatko varmasti hylätä tarjouspyynnön?<br /></p>
                  <div className="w-form">
                    {map(proxies['af-form-modal-rejected'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-modal-rejected" name="wf-form-af-form-modal-rejected" data-name="af-form-modal-rejected" method="get" data-wf-page-id="5d9c6c5957629d84db83f5b6" data-wf-element-id="cf467546-7186-627b-5049-0ee46352f634" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      {map(proxies['af-link-agent-container'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="reasonRejectedOpen" className="af-class-field-label">Miksi tarjouspyyntöä ei otettu vastaan?</label>{map(proxies['af-input-reason-rejected-open'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="reasonRejectedOpen" name="reasonRejectedOpen" data-name="reasonRejectedOpen" required {...{...props, className: `af-class-input-field af-class-bottom-margin w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                          <option value>Valitse syy hylkäykselle</option>
                        </React.Fragment>)}
                  </select>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}{map(proxies['af-modal-rejected-cancel-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Peruuta" {...{...props, className: `af-class-button-admin-modals-top w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Peruuta')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{map(proxies['af-modal-rejected-confirm-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Vahvista hylkäys" {...{...props, className: `af-class-button-admin-modals w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Vahvista hylkäys')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-modal-rejected-2'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-modal-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-modal-content">
                <div className="af-class-div-block-16">
                  <div className="af-class-admin-modal-heading">Vahvista tarjouspyynnön hylkäys</div>
                  <p className="af-class-paragraph af-class-bottom-extra-margin-modals">Haluatko varmasti hylätä yhteydenoton? Sinulla on voimassa oleva sopimus tästä kohteesta Asuntoarvio.fi:n kanssa myös kohteen hylkäyksen jälkeen. Varmistamme välittäjän antaman tiedon kohteen toteutumisesta aina myös <strong><em>asiakkaalta</em></strong>.<br /><br />Asuntoarvio.fi:n ja välitysliikkeen välisessä <a href="/app/documents/Asuntoarvio-fi-sopimusehdot_230101.pdf" target="_blank" className="af-class-admin-modals-link-text">palvelusopimuksessa</a> velvoitetaan välitysliike antamaan oikea tieto toteutuneista toimeksiantosopimuksista.<br /></p>
                  <div className="w-form">
                    {map(proxies['af-form-modal-rejected-2'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-modal-rejected" name="wf-form-af-form-modal-rejected" data-name="af-form-modal-rejected" method="get" data-wf-page-id="5d9c6c5957629d84db83f5b6" data-wf-element-id="bf0ca160-6b47-a04e-3eea-bdb83280ea19" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      {map(proxies['af-link-agent-container'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="reasonRejectedContacted" className="af-class-field-label">Miksi tarjouspyyntö hylätään?</label>{map(proxies['af-input-reason-rejected-contacted'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="reasonRejectedContacted" name="reasonRejectedContacted" data-name="reasonRejectedContacted" required {...{...props, className: `af-class-input-field af-class-bottom-margin w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                          <option value>Valitse syy hylkäykselle</option>
                        </React.Fragment>)}
                  </select>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}{map(proxies['af-modal-rejected-2-cancel-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Peruuta" {...{...props, className: `af-class-button-admin-modals-top w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Peruuta')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{map(proxies['af-modal-rejected-2-confirm-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Vahvista hylkäys" {...{...props, className: `af-class-button-admin-modals w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Vahvista hylkäys')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-modal-contract'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-modal-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-modal-content">
                <div className="af-class-div-block-16">
                  <div className="af-class-admin-modal-heading">Vahvista myyntikohteen hintapyyntö</div>
                  <p className="af-class-paragraph af-class-bottom-extra-margin">Asuntoarvio.fi käyttää <strong><em>välittäjän</em></strong> sekä <strong><em>asiakkaan</em></strong> antamaa tietoa asunnon myyntiprosessin sujuvuudesta yhteenvetoihin sivustolla. Varmistamme välittäjän antaman tiedon kohteen hintapyynnöstä ja myyntitiedoista aina myös <strong><em>asiakkaalta</em></strong>. <br /><br />Asuntoarvio.fi:n ja välitysliikkeen välisessä <a href="/app/documents/Asuntoarvio-fi-sopimusehdot_230101.pdf" target="_blank" className="af-class-admin-modals-link-text">palvelusopimuksessa</a> velvoitetaan välitysliike antamaan oikea tieto toimeksiantosopimuksen mukaisesta asunnon hintapyynnöstä.</p>
                  <div className="w-form">
                    {map(proxies['af-form-modal-contract'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-modal-contract" name="wf-form-af-form-modal-contract" data-name="af-form-modal-contract" method="get" data-wf-page-id="5d9c6c5957629d84db83f5b6" data-wf-element-id="69c2508a-8f10-97a6-21f3-7f079a7a87ef" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="contractAt" className="af-class-field-label">Toimeksiantosopimuksen päivämäärä</label>{map(proxies['af-input-contract-at'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="contractAt" data-name="contractAt" placeholder="esim. 24.10.2019" type="number" id="contractAt" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}<label htmlFor="askingPrice" className="af-class-field-label">Kohteen velaton hintapyyntö (€)</label>{map(proxies['af-input-asking-price'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="askingPrice" step={1} data-name="askingPrice" pattern="\d+" data-focus-type="number" min={0} placeholder="esim. 250000" type="text" id="askingPrice" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}
                      <div className="af-class-div-block-admin-modal-checkbox w-clearfix">
                        {map(proxies['af-is-offer-sale-checkbox'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-69 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-checked"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-lkv-result-list-address af-class-margin-form af-class-margin-form-2">Kohde on "tarjouskauppa" tai muu ei-perinteinen myynti</div>
                      </div>{map(proxies['af-modal-contract-cancel-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Peruuta" {...{...props, className: `af-class-button-admin-modals-top w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Peruuta')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{map(proxies['af-modal-contract-confirm-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Vahvista hintapyyntö" {...{...props, className: `af-class-button-admin-modals w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Vahvista hintapyyntö')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                  <p className="af-class-admin-modals-small-text"><em>Asuntoarvio.fi käyttää hintatietoja ja -arvioita vain palvelun tuottamiseen ja kehittämiseen. Yksilöidyt hintatiedot eivät ole julkista tietoa, eikä niitä luovuteta kolmansille osapuolille.<br /></em></p>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-modal-fulfilled'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-modal-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-modal-content">
                <div className="af-class-div-block-16">
                  <div className="af-class-admin-modal-heading">Vahvista toteutunut asuntokauppa</div>
                  <p className="af-class-paragraph af-class-bottom-extra-margin">Asuntoarvio.fi käyttää <strong><em>välittäjän</em></strong> sekä <strong><em>asiakkaan</em></strong> tarjoamaa tietoa asunnon myyntiprosessin sujuvuudesta yhteenvetoihin sivustolla. Varmistamme välittäjän antaman tiedon kohteen kauppahinta- ja myyntitiedoista aina myös <strong><em>asiakkaalta</em></strong>. <br /><br />Asuntoarvio.fi:n ja välitysliikkeen välisessä <a href="/app/documents/Asuntoarvio-fi-sopimusehdot_230101.pdf" target="_blank" className="af-class-admin-modals-link-text">palvelusopimuksessa</a> velvoitetaan välitysliike antamaan oikea tieto toteutuneesta kauppahinnasta ja välityspalkkiosta.</p>
                  <div className="w-form">
                    {map(proxies['af-form-modal-fulfilled'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-modal-fulfilled" name="wf-form-af-form-modal-fulfilled" data-name="af-form-modal-fulfilled" method="get" data-wf-page-id="5d9c6c5957629d84db83f5b6" data-wf-element-id="dd6a10c7-5dc5-f555-822d-3b02edb12c5e" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="fulfilledAt" className="af-class-field-label">Asunnon kaupan päivämäärä</label>{map(proxies['af-input-fulfilled-at'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="fulfilledAt" data-name="fulfilledAt" placeholder="esim. 24.10.2019" type="number" id="fulfilledAt" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}<label htmlFor="sellingPrice" className="af-class-field-label">Toteutunut velaton kauppahinta (€)</label>{map(proxies['af-input-selling-price'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="sellingPrice" step={1} data-name="sellingPrice" pattern="\d+" data-focus-type="number" min={0} placeholder="esim. 230000" type="text" id="sellingPrice" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}<label htmlFor="commission" className="af-class-field-label">Toteutunut välityspalkkio (€) (sis. alv 24 %)</label>{map(proxies['af-input-commission'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="commission" step={1} data-name="commission" pattern="\d+" data-focus-type="number" min={0} placeholder="esim. 7500" type="text" id="commission" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}<label htmlFor="commissionReceipt" className="af-class-field-label">Palkkiokuitti PDF</label>{map(proxies['af-input-commission-receipt'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="commissionReceipt" data-name="commissionReceipt" placeholder type="text" id="commissionReceipt" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}
                      {map(proxies['af-link-agent-container'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="agentLink" className="af-class-field-label">Linkitä kaupan tehnyt kiinteistönvälittäjä kohteelle</label>{map(proxies['af-input-agent-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="agentLink" name="agentLink" data-name="agentLink" {...{...props, className: `af-class-input-field af-class-bottom-margin w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                          <option value>Valitse kiinteistönvälittäjä</option>
                        </React.Fragment>)}
                  </select>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}{map(proxies['af-modal-fulfilled-cancel-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Peruuta" {...{...props, className: `af-class-button-admin-modals-top w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Peruuta')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{map(proxies['af-modal-fulfilled-confirm-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Vahvista kauppa" {...{...props, className: `af-class-button-admin-modals-large w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Vahvista kauppa')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                  <p className="af-class-admin-modals-small-text"><em>Asuntoarvio.fi käyttää hintatietoja ja -arvioita vain palvelun tuottamiseen ja kehittämiseen. Yksilöidyt hintatiedot eivät ole julkista tietoa, eikä niitä luovuteta kolmansille osapuolille.<br /></em></p>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-modal-not-fulfilled'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-modal-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-modal-content">
                <div className="af-class-div-block-16">
                  <div className="af-class-admin-modal-heading">Vahvista päättynyt sopimus</div>
                  <p className="af-class-paragraph af-class-bottom-extra-margin">Haluatko varmasti hylätä myyntikohteen? Sinulla on voimassa oleva sopimus tästä kohteesta Asuntoarvio.fi:n kanssa myös kohteen hylkäyksen jälkeen. Varmistamme välittäjän antaman tiedon kohteen toteutumisesta aina myös <strong><em>asiakkaalta</em></strong>. <br /><br />Asuntoarvio.fi:n ja välitysliikkeen välisessä <a href="/app/documents/Asuntoarvio-fi-sopimusehdot_230101.pdf" target="_blank" className="af-class-admin-modals-link-text">palvelusopimuksessa</a> velvoitetaan välitysliike antamaan oikea tieto toteutuneesta kauppahinnasta ja välityspalkkiosta.</p>
                  <div className="w-form">
                    {map(proxies['af-form-modal-not-fulfilled'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-modal-not-fulfilled" name="wf-form-af-form-modal-not-fulfilled" data-name="af-form-modal-not-fulfilled" method="get" data-wf-page-id="5d9c6c5957629d84db83f5b6" data-wf-element-id="81ed60ac-6bef-25b7-5db1-6ad6e6fc5674" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="notFulfilledAt" className="af-class-field-label">Toimeksiantosopimuksen päättymispäivä</label>{map(proxies['af-input-not-fulfilled-at'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="notFulfilledAt" data-name="notFulfilledAt" placeholder="esim. 24.10.2019" type="number" id="notFulfilledAt" required {...{...props, className: `af-class-input-field af-class-bottom-margin w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}
                      {map(proxies['af-link-agent-container'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="reasonRejectedContract" className="af-class-field-label">Miksi toimeksiantosopimus on päättynyt?</label>{map(proxies['af-input-reason-rejected-contract'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="reasonRejectedContract" name="reasonRejectedContract" data-name="reasonRejectedContract" required {...{...props, className: `af-class-input-field af-class-bottom-margin w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                          <option value>Valitse syy päättymiselle</option>
                        </React.Fragment>)}
                  </select>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}{map(proxies['af-modal-not-fulfilled-cancel-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Peruuta" {...{...props, className: `af-class-button-admin-modals-top w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Peruuta')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{map(proxies['af-modal-not-fulfilled-confirm-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Vahvista" {...{...props, className: `af-class-button-admin-modals w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Vahvista')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                  <p className="af-class-admin-modals-small-text"><em>Asuntoarvio.fi käyttää hintatietoja ja -arvioita vain palvelun tuottamiseen ja kehittämiseen. Yksilöidyt hintatiedot eivät ole julkista tietoa, eikä niitä luovuteta kolmansille osapuolille.<br /></em></p>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-modal-claim-company'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-modal-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-modal-content">
                <div className="af-class-div-block-16">
                  <div className="af-class-admin-modal-heading">Hei kiinteistönvälittäjä!</div>
                  <p className="af-class-paragraph af-class-bottom-extra-margin">Onko "{map(proxies['af-marketing-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...props}>
                    {loader || (props.children ? props.children : <React.Fragment>Kiinteistönvälitys LKV</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}" sinun yrityksesi tai oletko oikeutettu hallitsemaan sille välitettäviä myyntikohteita? </p>
                  {map(proxies['af-require-login'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <p className="af-class-paragraph af-class-bottom-extra-margin af-class-claim-modal-paragraph">Voit rekisteröityä maksutta Asuntoarvio.fi -palveluun ja ottaa yrityksen profiilin haltuun. Yrityksen haltijana voit päivittää sivun tietoja sekä lisätä niitä edustavan profiilin aikaansaamiseksi. Viimeistellyt yritysprofiilit keräävät paremmin myyntikohteita asunnon myyntiä suunnittelevilta.<br /><br />Tervetuloa mukaan! Jos rekisteröitymisestä on kysyttävää, niin voit olla yhteydessä asiakaspalveluumme <a href="mailto:asiakaspalvelu@asuntoarvio.fi?subject=Yhteydenotto%20rekister%C3%B6itymislomakkeelta" className="af-class-claim-modal-email-link">asiakaspalvelu@asuntoarvio.fi</a>.</p>
                    <div>
                      {map(proxies['af-modal-cancel-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/rekisteroidy" {...{...props, className: `af-class-button-claim-modal-top w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Peruuta</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {map(proxies['af-modal-login-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/rekisteroidy" {...{...props, className: `af-class-button-claim-modal w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment> Rekisteröidy</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    </div>
                    <p className="af-class-admin-modals-small-text"><em>Seuraavan näkymän alareunasta löydät linkin: "Eikö sinulla ole käyttäjätiliä? Rekisteröidy</em><em>". Siirry rekisteröityminen-näkymään ja syötä yrityksen sähköpostiosoite sekä salasana. Rekisteröitymällä palveluun hyväksyt palvelun </em>
                      <a href="/app/documents/Asuntoarvio-fi-tietosuojakaytanto_191030.pdf" target="_blank" className="af-class-admin-modals-small-link"><em>tietosuojakäytännön</em></a><em>.<br /></em>
                    </p>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-authenticated'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="w-form">
                      {map(proxies['af-form-authenticated'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="5d9c6c5957629d84db83f5b6" data-wf-element-id="39dd8d03-eb46-4821-2e76-52fd6ed23822" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <p className="af-class-paragraph af-class-bottom-extra-margin af-class-claim-modal-paragraph">Vahvista toimipisteen linkitys käyttäjällesi "Ota haltuun" -painikkeella. Varmistamme jokaisen pyynnön erikseen ja saat vahvistuksen sähköpostiosoitteeseen {map(proxies['af-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...props}>
                    {loader || (props.children ? props.children : <React.Fragment>osoite@yritys.fi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}, kun olemme vahvistaneet pyyntösi 2-3 arkipäivän sisällä.</p>
                        {map(proxies['af-modal-cancel-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/rekisteroidy" {...{...props, className: `af-class-button-claim-modal-top w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Peruuta</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        {map(proxies['af-modal-claim-company-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-claim-modal w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Ota haltuun</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-modal-terms'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-modal-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-modal-content">
                <div className="af-class-div-block-16">
                  <div className="af-class-admin-modal-heading">Hei kiinteistönvälittäjä!</div>
                  <div>
                    <div className="w-form">
                      {map(proxies['af-form-terms'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="5d9c6c5957629d84db83f5b6" data-wf-element-id="a930a8ca-85cb-657e-2991-078c51b69145" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <p className="af-class-paragraph af-class-bottom-extra-margin af-class-claim-modal-paragraph">‍<strong>Olemme päivittäneet Asuntoarvio.fi -palvelua 1.1.2023<br /><br /></strong>Palvelun käyttö edellyttää sopimusehtojen ja tietosuojakäytännön hyväksymistä.<strong><br /><br /></strong>Jos sinulla on kysyttävää, niin voit olla yhteydessä asiakaspalveluumme <a href="mailto:asiakaspalvelu@asuntoarvio.fi?subject=Yhteydenotto%20rekister%C3%B6itymislomakkeelta" className="af-class-claim-modal-email-link">asiakaspalvelu@asuntoarvio.fi</a>.<em>‍</em>‍<br /></p>
                        <p className="af-class-paragraph af-class-bottom-extra-margin af-class-claim-modal-paragraph">Jatkamalla palvelun käyttöä hyväksyt <a href="/app/documents/Asuntoarvio-fi-sopimusehdot_230101.pdf" target="_blank" className="af-class-admin-modals-link-text">sopimusehdot </a>ja <a href="/app/documents/Asuntoarvio-fi-tietosuojakaytanto_230101.pdf" target="_blank" className="af-class-admin-modals-link-text">tietosuojakäytännön</a>.<br /></p>
                        {map(proxies['af-modal-reject-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/rekisteroidy" {...{...props, className: `af-class-button-claim-modal-top w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kirjaudu ulos</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        {map(proxies['af-modal-accept-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-claim-modal w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Hyväksyn ehdot</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-modal-review-login'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-modal-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-modal-content">
                <div className="af-class-div-block-16">
                  <div className="af-class-admin-modal-heading">Arvostele kiinteistönvälittäjä!</div>
                  <div>
                    <div className="w-form">
                      {map(proxies['af-form-reviews-login'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="5d9c6c5957629d84db83f5b6" data-wf-element-id="e2b10e8d-cb25-59c8-3dad-695b88bcd0c9" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <p className="af-class-paragraph af-class-bottom-extra-margin af-class-claim-modal-paragraph">Aloita tunnistautumalla Facebookilla tai Googlella. </p>
                        {map(proxies['af-modal-login-facebook-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-157 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                          {map(proxies['af-modal-login-faceook'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/login/facebook" {...{...props, className: `af-class-button-some w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kirjaudu Facebookilla<span className="af-class-icon-some"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                        {map(proxies['af-modal-login-google-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-158 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                          {map(proxies['af-modal-login-google'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/login/google" {...{...props, className: `af-class-button-some-right w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kirjaudu Googlella<span className="af-class-icon-some"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                        <p className="af-class-paragraph af-class-top-extra-margin-negative af-class-claim-modal-paragraph">
                          {map(proxies['af-exit-some-modal'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-admin-modals-link-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Siirry takaisin profiilisivulle</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        </p>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                  <p className="af-class-admin-modals-small-text"><em>Asuntoarvio kerää ensimmäisenä suomalaisena palveluna arvostelut kiinteistönvälittäjistä. Luotettavuuden ja läpinäkyvyyden vuoksi edellytämme tunnistautumista arvioinneissa. Arvion pitää perustua todelliseen toimeksiantosopimukseen tai asiakassuhteeseen asunnon myyjän tai ostajan ja kiinteistönvälittäjän välillä. Asiattomia arvioita ei julkaista palvelussa. Tunnistautumalla hyväksyt palvelun </em>
                    <a href="/app/documents/Asuntoarvio-fi-kayttoehdot_230101.pdf" target="_blank"><em className="af-class-text-link-small-review-page">käyttöehdot</em></a><em> ja </em>
                    <a href="/app/documents/Asuntoarvio-fi-tietosuojakaytanto_230101.pdf" target="_blank" className="af-class-text-link-small-review-page"><em>tietosuojakäytännön</em></a><em>.<br /></em>
                  </p>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAdminModalsView

/* eslint-enable */