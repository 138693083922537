import React from 'react';
import { connect } from 'react-redux';
import { userSelectors } from '../store/user';
import AfComponentFooterWhiteView from '../views/AfComponentFooterWhiteView';

const AfComponentFooterWhiteControllerBase: React.FC = () => <AfComponentFooterWhiteView />;

const mapStateToProps = (state: any) => ({
  isAuthenticated: userSelectors.isAuthenticated(state),
  isAdmin: userSelectors.isAdmin(state),
});

export const AfComponentFooterWhiteController = connect(
  mapStateToProps,
  {
  },
)(AfComponentFooterWhiteControllerBase);
