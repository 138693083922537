import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CompanyService } from '../services/company';
import { AgentService } from '../services/agent';
import { AskForOffer, AskForOfferState } from './AskForOffer';
import { Company } from '../company/Company.interface';

interface State extends AskForOfferState {
  company: Company;
}

interface TParams {
  companySlug: string;
  agentSlug: string;
}

export class AskForOfferFromCompany extends AskForOffer<RouteComponentProps<TParams>, State> {
  componentDidMount() {
    super.componentDidMount();
    this.loadCompanyDetails();
  }

  getFormName() {
    return 'AskForOfferFromCompany';
  }

  section() {
    const { section, formData: { askOffersFromAnyCompany, email } } = this.state;

    const inputs = super.section();

    if (section === 3) {
      inputs.push((
        <af-ask-offers-from-any-company-checkbox-wrapper>
          {this.renderCheckbox('askOffersFromAnyCompany')}
        </af-ask-offers-from-any-company-checkbox-wrapper>
      ));
    }

    if (section === 4 && !askOffersFromAnyCompany) {
      inputs[0] = <af-done-company><af-confirm-email>{email}</af-confirm-email></af-done-company>;
    }

    return inputs;
  }

  async loadCompanyDetails() {
    try {
      const { formData: offerRequest } = this.state;
      const { match } = this.props;
      const { companySlug, agentSlug } = match.params;

      const service = agentSlug ? new AgentService(agentSlug) : new CompanyService(companySlug);
      const result = await service.get();
      const company = agentSlug ? null : result;
      const agent = agentSlug ? result : null;

      this.setState({ company, agent, formData: {
        ...offerRequest,
        askOffersFromAnyCompany: true,
      }});
    } catch (error) {
      this.props.history.push('/kilpailutuspalvelu');
    }
  }
}
