import { action } from 'typesafe-actions';
import { Company } from '../../company/Company.interface';
import {
    SET,
    CLEAR,
} from './constants';

// CLASSIC API
export const set = (company: Company) => action(SET, company);
export const clear = () => action(CLEAR);

interface SetCompany {
    type: typeof SET;
    payload: Company;
}

interface ClearCompany {
    type: typeof CLEAR;
}

export type CompanyActionTypes = SetCompany | ClearCompany;
