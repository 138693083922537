import React from 'react';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Company } from '../company/Company.interface';
import { SearchService } from '../services/search';
import AfFindAgencyController from '../controllers/AfFindAgencyController';
import { BaseComponent, BaseState } from '../components/BaseComponent';
import { logger } from '../lib/debug';

const log = logger('Search');

interface State extends BaseState {
  searchValue: string;
  results: Company[];
  pageCount: number;
  totalItems: number;
  page: number;
  searchType: string;
  searchCounter: number;
}

interface TParams {
  search: string;
  searchType: string;
}

export class Search extends BaseComponent<RouteComponentProps<TParams>, State> {
  state = {
    results: [] as Company[],
    searchValue: '',
    pageCount: 0,
    totalItems: 0,
    page: 1,
    searchType: 'company',
    searchCounter: 0,
  } as State;

  componentDidMount() {
    super.componentDidMount();
    const { match } = this.props;
    const { search, searchType } = match.params;

    if (searchType === 'kiinteistonvalittaja') {
      this.setSearchType('agent');
    }

    this.setSearchValue(search || '');
  }

  componentWillUnmount() {
    this.setSearchValue.cancel();
  }

  setSearchType = (searchType: string) => {
    if (searchType === this.state.searchType) {
      return;
    }

    this.setState({ searchType, results: [] }, () => {
      this.doSearch(1);
    });
  }

  doSearch = async (page = 1) => {
    try {
      const { searchCounter } = this.state;
      const searchId = searchCounter + 1;
      const loadKey = page === 1 ? 'data' : 'page';
      this.startLoadingData(loadKey, { searchCounter: searchId });
      const { searchValue, results, searchType } = this.state;

      const service = new SearchService();
      const { items, meta: { totalPages: pageCount, totalItems } } = await service[searchType === 'agent' ? 'agent' : 'company']()
        .query({ search: searchValue, order: 'score', page })
        .get();

      if (searchId !== this.state.searchCounter) {
        return;
      }

      this.endLoadingData(loadKey, { results: page > 1 ? results.concat(items) : items, pageCount, totalItems, page });
    } catch (error) {
      log('search failure', error);
      toast.error('Uups, jotain meni pieleen ja haku epäonnistui. Yritä uudelleen.');
    }
  }

  onChange = (event: any) => {
    this.setSearchValue(event.target.value);
  }

  setSearchValue = debounce((searchValue) => {
    this.setState({ searchValue }, this.doSearch);
  }, 750);

  render() {
    const { results, searchValue, pageCount, totalItems, page, searchType } = this.state;

    return (
      <AfFindAgencyController
        results={results}
        page={page}
        totalItems={totalItems}
        pageCount={pageCount}
        searchType={searchType}
        setSearchType={this.setSearchType}
        setSearchValue={this.setSearchValue}
        searchValue={searchValue}
        nextPage={() => this.doSearch(page + 1)}
        dataLoader={this.renderLoading('data')}
        pageLoading={this.isLoadingData('page')}
        {...this.props}
      />
    );
  }
}
