import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { UserService } from '../services/user';
import { SearchService } from '../services/search';
import { UserCompany } from '../store/user/reducer';
import { Company } from '../company/Company.interface';
import { BaseComponent, BaseState } from '../components/BaseComponent';
import { userSelectors } from '../store/user';
import { AfAdminCompanyListController } from '../controllers/AfAdminCompanyListController';
import { CompanyStatus } from '../shared/types';

interface State extends BaseState {
  companies: UserCompany[];
  approved: boolean;
}

interface Props {
  isAdmin: boolean;
}

export class AdminCompanyList extends BaseComponent<RouteComponentProps & Props, State> {
  state = {
    companies: [],
    approved: false,
  } as State;

  async componentDidMount() {
    super.componentDidMount();
    this.load();
  }

  load = async (approved = this.state.approved) => {
    const { isAdmin, history } = this.props;

    try {
      let companies;
      if (isAdmin) {
        const service = new SearchService();
        ({ items: companies } = await service.company().query({
          limit: 100,
          statuses: [CompanyStatus.notApproved].join(','),
          ignoreCache: true,
          skipNoOffers: true,
        }).get());
      } else {
        const service = new UserService();
        ({ companies } = await service.me().get());
      }

      if (!isAdmin && companies.length === 1 && companies[0].acceptedAt && history.location.pathname === '/hallinta') {
        history.push(`/hallinta/${companies[0].company.id}/tarjouspyynnot/avoimet`);
      }

      this.setStateAfterLoad({
        companies,
        approved,
      });
    } catch (error) {
      toast.error('Jotain meni pieleen.');
    }
  }

  render() {
    const { companies, approved } = this.state;
    const { isAdmin } = this.props;

    const companyList = companies as unknown as Company[];
    const userCompanyList = companies as unknown as UserCompany[];

    return (
      <div>
        <AfAdminCompanyListController
          companies={isAdmin ? companyList : null}
          userCompanies={isAdmin ? null : userCompanyList}
          onLoad={this.load}
          approved={approved}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isAdmin: userSelectors.isAdmin(state),
});

export default connect(
  mapStateToProps,
  {
  },
)(AdminCompanyList);
