import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CompanyManagerService } from '../services/company-manager';
import { SearchService } from '../services/search';
import {
  OfferRequestWithCompanies,
  offerRequestContractTypes,
  getStatusState,
  OfferRequest,
  CompanyOfferRequest,
} from './offer-request';
import { OfferRequestDetailsBase, OfferRequestDetailsState, OfferRequestDetailsTParams } from './OfferRequestDetailsBase';
import { Company } from '../company/Company.interface';
import { Agent } from '../company/Agent.interface';
import { slugifyCity } from '../shared/helpers';

import './OfferRequestDetails.scss';
import { CompanyStatus } from '../shared/types';

interface TParams extends OfferRequestDetailsTParams {
  companyId: string;
}

interface State extends OfferRequestDetailsState {
  offerRequest: OfferRequestWithCompanies;
  searchValue?: string;
  searchResult: Company[];
  suggestions?: Company[];
  hasActiveSearch: boolean;
}

export class OfferRequestDetails extends OfferRequestDetailsBase<RouteComponentProps<TParams>, State> {
  get isLocked() {
    const { offerRequest } = this.state;

    return (offerRequest.targetCompany || offerRequest.targetAgent) && !offerRequest.askOffersFromAnyCompany;
  }

  async componentDidMount() {
    await super.componentDidMount();
    const { offerRequest } = this.state;

    if (!offerRequest || this.isLocked || !offerRequest.location) {
      return;
    }

    const { companyOfferRequests, city, zipCode } = offerRequest;
    const { coordinates } = offerRequest.location;

    const service = new SearchService();
    const { items: companies } = await service.company()
      .query({
        point: `${coordinates[0]} ${coordinates[1]}`,
        city,
        zipCode,
        order: 'score',
        limit: 20,
        ignoreCache: true,
        statuses: [CompanyStatus.approved].join(','),
      })
      .get();
    const currentCompanies = companyOfferRequests.map(({ company }) => company.id);
    currentCompanies.push(...this.getTargetCompanies().map(c => c.id));
    this.setState({ suggestions: companies.filter((company: Company) => !currentCompanies.includes(company.id)) });
  }

  getTargetCompanies() {
    const { offerRequest } = this.state;
    const { companyOfferRequests, targetCompany, targetAgent } = offerRequest;
    const targetCompanies = [];
    if (targetCompany) {
      targetCompanies.push(targetCompany);
    }
    if (targetAgent) {
      targetCompanies.push(...targetAgent.companies.map((c: any) => c.company));
    }

    return targetCompanies.filter((c: Company) => !companyOfferRequests.find(row => row.company.id === c.id));
  }

  handleSubmit = async (event: React.FormEvent) => {
    const { offerRequest, suggestions } = this.state;
    const { companyOfferRequests } = offerRequest;

    event.preventDefault();

    if (!this.state.searchValue) {
      return;
    }

    const currentCompanies = companyOfferRequests.map(({ company }) => company.id);
    currentCompanies.push(...this.getTargetCompanies().map(c => c.id));
    if (suggestions) {
      currentCompanies.push(...suggestions.map(c => c.id));
    }

    const service = new SearchService();
    const { items: companies } = await service.company()
      .query({
        search: this.state.searchValue,
        order: 'score',
        ignoreCache: true,
        statuses: [CompanyStatus.approved].join(','),
      })
      .get();
    const searchResult = companies.filter((company: Company) => !currentCompanies.includes(company.id));
    this.setState({ searchResult, hasActiveSearch: true });
  }

  onChange = (event: React.FormEvent) => {
    const searchValue: string = (event.target as HTMLInputElement).value;
    this.setState({ searchValue });
  }

  removeResult(id: number) {
    const { searchResult, suggestions } = this.state;
    const searchResultNew = searchResult ? searchResult.filter(company => !(company.id === id)) : [];
    this.setState({
      suggestions: suggestions ? suggestions.filter(c => c.id !== id) : undefined,
      searchResult: searchResultNew,
      hasActiveSearch: searchResultNew.length > 0,
    });
  }

  async attachCompany(company: Company, isTargetAgent = false) {
    try {
      const service = new CompanyManagerService(company.id);
      const result = await service.offerRequest().post({ offerId: this.offerId });
      this.removeResult(company.id);

      result.company = company;
      const { offerRequest } = this.state;
      const { companyOfferRequests } = offerRequest;
      companyOfferRequests.push(result);

      if (isTargetAgent) {
        const { targetAgent } = this.state.offerRequest;
        await service.offerRequest().child(result.offerRequest.id).child('users').post({ userId: targetAgent.id });
      }

      this.setState({ offerRequest: { ...offerRequest, companyOfferRequests } });
      toast.success('Linkitys onnistui');
    } catch (error) {
      toast.error('Linkitys epäonnistui');
    }
  }

  renderForm() {
    const { offerRequest } = this.state;
    const { companyOfferRequests } = offerRequest;

    if (this.isLocked) {
      return 'Asiakas ei halua tarjouksia muilta toimistoilta.';
    }

    if (companyOfferRequests.find(c => offerRequestContractTypes.includes(c.status))) {
      return 'Asiakkaalla on jo sopimus.';
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          <input type='submit' value='Etsi' />
          <input
            type='text'
            placeholder='id, y-tunnus tai nimi'
            minLength={3}
            required
            onChange={this.onChange}
          />
        </label>
      </form>
    );
  }

  renderCompanyName(company: Company, offerRequest: OfferRequest) {
    let name = company.name;
    if (company.marketingName !== name) {
      name += ` / ${company.marketingName}`;
    }

    return (
      <>
        <a href={`/hallinta/${company.id}/tarjouspyynto/${offerRequest.id}`} target='_blank' rel='noopener noreferrer'>ID: {company.id}</a> |
        {' '}
        <a href={`/kiinteistonvalitys/${slugifyCity(company.city)}/${company.slug}`} target='_blank' rel='noopener noreferrer'>{name}</a>
      </>
    );
  }

  renderAgentName(company: Company, agent: Agent) {
    const { firstName, lastName, id, slug } = agent;

    const name = `${firstName} ${lastName}`;

    return (
      <>
        <a href={`/kiinteistonvalittaja/profiili/${slug}`} target='_blank' rel='noopener noreferrer'>{name}</a>
        {' '}
        (<a href={`/hallinta/${company.id}/kayttajat/${id}`} target='_blank' rel='noopener noreferrer'>ID: {id}</a>)
      </>
    );
  }

  renderCompany = (company: Company, suggestion: boolean = false) => {
    const { offerRequest } = this.state;
    const { targetCompany, targetAgent } = offerRequest;
    const isTargetAgent = targetAgent && targetAgent.companies.find((c: any) => c.company.id === company.id);
    const isTarget = (targetCompany && company.id === targetCompany.id) || isTargetAgent;
    const prefix = isTarget ? 'PYYDETTY' : 'EHDOTUS';

    if (!this.isValidCompany(company)) {
      return null;
    }

    return (
      <div key={company.id} className='company-link-suggestion'>
        <div>
          {(isTarget || suggestion) && <b>{prefix}: </b>}
          {this.renderCompanyName(company, offerRequest)}
        </div>
        {isTargetAgent && (
          <div><b>Välittäjä</b>: {this.renderAgentName(company, targetAgent)}</div>
        )}
        <button className='af-class-button-offer-page w-clearfix w-button' onClick={() => this.attachCompany(company, isTargetAgent)}>
          Linkitä
        </button>
      </div>
    );
  }

  renderSuggestions() {
    const { suggestions } = this.state;

    if (!suggestions) {
      return;
    }

    return suggestions.map(c => this.renderCompany(c, true));
  }

  renderSearchResults() {
    const { searchResult, hasActiveSearch } = this.state;

    if (!searchResult) {
      return null;
    }

    if (!searchResult.length && hasActiveSearch) {
      return 'Ei tuloksia';
    }

    return searchResult.map(c => this.renderCompany(c));
  }

  renderTargetCompanyLinks() {
    const companies = this.getTargetCompanies();

    if (!companies.length) {
      return null;
    }

    return companies.map((company: Company) => this.renderCompany(company));
  }

  renderCompanies = (): null | JSX.Element => {
    const { offerRequest } = this.state;
    const { companyOfferRequests } = offerRequest;

    return (
      <div>
      {
        companyOfferRequests
          .map((row: CompanyOfferRequest) => (
            <div key={row.id} className='company-link-suggestion'>
              {this.renderCompanyName(row.company, offerRequest)}
              <div>
                Tila: <b>{getStatusState(row.status)}</b>
              </div>
            </div>
      ))}
      {this.renderTargetCompanyLinks()}
      {this.renderSuggestions()}
      {this.renderSearchResults()}
      {this.renderForm()}
      </div>
    );
  }

  private isValidCompany(company: Company): boolean {
    if (!company.hasMainUser) {
      return false;
    }
    return true;
  }
}
