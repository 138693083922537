import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AfAdminUserFormView from '../views/AfAdminUserFormView';
import { BaseController } from './BaseController';
import { UserProfile, UserCompany } from '../store/user/reducer';
import { Company } from '../company/Company.interface';

interface State {
  searchValue: string;
  searchDone: boolean;
}

interface Props {
  users: UserProfile[];
  onActionLink: (accept: boolean, userId: number, companyId: number) => void;
  onSearch: (searchValue: string|null) => Promise<void>;
}

export class AfAdminUserFormController extends BaseController<RouteComponentProps & Props, State> {
  state = {
    searchValue: '',
    searchDone: false,
  };

  searchUser = async (e: any) => {
    e.preventDefault();
    const { searchValue } = this.state;
    await this.props.onSearch(searchValue);
    this.setState({ searchDone: !!searchValue });
  }

  onActionLink = (accept: boolean, user: UserProfile, company: Company) => (e: any) => {
    const { onActionLink } = this.props;
    e.preventDefault();
    if (window.confirm(`Haluatko varmasti ${accept ? 'HYVÄKSYÄ' : 'HYLÄTÄ'} tämän linkityksen?\n\n${user.email} -> ${company.marketingName}`)) {
      onActionLink(accept, user.id, company.id);
    }
  }

  renderUserCompanyRow(user: UserProfile, userCompany: UserCompany) {
    const { company, acceptedAt } = userCompany;
    const { city, slug, marketingName } = company;

    return (
       <af-user-company-link key={`${user.id}${userCompany.id}`}>
        {this.afLink('user-row-company', { city, company: slug }, marketingName)}
        {!acceptedAt && <af-user-accept  onClick={this.onActionLink(true, user, company)} />}
        <af-user-reject onClick={this.onActionLink(false, user, company)}>{acceptedAt ? 'Poista' : 'Hylkää'}</af-user-reject>
      </af-user-company-link>
    );
  }

  renderUserRow = (user: UserProfile) => {
    const { id, email, firstName, lastName, companies } = user;

    let name = email;
    if (firstName && lastName) {
      name += ` (${firstName} ${lastName})`;
    }

    return (
      <af-user-row key={id}>
        <af-user-row-id>ID: {id}</af-user-row-id>
        {this.afLink('user-row-email', { user: id }, name)}
        {companies.map((userCompany: UserCompany) => this.renderUserCompanyRow(user, userCompany))}
      </af-user-row>
    );
  }

  render() {
    const { users } = this.props;
    const { searchDone } = this.state;

    return (
      <AfAdminUserFormView>
        {this.props.children}
        <af-user-list-wrapper>
          <af-form-user-search>
            <af-input-user-search onChange={(e: any) => this.setState({ searchValue: e.target.value })} />
            <af-user-search-btn onClick={this.searchUser} />
          </af-form-user-search>
          {users.length && users.map(this.renderUserRow)}
          {!users.length && searchDone && <af-user-row><af-user-row-id>Haku ei löytänyt yhtään käyttäjää</af-user-row-id></af-user-row>}
          {!users.length && !searchDone && <af-user-row><af-user-row-id>Ei hyväksyntää odottovia käyttäjiä</af-user-row-id></af-user-row>}
        </af-user-list-wrapper>
      </AfAdminUserFormView>
    );
  }
}
