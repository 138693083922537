import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { slugifyCity } from '../shared/helpers';
import AfAdminCompanyFormView from '../views/AfAdminCompanyFormView';
import { BaseController } from './BaseController';

interface Props {
  activeSection?: string;
  onSelectSection?: (section: string) => void;
  sections?: string[];
  slug: string;
  city: string;
  isNew: boolean;
}

export class AfAdminCompanyFormController extends BaseController<RouteComponentProps & Props> {
  renderSectionButton = (section: string) => {
    const { onSelectSection, activeSection } = this.props;
    const BtnTag = `af-show-${section}` as unknown as React.ComponentType<any>;

    return (
      <BtnTag
        key={section}
        className={activeSection === section ? 'af-class-button-company-form-tabs-active' : undefined}
        onClick={() => onSelectSection && onSelectSection(section)}
      />
    );
  }

  render() {
    const { sections, slug, city, isNew, activeSection } = this.props;

    return (
      <AfAdminCompanyFormView>
        {this.props.children}
        {sections && (
          <af-select-section>
            {!isNew && activeSection === 'company-info' && <af-public-link-wrapper>{this.afLink('public-link', {
              city,
              company: slug,
            }, `asuntoarvio.fi/kiinteistonvalitys/${slugifyCity(city)}/${slug}`)}</af-public-link-wrapper>}
            {sections.length > 1 && sections.map(this.renderSectionButton)}
          </af-select-section>
        )}
      </AfAdminCompanyFormView>
    );
  }
}
