import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { userSelectors } from '../store/user';
import { UserProfile } from '../store/user/reducer';
import { Company } from './Company.interface';
import { Form, FormState, FormInitialState } from '../components/Form';
import { AfAdminModalsController } from '../controllers/AfAdminModalsController';
import { AuthHelper } from '../AuthProvider';
import { logger } from '../lib/debug';

const log = logger('ClaimAction');

interface Props {
  isOpen: boolean;
  onCloseModal: (e: any) => void;
  isAuthenticated: boolean;
  profile: UserProfile|null;
  company: Company;
  onTakeOverConfirm: () => void;
}

export class ClaimAction extends Form<Props & AuthHelper, FormState> {
  state = {
    ...FormInitialState,
    section: 'authenticated',
  } as FormState;

  async saveformData(): Promise<boolean> {
    log('Do claim action');

    this.props.onTakeOverConfirm();
    return true;
  }

  section() {
    return [
      this.renderCheckbox('acceptTerms'),
    ];
  }

  renderFormChildren(): null | JSX.Element | JSX.Element[] {
    const { profile, onCloseModal } = this.props;
    const email = profile ? profile.email : '';

    return [
        <af-modal-claim-company-btn onClick={this.handleSubmit} key='submit' />,
        <af-modal-cancel-btn onClick={onCloseModal} key='cancel' />,
        <af-email key='email'>{email}</af-email>,
    ];
  }

  render() {
    const { isAuthenticated, company, profile, loginWithRedirect, isOpen, location, onCloseModal } = this.props;

    return (
      <Modal
        key='modal'
        isOpen={isOpen}
        onRequestClose={onCloseModal}
      >
        <AfAdminModalsController>
          <af-modal-claim-company>
            <af-marketing-name>{company.marketingName}</af-marketing-name>
            {!isAuthenticated && (
              <af-require-login>
                <af-modal-cancel-btn onClick={onCloseModal} />
                <af-modal-login-btn onClick={() => loginWithRedirect({ appState: { targetUrl: `${location.pathname}/claim` } })}/>
              </af-require-login>
            )}
            {isAuthenticated && profile && this.renderForm()}
          </af-modal-claim-company>
        </AfAdminModalsController>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: userSelectors.isAuthenticated(state),
  profile: userSelectors.getProfile(state),
});

export default connect(
  mapStateToProps,
  {
  },
)(ClaimAction);
