import Api from '../lib/api';

export class SearchService extends Api {
    constructor() {
        super('search');
    }

    company() {
        return this.child('company');
    }

    agent() {
        return this.child('agent');
    }
}
