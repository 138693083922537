import { UserState, UserCompany } from './reducer';
import { RootState } from '../rootReducer';

export const getUser = (state: RootState): UserState => state.user;

export const getProfile = (state: RootState) => getUser(state).profile;

export const isAdmin = (state: RootState): boolean => {
    const profile = getProfile(state);
    return !!(isAuthenticated(state)
        && profile
        && profile
        && profile.permissions
        && profile.permissions.includes('admin:all'));
};

export const isAuthenticated = (state: RootState): boolean => !!getUser(state).isAuthenticated;

export const getCompany = (state: RootState) => {
    const profile = getProfile(state);
    return (companyId: number): null | UserCompany => {
        if (!isAuthenticated(state) || !profile || !profile.companies || !profile.companies.length) {
            return null;
        }

        return profile.companies.find(c => Number(c.companyId) === Number(companyId)) || null;
    };
};

export const hasCompany = (state: RootState) => (companyId: number): boolean => !!getCompany(state)(companyId);
export const isCompanyMainUser = (state: RootState) => (companyId: number): boolean => {
    const company = getCompany(state)(companyId);
    return company && company.isMainUser ? true : false;
};

export const getAccessToken = (state: RootState): string|null => getUser(state).accessToken;
