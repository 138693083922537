import React from 'react';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import kebabCase from 'lodash/kebabCase';
import { forOwn, isEmpty, isNil, isString } from 'lodash';
import { logger } from '../lib/debug';
import { userSelectors } from '../store/user';
import { UserProfile } from '../store/user/reducer';
import {
  OfferRequest,
  OfferRequestStatus,
  OfferRequestWithCompany,
  Stats,
  offerRequestRejectedTypes,
  offerRequestContractTypes,
  getApartmentTypeName,
  getScheduleName,
  getStatusState,
  getStatusName,
  getStatusBySlug,
  getSlugByStatus,
  CompanyOfferRequest,
} from './offer-request';
import { OfferRequestService } from '../services/offer-request';
import { AfAdminOfferInfoTableController } from '../controllers/AfAdminOfferInfoTableController';
import { AfAdminDashboardController } from '../controllers/AfAdminDashboardController';
import { BaseComponent, BaseState } from '../components/BaseComponent';
import { formatDate, formatNumber } from '../lib/helpers';
import { StringSignatureString, OfferRequestCustomStatusFilter, OfferRequestNotesStatus } from '../shared/types';

import './styles.scss';
import './OfferRequestList.scss';

const log = logger('OfferRequestList');

const emptyPlaceholders: StringSignatureString = {
  [OfferRequestStatus.open]: 'Yrityksellä ei ole avoimia tarjouspyyntöjä',
  [OfferRequestStatus.contacted]: 'Yrityksellä ei ole avoimia tarjouspyyntöjä "avoimet asiakkaat" -tilassa',
  [OfferRequestStatus.contract]: 'Yrityksellä ei ole aktiivisia toimeksiantosopimuksia',
  [OfferRequestStatus.fulfilled]: 'Yrityksellä ei ole toteutuneita kauppoja',
  [OfferRequestStatus.notFulfilled]: 'Yrityksellä ei ole tarjouspyyntöjä "Ei toteutuneet" -tilassa',
  [OfferRequestStatus.rejected]: 'Yrityksellä ei ole avoimia tarjouspyyntöjä',
  [OfferRequestStatus.lost]: 'Yrityksellä ei ole avoimia tarjouspyyntöjä',
};

const showCustomStatusForStates = new Set<OfferRequestStatus>([
  OfferRequestStatus.contract,
  OfferRequestStatus.fulfilled,
]);

export interface NotesData {
  notes?: string;
  notesStatus?: OfferRequestNotesStatus;
}

export interface OfferRequestListState extends BaseState {
  isSuperAdminView: boolean;
  offerRequests: OfferRequest|OfferRequestWithCompany[];
  status: OfferRequestStatus;
  stats: Stats;
  page: number;
  pageCount: number;
  customStatusFilter: OfferRequestCustomStatusFilter;
  textSearch: string;
  isTextSearchSet: boolean;
  notesByCompanyOfferRequest: {
    [key: string]: NotesData
  };
}

export interface OfferRequestListTParams {
  status?: string;
}

interface Props {
  currentUser: UserProfile;
  isAdmin: boolean;
}

export class OfferRequestList<T extends {}, S extends OfferRequestListState>
  extends BaseComponent<Props & RouteComponentProps<OfferRequestListTParams> & T, S> {
  state = {
    isSuperAdminView: false,
    offerRequests: [],
    status: OfferRequestStatus.open,
    stats: {} as Stats,
    page: 1,
    pageCount: 1,
    customStatusFilter: OfferRequestCustomStatusFilter.NoFiltering,
    textSearch: '',
    isTextSearchSet: false,
    notesByCompanyOfferRequest: {},
  } as OfferRequestListState as S;

  private textSearchRef;
  private textSearchDelay: NodeJS.Timeout | undefined;

  constructor(props: any) {
    super(props);
    this.textSearchRef = React.createRef<HTMLInputElement>();
  }

  get api(): any {
    return new OfferRequestService();
  }

  statsApi() {
    return this.api.stats();
  }

  async load(
    status: OfferRequestStatus = OfferRequestStatus.open,
    customStatusFilter: OfferRequestCustomStatusFilter = this.state.customStatusFilter,
    initial = false
  ) {
    const { status: prevStatus, textSearch, isTextSearchSet } = this.state;

    this.startLoadingData('data', { status }, () => {
      if (prevStatus !== this.state.status && !initial) {
        const { history, match } = this.props;
        let url;
        if (isTextSearchSet) {
          if (match.url.endsWith(getSlugByStatus(prevStatus))) {
            url = match.url.replace(getSlugByStatus(prevStatus), '');
          } else {
            url = match.url;
          }
          url = url.replace(/\/$/, '');
        } else {
          if (match.url.endsWith(getSlugByStatus(prevStatus))) {
            url = match.url.replace(getSlugByStatus(prevStatus), getSlugByStatus(this.state.status));
          } else {
            url = `${match.url}/${getSlugByStatus(this.state.status)}`;
          }
        }

        history.push(url);
      }
    });

    const page = 1;
    try {
      const promises = isTextSearchSet
        ? [
          this.api.offerRequest().query({ page, customStatusFilter, textSearch }).get(),
          Promise.resolve({}),
        ]
        : [
          this.api.offerRequest().query({ status, page, customStatusFilter }).get(),
          this.statsApi().get(),
        ];

      const [ offerRequests, stats ] = await Promise.all(promises);
      const { items, meta: { totalPages: pageCount } } = offerRequests;
      this.endLoadingData('data', { offerRequests: items, stats, pageCount, page });
    } catch (error) {
      log('error', error);
      toast.error('Virhe haettaessa tietoja palvelimelta.');
    }
  }

  componentDidMount() {
    super.componentDidMount();
    const status = this.props.match.params.status ? getStatusBySlug(this.props.match.params.status) : null;
    this.load(status || OfferRequestStatus.open, OfferRequestCustomStatusFilter.NoFiltering, true);
    this.setState({ isSuperAdminView: true });
  }

  endLoadingData(key: string, otherState: any = {}, cb: any = null) {
    const state = otherState;
    if (key === 'data') {
      const notesByCompanyOfferRequest = state.notesByCompanyOfferRequest ?? {};
      const initNotes = this.getNotesState(state.offerRequests);
      forOwn(initNotes, (value, key) => {
        if (!notesByCompanyOfferRequest[key]) {
          notesByCompanyOfferRequest[key] = value;
        }
      });
      state.notesByCompanyOfferRequest = notesByCompanyOfferRequest;
    }
    super.endLoadingData(key, state, cb);
  }

  goToPage = async (page: number) => {
    const { status } = this.state;
    try {
      const offerRequests = await this.api.offerRequest().query({ status, page }).get();
      const { items, meta: { totalPages: pageCount } } = offerRequests;
      this.setState({ offerRequests: items, pageCount, page });
    } catch (error) {
      toast.error('Virhe haettaessa tietoja palvelimelta.');
    }
  }

  linkToCompany(row: any): string {
    return `/hallinta/${row.company.id}/tarjouspyynnot/${getSlugByStatus(this.state.status)}`;
  }

  linkToCompanyOfferRequest(row: any): string {
    return `/hallinta/${row.company.id}/tarjouspyynto/${row.offerRequestId}`;
  }

  linkToOffer(offerRequest: OfferRequest) {
    return `/admin/tarjouspyynto/${offerRequest.id}`;
  }

  goToOfferDetail(offerRequest: OfferRequest, event: Event) {
    const { history } = this.props;
    event.preventDefault();
    event.stopPropagation();
    history.push(this.linkToOffer(offerRequest));
  }

  async doReclamation(row: CompanyOfferRequest, freeOfferRequest: boolean) {
    await this.api.child(`${row.id}/do-reclamation`).patch({ freeOfferRequest });
    this.load(this.state.status);
  }

  renderReclamationButton(row: CompanyOfferRequest): null | JSX.Element {
    const isReclamationAlwaysPossible = [
      OfferRequestStatus.contacted,
      OfferRequestStatus.contract,
      OfferRequestStatus.lost,
    ].includes(row?.status);
    const isRejectedAfterwards = row?.status === OfferRequestStatus.rejected && !isEmpty(row?.contactedAt);
    return isReclamationAlwaysPossible || isRejectedAfterwards
      ? <span>
        <button type="button" className="reclamation-button" onClick={() => this.doReclamation(row, true)}>Reklamaatio vapauta</button>
        <button type="button" className="reclamation-button" onClick={() => this.doReclamation(row, false)}>Reklamaatio</button>
      </span>
      : null;
  }

  getRowClassName(row: CompanyOfferRequest): string {
    const hasContactedAt = isString(row.contactedAt) && !isEmpty(row.contactedAt);
    switch (row.status) {
      case OfferRequestStatus.rejected:
        return hasContactedAt ? 'row-rejected has-contacted-at' : 'row-rejected';
      default:
        return `row-${kebabCase(row.status)}`;
    }
  }

  renderAction(offerRequest: OfferRequest): null | JSX.Element {
    if (!offerRequest.companyOfferRequests) {
      return null;
    }

    return (
      <af-action-wrapper>
        {!offerRequest.companyOfferRequests.length && (
          <af-action-info-wrapper>
            <af-action-info><b>Ei linkitettyjä yrityksiä</b></af-action-info>
          </af-action-info-wrapper>
        )}
        {
          offerRequest.companyOfferRequests.length &&
          offerRequest.companyOfferRequests.map(row => (
            <af-action-info-wrapper key={row.id}>
              <af-action-info className={this.getRowClassName(row)}>
                <a href={this.linkToCompanyOfferRequest(row)} target="_blank" rel="noopener noreferrer">ID: {row.company.id}</a>
                <span className="link-separator"> | </span>
                <a href={this.linkToCompany(row)} target="_blank" rel="noopener noreferrer">{row.company.name}</a>
                {', '}
                {getStatusState(row.status)}
                {this.renderReclamationButton(row as CompanyOfferRequest)}
              </af-action-info>
            </af-action-info-wrapper>
        ))}
      </af-action-wrapper>
    );
  }

  onFilter = (status: OfferRequestStatus, event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    return this.load(status);
  };

  onPriceCopy(event: React.ClipboardEvent<HTMLSpanElement>) {
    // Remove spaces when copying a price
    const selection = window.getSelection();
    const selectedText = selection ? selection.toString() : '';
    navigator.clipboard.writeText(selectedText.replace(/ | /g, ''));
  }

  onSelectAllClick(event: React.SyntheticEvent<HTMLSpanElement>) {
    // Select all text in the target element (using CSS for this breaks the ClipboardEvent)
    const selection = window.getSelection();
    if (selection) {
      const range = document.createRange();
      range.selectNodeContents(event.target as Node);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  async onCustomStatusChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const id = event.target.getAttribute('data-id');
    const customStatus = event.target.value !== 'null' ? event.target.value : null;
    await this.api.child(`${id}/custom-status/${customStatus}`).patch();
    this.load(this.state.status);
  }

  onCustomStatusFilterChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const customStatusFilter = event.target.value as OfferRequestCustomStatusFilter;
    this.setState({ customStatusFilter });
    this.load(this.state.status, customStatusFilter);
  }

  onTextSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    clearTimeout(this.textSearchDelay);
    this.textSearchDelay = setTimeout(() => {
      this.setState({
        textSearch: event.target.value || '',
        isTextSearchSet: isString(event.target.value) && !isEmpty(event.target.value),
      });
      this.load();
    }, 1000);
  }

  clearTextSearch() {
    if (this.textSearchRef.current) {
      this.textSearchRef.current.value = '';
    }
    this.setState({ textSearch: '', isTextSearchSet: false, customStatusFilter: OfferRequestCustomStatusFilter.NoFiltering });
    setTimeout(() => this.load());
  }

  renderRow = (offerRequest: OfferRequest) => {
    const { isSuperAdminView, isTextSearchSet } = this.state;
    const { isAdmin } = this.props;

    const {
      id,
      createdAt,
      name,
      email,
      phone,
      type,
      numberOfRooms,
      address,
      addressExtra,
      zipCode,
      city,
      schedule,
      customStatus,
    } = offerRequest;

    const contactedAt = this.pickUseableValue('contactedAt', offerRequest) as Date;
    const contractAt = this.pickUseableValue('contractAt', offerRequest) as Date;
    const fulfilledAt = this.pickUseableValue('fulfilledAt', offerRequest) as Date;
    const notFulfilledAt = this.pickUseableValue('notFulfilledAt', offerRequest) as Date;
    const rejectedAt = this.pickUseableValue('rejectedAt', offerRequest) as Date;
    const lostAt = this.pickUseableValue('lostAt', offerRequest) as Date;
    const askingPrice = this.pickUseableValue('askingPrice', offerRequest) as number;
    const sellingPrice = this.pickUseableValue('sellingPrice', offerRequest) as number;
    const commission = this.pickUseableValue('commission', offerRequest) as number;
    const commissionReceiptUrl = this.pickUseableValue('commissionReceiptUrl', offerRequest) as string;

    // Add color coding for super admin view only
    let rowClassName = '';
    if (this.shouldShowCustomStatus(offerRequest)) {
      switch (customStatus) {
        case 'fulfilled':
          rowClassName = 'custom-status-fulfilled';
          break;
        case 'notFulfilled':
          rowClassName = 'custom-status-not-fulfilled';
          break;
      }
    }

    const shouldShowCommissionReceiptLink = isTextSearchSet || this.state.status === OfferRequestStatus.fulfilled;
    let openCommissionReceiptLink = null;
    if (isSuperAdminView && shouldShowCommissionReceiptLink && commissionReceiptUrl) {
      openCommissionReceiptLink = <af-commission-receipt-link className="af-commission-receipt-link">
        <a type="button" className="af-class-button" href={commissionReceiptUrl} target="_blank" rel="noopener noreferrer">Palkkiokuitti PDF</a>
      </af-commission-receipt-link>;
    }

    let companyOfferRequestLink = null;
    if (!isSuperAdminView && isAdmin) {
      companyOfferRequestLink = <div>
        <a href={`/admin/tarjouspyynto/${offerRequest.id}`} target="_blank" rel="noopener noreferrer">ID: {offerRequest.id}</a>
      </div>;
    }

    return (
      <af-row key={id} className={rowClassName}>
        <af-date>
          {formatDate(createdAt)}
          {contactedAt && <div>Kontaktoitu: {formatDate(contactedAt)}</div>}
          {contractAt && <div>Sopimus: {formatDate(contractAt)}</div>}
          {rejectedAt && <div>Hylätty: {formatDate(rejectedAt)}</div>}
          {fulfilledAt && <div>Toteutunut: {formatDate(fulfilledAt)}</div>}
          {notFulfilledAt && <div>Suljettu: {formatDate(notFulfilledAt)}</div>}
          {lostAt && <div>Peruttu: {formatDate(lostAt)}</div>}
        </af-date>
        <af-name>{name ? name : '******'}</af-name>
        {email || phone ? <af-contact><span onClick={this.onSelectAllClick}>{email}</span><br />{phone}</af-contact> : <af-contact>******</af-contact>}
        <af-address>
          <div>
            {getApartmentTypeName(type)}
            {numberOfRooms && ` ${numberOfRooms}h`}
          </div>
          <div onClick={this.onSelectAllClick}>{`${address} ${addressExtra || ''}`.trim()}</div>
          <div>{zipCode}, {city}</div>
        </af-address>
        <af-info-button onClick={(event: any) => this.goToOfferDetail(offerRequest, event)} href={this.linkToOffer(offerRequest)} />
        {openCommissionReceiptLink}
        <af-info>
          {companyOfferRequestLink}
          <div>Myyntiaikataulu: {getScheduleName(schedule)}</div>
          {askingPrice && <div>Hintapyyntö: <span onClick={this.onSelectAllClick} onCopy={this.onPriceCopy}>{formatNumber(askingPrice)}</span> €</div>}
          {sellingPrice && <div>Kauppahinta: <span onClick={this.onSelectAllClick} onCopy={this.onPriceCopy}>{formatNumber(sellingPrice)}</span> €</div>}
          {commission && <div>Komissio: <span onClick={this.onSelectAllClick} onCopy={this.onPriceCopy}>{formatNumber(commission)}</span> €</div>}
        </af-info>
        {this.renderAction(offerRequest)}
        {this.renderCustomStatusSelector(offerRequest)}
      </af-row>
    );
  }

  renderStateButton = (filter: OfferRequestStatus) => {
    const { status, stats } = this.state;

    // Add "reclamation" tab only in super admin view
    if (filter === OfferRequestStatus.reclamation && !this.state.isSuperAdminView) {
      return null;
    }

    const ButtonTag = `af-state-${kebabCase(filter)}` as unknown as React.ComponentType<any>;
    const isRejected = offerRequestRejectedTypes.includes(filter);

    let cnt = undefined as any;
    if (stats) {
      cnt = isRejected
        ? offerRequestRejectedTypes.reduce((a, b) => Number(stats?.[`${b}Count`]) + a, 0)
        : stats?.[`${filter}Count`];

      if (isRejected && stats?.totalRejectedCount) {
        cnt = stats?.totalRejectedCount;
      }
    }

    return (
      <ButtonTag
        key={filter}
        name={filter}
        onClick={(event: any) => this.onFilter(filter, event)}
        href={this.getStatusButtonHref(filter)}
        className={filter === status || (isRejected && offerRequestRejectedTypes.includes(status)) ? 'w--current' : undefined}
      >
        <af-state-text>{`${getStatusName(filter)} (${cnt})`}</af-state-text>
      </ButtonTag>
    );
  }

  renderTableData() {
    const { offerRequests, page, pageCount, status } = this.state;
    const loading = this.renderLoading('data') || (offerRequests && !offerRequests.length ? emptyPlaceholders[status] : null);
    const dataTable = [
      <af-data-wrapper key="1" data-loader={loading}>
        {offerRequests.map(this.renderRow)}
      </af-data-wrapper>
    ];

    if (pageCount <= 1) {
      return dataTable;
    }

    if (page > 1) {
      dataTable.push(<af-link-prev key="2" onClick={() => this.goToPage(page - 1)} />);
    }

    dataTable.push(...Array.from(Array(pageCount)).map((_0, n) => (
      <af-link-page
        key={n + 4}
        onClick={() => this.goToPage(n + 1)}
        style={{ fontWeight: page === n + 1 ? 'bold' : 'normal' }}
      >
        {n + 1}
      </af-link-page>
    )));

    if (page < pageCount) {
      dataTable.push(<af-link-next key="3" onClick={() => this.goToPage(page + 1)} />);
    }

    return dataTable;
  }

  renderStats() {
    const { stats } = this.state;

    if (!stats) {
      return null;
    }

    const {
      totalCommission,
      totalFulfilled,
      openCount,
      numberOfStarsAvg,
    } = stats;

    return (
      <AfAdminDashboardController
        openCount={openCount}
        totalFulfilled={totalFulfilled}
        totalCommission={totalCommission}
        numberOfStarsAvg={numberOfStarsAvg}
      />
    );
  }

  renderCustomStatusSelector(offerRequest: OfferRequest): null | JSX.Element {
    if (!this.shouldShowCustomStatus(offerRequest)) {
      return null;
    }
    const currentCustomStatus = offerRequest.customStatus || 'null';
    return (
      <af-custom-status-selector className="af-class-custom-status-selector">
        <select value={currentCustomStatus} data-id={offerRequest.id} onChange={event => this.onCustomStatusChange(event)}>
          <option value="null">-</option>
          <option value="fulfilled">Toimeksiantosopimus merkitty</option>
          <option value="notFulfilled">Kauppa merkitty</option>
        </select>
      </af-custom-status-selector>
    );
  }

  renderAdminTools(): null | JSX.Element {
    if (!this.state.isSuperAdminView) {
      return null;
    }
    return <af-admin-tools className='af-admin-tools'>
      {this.renderTextSearch()}
      {this.renderCustomStatusFiltering()}
    </af-admin-tools>;
  }

  renderTextSearch(): null | JSX.Element {
    if (!this.state.isSuperAdminView) {
      return null;
    }
    return (
      <div className="af-class-text-search">
        <input type="text" ref={this.textSearchRef} onChange={event => this.onTextSearchChange(event)} className="af-class-input-field w-input text-search" />
        <button type="button" onClick={() => this.clearTextSearch()} className="clear-text-search-btn">
          <span className='clear-text-search-icon'></span>
        </button>
      </div>
    );
  }

  renderCustomStatusFiltering(): null | JSX.Element {
    if (!this.shouldShowCustomStatus() && !this.state.isTextSearchSet) {
      return null;
    }
    return (
      <div className="af-class-custom-status-filtering">
        <select value={this.state.customStatusFilter} onChange={event => this.onCustomStatusFilterChange(event)}>
          <option value="noFiltering">Ei suodatusta</option>
          <option value="withoutStatus">Näytä: Ilman statusta</option>
          <option value="fulfilled">Näytä: Toimeksiantosopimus merkitty</option>
          <option value="notFulfilled">Näytä: Kauppa merkitty</option>
        </select>
      </div>
    );
  }

  render(): null | JSX.Element {
    const { page, pageCount, isTextSearchSet } = this.state;

    if (this.isLoading) {
      return this.renderLoading();
    }

    return (
      <div className={ isTextSearchSet ? 'is-text-search-active' : '' }>
        {this.renderStats()}
        <AfAdminOfferInfoTableController
          page={page}
          pageCount={pageCount}
          goToPage={this.goToPage}
          {...this.props}
        >
          {isTextSearchSet ? null : Object.values(OfferRequestStatus).map(this.renderStateButton)}
          {this.renderAdminTools()}
          {this.renderTableData()}
        </AfAdminOfferInfoTableController>
      </div>
    );
  }

  private shouldShowCustomStatus(offerRequest?: OfferRequest): boolean {
    const { isSuperAdminView, isTextSearchSet } = this.state;
    const showSelector = isTextSearchSet
      ? (offerRequest?.companyOfferRequests ?? []).some(item => showCustomStatusForStates.has(item.status))
      : showCustomStatusForStates.has(this.state.status);
    return isSuperAdminView && showSelector;
  }

  private getNotesState(offerRequests: OfferRequest | OfferRequestWithCompany[]) {
    const notesState: OfferRequestListState['notesByCompanyOfferRequest'] = {
      null: {
        notes: '',
        notesStatus: OfferRequestNotesStatus.notContacted,
      }
    };
    const items = Number.isFinite(offerRequests.length) ? offerRequests : [offerRequests];
    items.forEach((offerRequest: OfferRequest) => {
      const companyOfferRequest = offerRequest.companyOfferRequest;
      if (companyOfferRequest) {
        const key = `${companyOfferRequest.id}`;
        notesState[key] = {
          notes: companyOfferRequest.notes || '',
          notesStatus: companyOfferRequest.notesStatus || OfferRequestNotesStatus.notContacted,
        };
      }
    });
    return notesState;
  }

  private pickUseableValue(key: string, offerRequest: OfferRequest): null | number | string | Date {
    const { status, isSuperAdminView, isTextSearchSet } = this.state;

    if (isSuperAdminView && isTextSearchSet) {
      return (offerRequest?.companyOfferRequests ?? []).find(item => !isNil(item[key]))?.[key] ?? null;
    } else {
      let { companyOfferRequest, companyOfferRequests } = offerRequest;

      if (!companyOfferRequest && companyOfferRequests && offerRequestContractTypes.includes(status)) {
        companyOfferRequest = companyOfferRequests.find((cor: CompanyOfferRequest)  => cor.status === status);
      }

      return companyOfferRequest?.[key] ?? null;
    }
  }

  private getStatusButtonHref(status: OfferRequestStatus): string {
    const { isSuperAdminView } = this.state;
    const { location } = this.props;
    let companyId;
    if (!isSuperAdminView) {
      const matches = location.pathname.match(/\/hallinta\/(\d+)\//);
      if (!Array.isArray(matches) || matches.length !== 2) {
        return '#';
      }
      companyId = parseInt(matches[1], 10);
    }
    return isSuperAdminView
      ? `/admin/tarjouspyynnot/${getSlugByStatus(status)}`
      : `/hallinta/${companyId}/tarjouspyynnot/${getSlugByStatus(status)}`;
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: userSelectors.getProfile(state),
  isAdmin: userSelectors.isAdmin(state),
});

export default connect(
  mapStateToProps,
  {
  },
)(OfferRequestList);
