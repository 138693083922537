/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAdminDashboardView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAdminDashboardController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAdminDashboardView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAdminDashboardView ? transformProxies(this.props.children) : {
      'af-open-count': [],
      'af-total-commission': [],
      'af-total-fulfilled': [],
      'af-stars-average': [],
      'af-announcement-wrapper': [],
      'af-announcement-close': [],
      'af-announcement-title': [],
      'af-announcement-message': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-section">
              <div className="af-class-div-block-9 w-clearfix">
                <h1 className="af-class-admin-h1">Yhteenveto</h1>
                <div className="af-class-div-block-4 w-clearfix">
                  <div className="af-class-dashboard-key-figures">
                    <div className="af-class-dashborad-overview-icon"></div>
                    {map(proxies['af-open-count'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>14 kpl</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    <div className="af-class-text-block-3">Avointa tarjouspyyntöä</div>
                  </div>
                </div>
                <div className="af-class-div-block-4 w-clearfix">
                  <div className="af-class-dashboard-key-figures">
                    <div className="af-class-dashborad-overview-icon"></div>
                    {map(proxies['af-total-commission'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>123 000 €</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    <div className="af-class-text-block-3">Välityspalkkiot 12 kk</div>
                  </div>
                </div>
                <div className="af-class-div-block-4 w-clearfix">
                  <div className="af-class-dashboard-key-figures">
                    <div className="af-class-dashborad-overview-icon"></div>
                    {map(proxies['af-total-fulfilled'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>18 kpl</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    <div className="af-class-text-block-3">Toteutuneet kaupat</div>
                  </div>
                </div>
                <div className="af-class-div-block-4 w-clearfix">
                  <div className="af-class-dashboard-key-figures">
                    <div className="af-class-dashborad-overview-icon"></div>
                    {map(proxies['af-stars-average'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>4,9</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    <div className="af-class-text-block-3">Asiakastyytyväisyys</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section w-clearfix">
              {map(proxies['af-announcement-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-5 w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                {map(proxies['af-announcement-close'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-5 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                {map(proxies['af-announcement-title'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-4 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Lorem ipsum dolor</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                {map(proxies['af-announcement-message'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-paragraph ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Viestikenttä, jossa ajankohtaisia asioita ja mahdollisia pyyntöjäkäydä varmistamassa tarjouspyyntöjen status</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
              {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAdminDashboardView

/* eslint-enable */