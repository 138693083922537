import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { CompanyService } from '../services/company';
import { UserService } from '../services/user';
import { Company } from './Company.interface';
import { userSelectors } from '../store/user';
import { AfAgencyPageController } from '../controllers/AfAgencyPageController';
import { Review } from './Review.interface';
import { BaseComponent, BaseState } from '../components/BaseComponent';
import { AuthHelper } from '../AuthProvider';
import { UserProfile } from '../store/user/reducer';
import ClaimAction from './ClaimAction';
import ReviewLogin from './ReviewLogin';

interface State extends BaseState {
  company: Company;
  reviews: Review[];
  reviewPage: number;
  reviewPageCount: number;
  reviewsCount: number;
  modalIsOpen: boolean;
  openLoginModal: boolean;
}

interface TParams {
  companySlug: string;
  claim?: string;
}

interface Props {
  isAuthenticated: boolean;
  hasCompany: (companyId: number) => boolean;
  isAdmin: boolean;
  profile: UserProfile | null;
}

export class CompanyDetails extends BaseComponent<RouteComponentProps<TParams> & Props & AuthHelper, State> {
  state = {
    reviewPage: 1,
  } as State;

  componentDidMount() {
    super.componentDidMount();
    this.loadCompanyDetails();

    const { match, history, location } = this.props;
    if (match.params.claim) {
      if (match.params.claim === 'claim') {
        this.setState({ modalIsOpen: true });
      }

      history.replace(location.pathname.replace(/\/[^/]+$/, ''));
    }
    if (!!location.state && !!(location as any).state.openLoginModal && (location as any).state.openLoginModal) {
      this.setState({
        openLoginModal: true,
      });
    }
  }

  async loadCompanyDetails() {
    const { match, history } = this.props;
    const { companySlug } = match.params;

    try {
      const service = new CompanyService(companySlug);
      const [company, reviewsPage] = await Promise.all([
        service.get(),
        service.review().get(),
      ]);

      const { items: reviews, meta: { totalItems: reviewsCount, totalPages: reviewPageCount } } = reviewsPage;

      this.setStateAfterLoad({ company, reviews, reviewsCount, reviewPageCount });
    } catch (err) {
      console.error('Failed to load company details', err);
      history.push('/kiinteistonvalitys/');
    }
  }

  loadReviewsNextPage = async () => {
    const { match } = this.props;
    const { companySlug } = match.params;

    this.startLoadingData('page');

    const { reviewPage, reviews } = this.state;

    const page = reviewPage + 1;
    const service = new CompanyService(companySlug);
    const { items, meta: { totalItems: reviewsCount, totalPages: reviewPageCount } } = await service.review().query({ page }).get();

    this.endLoadingData('page', { reviews: reviews.concat(items), reviewsCount, reviewPageCount, reviewPage: page });
  }

  onTakeOver = async (e: any) => {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  }

  onTakeOverConfirm = async () => {
    const { history } = this.props;

    try {
      const { company } = this.state;
      const service = new UserService();
      await service.companyTakeOver(company.id);
      toast.success('Pyyntö lähetetty');
      this.onCloseModal();
      history.push('/hallinta/asetukset', { claimed: company.id });
    } catch (error) {
      toast.error('Toiminto epäonnistui.');
    }
  }

  onCloseModal = (e: any = null) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ modalIsOpen: false });
  }

  onCloseModalLogin = (e: any = null) => {
    const { location, history } = this.props;
    const redirect = location.pathname;
    if (!!location.state && !!(location as any).state.openLoginModal && (location as any).state.openLoginModal) {
      history.push(redirect, {openLoginModal: false});
    }
    if (e) {
      e.preventDefault();
    }
    this.setStateAfterLoad({ openLoginModal: false });
  }

  onReviewClick = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setStateAfterLoad({
      openLoginModal: true,
    });
  }

  onSocialLogin = (e: any) => {
    const { company } = this.state;
    const { history } = this.props;

    history.push(`/kiinteistonvalitys/${company.city}/${company.slug}/arvostele/`, e);
  }

  render() {
    const { company, reviews, reviewPage, reviewPageCount, reviewsCount, modalIsOpen, openLoginModal } = this.state;
    const { isAdmin } = this.props;

    if (this.isLoading) {
      return this.renderLoading();
    }

    return (
      <>
        <AfAgencyPageController
          key='page'
          company={company}
          reviews={reviews}
          onTakeOver={this.onTakeOver}
          reviewPage={reviewPage}
          reviewPageCount={reviewPageCount}
          reviewsCount={reviewsCount}
          loadReviewsNextPage={this.loadReviewsNextPage}
          onReviewClick={this.onReviewClick}
          {...this.props}
        />
        <ClaimAction
          isOpen={modalIsOpen}
          onCloseModal={this.onCloseModal}
          onTakeOverConfirm={this.onTakeOverConfirm}
          company={company}
          {...this.props}
        />
        <ReviewLogin
          isOpen={openLoginModal}
          onCloseModalLogin = {this.onCloseModalLogin}
          onSocialLogin= {this.onSocialLogin}
          {...this.props}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isAdmin: userSelectors.isAdmin(state),
  isAuthenticated: userSelectors.isAuthenticated(state),
  profile: userSelectors.getProfile(state),
  hasCompany: userSelectors.hasCompany(state),
});

export default connect(
  mapStateToProps,
  {
  },
)(CompanyDetails);
