import slugifystr from 'slugify';
import keyBy from 'lodash/keyBy';
import { get, isString, isEmpty } from 'lodash';
import { format } from 'date-fns';
import { conditions, renovateOptions, extrasOptions, scheduleOptions, importantFeatureOptions } from './types';

const exts: any = {};

['ä', 'ö', 'å'].forEach((l) => {
    exts[l] = l;
    exts[l.toUpperCase()] = l;
});

slugifystr.extend(exts);

export const slugify = (raw: string) => {
    const name = (raw || '').toString();
    return slugifystr(name, {
        lower: true,
        remove: /[^A-Za-z0-9\-.äöåÄÖÅ]/gi,
    });
};

export const slugifyCity = slugify;

export const formatNumber = (n: string|number|null, dec: number|null = null) => {
    let value: string|number = Number(n || 0);
    if (dec !== null) {
        value = (Math.round(parseFloat(value.toString()) * 100) / 100).toFixed(dec);
    }

    return value.toLocaleString('fi-FI');
};

export function getApartmentTypeName(type: number): string {
    const typeName = ['Kerrostalo', 'Rivitalo', 'Paritalo', 'Omakotitalo', 'Erillistalo', 'Loma-asunto'][type];
    return isString(typeName) && !isEmpty(typeName) ? typeName : '';
}

export function getConditionName(condition: number): string {
    const conditionName = conditions[condition];
    return isString(conditionName) && !isEmpty(conditionName) ? conditionName : '';
}

export function getScheduleName(value: string): string {
    const options = keyBy(scheduleOptions, 'value');
    const label = get(options, [value, 'label']);
    return isString(label) && !isEmpty(label) ? label : '';
}

export function getImportantFeatureName(value: string): string {
    const options = keyBy(importantFeatureOptions, 'value');
    const label = get(options, [value, 'label']);
    return isString(label) && !isEmpty(label) ? label : '';
}

export const humanizeDataValue = (field: string, value: any, isAnonymized: boolean = false) => {
    if (field === 'createdAt') { // TODO: could we check type from interface and make this more dynamic?
        if (value instanceof Date) {
            return format(value, 'd.M.yyyy - HH:mm:ss');
        } else if (typeof value === 'string') {
            return format(new Date(value), 'd.M.yyyy - HH:mm:ss');
        } else {
            return '';
        }
    }

    if (field === 'condition') {
      return getConditionName(value);
    }

    if (field === 'type') {
      return getApartmentTypeName(value);
    }

    if (field === 'hasRecentlyRenovated') {
        const options = keyBy(renovateOptions, 'value');
        return value.map((option: string) => options[option].label).join(', ');
    }

    if (field === 'hasExtras') {
        const options = keyBy(extrasOptions, 'value');
        return value.map((option: string) => options[option].label).join(', ');
    }

    if (field === 'importantFeature') {
        return getImportantFeatureName(value);
    }

    if (field === 'schedule') {
        return getScheduleName(value);
    }

    if (field === 'priceEstimate' && value) {
        return `${formatNumber(value)} €`;
    }

    if (field === 'size') {
        return formatNumber(value);
    }

    if (!isAnonymized && value === null) {
        const visibleFields = new Set(['address', 'zipCode', 'city', 'name', 'email', 'phone']);
        if (visibleFields.has(field)) {
            return '******';
        }
    }

    return value;
};
