/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAdminReviewsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAdminReviewsController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAdminReviewsView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAdminReviewsView ? transformProxies(this.props.children) : {
      'af-link-open': [],
      'af-link-accepted': [],
      'af-link-rejected': [],
      'af-data-wrapper': [],
      'af-review': [],
      'af-date': [],
      'af-name': [],
      'af-description': [],
      'af-action-btn-accept': [],
      'af-action-btn-reject': [],
      'af-action-btn-offer-request': [],
      'af-link-prev': [],
      'af-link-page': [],
      'af-link-next': [],
      'af-form-section-review-email': [],
      'af-customer-name-form': [],
      'af-customer-email-form': [],
      'af-customer-email-submit': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-section-2">
              <div data-duration-in={300} data-duration-out={100} data-current data-easing="ease" className="af-class-tabs w-tabs">
                <div className="af-class-tabs-menu w-tab-menu">
                  {map(proxies['af-link-open'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a data-w-tab="Tab 1" {...{...props, className: `af-class-tab01 w-inline-block w-tab-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                    <div className="af-class-tab-link-text-1">Avoimet asiakasarviot</div>
                  </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  {map(proxies['af-link-accepted'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a data-w-tab="Tab 9" {...{...props, className: `af-class-tab04 w-inline-block w-tab-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                    <div>
                      <div className="af-class-tab-link-text-4">Julkiset asiakasarviot</div>
                    </div>
                  </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  {map(proxies['af-link-rejected'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a data-w-tab="Tab 10" {...{...props, className: `af-class-tab05 w-inline-block w-tab-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                    <div>
                      <div className="af-class-tab-link-text-5">Piilotetut asiakasarviot</div>
                    </div>
                  </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                </div>
              </div>
              {map(proxies['af-data-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                {map(proxies['af-review'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-feed-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  <div className="af-class-div-block-136 w-clearfix">
                    <div className="af-class-div-block-6-review">
                      <div>
                        {map(proxies['af-date'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-table-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><strong>21.05.2019</strong></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        {map(proxies['af-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-table-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Etunimi Sukunimi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-138">
                        {map(proxies['af-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-table-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Lorem </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                    </div>
                    <div className="af-class-div-block-137">
                      {map(proxies['af-action-btn-accept'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-admin-top-review w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Julkaise arvio</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {map(proxies['af-action-btn-reject'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-admin-bottom-review w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Piilota arvio</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {map(proxies['af-action-btn-offer-request'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]/tarjouspyynnot/[offerRequestId]" {...{...props, className: `af-class-button-admin-bottom-review w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Näytä tarjouspyyntö</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    </div>
                  </div>
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
              {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
              <div className="af-class-div-block-84 w-clearfix">
                {map(proxies['af-link-prev'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-div-block-8 af-class-alt-width ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Edellinen</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-link-page'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-div-block-8 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>1</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-link-next'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-div-block-8 af-class-alt-width ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Seuraava</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
              </div>
              <div>
                <div className="w-form">
                  {map(proxies['af-form-section-review-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section-review-email" name="wf-form-af-form-section-review-email" data-name="af-form-section-review-email" method="get" data-wf-page-id="5d9c6c5957629d0c4783f5bb" data-wf-element-id="ca01ea42-a0a2-4d2f-c65f-29241167b4a3" {...{...props, className: `af-class-agency-review-form ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="Customer-email" className="af-class-field-label-review-request">Lähetä arviointipyyntö asiakkaallesi sähköpostilla </label><label htmlFor="Customer-name" className="af-class-field-label-explain-review-email">Voit pyytää asiakasta arvioimaan asunnon kaupan tai muun palvelutapahtuman. Asiakas voi olla myyjä, ostaja tai muu palveluitasi käyttänyt henkilö. Huomioi, että Asuntoarvio lähettää automaattisen arviopyynnön jokaisesta toteutuneesta asuntokaupasta (voit lähettää viestin alta uudestaan itse samalle henkilölle, jos haluat). Toteutuneisiin asuntokauppoihin tai kiinteistönvälittäjän omaan arviopyyntöön perustuvat arviot julkaistaan profiilisivulla automaattisesti. Kiinteistönvälittäjän asiakkaat voivat käydä jättämässä arvion myös oma-aloitteisesti Asuntoarvio.fi -palvelussa yritysten profiilisivuilla. Nämä arviot ovat varmistettu Facebook- tai Google-kirjautumisella, mutta vaativat siitä huolimatta erillisen&nbsp;julkaisun, ennen kuin arviot julkaistaan yrityksen profiilisivulla.</label>{map(proxies['af-customer-name-form'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="Customer-name" data-name="Customer-name" placeholder="Etunimi Sukunimi (asiakas)" type="text" id="Customer-name" required {...{...props, className: `af-class-input-field-review-customer-email w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{map(proxies['af-customer-email-form'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="Customer-email" data-name="Customer-email" placeholder="antti.asiakas@esimerkki.fi" type="email" id="Customer-email" required {...{...props, className: `af-class-input-field-review-customer-email w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{map(proxies['af-customer-email-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Lähetä arviopyyntö" {...{...props, className: `af-class-button-agency-contract-button-large w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Lähetä arviopyyntö')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAdminReviewsView

/* eslint-enable */