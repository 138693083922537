import React from 'react';
import { get } from 'lodash';
import { Loader } from './Loader';
import { setPrerenderStatus } from '../lib/helpers';
import { HelperComponent } from './HelperComponent';

interface DataLoading {
  [key: string]: boolean;
}

export interface BaseState {
  isLoading?: boolean;
  dataLoading?: DataLoading;
  initialLoadDone?: boolean;
}

export class BaseComponent<T extends {} = {}, S extends BaseState = {
  isLoading: true, dataLoading: {}, initialLoadDone: false,
}> extends HelperComponent<T, S> {
  private allowSetSate: boolean = false;

  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      isLoading: true,
      dataLoading: {},
      initialLoadDone: false,
    };
  }

  set isLoading(isLoading: boolean) {
    this.setLoading(isLoading);
  }

  get isLoading() {
    return this.state.isLoading || !this.state.initialLoadDone ? true : false;
  }

  startLoadingData(key: string, otherState: any = {}, cb: any = null) {
    this.setLoadingData(key, true, otherState, cb);
  }

  endLoadingData(key: string, otherState: any = {}, cb: any = null) {
    if (!this.state.initialLoadDone) {
      otherState.initialLoadDone = true;
      otherState.isLoading = false;
    }

    this.setLoadingData(key, false, otherState, cb);
  }

  setLoadingData(key: string, value: boolean, otherState: any = {}, cb: any = null) {
    const dataLoading = { ...this.state.dataLoading } as DataLoading;
    dataLoading[key] = value;
    this.setState({ dataLoading, ...otherState }, cb);
  }

  isLoadingData(key: string): boolean {
    return get(this, ['state', 'dataLoading', key], false) === true;
  }

  componentDidMount() {
    this.allowSetSate = true;
    this.isLoading = true;
  }

  componentWillUnmount() {
    this.allowSetSate = false;
  }

  setLoading(isLoading: boolean) {
    this.setState({ isLoading });
  }

  setState(state: any, cb: any = null) {
    if (!this.allowSetSate) {
      return;
    }
    if (!state.isLoading && state.initialLoadDone) {
      // Set prerending status as "ready" once loading is done
      setPrerenderStatus(true);
    }
    super.setState(state, cb);
  }

  setStateAfterLoad(state: any, cb: any = null) {
    this.setState({ ...state, isLoading: false, initialLoadDone: true }, cb);
  }

  setStateBeforeLoad(state: any, cb: any = null) {
    this.setState({ ...state, isLoading: true }, cb);
  }

  renderLoading(key: string|null = null) {
    if ((key !== null && !this.isLoadingData(key)) || (key === null && !this.isLoading)) {
      return null;
    }

    // Set prerending status as "not ready" when loading is in progress
    setPrerenderStatus(false);
    return <Loader />;
  }
}
