import { action } from 'typesafe-actions';

import {
    UPDATE,
    CLEAR,
    TOKEN,
    ACCEPT_TERMS,
} from './constants';

// CLASSIC API
export const update = (profile: any) => action(UPDATE, profile);
export const clear = () => action(CLEAR);
export const updateAccessToken = (accessToken: string) => action(TOKEN, accessToken);
export const acceptTerms = () => action(ACCEPT_TERMS);

interface UpdateUserProfile {
    type: typeof UPDATE;
    payload: any;
}

interface AcceptTerms {
    type: typeof ACCEPT_TERMS;
}

interface ClearUserProfile {
    type: typeof CLEAR;
}

interface UpdateUserAccessToken {
    type: typeof TOKEN;
    payload: string;
}

export type UserActionTypes = UpdateUserProfile | ClearUserProfile | UpdateUserAccessToken | AcceptTerms;
