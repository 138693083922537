import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import queryString from 'query-string';
import mapValues from 'lodash/mapValues';
import {
  conditions,
  extrasOptions,
  renovateOptions,
  scheduleOptions,
  importantFeatureOptions,
  offerRequestFieldNames
} from './offer-request';
import { Form, FormState, FormInitialState, valueType } from '../components/Form';
import { OfferRequestService } from '../services/offer-request';
import { Company } from '../company/Company.interface';
import { Agent } from '../company/Agent.interface';
import { AfOfferRequestController } from '../controllers/AfOfferRequestController';
import icons from '../icons.json';
import { StringSignatureAny } from '../shared/types';
import { loadingProps } from '../lib/helpers';

import './styles.scss';

enum Section {
  Start = 1,
  SellingSchedule,
  ContactInfo,
  InitialDone,
  TargetInfo,
  TargetProperties,
  PriceEstimate,
  FinalDone,
  NotAvailable,
}

export interface AskForOfferState extends FormState {
  section: Section;
  company?: Company;
  agent?: Agent;
  isBrokerAvailable?: boolean;
  offerSlug: string|null;
}

export class AskForOffer<T extends {}, S extends AskForOfferState> extends Form<RouteComponentProps & T, S> {
  state = {
    ...FormInitialState,
    formData: {
      hasExtras: [] as string[],
      hasRecentlyRenovated: [] as string[],
    },
    isBrokerAvailable: true,
    offerSlug: null,
  } as S;

  componentDidMount() {
    super.componentDidMount();
    const { formData } = this.state;
    const { location: { search } } = this.props;
    if (!search) {
      return;
    }

    const params = queryString.parse(search);

    if (params.route) {
        formData.address = params.route;
    }

    if (params.locality) {
      formData.city = params.locality;
    }

    if (params.postal_code) {
      formData.zipCode = params.postal_code;
    }

    if (params.street_number && formData.address) {
      formData.address += ` ${params.street_number}`;
    }

    this.setState({ formData });
  }

  get formName() {
    return 'AskForOffer';
  }

  get sectionName() {
    return `section${this.state.section}`;
  }

  getElemName(elem: any): string {
    return offerRequestFieldNames[elem.name] || elem.name;
  }

  getCustomValidators(elemName: string|null = null) {
    const { formData: offerRequest } = this.state;

    const validators = {
      terms: () => {
        if (offerRequest.terms) {
          return [];
        }

        return ['Ehdot tulee hyväksyä'];
      },
      floorNumber: () => {
        if (!offerRequest.floorNumber || !offerRequest.numberOfFloorsInBuilding
          || parseInt(offerRequest.floorNumber, 10) <= parseInt(offerRequest.numberOfFloorsInBuilding, 10)) {
          return [];
        }

        return ['Asunnon kerros ei voi olla suurempi, kuin talon kerrosten lukumäärä.'];
      },
    } as StringSignatureAny;

    const elemValidators = mapValues(validators, (validator, name) => ({
      name,
      checkValidity: () => validator().length === 0,
      validity: null,
      getErrors: validator,
    })) as StringSignatureAny;

    if (elemName) {
      return elemValidators[elemName];
    }

    return elemValidators;
  }

  depensValidators = {
    numberOfFloorsInBuilding: ['floorNumber'],
  } as StringSignatureAny;

  normalizeValue(field: string, target: any, value: valueType) {
    let newValue = super.normalizeValue(field, target, value);

    // normalize decimal numbers
    if (field === 'formData.size' && newValue) {
      newValue = parseFloat(newValue.toString().replace(',', '.'));
    }

    const multiSelectFields = new Set([
      'formData.hasRecentlyRenovated',
      'formData.hasExtras',
    ]);

    if (multiSelectFields.has(field)) {
      const currentValue: any = get(this.state, field, []);
      const exists = currentValue.includes(newValue);
      if (exists) {
        newValue = currentValue.filter((v: string) => v !== newValue);
      } else {
        newValue = [ ...currentValue, newValue ];
      }
    }

    return newValue;
  }

  async saveOfferRequest(): Promise<boolean> {
    const { company, agent, formData: offerRequest, offerSlug } = this.state;

    try {
      const service = new OfferRequestService();

      if (offerSlug) {
        await service.child(offerSlug).patch(offerRequest);
      } else {
        let targetId = null;
        let target = null;
        if (company) {
          target = 'company';
          targetId = company.id;
        }
        if (agent) {
          target = 'agent';
          targetId = agent.id;
        }
        const postService = target && targetId ? service.child(target).child(targetId) : service;
        const offer = await postService.post(offerRequest);
        this.setState({ offerSlug: offer.slug });
      }

      return true;
    } catch (e) {
      toast.error('Kilpailutuspyynnön lähettäminen epäonnistui. Yritäthän hetken kuluttua uudestaan!');
    }

    return false;
  }

  async saveformData(): Promise<boolean> {
    const { section, formData } = this.state;

    if (section === Section.Start) {
      // Validate address
      try {
        const service = new OfferRequestService().child('validate-address');
        const result = await service.post({ zipCode: formData.zipCode, city: formData.city });
        this.setState({ isBrokerAvailable: result?.valid === true });
      } catch (error) {
        toast.error('Osoitteen tarkistaminen epäonnistui! Yritä myöhemmin uudelleen.');
        return false;
      }
    }

    if (section === Section.FinalDone) {
      this.props.history.push('/');
      return true;
    }

    if (section === Section.InitialDone && formData.schedule === 'notSelling') {
      return false;
    }

    if (section >= Section.ContactInfo && !await this.saveOfferRequest()) {
        return false;
    }

    // Jump to correct section when broker isn't available
    const isBrokerAvailable = this.state.isBrokerAvailable;
    this.setState({
      section: isBrokerAvailable ? Number(section) + 1 : Section.NotAvailable,
      errors: {}
    });

    return true;
  }

  onPrevious = (event: React.FormEvent) => {
    event.preventDefault();

    const { section } = this.state;

    this.setState({ section: section - 1 });
  }

  section() {
    const { section, isBrokerAvailable, formData: { email, schedule } } = this.state;

    if (section === Section.Start) {
      return [
        this.renderInput('address'),
        this.renderInput('addressExtra'),
        this.renderInput('zipCode'),
        this.renderInput('city'),
      ];
    }

    if (section === Section.SellingSchedule) {
      this.registerRequiredBoxSelection('schedule', 'Myyntiaikataulu ei ole valittuna');
      return [
        ...scheduleOptions.map(this.renderBoxSelection('schedule')),
        this.renderInput('size'),
        this.renderSelect('type', (
          <>
            <option value=''>Valitse</option>
            <option value='0'>Kerrostalo</option>
            <option value='1'>Rivitalo</option>
            <option value='2'>Paritalo</option>
            <option value='3'>Omakotitalo</option>
            <option value='4'>Erillistalo</option>
            <option value='5'>Loma-asunto</option>
          </>
        )),
      ];
    }

    if (section === Section.ContactInfo) {
      return [
        this.renderInput('name'),
        this.renderInput('email'),
        this.renderInput('phone'),
      ];
    }

    if (section === Section.InitialDone) {
      if (!isBrokerAvailable) {
        return [
          <af-locality-unacceptable></af-locality-unacceptable>
        ];
      }
      return schedule === 'notSelling'
        ? [
          <af-not-selling></af-not-selling>,
        ]
        : [
          <af-done-all><af-confirm-email>{email}</af-confirm-email></af-done-all>,
          ...importantFeatureOptions.map(this.renderBoxSelection('importantFeature')),
        ];
    }

    if (section === Section.TargetInfo) {
      return [
        this.renderInput('yearOfConstruction'),
        this.renderInput('numberOfRooms'),
        this.renderInput('floorNumber'),
        this.renderInput('numberOfFloorsInBuilding'),
      ];
    }

    if (section === Section.TargetProperties) {
      return [
        ...extrasOptions.map(this.renderBoxSelection('hasExtras')),
        ...renovateOptions.map(this.renderBoxSelection('hasRecentlyRenovated')),
      ];
    }

    if (section === Section.PriceEstimate) {
      return [
        ...[...conditions.map((label: string, value: number) => ({ label, value }))].reverse().map(this.renderBoxSelection('condition')),
        this.renderInput('priceEstimate'),
        this.renderInput('extraInfo'),
      ];
    }

    if (section === Section.NotAvailable) {
      return [<af-locality-unacceptable></af-locality-unacceptable>];
    }

    return [];
  }

  renderFormChildren(): null | JSX.Element | JSX.Element[] {
    const { section } = this.state;

    const BtnNext = `af-btn-next-section${section}` as unknown as React.ComponentType<any>;
    const BtnPrev = `af-btn-prev-section${section}` as unknown as React.ComponentType<any>;

    return [
      <BtnNext onClick={this.handleSubmit} key='next' {...loadingProps(this.isSectionSaving())} />,
      <BtnPrev onClick={this.onPrevious} key='previous' />,
    ];
  }

  render() {
    const { section, formData } = this.state;
    const isNotSelling = formData.schedule === 'notSelling';
    const className = section === 4 && isNotSelling ? 'not-selling-last-section' : '';
    return (
      <AfOfferRequestController
        {...this.props}
      >
        {this.hasErrors() && (
          <af-error-wrapper>
            {this.getAllErrors().map((error: string) => (
              <af-error key={error}>
                <span className='af-class-exclamation'>{icons.exclamation}</span> {error}
              </af-error>
            ))}
          </af-error-wrapper>
        )}
        {this.renderForm(className)}
      </AfOfferRequestController>
    );
  }
}
