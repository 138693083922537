/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAdminOfferInfoPageView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAdminOfferInfoPageController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAdminOfferInfoPageView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAdminOfferInfoPageView ? transformProxies(this.props.children) : {
      'af-back-link': [],
      'af-action-wrapper': [],
      'af-action-button-wrapper': [],
      'af-action-btn-primary': [],
      'af-action-btn-secondary': [],
      'af-action-btn-third': [],
      'af-action-btn-fourth': [],
      'af-form-section-review-email': [],
      'af-initial-offer-form': [],
      'af-initial-offer-submit': [],
      'af-title': [],
      'af-value': [],
      'af-print': [],
      'af-action-btn-myyntipro': [],
      'af-data-row': [],
      'af-data-label': [],
      'af-data-value': [],
      'af-map': [],
      'af-linked-companies-wrapper': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-section-offer-info-page">
              {map(proxies['af-back-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/[path]/[companyId]/tarjouspyynnot/[status]" {...{...props, className: `af-class-button-back w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Palaa edelliselle sivulle<span className="af-class-arrow-back"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
              <div className="af-class-div-block-13 w-clearfix">
                {map(proxies['af-action-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-14 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  {map(proxies['af-action-button-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-action-btn-primary'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-offer-page w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Näytä yhteystiedot</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-action-btn-secondary'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-offer-page-bottom w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Hylkää tarjouspyyntö</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-action-btn-third'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-offer-page-bottom w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Reklamaatio</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-action-btn-fourth'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-offer-page-bottom w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>En ole kiinnostunut kohteesta</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                <div>
                  <div className="w-form">
                    {map(proxies['af-form-section-review-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section-review-email" name="wf-form-af-form-section-review-email" data-name="af-form-section-review-email" method="get" data-wf-page-id="5d9c6c5957629d82fa83f5b3" data-wf-element-id="b99b8329-a722-5ef8-66ce-a077db8cb6ed" {...{...props, className: `af-class-initial-offer-form ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="Customer-email" className="af-class-field-label-review-request">Anna kohteesta alustava hinta-arvio</label><label htmlFor="Initial-Offer-2" className="af-class-field-label-explain-review-email">Voit antaa kohteesta asiakkaan antamien tietojen pohjalta alustavan hinta-arvion. Alustava hinta-arvio ei ole kiinteistönvälittäjää sitova, mutta antaa asunnon myyjälle keinon arvioida eri kiinteistönvälittäjien näkemystä asunnon hintatasosta. Kiinteistönvälittäjän tulee aina käydä tutustumassa henkilökohtaisesti myyntikohteeseen ja antaa virallinen hinta-arvio toimeksiantosopimusta varten.<br /><br /></label>{map(proxies['af-initial-offer-form'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="Initial-Offer" data-name="Initial Offer" placeholder="esim. 200000" type="number" id="Initial-Offer-Agency" {...{...props, className: `af-class-input-field-initial-offer w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{map(proxies['af-initial-offer-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Lähetä alustava hinta-arvio" {...{...props, className: `af-class-button-initial-offer w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Lähetä alustava hinta-arvio')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
                <div className="af-class-div-block-14">
                  {map(proxies['af-title'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h5 {...{...props, className: `af-class-heading-6 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Toteutunut kauppa</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </h5>
                ))}
                </div>
                <div className="af-class-div-block-14">
                  {map(proxies['af-value'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-admin-p-status-info ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Hinta-arvio:&nbsp;250.000 €, 13.8.2019</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                  <p className="af-class-admin-p-customer-service">Myynnin status tai hintatiedot virheelliset? Ota yhteyttä asiakaspalvelu@asuntoarvio.fi</p>
                </div>
                <div className="af-class-div-block-14 w-clearfix">
                  {map(proxies['af-print'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-link af-class-float-right ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Tulosta tiedot</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                </div>
                <div className="af-class-div-block-14">
                  {map(proxies['af-action-btn-myyntipro'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-offer-page-myyntipro w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Lähetä tiedot MyyntiPro</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                </div>
                <div className="af-class-div-block-10">
                  {map(proxies['af-data-row'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-admin-offfer-info-row w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-div-block-11">
                      {map(proxies['af-data-label'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-body-bold ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Tarjouspyynnön pvm</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    </div>
                    <div className="af-class-div-block-11">
                      {map(proxies['af-data-value'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-admin-offer-info ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>-</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                </div>
                <div className="af-class-div-block-12">
                  {map(proxies['af-map'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment>
                    <div className="af-class-map w-widget w-widget-map" data-widget-style="roadmap" data-widget-latlng data-enable-scroll="true" role title data-enable-touch="true" data-widget-zoom={12} data-widget-tooltip />
                  </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {map(proxies['af-linked-companies-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-39 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Linked companies here</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                </div>
              </div>
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAdminOfferInfoPageView

/* eslint-enable */