import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import Moment from 'react-moment';
import { logger } from '../lib/debug';
import { OfferRequestService } from '../services/offer-request';
import { CompanyOfferRequest } from './offer-request';
import { NumberSignatureString, OfferRequestStatus } from '../shared/types';
import { AfReviewsLandingController } from '../controllers/AfReviewsLandingController';
import { Form, FormState, FormInitialState } from '../components/Form';
import { loadingProps, formatNumber } from '../lib/helpers';
import icons from '../icons.json';
import { Company } from '../company/Company.interface';
import { UserProfile } from '../store/user/reducer';

const log = logger('WriteReview');

const reviewTranslations: NumberSignatureString = {
  1: 'Huono',
  2: 'Kohtalainen',
  3: 'Hyvä',
  4: 'Erinomainen',
  5: 'Loistava',
};

export interface State extends FormState {
  numberOfStars: number;
  wouldRecommend: boolean;
  description: string;
  offerRequestOfCompany?: CompanyOfferRequest;
  company?: Company;
  agent?: UserProfile;
  reviewRequest?: any;
}

interface TParams {
  offerSlug: string;
  numberOfStars?: string;
  companySlug?: string;
  agentSlug?: string;
  reviewSlug?: string;
}

export class WriteReview<T extends {} = {}, S extends FormState = FormState>
  extends Form<RouteComponentProps<TParams>, State> {
  state = {
    ...FormInitialState,
    section: 'section-review',
  } as State;

  componentDidMount() {
    super.componentDidMount();
    const { match} = this.props;
    const { offerSlug } = match.params;
    if (!!offerSlug) {
      this.loadOfferRequest(true);
    }
  }

  get service() {
    const { match } = this.props;
    const { offerSlug } = match.params;

    const service = new OfferRequestService();
    return service.child(offerSlug).child('review');
  }

  async loadOfferRequest(onMount = false) {
    const { match, history } = this.props;

    try {
      const { numberOfStars } = match.params;
      const offerRequestOfCompany = await this.service.get();

      let section = this.state.section;
      if (onMount && numberOfStars === 'vahvista') {
        section = 'section-fix-data';
      } else if (onMount && !offerRequestOfCompany.review && numberOfStars !== undefined) {
        const stars = parseInt(numberOfStars, 10);
        this.updateNumberOfStars(stars);
        offerRequestOfCompany.review = { numberOfStars: stars };
      }

      if (offerRequestOfCompany.status === OfferRequestStatus.notFulfilled) {
        section = 'section-review-not-fulfilled';
      }

      const formData = offerRequestOfCompany.review || {};
      if (section === 'section-fix-data') {
        const fields = ['commission', 'sellingPrice', 'fulfilledAt'];
        fields.forEach((field) => {
          if (!formData[field]) {
            formData[field] = offerRequestOfCompany[field];
          }
        });
      }

      this.setState({
        offerRequestOfCompany,
        formData,
        section,
      });
    } catch (error: any) {
      if (error.json && error.json.statusCode === 405 && error.json.message === 'Locked') {
        toast.error('Tämä linkki ei ole enää voimassa.');
        history.push('/');
        return;
      }

      log('ERROR', error.json);
      toast.error('Jotain meni pieleen. Ole hyvä ja yritä uudelleen.');
    }
  }

  async saveformData(): Promise<boolean> {
    const { match, history } = this.props;
    const { offerSlug } = match.params;

    try {
      await this.service.patch(this.state.formData);
      toast.success('Kiitos.');

      if (this.state.section === 'section-fix-data') {
        history.push(`/tarjouspyynto/${offerSlug}`);
        this.setState({ section: 'section-review' });
      }

      return true;
    } catch (error) {
      toast.error('Tietojen päivittäminen epäonnistui');
      return false;
    }
  }

  patch = debounce(() => this.saveformData(), 500);

  updateNumberOfStars(numberOfStars: number) {
    const { formData} = this.state;
    const { match} = this.props;
    const {offerSlug, reviewSlug } = match.params;
    this.setState({ formData: { ...formData, numberOfStars } },
      () => {
        if (offerSlug || reviewSlug) {
          this.patch();
        }
      });
  }

  updateWouldRecommend(wouldRecommend: boolean) {
    const { formData } = this.state;
    const { match} = this.props;
    const {offerSlug, reviewSlug } = match.params;
    this.setState({ formData: { ...formData, wouldRecommend } },
      () => {
        if (offerSlug || reviewSlug) {
          this.patch();
        }
      });
  }

  section() {
    if (this.sectionName.includes('section-review')) {
      return [
        this.renderTextInput('description'),
        this.renderCheckbox('wouldRecommend', {
          onClick: () => {
            const { formData } = this.state;
            const { wouldRecommend } = formData;
            this.updateWouldRecommend(!wouldRecommend);
          },
        }),
      ];
    }

    if (this.sectionName === 'section-fix-data') {
      return [
        this.renderInput('sellingPrice'),
        this.renderDateInput(`fulfilledAt`),
        this.renderInput('commission'),
      ];
    }

    return [];
  }

  renderReview() {
    const { formData } = this.state;
    const { numberOfStars } = formData;
    const faces = [icons.frown, icons.meh, icons.smile, icons.grin, icons.laugh];

    return faces.map((icon, i) => (
      <af-face-wrapper
        key={i}
        className={numberOfStars === i + 1 ? 'af-class-div-block-customer-review-icon-actv' : undefined}
        onClick={() => this.updateNumberOfStars(i + 1)}
      >
        <af-face-icon>{icon}</af-face-icon>
      </af-face-wrapper>
    ));
  }

  renderSectionChildren(): any {
    const { formData, section, offerRequestOfCompany } = this.state;
    const { numberOfStars } = formData;

    if (section === 'section-fix-data' && !!offerRequestOfCompany) {
        const { fulfilledAt, sellingPrice, commission } = offerRequestOfCompany;

        return (
          <af-section-fix-data-children>
            <af-date><Moment local date={fulfilledAt} /></af-date>
            <af-selling-price>{formatNumber(sellingPrice)} euroa</af-selling-price>
            <af-commission>{formatNumber(commission)} euroa</af-commission>
          </af-section-fix-data-children>
        );
    }

    return (
      <af-section-review-children>
        {this.renderReview()}
        {numberOfStars && (
          <af-review-text-wrapper key='review-text'>
            <af-review-text>{reviewTranslations[numberOfStars]}</af-review-text>
          </af-review-text-wrapper>
        )}
      </af-section-review-children>
    );
  }

  renderFormChildren(): any {
    if (this.state.savedSections.includes(this.sectionName)) {
      return <af-section-submit disabled>Kiitos, arvostelusi on tallennettu</af-section-submit>;
    }

    return <af-section-submit onClick={this.handleSubmit} key='submit'  {...loadingProps(this.isSectionSaving())} />;
  }

  render() {
    const { offerRequestOfCompany } = this.state;

    if (!offerRequestOfCompany) {
      return null;
    }

    return (
      <AfReviewsLandingController
        {...this.props}
      >
        {this.renderForm()}
      </AfReviewsLandingController>
    );
  }
}

export default WriteReview;
