import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { userSelectors } from '../store/user';
import AfFindAgencyView from '../views/AfFindAgency2View';
import { BaseController } from './BaseController';
import { Company } from '../company/Company.interface';
import { UserProfile } from '../store/user/reducer';
import { loadingProps } from '../lib/helpers';

import './styles.scss';

const DESCRIPTION_LENGTH = 160;

interface Props {
  results: Company[];
  setSearchValue: (searchValue: string) => void;
  setSearchType: (searchValue: string) => void;
  searchValue: string;
  totalItems: number;
  page: number;
  pageCount: number;
  nextPage: () => void;
  dataLoader: any;
  pageLoading: boolean;
  searchType: string;
  isAuthenticated: boolean;
}

interface State {
  searchValue: string;
}

const popularAreasAgency: string[] = ['Helsinki', 'Espoo', 'Vantaa', 'Tampere', 'Turku', 'Oulu', 'Vaasa'];
const popularAreasAgent: string[] = [];

export class AfFindAgencyController extends BaseController<RouteComponentProps & Props, State> {
  searchBoxElement = createRef<HTMLElement>();

  searchInput: any;

  state = {
    searchValue: '',
  } as State;

  componentDidUpdate(prevProps: any) {
    super.componentDidUpdate(prevProps);
    const { searchType, searchValue } = this.props;
    if (prevProps.searchValue !== searchValue || prevProps.searchType !== searchType) {
      this.setState({ searchValue });
      // Set correct meta tag data
      if (searchValue) {
        const s = this.props.searchValue;
        const searchMeta = s.charAt(0).toUpperCase() + s.slice(1);
        if (searchType === 'company') {
          this.metaTagService.setCompanyCityTags(searchMeta);
        } else {
          this.metaTagService.setAgentCityTags(searchMeta);
        }
      } else {
        if (searchType === 'company') {
          this.metaTagService.setCompanySearchTags();
        } else {
          this.metaTagService.setAgentSearchTags();
        }
      }
    }
  }

  componentDidMount() {
    super.componentDidMount();
    const { searchType } = this.props;
    if (searchType === 'company') {
      this.metaTagService.setCompanySearchTags();
    } else {
      this.metaTagService.setAgentSearchTags();
    }
  }

  doSearch = (value: any = null) => {
    if (!this.searchInput) {
      return;
    }

    const { setSearchValue } = this.props;
    const searchValue = typeof value === 'string' && value ? value : this.searchInput.value;
    this.setState({ searchValue }, () => setSearchValue(searchValue));
  }

  setSearchValue = (event: any) => {
    const { setSearchValue } = this.props;
    const searchValue = event.target.value;
    this.setState({ searchValue }, () => setSearchValue(searchValue));
  }

  setSearchType = (searchType: string) => (event: any) => {
    this.onAfLinkClick(event, { disableScrollToTop: true });
    const {
      setSearchType,
    } = this.props;
    setSearchType(searchType);
  }

  setSearchValueClick = (searchValue: string) => (event: any) => {
    this.onAfLinkClick(event, { disableScrollToTop: true });
    this.doSearch(searchValue);
  }

  renderAgentCard = (data: UserProfile) => {
    const {
      id,
      stats,
      slug: agent,
      userMainCompany,
      firstName,
      lastName,
      logoImage,
      description,
      titles,
    } = data;

    const {
      logoImage: logoImageCompany,
    } = userMainCompany || {};

    const name = `${firstName} ${lastName}`;

    return (
      <af-agent-card key={id}>
        {this.afLink('agent-name', { agent }, name)}
        {titles && titles.length && (<af-agent-title>{titles.map(({ label }) => label).join(', ')}</af-agent-title>)}
        {description && (
          <af-description-agent>
            {
              description.substr(0, DESCRIPTION_LENGTH - 1) + (description.length > DESCRIPTION_LENGTH ? '...' : '')
            }
          </af-description-agent>
        )}
        <af-logo-image-agent style={{ backgroundImage: logoImage ? `url(${logoImage})` : undefined }}>
          <af-logo-image-company-small style={{ backgroundImage: logoImageCompany ? `url(${logoImageCompany})` : undefined }} />
        </af-logo-image-agent>
        {this.renderStars('stars-agent', stats?.numberOfStarsAvg ?? 0)}
        {this.afLink('link-agent', { agent })}
        {this.afLink('ask-offer-link-agent', { agent })}
      </af-agent-card>
    );
  }

  renderCompanyCard = (data: Company) => {
    const {
      id,
      slug,
      marketingName,
      address,
      zipCode,
      city,
      description,
      stats,
      logoImage,
    } = data;

    return (
      <af-agency-card key={id}>
        {this.afLink('marketing-name', { city, company: slug }, marketingName)}
        <af-address>{`${address}, ${zipCode}, ${city}`}</af-address>
        {description && (
          <af-description>
          {
            description.substr(0, DESCRIPTION_LENGTH - 1) + (description.length > DESCRIPTION_LENGTH ? '...' : '')
          }
          </af-description>
        )}
        {this.afLink('company-link', { city, company: slug })}
        <af-logo-image style={{ backgroundImage: logoImage ? `url(${logoImage})` : undefined }} />
        {this.renderStars('stars', stats?.numberOfStarsAvg ?? 0)}
        {this.afLink('ask-offer-link', { company: slug })}
      </af-agency-card>
    );
  }

  renderResults() {
    const { results, searchType } = this.props;

    if (!results) {
      return null;
    }

    return results.map(row => searchType === 'agent'
      ? this.renderAgentCard(row as unknown as UserProfile)
      : this.renderCompanyCard(row as unknown as Company));
  }

  render() {
    const {
      totalItems,
      page,
      pageCount,
      nextPage,
      dataLoader,
      pageLoading,
      searchType,
      isAuthenticated,
    } = this.props;
    const { searchValue } = this.state;

    const term1 = searchType === 'company' ? 'välitysliikettä' : 'välittäjää';
    const term2 = searchType === 'company' ? 'välitysliikkeen' : 'välittäjän';
    let totalMessage = `Valitettavasti emme löytäneet haullasi yhtään ${term1}.`;
    if (totalItems > 1) {
      totalMessage = `Yhteensä ${totalItems} ${term1}`;
    }
    if (totalItems === 1) {
      totalMessage = `Löysimme sinulle yhden ${term2}`;
    }

    const popularAreas = searchType === 'agent' ? popularAreasAgent : popularAreasAgency;

    const requireAuthForAgencySearch = false;

    const searchTypeString =  searchType === 'agent' ? 'kiinteistonvalittaja' : 'kiinteistonvalitys';
    const keywordPrefix =  searchType === 'agent' ? 'Kiinteistönvälittäjä' : 'Kiinteistönvälitys';

    return (
      <AfFindAgencyView>
        {searchType === 'company' && <af-agency-heading />}
        {searchType !== 'company' && <af-agent-heading />}
        <af-search-box ref={(ref: any) => this.searchBoxElement = ref}>
          <input
            type='text'
            onChange={this.setSearchValue}
            value={searchValue}
            className='search-input'
            placeholder={searchType === 'company' ? 'Etsi paikkakunta tai yritys' : 'Etsi paikkakunta tai välittäjä'}
            ref={(ref: any) => { this.searchInput = ref; }}
          />
        </af-search-box>
        {popularAreas.length && (
          <af-popular-search-wrapper>
            {popularAreas.map((city) => this.afLink('popular-area',
              {
                searchtype: searchTypeString,
                city,
              }, city, { onClick: this.setSearchValueClick(city), key: city }))
            }
          </af-popular-search-wrapper>
        )}
        <af-search-btn onClick={this.doSearch} />
        {(isAuthenticated || !requireAuthForAgencySearch) && (
          <af-search-type-wrapper>
            <af-company-search onClick={this.setSearchType('company')} className={searchType === 'company' ? 'af-class-select-btn-active' : 'af-class-select-btn'} />
            <af-agent-search onClick={this.setSearchType('agent')}  className={searchType === 'agent' ? 'af-class-select-btn-active' : 'af-class-select-btn'} />
          </af-search-type-wrapper>
        )}
        <af-data-wrapper data-loader={dataLoader}>
          {<af-search-keyword>{`${keywordPrefix} ${searchValue}`}</af-search-keyword>}
          {<af-search-total>{totalMessage}</af-search-total>}
          {this.renderResults()}
          {pageCount > page && (
            <af-fetch-more {...loadingProps(pageLoading)} onClick={nextPage}>
              Näytä lisää {searchType === 'company' ? 'välitysliikkeitä' : 'välittäjiä'} ({page}/{pageCount})
            </af-fetch-more>
          )}
        </af-data-wrapper>
      </AfFindAgencyView>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: userSelectors.isAuthenticated(state),
});

export default connect(
  mapStateToProps,
  {
  },
)(AfFindAgencyController);
