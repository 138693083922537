/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfComponentNavWhiteView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfComponentNavWhiteController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfComponentNavWhiteView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfComponentNavWhiteView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-bodyremove">
            <div className="af-class-section-11">
              <div data-collapse="medium" data-animation="default" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="af-class-navbar-color w-nav">
                <div className="w-container">
                  <div className="af-class-menu-button af-class-menu-button-alt w-nav-button">
                    <div className="af-class-menu-dark-hamburger af-class-menu-alt-hamburger w-icon-nav-menu" />
                  </div>
                </div>
                <a href="/" className="af-class-brand w-nav-brand"><img src="images/asuntoarvio-logo-org-2020.svg" width={220} height={53} className="af-class-asuntoarvio-logo" /></a>
                <nav role="navigation" className="af-class-nav-menu af-class-nav-menu-alt-color w-nav-menu">
                  <a href="/kilpailutuspalvelu" className="af-class-nav-link-color af-class-nav-link-color-alt w-nav-link">Kilpailuta kiinteistönvälitys</a>
                  <a href="/kiinteistonvalitys" className="af-class-nav-link-color w-nav-link">Etsi kiinteistönvälittäjä</a>
                  <a href="/mika-on-asuntoarvio" className="af-class-nav-link-color w-nav-link">Mikä on Asuntoarvio?</a>
                  <a href="/kiinteistonvalittajille" className="af-class-nav-link-color af-class-nav-link-color-last w-nav-link">Kiinteistönvälittäjille</a>
                </nav>
              </div>
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfComponentNavWhiteView

/* eslint-enable */