import React, { Fragment } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { userSelectors } from '../store/user';
import { companyActions, companySelectors } from '../store/company';
import { AfComponentFooterWhiteController } from '../controllers/AfComponentFooterWhiteController';
import { AfComponentNavDarkController } from '../controllers/AfComponentNavDarkController';
import { AfComponentNavWhiteController } from '../controllers/AfComponentNavWhiteController';
import { Company } from '../company/Company.interface';
import { CompanyManagerService } from '../services/company-manager';
import { Auth0Context } from '../AuthProvider';
import { Loader } from './Loader';

export interface LayoutRouteProps {
  component: React.ReactType;
  routeComponent?: React.ReactType;
  headerComponent?: React.ReactType|null|undefined;
  footerComponent?: React.ReactType|null|undefined;
  privateRoute?: boolean;
  adminRoute?: boolean;
}

interface Props {
  onCompanySelect: (companyId: number) => void;
  onCompanyClear: () => void;
  company: Company | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
}

export const LayoutRouteBase: React.FC<RouteProps & LayoutRouteProps & Props> = ({
  component: Component,
  routeComponent: RouteComponent = Route,
  headerComponent,
  footerComponent,
  privateRoute = false,
  adminRoute = false,
  onCompanySelect,
  onCompanyClear,
  path,
  company,
  isAuthenticated,
  isAdmin,
  ...rest
}) => {
  const render = (matchProps: any) => {
    if (privateRoute) {
      const companyId = parseInt(get(matchProps, 'match.params.companyId'), 10);
      if (isAuthenticated && companyId && (!company || companyId !== company.id)) {
        onCompanySelect(companyId);
      }
      if (!companyId && company) {
        onCompanyClear();
      }
    }

    let canRender = true;
    if (privateRoute && !isAuthenticated) {
      canRender = false;
    }

    if (adminRoute && (!isAdmin || !isAuthenticated)) {
      canRender = false;
    }

    let HeaderComponent = headerComponent;
    if (HeaderComponent !== null && !HeaderComponent) {
      HeaderComponent = privateRoute ? AfComponentNavDarkController : AfComponentNavWhiteController;
    }

    let FooterComponent = footerComponent;
    if (FooterComponent !== null && !FooterComponent) {
      FooterComponent = AfComponentFooterWhiteController;
    }

    return (
      <Auth0Context.Consumer>
        {(authProps: any) => {
          if (authProps.loading) {
            return <Loader />;
          }

          if (privateRoute && !authProps.isAuthenticated) {
            authProps.loginWithRedirect({
              appState: { targetUrl: path },
            });
            // Return here to stop router redirects before login screen is loaded
            return null;
          }

          if (!isAdmin && adminRoute) {
            return <Redirect to='/hallinta' />;
          }

          return (
            <Fragment>
              {HeaderComponent && <HeaderComponent {...matchProps} privateRoute={privateRoute} adminRoute={adminRoute} />}
              {canRender && <Component { ...matchProps } {...authProps} /> }
              {FooterComponent && <AfComponentFooterWhiteController {...authProps} />}
            </Fragment>
          );
        }}
      </Auth0Context.Consumer>
    );
  };

  return (
    <RouteComponent {...rest} render={render} />
  );
};

const mapStateToProps = (state: any) => ({
  company: companySelectors.getCompany(state),
  isAdmin: userSelectors.isAdmin(state),
  isAuthenticated: userSelectors.isAuthenticated(state),
});

function mapDispatchToProps(dispatch: any) {
  const actions = {
    onCompanySelect: async (companyId: number) => {
      try {
          const service = new CompanyManagerService(companyId);
          const company = await service.get();
          dispatch(companyActions.set(company));
      } catch (error) {
        toast.error('Yrityksen haku epäonnistui');
      }
    },
    onCompanyClear: () => dispatch(companyActions.clear()),
  };
  return actions;
}

export const LayoutRoute = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutRouteBase);
