import { ActionType } from 'typesafe-actions';
import { Company, CompanyStats } from '../../company/Company.interface';
import { TerritoryValue, OptionType } from '../../shared/types';
import * as actions from './actions';
import { UPDATE, CLEAR, TOKEN, ACCEPT_TERMS } from './constants';

export type UsersAction = ActionType<typeof actions>;

export interface UserCompany {
    [key: string]: any;
    id: number;
    acceptedAt: Date|null;
    company: Company;
    companyId: number;
    isMainUser: boolean;
    slug: string;
    isPublicProfile: boolean;
}

export interface UserProfile {
    id: number;
    slug: string;
    email: string;
    firstName: string;
    lastName: string;
    facebook: string;
    instagram: string;
    linkedin: string;
    yearsOfExperience: number;
    companies: [UserCompany];
    userMainCompany: Company;
    permissions: [string];
    territoryCities: TerritoryValue[];
    territoryZipCodeAreas: TerritoryValue[];
    titles: OptionType[];
    specialities: OptionType[];
    languages: OptionType[];
    logoImage: string;
    backgroundImage: string;
    description: string;
    stats: CompanyStats;
    isAcceptedTerms: boolean;
    priceEstimation?: number;
    isAdmin?: boolean;
}

export interface UserState {
  profile: UserProfile|null;
  accessToken: string|null;
  isAuthenticated: boolean;
}

const initialSate: UserState = {
    profile: null,
    accessToken: null,
    isAuthenticated: false,
};

export default function userReducer(state = initialSate, action: actions.UserActionTypes): UserState {
    switch (action.type) {
        case UPDATE:
            return {
                ...state,
                isAuthenticated: true,
                profile: action.payload,
            };
        case ACCEPT_TERMS:
            return {
                ...state,
                isAuthenticated: true,
                profile: {
                    ...state.profile as UserProfile,
                    isAcceptedTerms: true,
                },
            };
        case CLEAR:
            return {
                ...initialSate,
            };
        case TOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };
        default:
            return state;
    }
}
