import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AfOfferRequestView from '../views/AfOfferRequestView';
import { BaseController } from './BaseController';
import { Company } from '../company/Company.interface';
import { setPrerenderStatus } from '../lib/helpers';

interface Props {
  company?: Company;
}

export class AfOfferRequestController extends BaseController<RouteComponentProps & Props> {
  componentDidMount() {
    super.componentDidMount();
    this.metaTagService.setOfferRequestFormTags();
    setPrerenderStatus(true);
  }

  render() {
    return (
      <AfOfferRequestView>
        {this.props.children}
      </AfOfferRequestView>
    );
  }
}
