import React, { ChangeEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { BaseComponent, BaseState } from '../components/BaseComponent';
import { userSelectors } from '../store/user';
import { formatDate } from '../lib/helpers';
import { ExportService } from '../services/export';
import { ExportData } from './export-data';
import { ExportDataFormat, ExportDataStatus, ExportDataType } from '../shared/types';

import './AdminExportTools.scss';

const API_URL = process.env.REACT_APP_API_URL;
const supportedDateFormats = ['D.M.YYYY', 'YYYY-MM-DD'];

interface DownloadItem {
  filename: string;
  data: Blob;
}

interface State extends BaseState {
  dataType: ExportDataType;
  dataFormat: ExportDataFormat;
  dateFrom: string;
  dateTo: string;
  dateValidation: {
    dateFrom: boolean;
    dateTo: boolean;
  },
  downloadItems: DownloadItem[];
  exportData: ExportData[];
}

interface Props {
  isAdmin: boolean;
}

export class AdminExportTools extends BaseComponent<RouteComponentProps & Props, State> {
  state = {
    dataType: ExportDataType.OfferRequestsPerCompany,
    dataFormat: ExportDataFormat.pdf,
    dateFrom: moment().subtract(30, 'days').format('D.M.YYYY'),
    dateTo: moment().format('D.M.YYYY'),
    dateValidation: {
      dateFrom: true,
      dateTo: true
    },
    downloadItems: [],
    exportData: [],
  } as State;

  private statusText = new Map<ExportDataStatus, string>([
    [ExportDataStatus.Completed, 'Valmis'],
    [ExportDataStatus.Failed, 'Virhe'],
    [ExportDataStatus.Pending, 'Käsittelyssä'],
  ]);

  private formatText = new Map<ExportDataFormat, string>([
    [ExportDataFormat.pdf, 'PDF'],
    [ExportDataFormat.xlsx, 'Excel'],
  ]);

  private typeText = new Map<ExportDataType, string>([
    [ExportDataType.AllContracts, 'Avoimet toimeksiantosopimukset'],
    [ExportDataType.OfferRequestsPerCompany, 'Vinkit per yritys'],
    [ExportDataType.OfferRequestsPerCompanyAdditional, 'Vinkit per yritys (tarjoukset)'],
  ]);

  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      isLoading: false,
      initialLoadDone: true,
    });
    this.fetchExports();
  }

  onExportDataChange(dataType: string, dataFormat: string) {
    this.setState({ dataType, dataFormat });
  }

  onDatePickerChange(fieldName: 'dateFrom' | 'dateTo', event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const dateValidation = {
      ...this.state.dateValidation,
      [fieldName]: moment(value, supportedDateFormats, true).isValid(),
    };
    this.setState({ dateValidation, [fieldName]: value });
  }

  onFileDownload(item: DownloadItem, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    event.stopPropagation();
    if (item?.filename && item?.data) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(item.data);
      link.download = item.filename;
      link.click();
    }
  }

  async fetchExports() {
    const service = new ExportService();
    this.setState({
      exportData: await service.get(),
    });
    const hasPending = this.state.exportData.some(item => item.status === ExportDataStatus.Pending);
    if (hasPending) {
      setTimeout(() => this.fetchExports(), 2500);
    }
  }

  async doExport() {
    if (!this.state.dateValidation.dateFrom || !this.state.dateValidation.dateTo) {
      return;
    }
    this.isLoading = true;
    try {
      const service = new ExportService();
      const dateFrom = moment(this.convertToDate(this.state.dateFrom)).format('YYYY-MM-DD');
      const dateTo = moment(this.convertToDate(this.state.dateTo)).format('YYYY-MM-DD');
      await service.createExport().post({
        dataType: this.state.dataType,
        dataFormat: this.state.dataFormat,
        dateFrom,
        dateTo
      });
      await this.fetchExports();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      toast.error('Jotain meni pieleen.');
    }
  }

  render() {
    return <div className="af-view">
      <div className="af-class-body">
        <div className="w-clearfix">
          <h1 className="af-class-admin-h1">Export-työkalut</h1>
        </div>

        <div className="w-container export-tools-container">
          <div className="admin-export-tools-container">
            <fieldset disabled={this.isLoading}>
              <legend>Kerättävä data</legend>
              <div className="radio-button-container">
                <input
                  type="radio"
                  id="offerRequestsPerCompanyPdf"
                  checked={this.state.dataType === 'offerRequestsPerCompany' && this.state.dataFormat === 'pdf'}
                  onChange={() => this.onExportDataChange('offerRequestsPerCompany', 'pdf')} />
                <label htmlFor="offerRequestsPerCompanyPdf">Vinkit per yritys (PDF)</label>
              </div>
              <div className="radio-button-container">
                <input
                  type="radio"
                  id="offerRequestsPerCompanyXlsx"
                  checked={this.state.dataType === 'offerRequestsPerCompany' && this.state.dataFormat === 'xlsx'}
                  onChange={() => this.onExportDataChange('offerRequestsPerCompany', 'xlsx')} />
                <label htmlFor="offerRequestsPerCompanyXlsx">Vinkit per yritys (Excel)</label>
              </div>
              <div className="radio-button-container">
                <input
                  type="radio"
                  id="allContracts"
                  checked={this.state.dataType === 'allContracts'}
                  onChange={() => this.onExportDataChange('allContracts', 'xlsx')} />
                <label htmlFor="allContracts">Avoimet toimeksiantosopimukset (zip)</label>
              </div>
            </fieldset>
            <div className="date-picker-container">
              <div className="date-picker-section">
                <label htmlFor="dateFrom">Alkaen:</label>
                <input
                  type="text"
                  id="dateFrom"
                  className="af-class-input-field w-input"
                  value={this.state.dateFrom}
                  disabled={this.isLoading}
                  onChange={event => this.onDatePickerChange('dateFrom', event)} />
                <div className={this.state.dateValidation.dateFrom ? 'date-validation-msg' : 'date-validation-msg is-invalid'}>
                  <span>{this.state.dateValidation.dateFrom ? '' : 'Anna päivämäärä muodossa: pp.kk.vvvv'}</span>
                </div>
              </div>
              <div className="date-picker-section">
                <label htmlFor="dateTo">Päättyen:</label>
                <input
                  type="text"
                  id="dateTo"
                  className="af-class-input-field w-input"
                  value={this.state.dateTo}
                  disabled={this.isLoading}
                  onChange={event => this.onDatePickerChange('dateTo', event)} />
                <div className={this.state.dateValidation.dateTo ? 'date-validation-msg' : 'date-validation-msg is-invalid'}>
                  <span>{this.state.dateValidation.dateTo ? '' : 'Anna päivämäärä muodossa: pp.kk.vvvv'}</span>
                </div>
              </div>
            </div>
            <div className="button-container">
              <button
                type="button"
                className="af-class-button af-class-alt-button w-button"
                disabled={this.isLoading}
                onClick={() => this.doExport()}>
                {this.isLoading ? <div className="ld ld-ring ld-spin"></div> : 'Export'}</button>
            </div>
            {this.getExportDataList()}
          </div>
        </div>
      </div>
    </div>;
  }

  private getExportDataList() {
    const { exportData } = this.state;
    if (!Array.isArray(exportData) || exportData.length === 0) {
      return null;
    }
    return (
      <table className="export-data-list">
        <thead>
          <tr>
            <th className="updated-at">Päivittynyt</th>
            <th className="type">Tyyppi</th>
            <th className="format">Formaatti</th>
            <th className="status">Status</th>
            <th className="download-link">Lataa</th>
          </tr>
        </thead>
        <tbody>
          {exportData.map((item, index) => {
            const downloadLink = item.status === ExportDataStatus.Completed
              ? <a href={`${API_URL}/export/download?filename=${item.filename}&accessToken=${item.accessToken}`}>{item.filename}</a>
              : null;
            const status = item.status === ExportDataStatus.Pending
              ? <span><span className="ld ld-ring ld-spin"></span> {this.statusText.get(item.status)}</span>
              : <span>{this.statusText.get(item.status)}</span>
            return <tr key={`tr-${index + 1}`}>
              <td className="updated-at">{formatDate(item.updatedAt, 'd.M.yyyy, HH:mm')}</td>
              <td className="type">{this.typeText.get(item.type)}</td>
              <td className="type">{this.formatText.get(item.format)}</td>
              <td className={`status status-${item.status}`}>{status}</td>
              <td className="download-link">{downloadLink}</td>
            </tr>;
          })}
        </tbody>
      </table>
    );
  }

  private convertToDate(value: string): Date | null {
    try {
      if (typeof value !== 'string' || value.length === 0) {
        return null;
      }
      if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        return new Date(Date.parse(value));
      }
      const matches = value.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/)
      if (matches) {
        const [, day, month, year] = matches;
        return new Date(Date.parse(`${year}-${month}-${day}`));
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}

const mapStateToProps = (state: any) => ({
  isAdmin: userSelectors.isAdmin(state),
});

export default connect(
  mapStateToProps,
  {
  },
)(AdminExportTools);
