import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import invert from 'lodash/invert';
import { UserProfile } from '../store/user/reducer';
import { ApiError } from '../lib/api-error';
import { Form, FormState, FormInitialState } from '../components/Form';
import { CompanyManagerService } from '../services/company-manager';
import { CompanyService } from '../services/company';
import { AfAdminCompanyFormController } from '../controllers/AfAdminCompanyFormController';
import { userSelectors } from '../store/user';
import icons from '../icons.json';
import { logger } from '../lib/debug';
import { BillinMethod, Territory, TerritoryValue, companyServicesOptions, companyAdvertisingOptions, UploadFileType, CompanyStatus, companyStatusNames } from '../shared/types';
import { loadingProps } from '../lib/helpers';
import Api from '../lib/api';

declare global {
    interface Window { PUBLIC_URL: string; }
}

const { REACT_APP_UPLOADS_URL } = process.env;

const log = logger('CompanyForm');

interface TParams {
  companyId: string;
  section?: string;
}

interface Props {
  isAdmin: boolean;
  isCompanyMainUser: (companyId: number) => boolean;
  profile: UserProfile;
}

interface State extends FormState {
  users: UserProfile[];
  territoryList: Territory[];
  territoryCityOptions: TerritoryValue[];
  territoryZipCodeAreaOptions: TerritoryValue[];
  initialFormData: any;
}

const defaults = {
  services: [],
  advertising: [],
  territoryCities: [],
  territoryZipCodeAreas: [],
};

interface Sections {
  [key: string]: string|undefined;
  tiedot: string;
  kayttajat?: string;
  kuvat?: string;
  laskutustiedot?: string;
}

export class CompanyForm extends Form<RouteComponentProps<TParams> & Props, State> {
  state = {
    ...FormInitialState,
    formData: {
      ...defaults,
    },
    initialFormData : {
      ...defaults,
    },
    section: 'company-info',
  } as State;

  get companyId(): number {
    return parseInt(this.props.match.params.companyId, 10);
  }

  get isNew(): boolean {
    return !this.props.match.params.companyId;
  }

  get sections(): Sections {
    const { isAdmin, isCompanyMainUser } = this.props;

    const sections: Sections = {
      tiedot: 'company-info',
    };

    if (!this.isNew) {
      sections.laskutustiedot = 'billing-info';
      sections.kuvat = 'images-info';
    }

    if ((isAdmin || isCompanyMainUser(this.companyId)) && !this.isNew) {
      sections.kayttajat = 'users-info';
    }

    return sections;
  }

  async componentDidMount() {
    super.componentDidMount();

    await this.load();
    if (this.props.match.params.section && this.sections[this.props.match.params.section]) {
      this.onSelectSection(this.sections[this.props.match.params.section] as string);
    }
  }

  onSubmitError() {
    toast.error('Lomakkeella on virheitä.');
  }

  async load() {
    try {
      const publicApi = new Api('json/territory-list.json', `${window.location.origin}${window.PUBLIC_URL}`);

      const service = new CompanyManagerService(this.companyId);
      const [ company, territoryList ] = await Promise.all([this.isNew ? Promise.resolve({}) : service.get(), publicApi.get()]);

      const list = uniqBy(territoryList, 'city') as unknown as Territory[];
      const territoryCityOptions = list
        .map((territory: Territory): TerritoryValue => ({
          value: territory.city,
          label: territory.city,
          data: territory,
        }));

      const territoryZipCodeAreaOptions = uniqBy(territoryList, (territory: Territory) => territory.area + territory.zipCode)
        .map((territory: Territory): TerritoryValue => ({
          value: `${territory.zipCode} ${territory.area}`,
          label: `${territory.zipCode} ${territory.area}`,
          data: territory,
        }));

      const initialFormData = { ...this.state.formData, ...company };

      this.setStateAfterLoad({
        formData: { ...initialFormData },
        initialFormData: { ...initialFormData },
        territoryList,
        territoryCityOptions,
        territoryZipCodeAreaOptions,
      });
    } catch (error) {
      log('Failed to load company', error);
      toast.error('Yrityksen tietojen hakeminen epäonnistui!');
    }
  }

  async saveformData(): Promise<boolean> {
    const { formData, section } = this.state;

    try {
      if (section === 'users-info') {
        const result = await this.linkUser();
        return result;
      } else if (section === 'images-info') {
        const result = await this.uploadImages();
        return result;
      } else if (this.isNew) {
        const service = new CompanyService();
        const company = await service.post(formData);
        this.props.history.push(`/hallinta/${company.id}`);
      } else {
        const service = new CompanyManagerService(this.companyId);
        await service.put(formData);
      }

      this.setState({ initialFormData: { ...this.state.initialFormData, ...formData } });
      toast.success('Tallennus onnistui.');

      return true;
    } catch (error) {
      log('save failure', error);
      toast.error('Tallennus epäonnistui.');
    }

    return false;
  }

  async uploadImages() {
    const { formData } = this.state;

    const files = ['logoImage', 'backgroundImage'];

    const preData = { fileType: UploadFileType.Image } as any;
    files.forEach((key) => {
      if (!formData[key] || !formData[key].type) {
        return;
      }
      preData[key] = {
        size: formData[key].size,
        type: formData[key].type
      };
    });

    const service = new CompanyManagerService(this.companyId).child('upload');
    const result = await service.post(preData);

    const confirmData = {} as any;
    await Promise.all(files.map(async (key) => {
      if (!formData[key] || !formData[key].type) {
        if (formData[key] === false) {
          confirmData[key] = null;
        }
        return;
      }

      const uploadResult = await fetch(result[key].url, {
        method: 'PUT',
        body: formData[key],
        headers: {
          'cache-control': 'public, max-age=31536000',
        },
      });
      if (!uploadResult || uploadResult.status !== 200) {
        throw new Error(`Failed to upload ${key}`);
      }

      confirmData[key] = `${REACT_APP_UPLOADS_URL}/${result[key].key}`;
    }));

    await service.put(confirmData);

    toast.success('Kuvat lähetetty onnistuneesti.');
    return true;
  }

  async linkUser() {
    const { formData } = this.state;
    const { userId } = formData;

    let user: UserProfile;
    const service = new CompanyManagerService(this.companyId).child('users');

    try {
      if (userId.match(/^[0-9]+$/)) {
        user = await service.child(userId).get() as UserProfile;
      } else {
        user = await service.query({ email: userId, exact: true }).get() as UserProfile;
      }
    } catch (error) {
      if (error instanceof ApiError && error.isNotFound()) {
        toast.warn('Käyttäjää ei löydy');
        return false;
      }

      throw error;
    }

    const { users } = this.state;

    if (users.find((u: UserProfile) => u.id === user.id)) {
      toast.warn('Käyttäjää on jo linkitetty');
      return false;
    }

    await service.query({}).post({ userId: user.id });
    await this.loadUsers();
    this.setState({ formData: { ...formData, userId: '' } });
    toast.success('Käyttäjä linkitetty yritykseen onnistuneesti!');

    return true;
  }

  async loadUsers() {
    try {
      const service = new CompanyManagerService(this.companyId).child('users');
      const users = await service.get();
      this.setState({ users });
    } catch (error) {
      toast.error('Käyttäjien haku epäonnistui');
    }
  }

  async removeLink(userId: number) {
    const { isAdmin, profile } = this.props;

    try {
      if (!isAdmin && userId === profile.id) {
        window.alert('Et voi poistaa omaa tunnustasi yrityksestä. Ota yhteys asiakaspalveluun tai yrityksen toiseen pääkäyttäjään.');
        return;
      }

      if (!window.confirm('Olet varma, että linkitys poistetaan?')) {
        return;
      }
      await new CompanyManagerService(this.companyId).child('users').child(userId).del();
      await this.loadUsers();
      toast.success('Käyttäjän ja yrityksen linkitys poistettu onnistuneesti!');
    } catch (e) {
      toast.error('Käyttäjän ja yrityksen linkityksen poisto epäonnistui!');
    }
  }

  section() {
    const { isAdmin } = this.props;
    const { section, formData, territoryCityOptions, territoryZipCodeAreaOptions } = this.state;

    if (section === 'company-info') {
      const { id } = formData;
      const inputs = [
        this.renderTextInput('name'),
        this.renderTextInput('marketingName'),
        this.renderTextInput('description'),
        this.renderTextInput('businessId'),
        this.renderTextInput('address'),
        this.renderInput('zipCode'),
        this.renderInput('city'),
        this.renderInput('email'),
        this.renderInput('www', null, { type: 'url' }),
        this.renderInput('phone'),
        this.renderInput('visitingHours'),
        this.renderInput('founded'),
        this.renderInput('numberOfEmployees'),
        this.renderInput('commissionOfHousingCooperative'),
        this.renderInput('commissionOfProperty'),
        this.renderInput('minCommissionOfHousingCooperative'),
        this.renderInput('minCommissionOfProperty'),
        this.renderInput('baseCostsOfHousingCooperative'),
        this.renderInput('baseCostsOfProperty'),
        this.renderSelect('documentCharges'),
        this.renderSelect('marketingExpenses'),
        this.renderSelect('useDeptFreePrice'),
        this.renderSearchSelection('services', companyServicesOptions),
        this.renderSearchSelection('advertising', companyAdvertisingOptions),
        this.renderSearchSelection('territoryCities', territoryCityOptions, { max: 5 }),
        this.renderSearchSelection('territoryZipCodeAreas', territoryZipCodeAreaOptions, { max: 20 }),
      ];

      if (isAdmin && !this.isNew) {
        inputs.push((
          <af-approval-wrapper key='approval'>
            <af-company-id>ID: {id}</af-company-id>
            {this.renderSelect('status', (
              <>
                <option value={CompanyStatus.approved}>{companyStatusNames[CompanyStatus.approved]}</option>
                <option value={CompanyStatus.notApproved}>{companyStatusNames[CompanyStatus.notApproved]}</option>
                <option value={CompanyStatus.noOffers}>{companyStatusNames[CompanyStatus.noOffers]}</option>
              </>
            ), {}, 'approved')}
          </af-approval-wrapper>
        ));
      }

      return inputs;
    }

    if (section === 'billing-info') {
      const { billingMethod } = formData;
      const inputs = [
        <af-company-id>ID: {this.companyId}</af-company-id>,
        this.renderSelect('billingMethod', (
          <>
            <option value=''>Valitse laskutustapa</option>
            <option value={BillinMethod.email}>Sähköposti</option>
            <option value={BillinMethod.eInvoice}>Verkkolasku</option>
            <option value={BillinMethod.paperInvoice}>Paperilasku</option>
          </>
        )),
      ];

      if (billingMethod === BillinMethod.email) {
        inputs.push((
          <af-billing-type-email key='billing-type-email'>
            {[
              this.renderInput('billingEmail'),
            ]}
          </af-billing-type-email>
        ));
      }
      if (billingMethod === BillinMethod.eInvoice) {
        inputs.push((
          <af-billing-type-e-invoicing key='billing-type-e-invoicing'>
            {[
              this.renderInput('eInvoiceAddress'),
              this.renderInput('eInvoiceBrokerId'),
            ]}
          </af-billing-type-e-invoicing>
        ));
      }
      if (billingMethod === BillinMethod.paperInvoice) {
          inputs.push((
            <af-billing-type-paper-invoice key='billing-type-paper-invoice'>
              {[
                this.renderInput('billingAddress'),
                this.renderInput('billingZipCode'),
                this.renderInput('billingCity'),
              ]}
            </af-billing-type-paper-invoice>
          ));
      }

      return inputs;
    }

    if (section === 'users-info') {
      return [
        this.renderInput('userId'),
      ];
    }

    if (section === 'images-info') {
      return [
        ...this.renderImageInput('logoImage'),
        ...this.renderImageInput('backgroundImage'),
      ];
    }

    return [];
  }

  onSelectSection = (newSection: string) => {
    const prevSection = this.state.section;
    const sections = invert(this.sections);
    this.setState({ section: newSection, formData: { ...this.state.initialFormData } }, () => {
      const { section } = this.state;
      if (prevSection !== section) {
        const { history, match } = this.props;
        let url = match.url;
        if (!match.url.match(/[0-9]+$/)) {
          const parts = match.url.split('/');
          parts.pop();
          url = parts.join('/');
        }
        if (section !== 'company-info') {
          url += `/${sections[section]}`;
        }
        history.push(url);
      }

      if (section === 'users-info') {
        this.loadUsers();
      }
    });
  }

  onChangeUserStatus(user: UserProfile) {
    return async (e: any) => {
      try {
        const service = new CompanyManagerService(this.companyId).child('users').child(user.id);
        const key = e.target.name;
        const value = parseInt(e.target.value,  10) === 1;
        const result = await service.put({
          [key]: value,
        });

        const indx = user.companies.findIndex(c => c.companyId === this.companyId);
        if (indx >= 0) {
          user.companies[indx][key] = result[key];
        }

        const users = [
          ...this.state.users.filter(u => u.id !== user.id),
          user,
        ];

        this.setState({ users });

        toast.success('Käyttäjän asema asetettu onnistuneesti!');
      } catch (e) {
        toast.error('Käyttäjän aseman asettaminen epäonnistui!');
      }
    };
  }

  renderFormChildren(): any {
    const { section } = this.state;

    const SubmitBtn = `af-${section}-submit` as unknown as React.ComponentType<any>;

    return [
      <SubmitBtn onClick={this.handleSubmit} key='submit' {...loadingProps(this.isSectionSaving())} />,
    ];
  }

  renderUserRow = (user: any) => {
    const { isAdmin, profile } = this.props;
    const { formData } = this.state;
    const {
      companies,
      firstName,
      lastName,
      id,
      email,
      slug,
    } = user;
    const company = companies.find((c: any) => c.companyId === this.companyId);

    const {
      isMainUser,
      isPublicProfile,
    } = company;

    const hasProfile = firstName && lastName && slug;

    return (
      <af-linked-user key={id}>
        {isAdmin && <af-linked-user-id>ID: {id}</af-linked-user-id>}
        {this.afLink('linked-user-info', {
          company: this.companyId,
          userId: id,
        }, hasProfile ? `${firstName} ${lastName} (${email})` : `${email} (profiili puuttuu)`)}
        <af-linked-user-remove onClick={() => this.removeLink(id)} />
        {hasProfile && isPublicProfile && this.afLink('public-link-agent', {
          city: formData.city,
          company: formData.slug,
          agent: slug,
        }, `asuntoarvio.fi/kiinteistonvalittaja/profiili/${slug}`)}
        {hasProfile && (
          <af-linked-user-profile-status
            value={isPublicProfile ? '1' : '0'}
            onChange={this.onChangeUserStatus(user)}
          />
        )}
        <af-linked-user-admin-status
          value={isMainUser ? '1' : '0'}
          disabled={user.id === profile.id}
          onChange={this.onChangeUserStatus(user)}
        />
      </af-linked-user>
    );
  }

  renderSectionChildren(): any {
    const { section, users } = this.state;

    if (section !== 'users-info' || !users) {
      return null;
    }

    return (
      <af-linked-users>
        {users.map(this.renderUserRow)}
      </af-linked-users>
    );
  }

  render() {
    const { section, formData } = this.state;
    const sections: string[] = Object.values(this.sections) as string[];

    return (
      <AfAdminCompanyFormController
        {...(this.props as any)}
        sections={sections}
        activeSection={section as string}
        onSelectSection={this.onSelectSection}
        slug={formData.slug}
        city={formData.city}
        isNew={this.isNew}
      >
        {this.hasErrors() && (
          <af-error-wrapper>
            {this.getAllErrors().map((error: string) => (
              <af-error key={error}>
                <span className='af-class-exclamation'>{icons.exclamation}</span> {error}
              </af-error>
            ))}
          </af-error-wrapper>
        )}
        {this.renderForm()}
      </AfAdminCompanyFormController>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isAdmin: userSelectors.isAdmin(state),
  isCompanyMainUser: userSelectors.isCompanyMainUser(state),
  profile: userSelectors.getProfile(state),
});

export default connect(
  mapStateToProps,
  {
  },
)(CompanyForm);
