/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfFindAgency2View extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfFindAgency2Controller')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfFindAgency2View

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfFindAgency2View ? transformProxies(this.props.children) : {
      'af-agency-heading': [],
      'af-agent-heading': [],
      'af-search-box': [],
      'af-search-btn': [],
      'af-search-type-wrapper': [],
      'af-sample-search': [],
      'af-company-search': [],
      'af-agent-search': [],
      'af-popular-search-wrapper': [],
      'af-popular-area': [],
      'af-data-wrapper': [],
      'af-search-keyword': [],
      'af-search-total': [],
      'af-agency-card': [],
      'af-logo-image': [],
      'af-top-100': [],
      'af-marketing-name': [],
      'af-address': [],
      'af-stars': [],
      'af-description': [],
      'af-company-link': [],
      'af-ask-offer-link': [],
      'af-agent-card': [],
      'af-logo-image-agent': [],
      'af-logo-image-company-small': [],
      'af-top-100-agent': [],
      'af-agent-name': [],
      'af-agent-title': [],
      'af-stars-agent': [],
      'af-description-agent': [],
      'af-link-agent': [],
      'af-ask-offer-link-agent': [],
      'af-fetch-more': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-find-heading-section">
              <div data-collapse="medium" data-animation="default" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="af-class-navbar-color af-class-navbar-color-alt w-nav">
                <div className="w-container">
                  <div className="af-class-menu-button w-nav-button">
                    <div className="af-class-menu-dark-hamburger w-icon-nav-menu" />
                  </div>
                </div>
                <a href="/" className="af-class-brand w-nav-brand"><img src="images/asuntoarvio-logo-white-2020.svg" width={220} alt="Asuntoarvio logo white" height={53} className="af-class-asuntoarvio-logo" /></a>
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <a href="/kilpailutuspalvelu" className="af-class-nav-link-color af-class-nav-link-alt-2 w-nav-link">Kilpailuta kiinteistönvälitys</a>
                  <a href="/kiinteistonvalitys" className="af-class-nav-link-color af-class-nav-link-alt-2 w-nav-link">Etsi kiinteistönvälittäjä</a>
                  <a href="https://asuntoarvio.fi/mika-on-asuntoarvio" className="af-class-nav-link-color af-class-nav-link-alt-2 w-nav-link">Mikä on Asuntoarvio?</a>
                  <a href="https://asuntoarvio.fi/kiinteistonvalittajille" className="af-class-nav-link-color af-class-nav-link-alt-2 af-class-nav-link-alt-last w-nav-link">Kiinteistönvälittäjille</a>
                </nav>
              </div>
              {map(proxies['af-agency-heading'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-heading-container w-container ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                <h1 className="af-class-heading af-class-h1-small-new">Etsi kiinteistönvälitys</h1>
                <p className="af-class-lead-paragraph-heading-new">Kiinteistönvälityshaun avulla löydät Suomen kiinteistönvälitystoimistot. Vertaa kokemuksia, arvostelut ja löydä asunnolle paras kiinteistönvälitys. Vertailu asunnon myynnissä on aina ilmaista.</p>
              </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
              {map(proxies['af-agent-heading'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-heading-container w-container ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                <h1 className="af-class-heading af-class-h1-small-new">Etsi kiinteistönvälittäjä</h1>
                <p className="af-class-lead-paragraph-heading-new">Kiinteistönvälittäjähaun avulla löydät Suomen kiinteistönvälittäjät. Vertaa kokemuksia, arvostelut ja löydä asunnolle paras kiinteistönvälittäjä. Vertailu asunnon myynnissä on aina ilmaista.</p>
              </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
              <div className="af-class-lkv-heading-container w-container">
                <div id="input-hero-frontpage" className="af-class-input-hero-find-agency-agents w-clearfix">
                  {map(proxies['af-search-box'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-43 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Etsi välitysliike</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {map(proxies['af-search-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-4 w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Etsi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                </div>
                {map(proxies['af-search-type-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-152 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  {map(proxies['af-sample-search'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-select-btn-active ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Aktiivinen esimerkki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  {map(proxies['af-company-search'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys" {...{...props, className: `af-class-select-btn ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kiinteistönvälitys</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  {map(proxies['af-agent-search'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalittaja" {...{...props, className: `af-class-select-btn ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kiinteistönvälittäjä</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                {map(proxies['af-popular-search-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-city-lift-wrapper-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  <div className="af-class-lkv-city-lift-2-title">Suosituimpia hakuja:</div>
                  <div className="af-class-lkv-city-lift-tag-2">
                    {map(proxies['af-popular-area'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/[searchtype]/[city]" {...{...props, className: `af-class-lkv-city-lift-tag-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Helsinki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  </div>
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
              </div>
            </div>
            <div className="af-class-lkv-list-results-section">
              {map(proxies['af-data-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                <div className="af-class-lkv-result-list-container w-container">
                  {map(proxies['af-search-keyword'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h2 {...{...props, className: `af-class-lkv-list-result-search ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kiinteistönvälitys Helsinki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </h2>
                ))}
                  {map(proxies['af-search-total'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-list-result-heading-small ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Yhteensä 32 välitysliikettä</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {map(proxies['af-agency-card'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-lkv-result-wrapper w-clearfix">
                      <div className="af-class-lkv-result-logo-wrapper">
                        {map(proxies['af-logo-image'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-86 ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-lkv-result-content-wrapper w-clearfix">{map(proxies['af-top-100'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="images/top100.svg" width={41} alt="top-100" {...{...props, className: `af-class-image-6 ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </img>
                ))}
                        <h3 className="af-class-lkv-result-list-heading">
                          {map(proxies['af-marketing-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[city]/[company]" {...{...props, className: `af-class-link-6 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kiinteistönvälitys Matti Meikäläinen Oy</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        </h3>
                        {map(proxies['af-address'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-address-orginal ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Töölönkatu 3 A, 00260 Helsinki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-lkv-result-list-rating-wrapper w-clearfix">
                          {map(proxies['af-stars'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-star-full ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        </div>
                        <div>
                          {map(proxies['af-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                            <a href="/kiinteistonvalitys/[city]/[company]" className="af-class-lkv-result-p-link">Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet...</a>
                          </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                        </div>
                      </div>
                      <div className="af-class-lkv-result-button-wrapper w-clearfix">
                        {map(proxies['af-company-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[city]/[company]" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-top w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Katso yrityksen tiedot<span className="af-class-arrow-right af-class-arrow-button-medium"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        {map(proxies['af-ask-offer-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kilpailutuspalvelu/[company]" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-margin w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Pyydä tarjous yritykseltä<span className="af-class-arrow-right af-class-arrow-button-medium"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-agent-card'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-lkv-result-wrapper w-clearfix">
                      <div className="af-class-lkv-result-logo-wrapper">
                        {map(proxies['af-logo-image-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agent-face-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                          {map(proxies['af-logo-image-company-small'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-results-logo-small ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      </div>
                      <div className="af-class-lkv-result-content-wrapper w-clearfix">{map(proxies['af-top-100-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="images/top100.svg" width={41} alt="top-100" {...{...props, className: `af-class-image-6 ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </img>
                ))}
                        <div className="af-class-lkv-result-list-agent-name-wrapper">
                          <h3 className="af-class-lkv-result-list-heading-agent">
                            {map(proxies['af-agent-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalittaja/profiili/[agent]" {...{...props, className: `af-class-link-6 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Matti Meikäläinen</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                          </h3>
                        </div>
                        {map(proxies['af-agent-title'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-agent-title ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Toimitusjohtaja, LKV, LVV</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-lkv-result-list-rating-wrapper-agent w-clearfix">
                          {map(proxies['af-stars-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-star-full ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        </div>
                        <div>
                          {map(proxies['af-description-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                            <a href="/kiinteistonvalitys/[city]/[company]/[agent]" className="af-class-lkv-result-p-link">Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet...</a>
                          </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                        </div>
                      </div>
                      <div className="af-class-lkv-result-button-wrapper w-clearfix">
                        {map(proxies['af-link-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalittaja/profiili/[agent]" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-top w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Katso välittäjän tiedot<span className="af-class-arrow-right af-class-arrow-button-medium"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        {map(proxies['af-ask-offer-link-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kilpailutuspalvelu/kiinteistonvalittaja/[agent]" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-margin w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Pyydä tarjous välittäjältä<span className="af-class-arrow-right af-class-arrow-button-medium"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                </div>
                <div className="af-class-lkv-result-list-show-more-container w-container">
                  {map(proxies['af-fetch-more'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-medium af-class-static-button af-class-no-float w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Näytä lisää välitysliikkeitä (12/32)</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                </div>
              {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfFindAgency2View

/* eslint-enable */