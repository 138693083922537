import React from 'react';
import Modal from 'react-modal';
import { omit, kebabCase, mapValues, isFunction, isString, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { CompanyManagerService } from '../services/company-manager';
import { UploadService } from '../services/upload';
import { Form, FormState, FormInitialState } from '../components/Form';
import {
  OfferRequestWithCompany,
  OfferRequestStatus,
  getOfferRequestActions,
  OfferRequestActionType,
  CompanyOfferRequest,
} from './offer-request';
import { AfAdminModalsController } from '../controllers/AfAdminModalsController';
import { logger } from '../lib/debug';
import { UploadFileType } from '../shared/types';
import { UserProfile } from '../store/user/reducer';
import { Company } from '../company/Company.interface';

const log = logger('OfferRequestAction');
const { REACT_APP_UPLOADS_URL } = process.env;

interface Props {
  onStatusUpdated: (offerRequest: OfferRequestWithCompany, ...rest: any) => void;
}

interface State extends FormState {
  modalIsOpen: boolean;
  modalType: OfferRequestStatus|null;
  offerRequest: OfferRequestWithCompany;
  users: any;
  currentUser: UserProfile;
}

interface RenderActionButtonsOptions {
  history: any;
  company: Company | null;
  offerRequest: OfferRequestWithCompany;
  getActionRef: GetActionRef;
  users: any[];
  currentUser: UserProfile;
  renderNotesFormFn?: (companyOfferRequest: CompanyOfferRequest) => JSX.Element | null;
  reloadDataFn?: () => void;
}

export type GetActionRef = () => OfferRequestAction;

export class OfferRequestAction extends Form<Props, State> {
  state = {
    ...FormInitialState,
    modalIsOpen: false,
    modalType: null,
  } as State;

  static renderActionButtons(options: RenderActionButtonsOptions) {
    const { history, company, offerRequest, getActionRef, users, currentUser, renderNotesFormFn, reloadDataFn } = options;
    const { status } = offerRequest.companyOfferRequest;
    const action = getOfferRequestActions(status, currentUser, offerRequest, company ?? undefined);

    if (!action) {
      return null;
    }

    const openModal = (modalType?: OfferRequestStatus) => {
      const ref = getActionRef();

      if (!ref || !modalType) {
        return;
      }

      log('openModal', modalType);
      ref.openModal(modalType, offerRequest, users, currentUser);
    };

    const unlinkMe = () => {
      const ref = getActionRef();

      if (!ref) {
        return;
      }

      log('unlinkMe', currentUser);
      ref.unlinkMe(history, offerRequest, reloadDataFn);
    };

    const onClick = (type: OfferRequestActionType, modalType?: OfferRequestStatus) => {
      switch (type) {
        case OfferRequestActionType.ChangeStatus:
          return openModal(modalType);
        case OfferRequestActionType.UnlinkAgent:
          return unlinkMe();
      }
    }

    const renderNotesForm = isFunction(renderNotesFormFn) ? renderNotesFormFn : () => null;

    const { buttons, showInfo } = action;
    return (
      <af-action-wrapper>
        <af-action-button-wrapper>
          <af-action-btn-primary onClick={() => onClick(buttons[0].type, buttons[0].status)}>{buttons[0].label}</af-action-btn-primary>
          {buttons.length > 1 ? <af-action-btn-secondary onClick={() => onClick(buttons[1].type, buttons[1].status)}>{buttons[1].label}</af-action-btn-secondary> : null}
          {buttons.length > 2 ? <af-action-btn-third onClick={() => onClick(buttons[2].type, buttons[2].status)}>{buttons[2].label}</af-action-btn-third> : null}
          {buttons.length > 3 ? <af-action-btn-fourth onClick={() => onClick(buttons[3].type)}>{buttons[3].label}</af-action-btn-fourth> : null}
        </af-action-button-wrapper>
        {renderNotesForm(offerRequest.companyOfferRequest)}
        {showInfo && (
          <af-action-info-wrapper>
            <af-action-info></af-action-info>
          </af-action-info-wrapper>
        )}
      </af-action-wrapper>
    );
  }

  openModal = (modalType: OfferRequestStatus, offerRequest: OfferRequestWithCompany, users: any, currentUser: UserProfile) => {
    const isRevertingToContacted = this.isRevertingToContacted(modalType, offerRequest, currentUser);

    if (isRevertingToContacted) {
      this.setState({
        modalIsOpen: false,
        formData: {},
        modalType,
        offerRequest,
        users,
        currentUser,
      });
      setTimeout(() => this.saveformData());
      return;
    }

    let section = `modal-${kebabCase(modalType)}`;
    const currentStatus = offerRequest.companyOfferRequest.status;
    if (modalType === OfferRequestStatus.rejected && currentStatus === OfferRequestStatus.contacted) {
      section += '-2';
    }

    this.setState({
      modalIsOpen: true,
      formData: {},
      modalType,
      section,
      offerRequest,
      users,
      currentUser,
    });
  }

  unlinkMe = async (history: any, offerRequest: OfferRequestWithCompany, reloadDataFn?: () => void) => {
    const service = new CompanyManagerService(offerRequest.companyOfferRequest.companyId);
    const api = service.offerRequest().child(offerRequest.id).child('unlink-me');
    const isDetailView = /\/hallinta\/\d+\/tarjouspyynto\/\d+$/.test(history?.location?.pathname ?? '');

    try {
      await api.put({});
      if (isDetailView) {
        history.push(`/hallinta/${offerRequest.companyOfferRequest.companyId}/tarjouspyynnot/avoimet`);
      } else if (isFunction(reloadDataFn)) {
        reloadDataFn();
      }
    } catch (error) {
      toast.error('Linkityksen poistaminen epäonnistui');
    }
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      modalType: null,
    });
  }

  async saveformData(): Promise<boolean> {
    const { onStatusUpdated } = this.props;
    const { modalType, formData, offerRequest } = this.state;

    if (!modalType) {
      return false;
    }

    if (modalType === OfferRequestStatus.fulfilled) {
      await this.uploadCommissionReceipt();
    }

    const reasonRejected = this.getReasonRejected(formData);
    const formDataParsed = reasonRejected
      ? { ...omit(formData, ['reasonRejectedOpen', 'reasonRejectedContacted', 'reasonRejectedContract']), reasonRejected }
      : formData;

    const service = new CompanyManagerService(offerRequest.companyOfferRequest.companyId);
    const api = service.offerRequest().child(offerRequest.id);

    try {
      const result = await api.put({
        ...formDataParsed,
        status: modalType,
      });

      onStatusUpdated(offerRequest, modalType as OfferRequestStatus, mapValues(formDataParsed, (_, key: any) => result[key]));
      this.closeModal();
      toast.success('Tarjouksen tilan muutettu onnistuneesti.');
    } catch (error) {
      toast.error('Tarjouksen tilan muutos epäonnistui.');
    }

    return true;
  }

  async uploadCommissionReceipt(): Promise<boolean> {
    try {
      const { formData, offerRequest, currentUser } = this.state;
      const { commissionReceipt } = formData;
      const companyId: number = offerRequest.companyOfferRequest?.company?.id;

      const preData = {
        commissionReceipt: {
          size: commissionReceipt.size,
          type: commissionReceipt.type
        },
        fileType: UploadFileType.CommissionReceipt
      };

      const service = new UploadService(companyId);
      const result = await service.post(preData);
      const { key, url } = result?.commissionReceipt ?? {};

      if (typeof key !== 'string' || key.length === 0) {
        throw new Error('Failed to upload commission receipt');
      }
      const uploadResult = await fetch(url, {
        method: 'PUT',
        body: commissionReceipt,
        headers: {
          'cache-control': 'public, max-age=31536000',
        },
      });

      if (!uploadResult || uploadResult.status !== 200) {
        throw new Error(`Failed to upload ${key}`);
      }

      const confirmData = `${REACT_APP_UPLOADS_URL}/${key}`;

      await service.put({
        commissionReceipt: confirmData,
        fileType: UploadFileType.CommissionReceipt,
        companyOfferRequestId: offerRequest.companyOfferRequest.id,
      });

      return true;
    } catch (error) {
      toast.error('PDF:n lataus epäonnistui');
      throw error;
    }
  }

  section() {
    const { modalType, offerRequest, users } = this.state;

    const inputs = [
      this.renderDateInput(`${modalType}At`),
    ];

    if (modalType === OfferRequestStatus.contract) {
      inputs.push(this.renderInput('askingPrice'));
      inputs.push(this.renderCheckbox('isOfferSale'));
    }
    if (modalType === OfferRequestStatus.fulfilled) {
      inputs.push(this.renderInput('sellingPrice'));
      inputs.push(this.renderInput('commission'));
      inputs.push(this.renderFileUpload('commissionReceipt'));

      const { companyOfferRequest } = offerRequest;
      if (companyOfferRequest) {
        const hasLinkedUsers = (companyOfferRequest.users || []).length > 0;
        if (!hasLinkedUsers && users.length) {
          inputs.push(
            <af-link-agent-container>
              {this.renderSelect('agentLink', (
                <>
                  <option value=''>Valitse kiinteistönvälittäjä</option>
                  {
                    users
                      .sort((a: any, b: any) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
                      .map((user: any) => <option value={user.id}>{user.firstName} {user.lastName}</option>)
                  }
                </>
              ))}
            </af-link-agent-container>
          );
        }
      }
    }

    return inputs;
  }

  getRejectReasonElements(): JSX.Element[] {
    const { offerRequest } = this.state;
    const { companyOfferRequest } = offerRequest;
    const { status } = companyOfferRequest;
    const elements = [];

    if (status === OfferRequestStatus.open) {
      elements.push(
        <af-link-agent-container key="reject-reason">
          {this.renderSelect('reasonRejectedOpen', (
            <>
              <option value=''>Valitse syy hylkäykselle</option>
              <option value='outOfTerritory'>Kohde ei toimialueella</option>
              <option value='areaNotInteresting'>Asuinalue ei ole kiinnostava</option>
              <option value='targetNotInteresting'>Kohde ei ole kiinnostava</option>
              <option value='otherLeadSource'>Vinkki saapunut aikaisemmin toisesta liidikanavasta</option>
              <option value='existingCustomer'>Myyjä on jo asiakas</option>
            </>
          ))}
        </af-link-agent-container>
      );
    } else if (status === OfferRequestStatus.contacted) {
      elements.push(
        <af-link-agent-container key="reject-reason">
          {this.renderSelect('reasonRejectedContacted', (
            <>
              <option value=''>Valitse syy hylkäykselle</option>
              <option value='noContact'>Asiakkaaseen ei saatu yhteyttä</option>
              <option value='noSale'>Asiakas ei ole myymässä kohdetta</option>
              <option value='ownAcquisition'>Kohde on tullut oman hankinnan kautta ennen vinkin saapumista</option>
              <option value='otherService'>Kohde on tullut toisen vinkkipalvelun kautta ennen vinkin saapumista</option>
              <option value='otherReason'>Muu syy</option>
            </>
          ))}
        </af-link-agent-container>
      );
    } else if (status === OfferRequestStatus.contract) {
      elements.push(
        <af-link-agent-container key="reject-reason">
          {this.renderSelect('reasonRejectedContract', (
            <>
              <option value=''>Valitse syy hylkäykselle</option>
              <option value='contractExpired'>Toimeksiantosopimus päättynyt ja uutta sopimusta ei tehdä</option>
              <option value='terminatedByCustomer'>Asiakas irtisanonut sopimuksen</option>
              <option value='terminatedByCompany'>Välitysliike irtisanonut sopimuksen</option>
              <option value='otherReason'>Muu syy</option>
            </>
          ))}
        </af-link-agent-container>
      );
    }

    return elements;
  }

  renderFormChildren(): null | JSX.Element | JSX.Element[] {
    const { section } = this.state;
    const CancelButton = `af-${section}-cancel-btn` as unknown as React.ComponentType<any>;
    const ConfirmButton = `af-${section}-confirm-btn` as unknown as React.ComponentType<any>;
    const rejectReasonElems = ['modal-rejected', 'modal-rejected-2', 'modal-not-fulfilled'].includes(`${section}`)
      ? this.getRejectReasonElements()
      : [];

    return [
        ...rejectReasonElems,
        <CancelButton onClick={this.closeModal} key='cancel' />,
        <ConfirmButton onClick={this.handleSubmit} key='confirm' />,
    ];
  }

  renderModal() {
    const { modalIsOpen } = this.state;

    return (
      <Modal
        key='modal'
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel='Example Modal'
      >
        <AfAdminModalsController>
          {this.renderForm()}
        </AfAdminModalsController>
      </Modal>
    );
  }

  render() {
    return this.renderModal();
  }

  private getReasonRejected(formData: any): string | undefined {
    const { reasonRejectedOpen, reasonRejectedContacted, reasonRejectedContract } = formData;
    if (isString(reasonRejectedOpen) && !isEmpty(reasonRejectedOpen)) {
      return reasonRejectedOpen;
    } else if (isString(reasonRejectedContacted) && !isEmpty(reasonRejectedContacted)) {
      return reasonRejectedContacted;
    } else if (isString(reasonRejectedContract) && !isEmpty(reasonRejectedContract)) {
      return reasonRejectedContract;
    }
    return undefined;
  }

  private isRevertingToContacted(modalType: OfferRequestStatus | null, offerRequest: OfferRequestWithCompany, currentUser: UserProfile): boolean {
    const currentStatus = offerRequest.companyOfferRequest.status;
    const isSuperAdmin = currentUser.isAdmin === true;
    return isSuperAdmin && modalType === OfferRequestStatus.contacted && currentStatus === OfferRequestStatus.rejected;
  }
}
