import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AfReviewsLandingView from '../views/AfReviewsLandingView';
import { BaseController } from './BaseController';

export class AfReviewsLandingController extends BaseController<RouteComponentProps> {
  render() {
    return (
      <AfReviewsLandingView>
        {this.props.children}
      </AfReviewsLandingView>
    );
  }
}
