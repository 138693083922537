import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  OfferRequestWithCompany,
  OfferRequestWithCompanies,
} from './offer-request';
import { AfAdminOfferInfoPageController } from '../controllers/AfAdminOfferInfoPageController';
import { OfferRequestService } from '../services/offer-request';
import { BaseComponent, BaseState } from '../components/BaseComponent';

import './styles.scss';

export interface OfferRequestDetailsState extends BaseState {
  offerRequest: OfferRequestWithCompany | OfferRequestWithCompanies;
  isOfferRequestAvailable: boolean;
}

export interface OfferRequestDetailsTParams {
  offerId: string;
}

export class OfferRequestDetailsBase<T extends {}, S extends OfferRequestDetailsState>
  extends BaseComponent<RouteComponentProps<OfferRequestDetailsTParams> & T, S> {

  actionRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isOfferRequestAvailable: true
    } as S;
  }

  get offerId(): number {
    const { match } = this.props;
    return parseInt(match.params.offerId, 10);
  }

  get api(): any {
    const service = new OfferRequestService();
    return service.child(this.offerId);
  }

  get myyntiPro(): any {
    return { enabled: false, sent: null };
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.loadOfferRequest();
  }

  async loadOfferRequest() {
    try {
      const offerRequest = await this.api.get();

      this.setStateAfterLoad({
        offerRequest,
        isOfferRequestAvailable: true,
      });
    } catch (error: any) {
      // "409 Conflict" means offer request isn't viewable
      if (error.statusCode === 409) {
        this.setStateAfterLoad({
          offerRequest: null,
          isOfferRequestAvailable: false,
        });
      } else {
        toast.error('Tarjouspyynnön lataaminen epäonnistui');
        throw error;
      }
    }
  }

  onStatusUpdateAction = (status: any) => () => {
    if (this.actionRef) {
      this.actionRef.updateStatus(status);
    }
  }

  renderCompanies = (): null | JSX.Element => {
    return null;
  }

  renderAction = (): null | JSX.Element => {
    return null;
  }

  onSubmitEstimation = async () => {
    return;
  }

  onChangeEstimation = () => {
    return;
  }

  onSendMyyntiPro = async () => {
    return;
  }

  render() {
    const { offerRequest } = this.state;

    if (!offerRequest) {
      return null;
    }

    return (
      <AfAdminOfferInfoPageController
        offerRequest={offerRequest}
        onStatusUpdateAction={this.onStatusUpdateAction}
        renderCompanies={this.renderCompanies}
        renderAction={this.renderAction}
        onSubmitEstimation={this.onSubmitEstimation}
        onChangeEstimation={this.onChangeEstimation}
        onSendMyyntiPro={this.onSendMyyntiPro}
        myyntiPro={this.myyntiPro}
        {...this.props}
      >
      </AfAdminOfferInfoPageController>
    );
  }
}
