import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { userSelectors } from '../store/user';
import AfOfferConfirmationView from '../views/AfOfferConfirmationView';
import { BaseController } from './BaseController';
import { Company } from '../company/Company.interface';
import { UserProfile } from '../store/user/reducer';
import { getApartmentTypeName, formatNumber } from '../lib/helpers';
import { IOfferRequestConfirmation, OfferRequest } from '../offer-request/offer-request';
import './styles.scss';

const DESCRIPTION_LENGTH = 160;

interface Props {
    dataLoader: any;
    pageLoading: boolean;
    isAuthenticated: boolean;
    renderResult: IOfferRequestConfirmation[];
    offerRequest: OfferRequest;
}

export class AfOfferConfirmationController extends BaseController<RouteComponentProps & Props> {

    renderAgentCard = (data: UserProfile) => {
        const {
            id,
            stats,
            slug: agent,
            userMainCompany,
            firstName,
            lastName,
            logoImage,
            description,
            titles,
            priceEstimation,
        } = data;

        const {
            logoImage: logoImageCompany,
        } = userMainCompany;

        const name = `${firstName} ${lastName}`;

        return (
            <af-agent-card key={id}>
                {this.afLink('agent-name', { agent }, name)}
                {titles && titles.length && (<af-agent-title>{titles.map(({ label }) => label).join(', ')}</af-agent-title>)}
                {description && (
                    <af-description-agent>
                        {
                            description.substr(0, DESCRIPTION_LENGTH - 1) + (description.length > DESCRIPTION_LENGTH ? '...' : '')
                        }
                    </af-description-agent>
                )}
                <af-estimation-waiting>
                    {priceEstimation
                        ?
                        <span>
                            <span>
                                Välittäjän alustava
                        </span>
                            <br />
                            <span>
                                hinta-arvio: {formatNumber(priceEstimation)} €
                        </span>
                        </span>
                        :
                        <span>
                            <span>
                                Alustavaa hinta-arviota
                        </span>
                            <br />
                            <span>
                                ei annettu
                        </span>
                        </span>
                    }
                </af-estimation-waiting>
                <af-logo-image-agent style={{ backgroundImage: logoImage ? `url(${logoImage})` : undefined }}>
                    <af-logo-image-company-small style={{ backgroundImage: logoImageCompany ? `url(${logoImageCompany})` : undefined }} />
                </af-logo-image-agent>
                {this.renderStars('stars-agent', stats?.numberOfStarsAvg ?? 0)}
                {this.afLink('link-agent', { agent })}
                {this.afLink('ask-offer-link-agent', { agent })}
            </af-agent-card>
        );
    }

    renderCompanyCard = (data: Company) => {
        const {
            id,
            slug,
            marketingName,
            address,
            zipCode,
            city,
            description,
            stats,
            logoImage,
            priceEstimation,
        } = data;

        return (
            <af-agency-card key={id}>
                {this.afLink('marketing-name', { city, company: slug }, marketingName)}
                <af-address>{`${address}, ${zipCode}, ${city}`}</af-address>
                {description && (
                    <af-description>
                        {
                            description.substr(0, DESCRIPTION_LENGTH - 1) + (description.length > DESCRIPTION_LENGTH ? '...' : '')
                        }
                    </af-description>
                )}
                <af-price-est>
                    {priceEstimation
                        ?
                        <span>
                            <span>
                                Välittäjän alustava
                            </span>
                            <br />
                            <span>
                                hinta-arvio: {formatNumber(priceEstimation)} €
                            </span>
                        </span>
                        :
                        <span>
                            <span>
                                Alustavaa hinta-arviota
                            </span>
                            <br />
                            <span>
                                ei annettu
                            </span>
                        </span>
                    }
                </af-price-est>
                {this.afLink('company-link', { city, company: slug })}
                <af-logo-image style={{ backgroundImage: logoImage ? `url(${logoImage})` : undefined }} />
                {this.renderStars('stars', stats?.numberOfStarsAvg ?? 0)}
                {this.afLink('ask-offer-link', { company: slug })}
            </af-agency-card>
        );
    }

    renderResults() {
        const { renderResult } = this.props;
        if (!renderResult) {
            return null;
        }
        const emptyCards = (renderResult)
            ? 3 - (renderResult.length)
            : 3;
        let cardList: JSX.Element[] = [];
        if (emptyCards > 0) {
            for (let i = 0; i < emptyCards; i++) {
                cardList = [...cardList, (<af-placeholder-card><af-logo-image /></af-placeholder-card>)];
            }
        }

        const userCards = renderResult.filter((row: IOfferRequestConfirmation) => row.type === 'user');
        const companyCards = renderResult.filter((row: IOfferRequestConfirmation) => row.type === 'company');
        const allCards = [...userCards, ...companyCards];
        const cardRenders = allCards.map((row: IOfferRequestConfirmation) => {
            if (row.type === 'user') {
                return this.renderAgentCard(row.content as unknown as UserProfile);
            } else {
                return this.renderCompanyCard(row.content as unknown as Company);
            }
        });

        cardList = [...cardRenders, ...cardList];
        return cardList;
    }

    render() {
        const {
            dataLoader,
            offerRequest,
            renderResult,
        } = this.props;
        if (renderResult[0] && renderResult[0].type === 'expired' && !!offerRequest) {
            return (
                <p style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '15px',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                }}>
                    Hakemaasi tarjouspyyntöä ei löydy. Tarjouspyyntö on vanhentunut,
                    linkki on väärin tai tarjouspyyntö ei ole enää voimassa.
                    Jos uskot tämän olevan virhe, ota yhteyttä asiakaspalveluun.
                </p>
            );
        }

        if (offerRequest === null || typeof offerRequest === 'undefined') {
            return (
                <AfOfferConfirmationView>
                    <af-section-fix-data data-loader={dataLoader} />
                </AfOfferConfirmationView>);
        }

        const address = `${offerRequest.address} ${offerRequest.addressExtra || ''}`.trim();

        return (
            <AfOfferConfirmationView>
                <af-section-fix-data>
                    <af-section-fix-data-children>
                        <af-confirmation-address>
                            {`${address}, ${offerRequest.zipCode} ${offerRequest.city}`}
                        </af-confirmation-address>
                        <af-confirmation-area>
                            {offerRequest.size}
                        </af-confirmation-area>
                        <af-confirmaiton-type>
                            {getApartmentTypeName(offerRequest.type)}
                        </af-confirmaiton-type>
                        <af-confirmation-name>
                            {offerRequest.name}
                        </af-confirmation-name>
                        <af-confirmation-email>
                            {offerRequest.email}
                        </af-confirmation-email>
                        <af-confirmation-phone>
                            {offerRequest.phone}
                        </af-confirmation-phone>
                    </af-section-fix-data-children>
                </af-section-fix-data>
                <af-data-wrapper data-loader={dataLoader}>
                    <af-search-total />
                    {this.renderResults()}
                </af-data-wrapper>
            </AfOfferConfirmationView>
        );
    }
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: userSelectors.isAuthenticated(state),
});

export default connect(
    mapStateToProps,
    {
    },
)(AfOfferConfirmationController);
