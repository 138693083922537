import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { isString } from 'lodash';
import {
  OfferRequest,
  OfferRequestWithCompany,
  OfferRequestStatus,
  CompanyOfferRequest,
  maskContactData,
} from './offer-request';
import { CompanyManagerService } from '../services/company-manager';
import { OfferRequestService } from '../services/offer-request';
import { OfferRequestList, OfferRequestListState, OfferRequestListTParams, NotesData } from './OfferRequestList';
import { OfferRequestAction } from './OfferRequestAction';
import { userSelectors } from '../store/user';
import { companySelectors } from '../store/company';
import { UserProfile } from '../store/user/reducer';
import { Company } from '../company/Company.interface';

import './styles.scss';

interface TParams extends OfferRequestListTParams {
  companyId: string;
}

interface Props {
  company: Company;
  currentUser: UserProfile;
  isAdmin: boolean;
  isCompanyMainUser: (companyId: number) => boolean;
}

export class CompanyOfferRequestList extends OfferRequestList<RouteComponentProps<TParams> & Props, OfferRequestListState> {
  actionRef: any;
  users: any[] = [];

  get companyId(): number {
    const { match } = this.props;

    return parseInt(match.params.companyId, 10);
  }

  get api(): any {
    return new CompanyManagerService(this.companyId);
  }

  get offerRequestApi(): any {
    return new OfferRequestService();
  }

  componentDidMount() {
    super.componentDidMount();
    this.setState({ isSuperAdminView: false });
    this.api.child('users').get().then((users: any) => this.users = Array.isArray(users) ? users : []);
  }

  statsApi() {
    const { isAdmin, isCompanyMainUser } = this.props;

    const api = super.statsApi();
    if (isAdmin || isCompanyMainUser(this.companyId)) {
      return api;
    }

    return api.child('me');
  }

  endLoadingData(key: string, otherState: any = {}, cb: any = null) {
    const state = otherState;
    if (key === 'data') {
      state.offerRequests = state.offerRequests.map((of: OfferRequestWithCompany) => maskContactData(of));
    }

    super.endLoadingData(key, state, cb);
  }

  linkToOffer(offerRequest: OfferRequest) {
    return `/hallinta/${this.companyId}/tarjouspyynto/${offerRequest.id}`;
  }

  onStatusUpdated = async (offerRequest: OfferRequest) => {
    const { history } = this.props;
    history.push(this.linkToOffer(offerRequest));
  }

  onNotesFormSubmit = (event: any, key: string) => {
    event.preventDefault();
    if (isString(key) && /^\d+$/.test(key)) {
      const notesData = this.state.notesByCompanyOfferRequest[key];
      this.offerRequestApi.child(`${key}/notes`).patch(notesData)
        .then(() => toast.success('Yhteydenoton muistiinpanot päivitetty!'))
        .catch(() => toast.error('Yhteydenoton muistiinpanojen päivitys epäonnistui!'));
    }
  }

  onNotesFormChange = (event: any, key: string) => {
    this.setNotesData(key, { notes: event.target.value });
  }

  onNotesStatusChange = (event: any, key: string) => {
    this.setNotesData(key, { notesStatus: event.target.value }).then(({ notesStatus }) => {
      // Save status
      if (isString(key) && /^\d+$/.test(key)) {
        this.offerRequestApi.child(`${key}/notes`).patch({ notesStatus })
          .then(() => toast.success('Yhteydenoton tila päivitetty!'))
          .catch(() => toast.error('Yhteydenoton tilan päivitys epäonnistui!'));
      }
    });
  }

  renderAction(offerRequest: OfferRequest): null | JSX.Element {
    const { history, currentUser, company } = this.props;
    return OfferRequestAction.renderActionButtons({
      history,
      company,
      offerRequest: offerRequest as OfferRequestWithCompany,
      getActionRef: () => this.actionRef,
      users: this.users,
      currentUser,
      renderNotesFormFn: (companyOfferRequest: CompanyOfferRequest) => this.renderNotesForm(companyOfferRequest),
      reloadDataFn: () => this.load(this.state.status)
    });
  }

  renderStats() {
    const { isAdmin, isCompanyMainUser } = this.props;

    if (isAdmin || isCompanyMainUser(this.companyId)) {
      return super.renderStats();
    }

    return null;
  }

  renderNotesForm(companyOfferRequest: CompanyOfferRequest): null | JSX.Element {
    if (!this.shouldShowNotesForm()) {
      return null;
    }
    const key = companyOfferRequest ? `${companyOfferRequest.id}` : 'null';
    return (
      <af-admin-form-notes onSubmit={(event: any) => this.onNotesFormSubmit(event, key)}>
        <af-input-notes-status
          value={this.state.notesByCompanyOfferRequest[key]?.notesStatus ?? ''}
          onChange={(event: any) => this.onNotesStatusChange(event, key)}>
          <option value="notContacted">Ei kontaktoitu</option>
          <option value="contacted">Asiakas kontaktoitu</option>
          <option value="callLater">Soita myöhemmin</option>
          <option value="appraisalAgreed">Arviokäynti sovittu</option>
          <option value="offerSubmitted">Tarjous sopimuksesta jätetty</option>
          <option value="notAnswered">Ei vastausta</option>
          <option value="notInterested">Ei kiinnostunut</option>
        </af-input-notes-status>
        <af-input-notes-textarea
          value={this.state.notesByCompanyOfferRequest[key]?.notes ?? ''}
          onChange={(event: any) => this.onNotesFormChange(event, key)}>
        </af-input-notes-textarea>
      </af-admin-form-notes>
    );
  }

  render() {
    return (
      <>
        {super.render()}
        <OfferRequestAction
          {...this.props}
          ref={(ref: OfferRequestAction) => this.actionRef = ref}
          onStatusUpdated={this.onStatusUpdated}
        />
      </>
    );
  }

  private async setNotesData(key: string, data: NotesData): Promise<NotesData> {
    const currentData = this.state.notesByCompanyOfferRequest[key] || {};
    const updatedNotes = {
      ...this.state.notesByCompanyOfferRequest,
      [key]: {
        ...currentData,
        ...data,
      }
    };
    return new Promise(resolve => this.setState({ notesByCompanyOfferRequest: updatedNotes }, () =>
      resolve(this.state.notesByCompanyOfferRequest[key])));
  }

  private shouldShowNotesForm(): boolean {
    const showFormForStates = new Set<OfferRequestStatus>([
      OfferRequestStatus.contacted,
    ]);
    return showFormForStates.has(this.state.status);
  }
}

const mapStateToProps = (state: any) => ({
  company: companySelectors.getCompany(state),
  currentUser: userSelectors.getProfile(state),
  isAdmin: userSelectors.isAdmin(state),
  isCompanyMainUser: userSelectors.isCompanyMainUser(state),
});

export default connect(
  mapStateToProps,
  {
  },
)(CompanyOfferRequestList);
