import { CompanyService } from './company';

export class CompanyManagerService extends CompanyService {
    constructor(companyId: number|null = null) {
        const path = ['manage'];
        if (companyId) {
            path.push(companyId.toString());
        }
        super(path.join('/'));
    }
}
