import React from 'react';
import './loader.scss';

export const Loader = () => (
  <div className='lds-roller'>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
