import Api from '../lib/api';

export class CompanyService extends Api {
    constructor(companySlug: string|null = null) {
        const path = ['company'];
        if (companySlug) {
            path.push(companySlug.toString());
        }
        super(path.join('/'));
    }

    offerRequest() {
        return this.child('offer-request');
    }

    stats() {
        return this.child('stats');
    }

    review() {
        return this.child('review');
    }

    agent(slug: string) {
        return this.child('agent').child(slug);
    }
}
