import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { Auth0ProviderConnectedBindActionCreators, Auth0Context } from './AuthProvider';
import Moment from 'react-moment';

import './index.scss';

Modal.setAppElement('#root');

Moment.globalFormat = 'D.M.YYYY';

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Auth0ProviderConnectedBindActionCreators
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirect_uri={`${window.location.origin}/authorize`}
        audience={process.env.REACT_APP_JWT_AUDIENCE || `${process.env.REACT_APP_API_URL}/`}
        scope='openid email profile permissions'
      >
        <Auth0Context.Consumer>
          {(authProps: any) => <App {...authProps} />}
        </Auth0Context.Consumer>
      </Auth0ProviderConnectedBindActionCreators>
    </Provider>,
    document.getElementById('root'),
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

render();
store.subscribe(render);
