import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { slugifyCity } from '../shared/helpers';
import icons from '../icons.json';

export class HelperComponent<T extends {} = {}, S extends {} = {}> extends React.Component<RouteComponentProps & T, S> {
  onAfLinkClick = (e: any, state: any = null) => {
    const toTarget = e.target.getAttribute('target') || e.currentTarget.getAttribute('target');
    if (toTarget === '_blank') {
      return;
    }

    e.preventDefault();
    const to = e.target.getAttribute('href') || e.currentTarget.getAttribute('href');
    const { history } = this.props;
    history.push(to, state);
  }

  afLink(el: string, placeholders = {}, children: JSX.Element|null|string = null, props: any = {}, state: any = null) {
    const TagName = `af-${el}` as unknown as React.ComponentType<any>;

    const { activeClassName, isActive } = props;
    if (activeClassName !== undefined) {
      delete props.activeClassName;
    }
    if (isActive !== undefined) {
      delete props.isActive;
    }

    return (
      <TagName
        onClick={(e: any) => this.onAfLinkClick(e, state)}
        {...props}
        ref={(ref: any) => {
          if (!ref) { return; }
          let href = ref.getAttribute('data-original-href') || ref.getAttribute('href');
          ref.setAttribute('data-original-href', href);
          if (typeof placeholders === 'string') {
            href = placeholders;
          } else {
            Object.entries(placeholders).forEach(([placeholder, value]) => {
              href = href.replace(`[${placeholder}]`, slugifyCity(value as string));
            });
          }

          ref.setAttribute('href', href);

          if (activeClassName && isActive) {
            const active = typeof isActive === 'function' ? isActive(href, props) : isActive;
            const contains = ref.classList.contains(activeClassName);
            if (active && !contains) {
              ref.classList.add(activeClassName);
            }
            if (!active && contains) {
              ref.classList.remove(activeClassName);
            }
          }
        }}
      >
        {children && children}
      </TagName>
    );
  }

  renderStars(el: string, numberOfStars: number, x = 5) {
    const TagName = `af-${el}` as unknown as React.ComponentType<any>;
    const roundedStars = Math.round(numberOfStars * 2) / 2;
    const full = (i: number) => numberOfStars >= i && i < roundedStars;
    const half = (i: number) => roundedStars >= (i + 0.4) && roundedStars < (i + 1);
    const getIcon = (i: number) => {
      if (half(i)) { return icons['star-half-alt']; }
      if (!full(i)) { return icons['star-empty']; }
      return icons.star;
    };

    return [...Array(x)].map((_, i) => <TagName key={i}>{getIcon(i)}</TagName>);
  }

  renderPagination(page: number, pageCount: number, goToPage: (page: number) => void) {
    const arr = [];
    if (page > 1 && pageCount > 1) {
      arr.push(<af-link-prev key='prev' onClick={() => goToPage(page - 1)} />);
    }
    if (pageCount > 1) {
      arr.push(...Array.from(Array(pageCount)).map((_0, n) => (
        <af-link-page
          key={n}
          onClick={() => goToPage(n + 1)}
          style={{ fontWeight: page === n + 1 ? 'bold' : 'normal' }}
        >
          {n + 1}
        </af-link-page>
      )));
    }

    if (page < pageCount && pageCount > 1) {
      arr.push(<af-link-next key='next' onClick={() => goToPage(page + 1)} />);
    }

    return arr;
  }
}
