export interface StringSignatureString {
    [key: string]: string;
}

export interface StringSignatureAny {
    [key: string]: any;
}

export interface StringSignatureNumber {
    [key: string]: any;
}

export interface NumberSignatureString {
    [key: number]: string;
}

export interface OptionType extends StringSignatureString {
    value: string;
    label: string;
}

export enum CompanyStatus {
    approved = 'approved',
    notApproved = 'notApproved',
    noOffers = 'noOffers',
}

export const companyStatusNames = {
    approved: 'Hyväksytty',
    notApproved: 'Hyväksymätön',
    noOffers: 'Ei vinkkejä',
};

export enum OfferRequestStatus {
  open = 'open',
  contacted = 'contacted',
  contract = 'contract',
  fulfilled = 'fulfilled',
  notFulfilled = 'notFulfilled',
  rejected = 'rejected',
  lost = 'lost',
  notAvailable = 'notAvailable',
  reclamation = 'reclamation',
}

export enum OfferRequestCustomStatus {
  fulfilled = 'fulfilled',
  notFulfilled = 'notFulfilled',
}

export enum OfferRequestCustomStatusFilter {
  NoFiltering = 'noFiltering',
  WithoutStatus = 'withoutStatus',
  Fulfilled = 'fulfilled',
  NotFulfilled = 'notFulfilled',
}

export enum OfferRequestNotesStatus {
  notContacted = 'notContacted',
  contacted = 'contacted',
  callLater = 'callLater',
  appraisalAgreed = 'appraisalAgreed',
  offerSubmitted = 'offerSubmitted',
  notAnswered = 'notAnswered',
  notInterested = 'notInterested',
}

export enum OfferRequestReasonRejected {
  outOfTerritory = 'outOfTerritory',
  areaNotInteresting = 'areaNotInteresting',
  targetNotInteresting = 'targetNotInteresting',
  otherLeadSource = 'otherLeadSource',
  existingCustomer = 'existingCustomer',
  noContact = 'noContact',
  noSale = 'noSale',
  ownAcquisition = 'ownAcquisition',
  otherService = 'otherService',
  contractExpired = 'contractExpired',
  terminatedByCustomer = 'terminatedByCustomer',
  terminatedByCompany = 'terminatedByCompany',
  otherReason = 'otherReason',
}

export enum UploadFileType {
    Image,
    CommissionReceipt,
}

export const offerRequestAllowedNewStatuses = {
    [OfferRequestStatus.open]: [OfferRequestStatus.contacted, OfferRequestStatus.rejected],
    [OfferRequestStatus.contacted]: [OfferRequestStatus.contract, OfferRequestStatus.rejected],
    [OfferRequestStatus.contract]: [OfferRequestStatus.fulfilled, OfferRequestStatus.notFulfilled],
    [OfferRequestStatus.rejected]: [OfferRequestStatus.contacted],
};

export const offerRequestRejectedTypes = [
    OfferRequestStatus.rejected,
    OfferRequestStatus.notFulfilled,
    OfferRequestStatus.lost,
];

export const offerRequestMaskTypes = [
    OfferRequestStatus.open,
    OfferRequestStatus.rejected,
    OfferRequestStatus.lost,
    OfferRequestStatus.notFulfilled,
];

export const offerRequestContractTypes = [
    OfferRequestStatus.contract,
    OfferRequestStatus.fulfilled,
    OfferRequestStatus.notFulfilled,
];

export const offerRequestAllowedReviewTypes = [
    OfferRequestStatus.fulfilled,
    OfferRequestStatus.notFulfilled,
];

export const offerRequestClosedTypes = [
    ...offerRequestRejectedTypes,
    OfferRequestStatus.fulfilled,
];

export const offerRequestLeadIntegrationTypes = [
    OfferRequestStatus.contacted,
    OfferRequestStatus.contract,
];

export const companyServicesOptions = [
    { value: 'professional-photographer', label: 'Ammattivalokuvaaja' },
    { value: 'introduction-video', label: 'Esittelyvideo' },
    { value: 'silen-sales', label: 'Hiljainen myynti' },
    { value: 'aerial-photography', label: 'Ilmakuvaus' },
    { value: 'sale-confirmation', label: 'Kaupanvahvistus' },
    { value: 'condition-inspection', label: 'Kuntotarkastus' },
    { value: 'lawyer-services', label: 'Lakimiespalvelut' },
    { value: 'moving-services', label: 'Muuttopalvelut' },
    { value: 'sales-furnishings', label: 'Myyntikalustus' },
    { value: 'legacy-services', label: 'Perintöasiat' },
    { value: 'patio-design', label: 'Pihasuunnittelu' },
    { value: 'floorplan', label: 'Pohjakuva' },
    { value: 'renovation-services', label: 'Remontointipalvelut' },
    { value: 'cleaning-services', label: 'Siivouspalvelut' },
    { value: 'interior-design', label: 'Sisustussuunnittelu' },
    { value: 'styling-service', label: 'Stailauspalvelu' },
    { value: 'virtual-tours', label: 'Virtuaaliesittelyt' },
] as OptionType[];

export const companyAdvertisingOptions = [
    { value: 'etuovi', label: 'Etuovi' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'google', label: 'Google' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'twitter', label: 'Twitter' },
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'retargeting', label: 'Retargetointi' },
    { value: 'oikotie', label: 'Oikotie' },
    { value: 'company-webpages', label: 'Omat kotisivut' },
    { value: 'newspapers', label: 'Sanomalehdet' },
    { value: 'direct-marketing', label: 'Suoramarkkinointi' },
    { value: 'immowelt.de', label: 'immowelt.de' },
    { value: 'prian.ru', label: 'prian.ru' },
    { value: 'ovikoodi', label: 'Ovikoodi' },
] as OptionType[];

export const languageOptions = [
    { value: 'fin', label: 'Suomi' },
    { value: 'swe', label: 'Ruotsi' },
    { value: 'eng', label: 'Englanti' },
    { value: 'rus', label: 'Venäjä' },
    { value: 'est', label: 'Viro' },
    { value: 'deu', label: 'Saksa' },
    { value: 'spa', label: 'Espanja' },
    { value: 'ita', label: 'Italia' },
    { value: 'hun', label: 'Unkari' },
] as OptionType[];

export const specialityOptions = [
    { value: 'apartment-houses', label: 'Kerrostalot' },
    { value: 'houses', label: 'Omakotitalot' },
    { value: 'row-houses', label: 'Rivitalot' },
    { value: 'investment-properties', label: 'Sijoitusasunnot' },
    { value: 'luxury-properties', label: 'Arvoasunnot' },
    { value: 'new-properties', label: 'Uudiskohteet' },
    { value: 'renovation-properties', label: 'Remontoitavat kohteet' },
    { value: 'sparsely-populated-areas', label: 'Haja-asutusalueet' },
    { value: 'beach-properties', label: 'Rantakohteet' },
    { value: 'holiday-properties', label: 'Loma-asunnot' },
    { value: 'plots', label: 'Tontit' },
    { value: 'land-and-forests', label: 'Maa- ja metsätilat' },
] as OptionType[];

export const titleOptions = [
    { value: 'entrepreneur', label: 'Yrittäjä' },
    { value: 'CEO', label: 'Toimitusjohtaja' },
    { value: 'sales-director', label: 'Myyntijohtaja' },
    { value: 'partner', label: 'Osakas' },
    { value: 'real-estate-agent', label: 'Kiinteistönvälittäjä' },
    { value: 'senior-real-estate-agent', label: 'Ylempi kiinteistönvälittäjä' },
    { value: 'property-seller', label: 'Asuntomyyjä' },
    { value: 'sales-negotiator', label: 'Myyntineuvottelija' },
    { value: 'sales-assistant', label: 'Myyntiassistentti' },
    { value: 'notary', label: 'Kaupanvahvistaja' },
    { value: 'LKV', label: 'LKV' },
    { value: 'LVV', label: 'LVV' },
    { value: 'YKV', label: 'YKV' },
    { value: 'KiAT', label: 'KiAT' },
    { value: 'KED', label: 'KED®' },
    { value: 'AKA', label: 'AKA' },
    { value: 'KHK', label: 'KHK' },
    { value: 'KTM', label: 'KTM' },
    { value: 'VTM', label: 'VTM' },
    { value: 'MBA', label: 'MBA' },
] as OptionType[];

export enum BillinMethod {
    email = 'email',
    eInvoice = 'e-invoice',
    paperInvoice = 'paper-invoice',
}

export enum ReviewStatus {
  open = 'open',
  accepted = 'accepted',
  rejected = 'rejected',
}

export interface Territory {
  area: string;
  zipCode: string;
  city: string;
}

export interface TerritoryValue {
  value: string;
  label: string;
  data: Territory;
}

export const extrasOptions = [
    { value: 'balcony', label: 'Parveke' },
    { value: 'elevator', label: 'Hissi' },
    { value: 'ownPlot', label: 'Oma tontti' },
    { value: 'sauna', label: 'Sauna' },
];

export const renovateOptions = [
    { value: 'facade', label: 'Julkisivu' },
    { value: 'pipes', label: 'Putki' },
    { value: 'roof', label: 'Katto' },
];

export const conditions = ['Huono', 'Tyydyttävä', 'Hyvä', 'Erinomainen', 'Uusi'];

export const scheduleOptions = [
    { value: 'now', label: 'Heti' },
    { value: '3kk', label: '1-3 kk' },
    { value: '12kk', label: '3-12 kk' },
    { value: 'notSelling', label: 'En ole myymässä' },
];

export const importantFeatureOptions = [
    { value: 'bestPrice', label: 'Paras hinta asunnolle' },
    { value: 'fastSale', label: 'Nopea myynti' },
    { value: 'lowestBrokerage', label: 'Edullisin palkkio' },
    { value: 'experiencedBroker', label: 'Kokenut välittäjä' },
    { value: 'goodReviews', label: 'Hyvät arvostelut' },
];

export const offerRequestFieldNames = {
    createdAt: 'Päivämäärä',
    address: 'Katuosoite',
    addressExtra: 'Rappu ja asunnon numero',
    zipCode: 'Postinumero',
    city: 'Paikkakunta',
    size: 'Asunnon pinta-ala (m2)',
    type: 'Asunnon tyyppi',
    schedule: 'Olen myymässä asuntoani',
    name: 'Nimi',
    email: 'Sähköposti',
    phone: 'Puhelinnumero',
    importantFeature: 'Valintaperuste',
    condition: 'Asunnon kunto',
    yearOfConstruction: 'Rakennusvuosi',
    numberOfRooms: 'Huoneluku',
    floorNumber: 'Asunnon kerros',
    numberOfFloorsInBuilding: 'Talon kerrosten lukumäärä',
    hasExtras: 'Lisätiedot asunnosta',
    hasRecentlyRenovated: 'Tehdyt remontit',
    priceEstimate: 'Asiakkaan hinta-arvio' ,
    extraInfo: 'Lisätiedot',
} as StringSignatureString;


export enum ExportDataStatus {
    Pending = 'pending',
    Completed = 'completed',
    Failed = 'failed',
}

export enum ExportDataType {
    OfferRequestsPerCompany = 'offerRequestsPerCompany',
    OfferRequestsPerCompanyAdditional = 'offerRequestsPerCompanyAdditional',
    AllContracts = 'allContracts',
}

export enum ExportDataFormat {
    xlsx = 'xlsx',
    pdf = 'pdf',
}
