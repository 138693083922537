import React from 'react';
import AfAdminDashboardView from '../views/AfAdminDashboardView';
import { formatNumber } from '../lib/helpers';

export interface Announcement {
  title: string;
  message: string;
}

interface Props {
  openCount: number;
  totalCommission: number;
  totalFulfilled: number;
  numberOfStarsAvg: number|null;
  announcement?: Announcement;
}

export class AfAdminDashboardController extends React.Component<Props> {

  render() {
    const {
      openCount,
      totalCommission,
      totalFulfilled,
      numberOfStarsAvg,
      announcement,
    } = this.props;

    return (
      <AfAdminDashboardView>
        {announcement && (
          <af-announcement-wrapper>
            <af-announcement-title>{announcement.title}</af-announcement-title>
            <af-announcement-message>{announcement.title}</af-announcement-message>
          </af-announcement-wrapper>
        )}
        <af-open-count>{formatNumber(openCount)} KPL</af-open-count>
        <af-total-commission>{formatNumber(Math.round(totalCommission))} €</af-total-commission>
        <af-total-fulfilled>{formatNumber(totalFulfilled)} KPL</af-total-fulfilled>
        <af-stars-average>{formatNumber(numberOfStarsAvg, 2) || '-'}</af-stars-average>
        {this.props.children}
      </AfAdminDashboardView>
    );
  }
}
