import { Company } from '../company/Company.interface';
import { UserProfile } from '../store/user/reducer';
import { Review } from '../company/Review.interface';
import { getDefaultDescriptionForAgency, getDefaultDescriptionForAgent } from './meta-tags';

export interface StructuredDatas {
    [key: string]: string;
}

export class StructuredDataService {
    setCompanySD(company: Company, reviews: Review[]) {
        this.setStructuredDatas(company, reviews);
    }

    setAgentSD(agent: UserProfile, reviews: Review[]) {
        this.setStructuredDatas(agent.companies[0].company, reviews, agent);
    }

    getStructuredReviews(reviews: Review[]) {
        return reviews.map((review: any) => {
            const {
                acceptedAt,
                customerName,
                description,
                numberOfStars,
            } = review;

            const reviewJsonLd = {
                '@context': 'http://schema.org',
                '@type': 'Review',
                datePublished: acceptedAt,
                description,
                inLanguage: 'fi',
                author: {
                  name: customerName,
                  '@type': 'Person',
                },
                reviewRating: {
                    '@type': 'Rating',
                    ratingValue: numberOfStars,
                    worstRating: 1,
                    bestRating: 5,
                },
                publisher: {
                  '@type': 'Organization',
                  name: 'Asuntoarvio',
                  sameAs: 'https://asuntoarvio.fi',
                },
            };
            return reviewJsonLd;
        });
    }

    setStructuredDatas = (company: Company, reviews: Review[], agent: UserProfile|null = null) => {
        const stats = agent && agent.stats ? agent.stats : company.stats;

        const {
            phone: telephone,
            city,
            zipCode: zip,
            address,
            location,
        } = company;

        let  {
            email,
            logoImage,
            marketingName: name,
            description = getDefaultDescriptionForAgency(company.marketingName, city),
        } = company;

        if (agent) {
            name = `${agent.firstName} ${agent.lastName}`;
            ({
                description = getDefaultDescriptionForAgent(name, company.marketingName, city),
                logoImage,
                email,
            } = agent);

        } else {
            ({
                logoImage,
                marketingName: name,
                description = getDefaultDescriptionForAgency(company.marketingName, city),
            } = company);
        }

        const {
            numberOfStarsAvg,
            ratingCount,
        } = stats;

        const structuredData = {
            '@context': 'http://schema.org',
            '@type': 'RealEstateAgent',
            name,
            url: window.location.toString(),
            description,
            telephone,
            image: logoImage,
            email,
            priceRange: 'Contact Agency',
            address: {
                '@type': 'PostalAddress',
                streetAddress: address,
                postalCode: zip,
                addressLocality: city,
                addressCountry: 'FI',
            },
            review: this.getStructuredReviews(reviews),
        } as any;

        if (location && location.coordinates) {
            structuredData.geo = {
                '@type': 'GeoCoordinates',
                latitude: location.coordinates[0],
                longitude: location.coordinates[1],
            };
        }

        const ratingCountAsNumber = Number.isFinite(ratingCount)
            ? ratingCount
            : parseInt(ratingCount as any, 10);

        if (Number.isFinite(ratingCountAsNumber) && ratingCountAsNumber > 0) {
            structuredData.aggregateRating = {
                '@type': 'AggregateRating',
                ratingValue: numberOfStarsAvg,
                ratingCount: ratingCountAsNumber,
                bestRating: 5,
                worstRating: 1,
            };
        }

        const jsonLdTag = document.getElementById('afstructureddata');
        if (jsonLdTag !== null) {
            jsonLdTag.textContent = JSON.stringify(structuredData);
        } else {
            const script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.setAttribute('id', 'afstructureddata');
            script.textContent = JSON.stringify(structuredData);
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    }
}
