import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import user, { UserState } from './user/reducer';
import company, { CompanyState } from './company/reducer';

import history from '../history';

const appReducer = combineReducers({
    user,
    company,
    router: connectRouter(history),
});

export interface RootState {
    user: UserState;
    company: CompanyState;
    router: RouterState;
}

export default function(state: any, action: any) {
  const returnableState = { ...state };

  return appReducer(returnableState, action);
}
