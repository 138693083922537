import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import uniqBy from 'lodash/uniqBy';
import { Form, FormState, FormInitialState } from '../components/Form';
import { UserService } from '../services/user';
import { CompanyManagerService } from '../services/company-manager';
import { AfAdminAgentFormController } from '../controllers/AfAdminAgentFormController';
import icons from '../icons.json';
import { logger } from '../lib/debug';
import { Territory, TerritoryValue, languageOptions, specialityOptions, titleOptions, UploadFileType } from '../shared/types';
import { loadingProps } from '../lib/helpers';
import Api from '../lib/api';

declare global {
    interface Window { PUBLIC_URL: string; }
}

const { REACT_APP_UPLOADS_URL } = process.env;

const log = logger('AgentForm');

interface TParams {
  companyId?: string;
  userId?: string;
}

interface State extends FormState {
  territoryList: Territory[];
  territoryCityOptions: TerritoryValue[];
  territoryZipCodeAreaOptions: TerritoryValue[];
  initialFormData: any;
}

const defaults = {
  languages: [],
  specialities: [],
  titles: [],
  territoryCities: [],
  territoryZipCodeAreas: [],
};

export class AgentForm extends Form<RouteComponentProps<TParams>, State> {
  state = {
    ...FormInitialState,
    formData: {
      ...defaults,
    },
    initialFormData: {
      ...defaults,
    },
    section: 'agent-info',
  } as State;

  get companyId(): number|undefined {
    return this.props.match.params.companyId ? parseInt(this.props.match.params.companyId, 10) : undefined;
  }

  get userId(): number|string {
    return parseInt(this.props.match.params.userId || '', 10) || 'me';
  }

  get service() {
    if (this.companyId) {
      return (new CompanyManagerService(this.companyId)).child('users').child(this.userId).child('profile');
    }

    return (new UserService()).child(this.userId);
  }

  componentDidMount() {
    super.componentDidMount();
    this.load();
  }

  onSubmitError() {
    toast.error('Lomakkeella on virheitä.');
  }

  async load() {
    try {
      const publicApi = new Api('json/territory-list.json', `${window.location.origin}${window.PUBLIC_URL}`);

      const [ company, territoryList ] = await Promise.all([this.service.get(), publicApi.get()]);

      const list = uniqBy(territoryList, 'city') as unknown as Territory[];
      const territoryCityOptions = list
        .map((territory: Territory): TerritoryValue => ({
          value: territory.city,
          label: territory.city,
          data: territory,
        }));

      const territoryZipCodeAreaOptions = uniqBy(territoryList, (territory: Territory) => territory.area + territory.zipCode)
        .map((territory: Territory): TerritoryValue => ({
          value: `${territory.zipCode} ${territory.area}`,
          label: `${territory.zipCode} ${territory.area}`,
          data: territory,
        }));

      const initialFormData = { ...this.state.formData, ...company };

      this.setStateAfterLoad({
        formData: { ...initialFormData },
        initialFormData: { ...initialFormData },
        territoryList,
        territoryCityOptions,
        territoryZipCodeAreaOptions,
      });
    } catch (error) {
      log('Failed to load company', error);
      toast.error('Käyttäjän tietojen hakeminen epäonnistui!');
    }
  }

  async saveformData(): Promise<boolean> {
    const { formData, section } = this.state;

    try {
      if (section === 'images-info') {
        const result = await this.uploadImages();
        return result;
      } else {
        await this.service.put(formData);
      }

      this.setState({ initialFormData: { ...this.state.initialFormData, ...formData } });
      toast.success('Tallennus onnistui.');

      return true;
    } catch (error) {
      log('save failure', error);
      toast.error('Tallennus epäonnistui.');
    }

    return false;
  }

  async uploadImages() {
    const { formData } = this.state;

    const files = ['logoImage', 'backgroundImage'];

    const preData = { fileType: UploadFileType.Image } as any;
    files.forEach((key) => {
      if (!formData[key] || !formData[key].type) {
        return;
      }
      preData[key] = { size: formData[key].size, type: formData[key].type };
    });

    const service = this.service.child('upload');
    const result = await service.post(preData);

    const confirmData = {} as any;
    await Promise.all(files.map(async (key) => {
      if (!formData[key] || !formData[key].type) {
        if (formData[key] === false) {
          confirmData[key] = null;
        }

        return;
      }

      const uploadResult = await fetch(result[key].url, {
        method: 'PUT',
        body: formData[key],
        headers: {
          'cache-control': 'public, max-age=31536000',
        },
      });
      if (!uploadResult || uploadResult.status !== 200) {
        throw new Error(`Failed to upload ${key}`);
      }

      confirmData[key] = `${REACT_APP_UPLOADS_URL}/${result[key].key}`;
    }));

    await service.put(confirmData);

    toast.success('Kuvat lähetetty onnistuneesti.');
    return true;
  }

  section() {
    const { section, territoryCityOptions, territoryZipCodeAreaOptions } = this.state;

    if (section === 'agent-info') {
      const inputs = [
        this.renderTextInput('firstName'),
        this.renderTextInput('lastName'),
        this.renderTextInput('description'),
        this.renderInput('email'),
        this.renderInput('facebook', null, { type: 'url' }),
        this.renderInput('instagram', null, { type: 'url' }),
        this.renderInput('linkedin', null, { type: 'url' }),
        this.renderInput('yearsOfExperience'),
        this.renderSearchSelection('languages', languageOptions, { max: 5 }),
        this.renderSearchSelection('specialities', specialityOptions, { max: 5 }),
        this.renderSearchSelection('titles', titleOptions, { max: 5 }),
        this.renderSearchSelection('territoryCities', territoryCityOptions, { max: 5 }),
        this.renderSearchSelection('territoryZipCodeAreas', territoryZipCodeAreaOptions, { max: 20 }),
      ];

      return inputs;
    }

    if (section === 'images-info') {
      return [
        ...this.renderImageInput('logoImage'),
        ...this.renderImageInput('backgroundImage'),
      ];
    }

    return [];
  }

  onSelectSection = (section: string) => {
    this.setState({ section, formData: { ...this.state.initialFormData } });
  }

  renderFormChildren(): any {
    const { section } = this.state;

    const SubmitBtn = `af-${section}-submit` as unknown as React.ComponentType<any>;

    return [
      <SubmitBtn onClick={this.handleSubmit} key='submit' {...loadingProps(this.isSectionSaving())} />,
    ];
  }

  render() {
    const { section, formData } = this.state;
    const sections = ['agent-info', 'images-info'];

    const { slug, firstName, lastName } = formData;

    const hasPublicProfile = formData.companies
      && formData.companies.length
      && formData.companies.find((c: any) => c.isPublicProfile) && slug && firstName && lastName;

    return (
      <AfAdminAgentFormController
        {...(this.props as any)}
        sections={sections}
        activeSection={section as string}
        onSelectSection={this.onSelectSection}
        slug={slug}
        hasPublicProfile={hasPublicProfile}
      >
        {this.hasErrors() && (
          <af-error-wrapper>
            {this.getAllErrors().map((error: string) => (
              <af-error key={error}>
                <span className='af-class-exclamation'>{icons.exclamation}</span> {error}
              </af-error>
            ))}
          </af-error-wrapper>
        )}
        {this.renderForm()}
      </AfAdminAgentFormController>
    );
  }
}
