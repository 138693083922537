import { logger } from '../lib/debug';
import store from '../store';
import { userSelectors } from '../store/user';
import { ApiError } from './api-error';

const log = logger('api');

const API_URL = process.env.REACT_APP_API_URL;

export default class Api {
    path: string;
    url: string;
    queryParams: unknown;
    headers: any;

    constructor(path: string|number, apiUrl: string|null = null) {
        const url = apiUrl || API_URL;
        log('API_URL', url);
        this.url = `${url}/${path}`;
        this.path = path.toString();
        this.headers = {
            'Content-Type': 'application/json',
        } as any;
    }

    auth() {
        const token = userSelectors.getAccessToken(store.getState());
        if (token) {
            this.headers.Authorization = `Bearer ${token}`;
        }
    }

    async request(method: string, data = null) {
        const url = new URL(this.url);
        if (this.queryParams) {
            Object.entries(this.queryParams as [[any, any]])
                .forEach(([key, param]) => url.searchParams.append(key, param.toString()));
        }

        this.auth();

        const response = await fetch(url.toString(), {
            method,
            headers: this.headers,
            body: data ? JSON.stringify(data) : undefined,
        });

        let json;
        try {
            json = await response.json();
            if (response.status === 200 || (response.status === 201 && method === 'POST')) {
                return json;
            }
        } catch (error) {
            json = {};
        }

        response.json = json;
        log('failed to fetch', response);
        throw new ApiError(response);
    }

    child(path: number|string) {
        return new Api(`${this.path}/${path}`);
    }

    query(params: object) {
        this.queryParams = params;
        return this;
    }

    get() {
        return this.request('GET');
    }

    post(data: any) {
        return this.request('POST', data);
    }

    put(data: any) {
        return this.request('PUT', data);
    }

    patch(data: any) {
        return this.request('PATCH', data);
    }

    del() {
        return this.request('DELETE');
    }
}
