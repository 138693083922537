/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAdminCompanyListView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAdminCompanyListController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAdminCompanyListView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAdminCompanyListView ? transformProxies(this.props.children) : {
      'af-info-text': [],
      'af-info-text2': [],
      'af-info-text3': [],
      'af-admin-wrapper': [],
      'af-admin-button': [],
      'af-agency-card': [],
      'af-logo-image': [],
      'af-top-100': [],
      'af-marketing-name': [],
      'af-address': [],
      'af-stars-average': [],
      'af-stars': [],
      'af-description': [],
      'af-offer-requests-link': [],
      'af-edit-info-link': [],
      'af-pending': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-section-2">
              <div className="af-class-admin-company-list-container w-container">
                {map(proxies['af-info-text'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-admin-company-list-company-guide ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><strong>Käyttäjääsi ei ole linkitetty palvelussa olevaan yritykseen.</strong><br /><br /><strong>Oletko välitysliikkeen yrittäjä tai vastaava henkilö?</strong><br />Yrityksen omistajana tai toimipisteen vastaavana voit linkittää yhden tai useamman toimipisteen käyttäjälle <a href="/kiinteistovalitys" target="_blank" className="af-class-admin-company-list-info-link">etsimällä sen palvelusta</a> ja ottamalla haltuun klikkaamalla “Ota yrityksesi profiili haltuun tästä” -painiketta yrityksen profiilisivulla. Jos toimistoasi ei löydy palvelusta, <a href="/hallinta/uusi" target="_blank" className="af-class-admin-company-list-info-link">voit perustaa uuden välitysliikkeen täältä</a>. Yritysten linkitykset palvelun käyttäjään hyväksytään aina erikseen Asuntoarvion toimesta 2-3 arkipäivän sisällä.<br /><br /><strong>Oletko välitysliikkeessä työskentelevä kiinteistönvälittäjä?</strong><br />Kiinteistönvälittäjän henkilöprofiilin julkaiseminen vaatii palveluun rekisteröityneen yrityksen. Henkilöprofiili linkitetään yhteen tai useampaan toimipisteeseen. Kiinteistönvälittäjän rekisteröitymisessä välitysliikkeen pääkäyttäjä lisää käyttäjäsi toimipisteen välittäjäksi rekisteröityneellä sähköpostilla.<br /><br />Asiakaspalvelu: <a href="mailto:asiakaspalvelu@asuntoarvio.fi?subject=Yhteydenotto%20Asuntoarvio.fi%20-hallinnasta" className="af-class-admin-company-list-info-link">asiakaspalvelu@asuntoarvio.fi</a>.<br />‍</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                {map(proxies['af-info-text2'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-admin-company-list-company-guide ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><strong>Olet välitysliikkeen pääkäyttäjä.<br />‍</strong>Alla on käyttäjäsi hallinnoimat välitysliikkeet ja toimipisteet.<strong /><br /><br />Asiakaspalvelu: <a href="mailto:asiakaspalvelu@asuntoarvio.fi?subject=Yhteydenotto%20Asuntoarvio.fi%20-hallinnasta" className="af-class-admin-company-list-info-link">asiakaspalvelu@asuntoarvio.fi</a>.<strong><em><br />‍</em></strong></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                {map(proxies['af-info-text3'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-admin-company-list-company-guide ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><strong>Välittäjätason käyttäjäsi on linkitetty alla oleviin toimipisteisiin.</strong><br />Voit muokata henkilökohtaisen <a href="/hallinta/asetukset">välittäjäprofiilin tietoja täältä</a>.<br /><br />Asiakaspalvelu: <a href="mailto:asiakaspalvelu@asuntoarvio.fi?subject=Yhteydenotto%20Asuntoarvio.fi%20-hallinnasta" className="af-class-admin-company-list-info-link">asiakaspalvelu@asuntoarvio.fi</a>.<strong><em><br />‍</em></strong></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                {map(proxies['af-admin-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-148 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  {map(proxies['af-admin-button'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-company-form-tabs-2 w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Hyväksymättömät yritykset</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                {map(proxies['af-agency-card'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  <div className="af-class-lkv-result-wrapper w-clearfix">
                    <div className="af-class-lkv-result-logo-wrapper">
                      {map(proxies['af-logo-image'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-86 ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    </div>
                    <div className="af-class-lkv-result-content-wrapper w-clearfix">{map(proxies['af-top-100'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="images/top100.svg" width={41} alt="top-100" {...{...props, className: `af-class-image-6 ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </img>
                ))}
                      {map(proxies['af-marketing-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h2 {...{...props, className: `af-class-lkv-result-list-heading ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kiinteistönvälitys Matti Meikäläinen Oy</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </h2>
                ))}
                      {map(proxies['af-address'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-address-orginal ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Töölönkatu 3 A, 00260 Helsinki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      <div className="af-class-lkv-result-list-rating-wrapper w-clearfix">
                        {map(proxies['af-stars-average'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-rating-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>4.6</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        {map(proxies['af-stars'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-star-full ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div>
                        {map(proxies['af-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet...</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                      </div>
                    </div>
                    <div className="af-class-lkv-result-button-wrapper w-clearfix">
                      {map(proxies['af-offer-requests-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]/tarjouspyynnot/avoimet" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-top w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Avoimet tarjouspyynnöt<span className="af-class-arrow-right af-class-arrow-button-medium"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {map(proxies['af-edit-info-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-margin w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Muokkaa tietoja<span className="af-class-arrow-right af-class-arrow-button-medium"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {map(proxies['af-pending'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-margin w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Odottaa hyväksyntää<span className="af-class-arrow-right af-class-arrow-button-medium"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    </div>
                  </div>
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
              </div>
              <div className="af-class-lkv-result-list-show-more-container w-container" />
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAdminCompanyListView

/* eslint-enable */