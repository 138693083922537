import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { GoogleMap } from '../components/GoogleMap';
import AfAdminOfferInfoPageView from '../views/AfAdminOfferInfoPageView';
import { BaseController } from './BaseController';
import { formatDate, formatNumber, loadingProps } from '../lib/helpers';
import {
  OfferRequest,
  CompanyOfferRequest,
  getStatusName,
  offerRequestFieldNames,
  humanizeDataValue,
  getSlugByStatus,
  OfferRequestStatus,
} from '../offer-request/offer-request';

interface Props {
  offerRequest: OfferRequest;
  onStatusUpdateAction: (event: any) => void;
  renderCompanies: () => null | JSX.Element;
  renderAction: () => null | JSX.Element;
  onSubmitEstimation: () => Promise<void>;
  onChangeEstimation: (estVal: number | null) => void;
  onSendMyyntiPro: () => Promise<void>;
  myyntiPro: any;
}

interface State {
  actions: any;
  savingMyyntiPro: boolean;
}

export class AfAdminOfferInfoPageController extends BaseController<RouteComponentProps & Props, State> {
  state = {
    actions: null,
    savingMyyntiPro: false,
  };

  renderData() {
    const { offerRequest } = this.props;
    return Object.entries(offerRequestFieldNames).map(([field, label]) => (
      <af-data-row key={field}>
        <af-data-label>{label}</af-data-label>
        <af-data-value>{humanizeDataValue(field, offerRequest[field], offerRequest.isAnonymized)}</af-data-value>
      </af-data-row>
    ));
  }

  renderValue() {
    const { offerRequest: { companyOfferRequest } } = this.props;
    const {
      askingPrice,
      contactedAt,
      contractAt,
      sellingPrice,
      fulfilledAt,
      commission,
      isOfferSale,
      priceEstimation,
    } = companyOfferRequest as CompanyOfferRequest;

    const values = [];

    const priceEstimationValue = priceEstimation ? `${formatNumber(priceEstimation)} €, ${formatDate(contactedAt)}` : '-';
    values.push(`Hinta-arvio: ${priceEstimationValue}`);

    if (askingPrice) {
      let str = `Hintapyyntö: ${formatNumber(askingPrice)} €, ${formatDate(contractAt)}`;
      if (isOfferSale) {
        str += ' (Tarjouskauppa)';
      }
      values.push(str);
    }
    if (sellingPrice) {
      values.push(`Kauppahinta: ${formatNumber(sellingPrice)} €, ${formatDate(fulfilledAt)}`);
    }
    if (commission) {
      values.push(`Välityspalkkio: ${formatNumber(commission)} €, ${formatDate(fulfilledAt)}`);
    }

    if (!values.length) {
      return null;
    }

    return values.map((value, index) => (
      <af-value key={index}>{value}</af-value>
    ));
  }

  submitEstimate = async (e: any) => {
    e.preventDefault();
    await this.props.onSubmitEstimation();
  }

  onSendMyyntiPro = async () => {
    if (this.state.savingMyyntiPro || this.props.myyntiPro.sent) {
      return;
    }

    this.setState({ savingMyyntiPro: true });

    const { onSendMyyntiPro } = this.props;

    await onSendMyyntiPro();

    this.setState({ savingMyyntiPro: false });
  }

  renderEstimate() {
    const { offerRequest } = this.props;
    const { companyOfferRequest } = offerRequest;
    const { priceEstimation } = companyOfferRequest as CompanyOfferRequest || {};
    return (
      <af-form-section-review-email>
        <af-initial-offer-form value={priceEstimation} onChange={(e: any) => this.props.onChangeEstimation(e.target.value)} />
        <af-initial-offer-submit onClick={this.submitEstimate}/>
      </af-form-section-review-email>
    );
  }

  render() {
    const { savingMyyntiPro } = this.state;
    const { offerRequest, renderAction, renderCompanies, myyntiPro } = this.props;
    const { location, companyOfferRequest } = offerRequest;
    const status = this.determineOfferRequestStatus(offerRequest);
    const isSingularView = !!companyOfferRequest;
    const linkedCompanies = renderCompanies();

    let back = companyOfferRequest ? `/hallinta/${companyOfferRequest.companyId}` : '/admin';
    back += `/tarjouspyynnot/${getSlugByStatus(status || OfferRequestStatus.open)}`;

    return (
      <AfAdminOfferInfoPageView>
        {this.afLink('back-link', back)}
        {this.renderData()}
        {this.state.actions}
        {status && status === OfferRequestStatus.contacted && this.renderEstimate()}
        {status && <af-title>{getStatusName(status, true)}</af-title>}
        {isSingularView && this.renderValue()}
        {myyntiPro.enabled && (
          <af-action-btn-myyntipro onClick={this.onSendMyyntiPro} {...loadingProps(savingMyyntiPro)} disabled={savingMyyntiPro || myyntiPro.sent}>
            {myyntiPro.sent ? 'MyyntiPro tiedot lähetetty' : null}
            </af-action-btn-myyntipro>
          )
        }
        <af-map><GoogleMap coordinates={location} /></af-map>
        {this.props.children}
        {renderAction()}
        {linkedCompanies && <af-linked-companies-wrapper>{linkedCompanies}</af-linked-companies-wrapper>}
      </AfAdminOfferInfoPageView>
    );
  }

  private determineOfferRequestStatus(offerRequest: OfferRequest): (OfferRequestStatus | null) {
    const { companyOfferRequest, companyOfferRequests } = offerRequest;
    const { status } = companyOfferRequest || {};
    if (status) {
      return status;
    }
    if (!companyOfferRequests) {
      return null;
    }
    // Status must be determined from all offer requests for admin
    const states = new Set(companyOfferRequests.map(item => item.status));
    const preferredStates = [
      OfferRequestStatus.fulfilled,
      OfferRequestStatus.notFulfilled,
      OfferRequestStatus.contract,
      OfferRequestStatus.contacted,
      OfferRequestStatus.open,
      OfferRequestStatus.rejected,
      OfferRequestStatus.lost,
    ];
    for (const preferredStatus of preferredStates) {
      if (states.has(preferredStatus)) {
        return preferredStatus;
      }
    }
    return null;
  }
}
