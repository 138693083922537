/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAdminCompanyFormView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAdminCompanyFormController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAdminCompanyFormView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAdminCompanyFormView ? transformProxies(this.props.children) : {
      'af-select-section': [],
      'af-show-company-info': [],
      'af-show-images-info': [],
      'af-show-billing-info': [],
      'af-show-users-info': [],
      'af-public-link-wrapper': [],
      'af-public-link': [],
      'af-company-info': [],
      'af-form-company-info': [],
      'af-approval-wrapper': [],
      'af-company-id': [],
      'af-input-approved': [],
      'af-delete-company': [],
      'af-input-name': [],
      'af-input-marketing-name': [],
      'af-input-description': [],
      'af-input-business-id': [],
      'af-input-address': [],
      'af-input-zip-code': [],
      'af-input-city': [],
      'af-input-email': [],
      'af-input-www': [],
      'af-input-phone': [],
      'af-input-visiting-hours': [],
      'af-input-founded': [],
      'af-input-number-of-employees': [],
      'af-input-commission-of-housing-cooperative': [],
      'af-input-commission-of-property': [],
      'af-input-min-commission-of-housing-cooperative': [],
      'af-input-min-commission-of-property': [],
      'af-input-base-costs-of-housing-cooperative': [],
      'af-input-base-costs-of-property': [],
      'af-input-document-charges': [],
      'af-input-marketing-expenses': [],
      'af-input-use-dept-free-price': [],
      'af-search-select-territory-cities': [],
      'af-search-select-territory-zip-code-areas': [],
      'af-search-select-services': [],
      'af-search-select-advertising': [],
      'af-accept-terms-wrapper': [],
      'af-accept-terms-checkbox': [],
      'af-company-info-submit': [],
      'af-users-info': [],
      'af-linked-users': [],
      'af-linked-user': [],
      'af-linked-user-id': [],
      'af-linked-user-info': [],
      'af-public-link-agent': [],
      'af-linked-user-profile-status': [],
      'af-linked-user-admin-status': [],
      'af-linked-user-remove': [],
      'af-form-users-info': [],
      'af-input-user-id': [],
      'af-users-info-submit': [],
      'af-images-info': [],
      'af-form-images-info': [],
      'af-input-logo-image': [],
      'af-logo-image-preview': [],
      'af-input-background-image': [],
      'af-background-image-preview': [],
      'af-images-info-submit': [],
      'af-billing-info': [],
      'af-form-billing-info': [],
      'af-company-id': [],
      'af-input-billing-method': [],
      'af-billing-type-email': [],
      'af-input-billing-email': [],
      'af-billing-type-e-invoicing': [],
      'af-input-e-invoice-address': [],
      'af-input-e-invoice-broker-id': [],
      'af-billing-type-paper-invoice': [],
      'af-input-billing-address': [],
      'af-input-billing-zip-code': [],
      'af-input-billing-city': [],
      'af-billing-info-submit': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            {map(proxies['af-select-section'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-container-6 w-container ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-div-block-95">
                {map(proxies['af-show-company-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-company-form-tabs-3 w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Yrityksen tiedot</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-show-images-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-company-form-tabs-3 w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Logot &amp; kuvat</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-show-billing-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-company-form-tabs-3 w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Laskutustiedot</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-show-users-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-company-form-tabs-3 w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Käyttäjät</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
              </div>
              {map(proxies['af-public-link-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-130 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                <div className="af-class-text-block-44">Katso yrityksen julkinen sivu:</div>
                {map(proxies['af-public-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[city]/[company]" target="_blank" {...{...props, className: `af-class-admin-company-public-link-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>www.asuntoarvio.fi/helsinki/helsingin-kiinteistonvalitys-lkv/</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
              {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-company-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-section-12 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-6 w-container">
                <div className="af-class-div-block-33">
                  <div id="company-form" className="w-form">
                    {map(proxies['af-form-company-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-company-info" name="wf-form-af-form-company-info" data-name="af-form-company-info" method="get" data-wf-page-id="5e2e9ffb5b8bed3e95bbd173" data-wf-element-id="d56693b4-1b91-a978-c279-41979d489a93" {...{...props, className: `w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      {map(proxies['af-approval-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-88 w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        {map(proxies['af-company-id'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-admin-id-tag-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>ID:&nbsp;12345</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}{map(proxies['af-input-approved'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="status-2" name="status-2" data-name="Status 2" required {...{...props, className: `af-class-admin-company-status-2 w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                          <option value="true">Hyväksytty</option>
                          <option value="false">Hyväksymätön</option>
                        </React.Fragment>)}
                  </select>
                ))}
                        {map(proxies['af-delete-company'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-link-2 af-class-alt-position ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Poista yritys</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      <div className="af-class-div-block-32">
                        <div className="af-class-heading-10">Yrityksen tiedot:</div>
                      </div>
                      <div className="w-clearfix">
                        <div className="af-class-div-block-87"><label htmlFor="name" className="af-class-field-label-4">Yrityksen toiminimi</label>{map(proxies['af-input-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="name" data-name="Name" placeholder="esim. Kiinteistönvälitys Matti Meikäläinen Oy" type="text" id="name" required {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="marketingName" className="af-class-field-label-4">Yrityksen markkinointinimi</label>{map(proxies['af-input-marketing-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="marketingName" data-name="Marketing Name" placeholder="esim. Helsingin kiinteistönvälitys LKV" type="text" id="marketingName" required {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87 af-class-full-width"><label htmlFor="description" className="af-class-field-label-4">Yrityksen kuvaus</label>{map(proxies['af-input-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <textarea maxLength={5000} name="description" data-name="Description" placeholder="Vapaa kuvaus yrityksen toiminnasta ja palveluista. Kirjoita mahdollisimman myyvä kuvaus yrityksesi asiakkaita ajatellen." id="description" required {...{...props, className: `af-class-input-field-company-form-textarea-2 w-input ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </textarea>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="businessId" className="af-class-field-label-4">Y-tunnus</label>{map(proxies['af-input-business-id'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="businessId" data-name="Business Id" pattern="[0-9]{7}-[0-9]{1}" placeholder="esim. 1234567-8" type="text" id="businessId" required {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="address" className="af-class-field-label-4">Katuosoite</label>{map(proxies['af-input-address'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="address" data-name="Address" placeholder="esim. Kampinkuja 2" type="text" id="address" required {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="zipCode" className="af-class-field-label-4">Postinumero</label>{map(proxies['af-input-zip-code'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={5} name="zipCode" data-name="Zip Code" pattern="[0-9]{5}" data-focus-type="number" placeholder="esim. 00100" minLength={5} type="text" id="zipCode" required {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="city" className="af-class-field-label-4">Paikkakunta</label>{map(proxies['af-input-city'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="city" data-name="City" placeholder="esim. Helsinki" type="text" id="city" required {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="email" className="af-class-field-label-4">Yrityksen sähköposti</label>{map(proxies['af-input-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="email" data-name="Email" placeholder="esim. info@yritys.fi" type="email" id="email" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="www" className="af-class-field-label-4">Yrityksen www-sivu (<em>myös osoitteen alku: http:// tai https://</em>)</label>{map(proxies['af-input-www'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="www" data-name="Www" pattern="https?://.+\..{2,}" placeholder="esim. https://www.yritys.fi" type="text" id="www" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="phone" className="af-class-field-label-4">Yrityksen puhelinnumero</label>{map(proxies['af-input-phone'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="phone" data-name="Phone" placeholder="esim. 020 1234 1234" type="tel" id="phone" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      </div>
                      <div className="af-class-div-block-87"><label htmlFor="visitingHours" className="af-class-field-label-4">Aukioloajat</label>{map(proxies['af-input-visiting-hours'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="visitingHours" data-name="Visiting Hours" placeholder="esim. ma-to 10-16.30, pe 10-14" type="text" id="visitingHours" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="founded" className="af-class-field-label-4">Yhtiö perustettu</label>{map(proxies['af-input-founded'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="founded" data-name="Founded" pattern="[0-9]{4}" data-focus-type="number" min={1800} placeholder="esim. 1992" type="text" id="founded" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="numberOfEmployees" className="af-class-field-label-4">Työntekijöiden lukumäärä</label>{map(proxies['af-input-number-of-employees'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="numberOfEmployees" data-name="Number Of Employees" pattern="[0-9]+" data-focus-type="number" placeholder="esim. 12" type="text" id="numberOfEmployees" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="commissionOfHousingCooperative" className="af-class-field-label-4">Välityspalkkio asunto-osake (%)</label>{map(proxies['af-input-commission-of-housing-cooperative'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="commissionOfHousingCooperative" data-name="Commission Of Housing Cooperative" pattern="[0-9]{1,2}([,.][0-9]{1,2})?" data-focus-type="number" placeholder="esim. 3,5" type="text" id="commissionOfHousingCooperative" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="commissionOfProperty" className="af-class-field-label-4">Välityspalkkio kiinteistö (%)</label>{map(proxies['af-input-commission-of-property'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="commissionOfProperty" data-name="Commission Of Property" pattern="[0-9]{1,2}([,.][0-9]{1,2})?" data-focus-type="number" placeholder="esim. 4,5" type="text" id="commissionOfProperty" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="minCommissionOfHousingCooperative" className="af-class-field-label-4">Minimipalkkio asunto-osake (€)</label>{map(proxies['af-input-min-commission-of-housing-cooperative'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="minCommissionOfHousingCooperative" data-name="Min Commission Of Housing Cooperative" pattern="[0-9]+" data-focus-type="number" placeholder="esim. 4500" type="text" id="minCommissionOfHousingCooperative" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="minCommissionOfProperty" className="af-class-field-label-4">Minimipalkkio kiinteistö (€)</label>{map(proxies['af-input-min-commission-of-property'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="minCommissionOfProperty" data-name="Min Commission Of Property" pattern="[0-9]+" data-focus-type="number" placeholder="esim. 5000" type="text" id="minCommissionOfProperty" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="baseCostsOfHousingCooperative" className="af-class-field-label-4">Peruskulut asunto-osake (€)</label>{map(proxies['af-input-base-costs-of-housing-cooperative'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="baseCostsOfHousingCooperative" data-name="Base Costs Of Housing Cooperative" pattern="[0-9]+" data-focus-type="number" placeholder="esim. 400" type="text" id="baseCostsOfHousingCooperative" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="baseCostsOfProperty" className="af-class-field-label-4">Peruskulut kiinteistö (€)</label>{map(proxies['af-input-base-costs-of-property'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="baseCostsOfProperty" data-name="Base Costs Of Property" pattern="[0-9]+" data-focus-type="number" placeholder="esim. 700" type="text" id="baseCostsOfProperty" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="documentCharges" className="af-class-field-label-4">Lisäkulut asiakirjoista</label>{map(proxies['af-input-document-charges'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="documentCharges" name="documentCharges" data-name="Document Charges" {...{...props, className: `af-class-admin-company-document-price-2 w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                          <option value="null">Ei tiedossa</option>
                          <option value="false">Ei</option>
                          <option value="true">Kyllä</option>
                        </React.Fragment>)}
                  </select>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="marketingExpenses" className="af-class-field-label-4">Lisäkulut markkinoinnista</label>{map(proxies['af-input-marketing-expenses'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="marketingExpenses" name="marketingExpenses" data-name="Marketing Expenses" {...{...props, className: `af-class-admin-company-document-price-2 w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                          <option value="null">Ei tiedossa</option>
                          <option value="false">Ei</option>
                          <option value="true">Kyllä</option>
                        </React.Fragment>)}
                  </select>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="useDeptFreePrice" className="af-class-field-label-4">Välityspalkkion laskentaperuste</label>{map(proxies['af-input-use-dept-free-price'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="useDeptFreePrice" name="useDeptFreePrice" data-name="Use Dept Free Price" {...{...props, className: `af-class-admin-company-document-price-2 w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                          <option value="true">Velattomasta kauppahinnasta</option>
                          <option value="false">Kauppahinnasta</option>
                        </React.Fragment>)}
                  </select>
                ))}</div>
                      <div className="af-class-div-block-87"><label htmlFor="territoryCities" className="af-class-field-label-4">Välitysliikkeen toimialueet <br /><em>(valitse max. 5 kpl kuntaa/kaupunkia)</em></label>
                        {map(proxies['af-search-select-territory-cities'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment><input className="af-class-input-field-51 w-input" maxLength={256} name="territoryCities" data-name="Territory Cities" placeholder="Etsi paikkakunta" type="email" id="territoryCities" /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-87"><label htmlFor="territory-zip-code-area" className="af-class-field-label-4">Välitysliikkeen toimialueet <br /><em>(valitse max. 20 kpl postinumeroaluetta)</em></label>
                        {map(proxies['af-search-select-territory-zip-code-areas'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment><input className="af-class-input-field-51 w-input" maxLength={256} name="territory-zip-code-area" data-name="Territory Zip Code Area" placeholder="Etsi postinumero tai postinumeroalue" type="email" id="territory-zip-code-area" /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-87"><label htmlFor="services" className="af-class-field-label-4">Lisäpalvelut <em>(valitse useampia)</em></label>
                        {map(proxies['af-search-select-services'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment><input className="af-class-input-field-51 w-input" maxLength={256} name="territoryCities-2" data-name="Territory Cities 2" placeholder="Valitse lisäpalvelut" type="email" id="territoryCities-2" /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-87"><label htmlFor="advertising" className="af-class-field-label-4">Näkyvyys <em>(valitse useampia)</em></label>
                        {map(proxies['af-search-select-advertising'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment><input className="af-class-input-field-51 w-input" maxLength={256} name="territoryCities-2" data-name="Territory Cities 2" placeholder="Valitse näkyvyyspalvelut" type="email" id="territoryCities-2" /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      {map(proxies['af-accept-terms-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-68 af-class-margin-2 w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        {map(proxies['af-accept-terms-checkbox'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-69 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-checked"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-lkv-result-list-address-2 af-class-margin-form af-class-margin-form-2">Hyväksyn palvelun <a href="/app/documents/Asuntoarvio-fi-sopimusehdot_230101.pdf" target="_blank" className="af-class-admin-modals-text-link-2">sopimusehdot </a>ja <a href="/app/documents/Asuntoarvio-fi-tietosuojakaytanto_230101.pdf" target="_blank" className="af-class-admin-modals-text-link-2">tietosuojakäytännön</a>.</div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      <div className="af-class-div-block-152">
                        <div>{map(proxies['af-company-info-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Tallenna tiedot" {...{...props, className: `af-class-button-5 af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Tallenna tiedot')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                      </div>
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-users-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-section-20 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-3 w-container">
                {map(proxies['af-linked-users'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  <div className="af-class-heading-10">Yrityksen käyttäjät:</div>
                  {map(proxies['af-linked-user'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-34 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-form-block-2 w-form">
                      <form id="email-form" name="email-form" data-name="Email Form" method="get" className="af-class-form-3 w-clearfix" data-wf-page-id="5e2e9ffb5b8bed3e95bbd173" data-wf-element-id="2c0c77aa-2f10-622a-008f-0211a7772e44">
                        {map(proxies['af-linked-user-id'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-admin-id-tag-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>ID:&nbsp;12345</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-div-block-155">
                          {map(proxies['af-linked-user-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[company]/kayttajat/[userId]" {...{...props, className: `af-class-admin-company-public-link-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Matti Meikäläinen (matti.meikalainen@yritys.fi)</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                          {map(proxies['af-public-link-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalittaja/profiili/[agent]" target="_blank" {...{...props, className: `af-class-admin-company-public-link-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>www.asuntoarvio.fi/kiinteistonvalittaja/profiili/matti.meikalainen</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        </div>
                        <div className="af-class-div-block-156 w-clearfix">{map(proxies['af-linked-user-profile-status'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="isPublicProfile" name="isPublicProfile" data-name="isPublicProfile" {...{...props, className: `af-class-select-field-3 w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                            <option value={1}>Julkinen profiili</option>
                            <option value={0}>Piilotettu profiili</option>
                          </React.Fragment>)}
                  </select>
                ))}{map(proxies['af-linked-user-admin-status'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="isMainUser" name="isMainUser" data-name="isMainUser" {...{...props, className: `af-class-select-field-2 w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                            <option value={1}>Pääkäyttäjä</option>
                            <option value={0}>Välittäjä</option>
                          </React.Fragment>)}
                  </select>
                ))}
                          {map(proxies['af-linked-user-remove'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-admin-x ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>X</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                <div className="w-form">
                  {map(proxies['af-form-users-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-users-info" name="wf-form-af-form-users-info" data-name="af-form-users-info" method="get" data-wf-page-id="5e2e9ffb5b8bed3e95bbd173" data-wf-element-id="2c0c77aa-2f10-622a-008f-0211a7772e4d" {...{...props, className: `w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-div-block-87 w-clearfix"><label htmlFor="userId" className="af-class-field-label-4 af-class-alt-margin">Lisää välittäjä sähköpostiosoitteella. Huom! Välittäjän tulee olla rekisteröitynyt palveluun, jotta linkitys onnistuu.</label>{map(proxies['af-input-user-id'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="userId" data-name="User Id" placeholder type="text" id="userId" required {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{map(proxies['af-users-info-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Lisää" {...{...props, className: `af-class-button-5 af-class-alt-button af-class-alt-button-position w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Lisää')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-images-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-section-20 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-3 w-container">
                <div>
                  <div className="af-class-heading-10">Yrityksen kuvat:</div>
                </div>
                <div className="w-form">
                  {map(proxies['af-form-images-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-images-info" name="wf-form-af-form-users-info" data-name="af-form-users-info" method="get" data-wf-page-id="5e2e9ffb5b8bed3e95bbd173" data-wf-element-id="c8fd788d-a6d4-6e9b-f125-1d6e5c75d5a2" {...{...props, className: `w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-div-block-87"><label htmlFor="logoImage" className="af-class-field-label-4 af-class-alt-margin af-class-image-upload-label">Yrityksen logo</label><label htmlFor="logoImage" className="af-class-field-label-4 af-class-alt-margin af-class-image-type-info">Koko 300x300 pikseliä (tiedostomuoto: .png, .jpg tai .gif)</label>{map(proxies['af-input-logo-image'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="logoImage" data-name="Logo Image" placeholder="Valitse kuva tietokoneelta" type="text" id="logoImage" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{map(proxies['af-logo-image-preview'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="https://uploads-ssl.webflow.com/5e2e9ece2ca1f7108618f53f/5e2e9ece2ca1f7097d18f54b_lkv%20placeholder.svg" width={165} height={165} alt {...props}>{props.children}
                  </img>
                ))}</div>
                    <div className="af-class-div-block-87"><label htmlFor="backgroundImage" className="af-class-field-label-4 af-class-alt-margin af-class-image-upload-label">Profiilisivun pääkuva</label><label htmlFor="backgroundImage" className="af-class-field-label-4 af-class-alt-margin af-class-image-type-info">Koko 1880x500 pikseliä (tiedostomuoto: .png, .jpg tai .gif)</label>{map(proxies['af-input-background-image'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="backgroundImage" data-name="Background Image" placeholder="Valitse kuva tietokoneelta" type="text" id="backgroundImage" {...{...props, className: `af-class-input-field-51 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{map(proxies['af-background-image-preview'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="https://d3e54v103j8qbb.cloudfront.net/plugins/Image/assets/placeholder.60f9b1840c.svg" alt {...props}>{props.children}
                  </img>
                ))}</div>
                    <div className="af-class-div-block-131 w-clearfix">{map(proxies['af-images-info-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Lähetä" {...{...props, className: `af-class-button-5 af-class-alt-button af-class-alt-button-position w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Lähetä')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-billing-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-section-12 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-6 w-container">
                <div className="af-class-div-block-33">
                  <div className="w-form">
                    {map(proxies['af-form-billing-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-billing-info" name="wf-form-af-form-billing-info" data-name="af-form-billing-info" method="get" data-wf-page-id="5e2e9ffb5b8bed3e95bbd173" data-wf-element-id="af52df6f-1b1e-443b-0e69-ce86a84a4f68" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <div className="af-class-div-block-32 w-clearfix">
                        {map(proxies['af-company-id'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-admin-id-tag-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>ID:&nbsp;12345</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-32">
                        <div className="af-class-heading-10">Yrityksen laskutustiedot:</div>
                      </div><label htmlFor="billingMethod" className="af-class-field-label-4">Laskutustapa</label>{map(proxies['af-input-billing-method'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="billingMethod" name="billingMethod" data-name="Billing Method" required {...{...props, className: `af-class-input-field-admin-user-form-2 w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                        <option value="null">Valitse laskutustapa</option>
                        <option value="email">Sähköposti</option>
                        <option value="e-invoice">Verkkolasku</option>
                        <option value="paper-invoice">Paperilasku</option>
                      </React.Fragment>)}
                  </select>
                ))}
                      {map(proxies['af-billing-type-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="billingEmail" className="af-class-field-label-4">Sähköposti</label>{map(proxies['af-input-billing-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="billingEmail" data-name="Billing Email" placeholder type="email" id="billingEmail" required {...{...props, className: `af-class-input-field-admin-user-form-2 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      {map(proxies['af-billing-type-e-invoicing'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="eInvoiceAddress" className="af-class-field-label-4">Verkkolaskuosoite</label>{map(proxies['af-input-e-invoice-address'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="eInvoiceAddress" data-name="E Invoice Address" placeholder type="text" id="eInvoiceAddress" required {...{...props, className: `af-class-input-field-admin-user-form-2 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}<label htmlFor="eInvoiceBrokerId" className="af-class-field-label-4">Välittäjätunnus</label>{map(proxies['af-input-e-invoice-broker-id'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="eInvoiceBrokerId" data-name="E Invoice Broker Id" placeholder type="text" id="eInvoiceBrokerId" required {...{...props, className: `af-class-input-field-admin-user-form-2 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      {map(proxies['af-billing-type-paper-invoice'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="billingAddress" className="af-class-field-label-4">Katuosoite</label>{map(proxies['af-input-billing-address'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="billingAddress" data-name="Billing Address" placeholder type="text" id="billingAddress" required {...{...props, className: `af-class-input-field-admin-user-form-2 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}<label htmlFor="billingZipCode" className="af-class-field-label-4">Postinumero</label>{map(proxies['af-input-billing-zip-code'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="billingZipCode" data-name="Billing Zip Code" placeholder type="number" id="billingZipCode" required {...{...props, className: `af-class-input-field-admin-user-form-2 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}<label htmlFor="billingCity" className="af-class-field-label-4">Paikkakunta</label>{map(proxies['af-input-billing-city'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="billingCity" data-name="Billing City" placeholder type="text" id="billingCity" required {...{...props, className: `af-class-input-field-admin-user-form-2 w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}
                        <div className="af-class-text-block-37">* Paperilaskun laskutuslisä 3,90 euroa / lähetetty lasku.</div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}{map(proxies['af-billing-info-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Tallenna tiedot" {...{...props, className: `af-class-button-5 af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Tallenna tiedot')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAdminCompanyFormView

/* eslint-enable */