import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AfAdminAgentFormView from '../views/AfAdminAgentFormView';
import { BaseController } from './BaseController';

interface Props {
  activeSection?: string;
  onSelectSection?: (section: string) => void;
  sections?: string[];
  slug: string;
  hasPublicProfile: boolean;
}

export class AfAdminAgentFormController extends BaseController<RouteComponentProps & Props> {
  renderSectionButton = (section: string) => {
    const { onSelectSection, activeSection } = this.props;
    const BtnTag = `af-show-${section}` as unknown as React.ComponentType<any>;

    return (
      <BtnTag
        key={section}
        className={activeSection === section ? 'af-class-button-company-form-tabs-active' : undefined}
        onClick={() => onSelectSection && onSelectSection(section)}
      />
    );
  }

  render() {
    const { sections, slug, hasPublicProfile } = this.props;

    return (
      <AfAdminAgentFormView>
        {this.props.children}
        {sections && (
          <af-select-section>
            {hasPublicProfile && <af-public-link-wrapper>{this.afLink('public-link', {
              agent: slug,
            }, `asuntoarvio.fi/kiinteistonvalittaja/profiili/${slug}`)}</af-public-link-wrapper>}
            {sections.length > 1 && sections.map(this.renderSectionButton)}
          </af-select-section>
        )}
      </AfAdminAgentFormView>
    );
  }
}
