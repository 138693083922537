import invert from 'lodash/invert';
import { UserProfile } from '../store/user/reducer';
import { Company, Point } from '../company/Company.interface';
import {
    StringSignatureString,
    StringSignatureNumber,
    StringSignatureAny,
    offerRequestFieldNames,
    scheduleOptions,
    importantFeatureOptions,
    conditions,
    extrasOptions,
    renovateOptions,
    OfferRequestStatus,
    OfferRequestCustomStatus,
    offerRequestContractTypes,
    offerRequestRejectedTypes,
    offerRequestMaskTypes,
    offerRequestClosedTypes,
    offerRequestLeadIntegrationTypes,
} from '../shared/types';
import {
    formatNumber,
    getConditionName,
    getApartmentTypeName,
    getScheduleName,
    humanizeDataValue,
} from '../lib/helpers';


export {
    OfferRequestStatus,
    offerRequestContractTypes,
    offerRequestRejectedTypes,
    offerRequestMaskTypes,
    offerRequestClosedTypes,
    offerRequestLeadIntegrationTypes,
};

export interface OfferRequest extends StringSignatureAny {
    id: number;
    slug: string;
    createdAt: Date;
    address: string;
    addressExtra: string;
    city: string;
    zipCode: string;
    condition: number;
    type: number;
    yearOfConstruction: number;
    size: number;
    numberOfRooms: number;
    floorNumber: number;
    numberOfFloorsInBuilding: number;
    hasExtras: string[];
    hasRecentlyRenovated: string[];
    schedule: string;
    name: string;
    email: string;
    phone: string;
    location: Point|null;
    companyOfferRequests?: CompanyOfferRequest[];
    companyOfferRequest?: CompanyOfferRequest;
    targetCompanyId?: number;
    targetCompany?: Company;
    askOffersFromAnyCompany: boolean;
    priceEstimate: number;
    extraInfo: string;
    isAnonymized: boolean;
}

export interface OfferRequestWithCompanies extends OfferRequest {
    companyOfferRequests: CompanyOfferRequest[];
}

export interface OfferRequestWithCompany extends OfferRequest {
    companyOfferRequest: CompanyOfferRequest;
}

export interface CompanyOfferRequestUser {
    id: number;
    userId: number;
    createdAt: Date;
    updatedAt: Date;
    user: UserProfile;
}

export interface IOfferRequestConfirmation {
    content: UserProfile | Company;
    type: string;
}

export interface CompanyOfferRequest extends StringSignatureAny {
    id: number;
    createdAt: Date;
    updatedAt: Date;
    slug: string;
    linkedAt: Date;
    contactedAt: Date;
    rejectedAt: Date;
    contractAt: Date;
    fulfilledAt: Date;
    notFulfilledAt: Date;
    lostAt: Date;
    askingPrice: number;
    sellingPrice: number;
    commission: number;
    offerRequest: OfferRequest;
    status: OfferRequestStatus;
    customStatus: OfferRequestCustomStatus;
    isOfferSale: boolean;
    users: CompanyOfferRequestUser[];
    priceEstimation: number;
}

export interface Stats extends StringSignatureNumber {
    totalCommission: number;
    totalFulfilled: number;
    openCount: number;
    contactedCount: number;
    contractCount: number;
    fulfilledCount: number;
    notFulfilledCount: number;
    rejectedCount: number;
    askingPriceRatio: number;
    numberOfStarsAvg: number|null;
}

export enum OfferRequestActionType {
  ChangeStatus = 'changeStatus',
  UnlinkAgent = 'unlinkAgent',
}

interface OfferRequestAction {
    buttons: {
        type: OfferRequestActionType;
        status?: OfferRequestStatus;
        label: string;
    }[],
    showInfo: boolean;
}

interface OfferRequestActions {
    [key: string]: OfferRequestAction;
}

export function getOfferRequestActions(
  status: OfferRequestStatus,
  currentUser?: UserProfile,
  offerRequest?: OfferRequest,
  company?: Company
): OfferRequestAction | null {
  const actions: OfferRequestActions = {
    [OfferRequestStatus.open]: {
      buttons: [{
        type: OfferRequestActionType.ChangeStatus,
        status: OfferRequestStatus.contacted,
        label: 'Näytä yhteystiedot',
      }, {
        type: OfferRequestActionType.ChangeStatus,
        status: OfferRequestStatus.rejected,
        label: 'Hylkää tarjouspyyntö',
      }],
      showInfo: true,
    },
    [OfferRequestStatus.contacted]: {
      buttons: [{
        type: OfferRequestActionType.ChangeStatus,
        status: OfferRequestStatus.contract,
        label: 'Vahvista toimeksiantosopimus',
      }, {
        type: OfferRequestActionType.ChangeStatus,
        status: OfferRequestStatus.rejected,
        label: 'Toimeksianto ei toteutunut',
      }, {
        type: OfferRequestActionType.ChangeStatus,
        status: OfferRequestStatus.reclamation,
        label: 'Reklamaatio',
      }],
      showInfo: false,
    },
    [OfferRequestStatus.contract]: {
      buttons: [{
        type: OfferRequestActionType.ChangeStatus,
        status: OfferRequestStatus.fulfilled,
        label: 'Vahvista asunnon kauppa',
      }, {
        type: OfferRequestActionType.ChangeStatus,
        status: OfferRequestStatus.notFulfilled,
        label: 'Kauppa ei toteutunut',
      }],
      showInfo: false,
    },
  };

  const isSuperAdmin = currentUser?.isAdmin === true;
  const isOfferRequestRejectionAllowed = company?.isOfferRequestRejectionAllowed === true;

  if (isSuperAdmin) {
    actions[OfferRequestStatus.rejected] = {
      buttons: [{
        type: OfferRequestActionType.ChangeStatus,
        status: OfferRequestStatus.contacted,
        label: 'Palauta kontaktoiduksi',
      }],
      showInfo: false,
    };
  }

  if (currentUser && offerRequest && isOfferRequestRejectionAllowed) {
    const isLinkedUser = (offerRequest.companyOfferRequest?.users ?? []).find(item => item.userId === currentUser.id) !== undefined;
    if (isLinkedUser) {
      actions[OfferRequestStatus.open].buttons.push({
        type: OfferRequestActionType.UnlinkAgent,
        label: 'En ole kiinnostunut kohteesta',
      });
    }
  }

  if (!actions[status]) {
    return null;
  }

  return actions[status];
}

export function getStatusName(status: OfferRequestStatus, title = false): string {
    const statuses = {
      [OfferRequestStatus.open]: title ? 'Avoin tarjouspyyntö' : 'Avoimet tarjouspyynnöt',
      [OfferRequestStatus.contacted]: title ? 'Asiakasta kontaktoitu' : 'Avoimet asiakkaat',
      [OfferRequestStatus.contract]: title ? 'Sopimus voimassa' : 'Toimeksiantosopimukset',
      [OfferRequestStatus.fulfilled]: title ? 'Toteutunut kauppa' : 'Toteutuneet kaupat',
      [OfferRequestStatus.notFulfilled]: title ? 'Kauppa ei toteutunut' : 'Ei toteutuneet',
      [OfferRequestStatus.rejected]: title ? 'Hylätty tarjous' : 'Hylätyt',
      [OfferRequestStatus.lost]: title ? 'Hävitty tarjous' : 'Hävityt',
      [OfferRequestStatus.reclamation]: title ? 'Reklamoitu tarjous' : 'Reklamaatiot',
  } as StringSignatureString;

    return statuses[status];
}

export function getStatusState(status: OfferRequestStatus): string {
    const statuses = {
      [OfferRequestStatus.open]: 'ei kontaktoitu',
      [OfferRequestStatus.contacted]: 'kontaktoitu',
      [OfferRequestStatus.contract]: 'toimeksianto',
      [OfferRequestStatus.fulfilled]: 'toteutunut kauppa',
      [OfferRequestStatus.notFulfilled]: 'ei toteutunut',
      [OfferRequestStatus.rejected]: 'hylätty',
      [OfferRequestStatus.lost]: 'hävitty',
      [OfferRequestStatus.notAvailable]: 'ei saatavilla',
      [OfferRequestStatus.reclamation]: 'reklamaatio',
  } as StringSignatureString;

    return statuses[status];
}

const statusSlugs = {
  [OfferRequestStatus.open]: 'avoimet',
  [OfferRequestStatus.contacted]: 'kontaktoidut',
  [OfferRequestStatus.contract]: 'toimeksiannot',
  [OfferRequestStatus.fulfilled]: 'kaupat',
  [OfferRequestStatus.notFulfilled]: 'hylatyt',
  [OfferRequestStatus.rejected]: 'hylatyt',
  [OfferRequestStatus.lost]: 'hylatyt',
  [OfferRequestStatus.reclamation]: 'reklamaatiot',
} as StringSignatureString;

export function getSlugByStatus(status: OfferRequestStatus): string {
    return statusSlugs[status];
}

export function getStatusBySlug(status: string): OfferRequestStatus {
    return invert(statusSlugs)[status] as OfferRequestStatus;
}

const mask = (str: string/*, splitter: string = ' '*/) => {
  return str ? str.toString().replace(/./g, '*') : '******';
  // const parts = str.split(splitter);
  // return parts.map((s: string) => s[0] + new Array(s.length - 2).join('*') + s[s.length - 1]).join(splitter);
};

export const maskContactData = (offerRequest: OfferRequest) => {
    if (!offerRequest.companyOfferRequest) {
        return offerRequest;
    }

    const { status } = offerRequest.companyOfferRequest;

    if (!offerRequestMaskTypes.includes(status)) {
        return offerRequest;
    }

    const data = {
        ...offerRequest,
    };

    const maskingFields = ['name', 'addressExtra', 'email', 'phone'];

    if (status !== OfferRequestStatus.open) {
        maskingFields.push(...['zipCode', 'address']);
    }

    maskingFields.forEach((field) => {
        data[field] = mask(offerRequest[field]);
    });

    return data;
};

export {
    offerRequestFieldNames,
    scheduleOptions,
    importantFeatureOptions,
    conditions,
    renovateOptions,
    extrasOptions,
    formatNumber,
    getConditionName,
    getApartmentTypeName,
    getScheduleName,
    humanizeDataValue,
};
