import React from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { Search } from './search/Search';
import { AskForOffer } from './offer-request/AskForOffer';
import { AskForOfferFromCompany } from './offer-request/AskForOfferFromCompany';
import { OfferRequestList } from './offer-request/OfferRequestList';
import CompanyOfferRequestList from './offer-request/CompanyOfferRequestList';
import { OfferRequestDetails } from './offer-request/OfferRequestDetails';
import { WriteReview } from './offer-request/WriteReview';
import { WriteReviewDirect } from './offer-request/WriteReviewDirect';
import CompanyOfferRequestDetails from './offer-request/CompanyOfferRequestDetails';
import CompanyDetails from './company/CompanyDetails';
import AgentDetails from './company/AgentDetails';
import CompanyForm from './company/CompanyForm';
import { AgentForm } from './company/AgentForm';
import { CompanyCustomerReviews } from './company/CompanyCustomerReviews';
import { AuthHelper } from './AuthProvider';
import history from './history';
import { PrivateRoute } from './components/PrivateRoute';
import { AdminRoute } from './components/AdminRoute';
import { LayoutRoute } from './components/LayoutRoute';
import AdminCompanyList from './admin/AdminCompanyList';
import { AdminUserList } from './admin/AdminUserList';
import ScrollToTop from './components/ScrollToTop';
import { Loader } from './components/Loader';
import { UserProfile } from './store/user/reducer';
import { userSelectors } from './store/user';
import { AfAdminModalsController } from './controllers/AfAdminModalsController';
import { UserService } from './services/user';
import { userActions } from './store/user';
import { OfferRequestConfirmation } from './offer-request/OfferRequestConfirmation';
import AdminExportTools from './admin/AdminExportTools';

import './App.scss';

Modal.defaultStyles = {
  ...Modal.defaultStyles,
  content: {
    ...Modal.defaultStyles.content,
    border: 'none',
    background: 'transparent',
    padding: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  overlay: {
    ...Modal.defaultStyles.overlay,
    zIndex: 1000,
  },
};

const LogoutComponent = ({ logout }: AuthHelper) => {
  logout();
  return <Loader />;
};

const AuthorizeComponent = () => {
  return <Loader />;
};

interface RegisterComponentParams {
  targetPath?: string;
}

const RegisterComponent = (props: AuthHelper & RouteComponentProps<RegisterComponentParams>) => {
  const { loginWithRedirect, match } = props;
  loginWithRedirect({ appState: { targetUrl: match.params.targetPath || '/hallinta' }, mode: 'signUp' });
  return <Loader />;
};

interface Props {
  isAuthenticated: boolean;
  profile: UserProfile|null;
  onAcceptTerms: () => void;
}

interface State {
  modalIsOpen: boolean;
}

class App extends React.Component<AuthHelper & Props, State> {
  state = {
    modalIsOpen: false,
  };

  shouldShowTermsModal() {
    const { isAuthenticated, profile } = this.props;
    const { modalIsOpen } = this.state;

    if (!isAuthenticated || !profile || profile.isAcceptedTerms || modalIsOpen) {
      return false;
    }

    return true;
  }

  componentDidUpdate() {
    if (!this.shouldShowTermsModal()) {
      return;
    }

    this.setState({ modalIsOpen: true });
  }

  onCloseModal = (e: any = null) => {
    if (e) {
      e.preventDefault();
    }

    if (this.shouldShowTermsModal()) {
      return;
    }

    this.setState({ modalIsOpen: false });
  }

  rejectTerms = (e: any) => {
    e.preventDefault();
    this.props.logout();
  }

  acceptTerms = async (e: any) => {
    e.preventDefault();
    try {
      await (new UserService())
        .child('me')
        .child('terms')
        .put({ accept: true });
      this.props.onAcceptTerms();
      this.onCloseModal();
      toast.success('Kiitos!');
    } catch (error) {
      toast.error('Ehtojen hyväksyminen epäonnistui');
    }
  }

  render() {
    const { modalIsOpen } = this.state;

    return (
      <div className='app'>
        <ConnectedRouter history={history}>
          <ScrollToTop />
          <Switch>
            <LayoutRoute exact path='/kiinteistonvalittaja/profiili/:agentSlug' component={AgentDetails} />
            <LayoutRoute exact path='/:searchType(kiinteistonvalitys|kiinteistonvalittaja)' component={Search} headerComponent={null} />
            <LayoutRoute exact path='/:searchType(kiinteistonvalitys|kiinteistonvalittaja)/:search' component={Search} headerComponent={null} />
            <LayoutRoute exact path='/kiinteistonvalitys/:city/:companySlug/:claim(claim)?' component={CompanyDetails} />
            <LayoutRoute exact path='/kiinteistonvalitys/:city/:companySlug/arvostele/:reviewSlug?/:numberOfStars(1|2|3|4|5)?'
              component={WriteReviewDirect} />
            <LayoutRoute exact path='/kiinteistonvalittaja/profiili/:agentSlug/arvostele/' component={WriteReviewDirect} />
            <LayoutRoute exact path='/kilpailutuspalvelu/kiinteistonvalittaja/:agentSlug'
              component={AskForOfferFromCompany} headerComponent={null} footerComponent={null}
            />
            <LayoutRoute exact path='/kilpailutuspalvelu/:companySlug'
              component={AskForOfferFromCompany} headerComponent={null} footerComponent={null}
            />
            <LayoutRoute exact path='/tarjouspyynto/d/:offerSlug([0-9a-z]+)'
              component={OfferRequestConfirmation}
            />
            <LayoutRoute exact path='/kilpailutuspalvelu'
              component={AskForOffer} headerComponent={null} footerComponent={null} />
            <LayoutRoute exact path='/rekisteroidy/:targetPath(.+)?' component={RegisterComponent} />

            <LayoutRoute exact path='/authorize' component={AuthorizeComponent} />
            <LayoutRoute exact path='/tarjouspyynto/:offerSlug([0-9a-z]+)/:numberOfStars(1|2|3|4|5|vahvista)?' component={WriteReview} />

            <PrivateRoute exact path='/hallinta/toimipisteet' component={AdminCompanyList} />
            <PrivateRoute exact path='/hallinta/ulos' component={LogoutComponent} />
            <PrivateRoute exact path='/hallinta/asetukset' component={AgentForm} />
            <PrivateRoute exact path='/hallinta/uusi' component={CompanyForm} />
            <PrivateRoute exact path='/hallinta/:companyId([0-9]+)/:section(kayttajat|kuvat|laskutustiedot)?' component={CompanyForm} />
            <PrivateRoute exact path='/hallinta/:companyId([0-9]+)/kayttajat/:userId([0-9]+)' component={AgentForm} />

            <Route
              exact
              path='/hallinta/:companyId([0-9]+)/:list(asiakasarviot|tarjouspyynnot)'
              render={(props: any) => <Redirect to={`/hallinta/${props.match.params.companyId}/${props.match.params.list}/avoimet`} />}
            />
            <Route
              exact
              path='/hallinta/:companyId([0-9]+)/tarjouspyynto'
              render={(props: any) => <Redirect to={`/hallinta/${props.match.params.companyId}/tarjouspyynnot/avoimet`} />}
            />

            <PrivateRoute exact path='/hallinta/:companyId([0-9]+)/asiakasarviot/:status' component={CompanyCustomerReviews} />
            <PrivateRoute exact path='/hallinta/:companyId([0-9]+)/tarjouspyynnot/:status' component={CompanyOfferRequestList} />
            <PrivateRoute exact path='/hallinta/:companyId([0-9]+)/tarjouspyynto/:offerId([0-9]+)' component={CompanyOfferRequestDetails} />

            <AdminRoute exact path='/admin' component={AdminCompanyList} />
            <AdminRoute exact path='/admin/yritykset' component={AdminCompanyList} />
            <AdminRoute exact path='/admin/kayttajat' component={AdminUserList} />
            <AdminRoute exact path='/admin/export' component={AdminExportTools} />
            <AdminRoute exact path='/admin/kayttajat/:userId([0-9]+)' component={AgentForm} />
            <AdminRoute exact path='/admin/tarjouspyynnot/:status?' component={OfferRequestList} />
            <AdminRoute exact path='/admin/tarjouspyynto/:offerId([0-9]+)' component={OfferRequestDetails} />

            <Redirect from='/hallinta(/*?)' to='/hallinta/toimipisteet' />
            <Redirect from='/admin/*' to='/admin' />

            <Redirect from='/kilpailuta-kiinteistonvalitys/:companySlug' to='/kilpailutuspalvelu/:companySlug' />
            <Redirect from='/kilpailuta-kiinteistonvalitys' to='/kilpailutuspalvelu' />
            <Redirect from='/etsi-kiinteistonvalitys/:search' to='/kiinteistonvalitys/:search' />
            <Redirect from='/etsi-kiinteistonvalitys/:city/:companySlug' to='/kiinteistonvalitys/:city/:companySlug' />
            <Redirect from='/etsi-kiinteistonvalitys' to='/kiinteistonvalitys' />
            <Redirect from='/*' to='/kiinteistonvalitys' />
          </Switch>
          <ToastContainer />
        </ConnectedRouter>

        <Modal
          key='modal'
          isOpen={modalIsOpen}
          onRequestClose={this.onCloseModal}
          style={{ ...Modal.defaultStyles, overlay: { ...Modal.defaultStyles.overlay, zIndex: 2000 } }}
        >
          <AfAdminModalsController>
            <af-modal-terms>
              <af-form-terms>
                <af-modal-reject-btn onClick={this.rejectTerms} />,
                <af-modal-accept-btn onClick={this.acceptTerms} />,
              </af-form-terms>
            </af-modal-terms>
          </AfAdminModalsController>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  const actions = {
    onAcceptTerms: () => dispatch(userActions.acceptTerms()),
  };
  return actions;
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: userSelectors.isAuthenticated(state),
  profile: userSelectors.getProfile(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
