/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfOfferConfirmationView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfOfferConfirmationController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfOfferConfirmationView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfOfferConfirmationView ? transformProxies(this.props.children) : {
      'af-section-fix-data': [],
      'af-section-fix-data-children': [],
      'af-confirmation-address': [],
      'af-confirmation-area': [],
      'af-confirmaiton-type': [],
      'af-confirmation-name': [],
      'af-confirmation-email': [],
      'af-confirmation-phone': [],
      'af-data-wrapper': [],
      'af-search-total': [],
      'af-agent-card': [],
      'af-logo-image-agent': [],
      'af-logo-image-company-small': [],
      'af-top-100-agent': [],
      'af-agent-name': [],
      'af-agent-title': [],
      'af-stars-agent': [],
      'af-description-agent': [],
      'af-link-agent': [],
      'af-estimation-waiting': [],
      'af-agency-card': [],
      'af-logo-image': [],
      'af-top-100': [],
      'af-marketing-name': [],
      'af-address': [],
      'af-stars': [],
      'af-description': [],
      'af-company-link': [],
      'af-price-est': [],
      'af-placeholder-card': [],
      'af-logo-image': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            {map(proxies['af-section-fix-data'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-view-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-5 w-container">
                <div className="af-class-agency-review-content-wrapper">
                  <div className="af-class-agency-contract-wrapper af-class-alt-padding">
                    {map(proxies['af-section-fix-data-children'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <h1 className="af-class-heading-3-agency-contract">Kiitos kun kilpailutit kiinteistönvälittäjät Asuntoarvion avulla.</h1>
                      <div className="af-class-lkv-confirmation-wrapper w-clearfix">
                        <div className="af-class-lkv-result-list-rating-number">Olemme saaneet sinulta seuraavat tiedot asunnosta:</div>
                      </div>
                      <div className="af-class-lkv-confirmation-wrapper">
                        <p className="af-class-p-agency-contract-details">Asunnon osoite: {map(proxies['af-confirmation-address'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...{...props, className: `af-class-agency-contract-detail-large ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kivelänkatu 3 A 20, 00260 Helsinki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}<br /><strong /></p>
                        <p className="af-class-p-agency-contract-details">Asunnon pinta-ala: {map(proxies['af-confirmation-area'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...{...props, className: `af-class-agency-contract-detail-large ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>120 m²<br /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}</p>
                        <p className="af-class-p-agency-contract-details"><strong />Asunnon tyyppi: {map(proxies['af-confirmaiton-type'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...{...props, className: `af-class-agency-contract-detail-large ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kerrostalo</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}</p>
                      </div>
                      <div className="af-class-lkv-confirmation-wrapper w-clearfix">
                        <div className="af-class-lkv-result-list-rating-number">Yhteystiedot, joihin välittäjät ovat yhteydessä:</div>
                      </div>
                      <div className="af-class-lkv-confirmation-wrapper">
                        <p className="af-class-p-agency-contract-details">Nimi: {map(proxies['af-confirmation-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...{...props, className: `af-class-agency-contract-detail-large ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Joni Saari</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}<br /><strong /></p>
                        <p className="af-class-p-agency-contract-details">Sähköposti: {map(proxies['af-confirmation-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...{...props, className: `af-class-agency-contract-detail-large ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>joni.saari@lohkoa.fi<br /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}</p>
                        <p className="af-class-p-agency-contract-details"><strong />Puhelinnumero: {map(proxies['af-confirmation-phone'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...{...props, className: `af-class-agency-contract-detail-large ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>0400665031</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}</p>
                      </div>
                      <p className="af-class-p-offer-confirmation-info">Olemme käynnistäneet kilpailutuksen ja toimittaneet tiedot alueesi parhaille välittäjille. Saat yhteydenottoja välittäjiltä n. 1 arkipäivän kuluessa. Arviomme parhaista välittäjistä perustuu antamaanne tietoon myyntikohteesta sekä vastaavien kohteiden toteutuneista asuntokaupoista sekä näiden vertaisarvioista.<br /><br />Sinun tarvitsee tässä vaiheessa vain odottaa yhteydenottoja. Alta löydät kiinteistönvälittäjät, jotka ovat vastanneet yhteydenottoosi. Jos haluat muutoksia antamiisi tietoihin, ole yhteydessä <a href="mailto:asiakaspalvelu@asuntoarvio.fi">asiakaspalvelu@asuntoarvio.fi</a><br /></p>
                      <p className="af-class-p-offer-confirmation-info">
                        <a href="https://asuntoarvio.fi/asunnon-myynti-ja-kiinteistonvalittaja" target="_blank"><strong>Tutustu asunnon myyjän oppaaseen kiinteistönvälittäjän valinnasta täältä</strong></a><br />
                      </p>
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-data-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-lkv-result-list-container w-container">
                {map(proxies['af-search-total'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-list-result-heading ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Seuraavat kiinteistönvälittäjät ovat vastanneet yhteydenottoon</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                {map(proxies['af-agent-card'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  <div className="af-class-lkv-result-wrapper w-clearfix">
                    <div className="af-class-lkv-result-logo-wrapper">
                      {map(proxies['af-logo-image-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agent-face-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        {map(proxies['af-logo-image-company-small'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-results-logo-small ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    </div>
                    <div className="af-class-lkv-result-content-wrapper w-clearfix">{map(proxies['af-top-100-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="images/top100.svg" width={41} alt="top-100" {...{...props, className: `af-class-image-6 ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </img>
                ))}
                      <div className="af-class-lkv-result-list-agent-name-wrapper">
                        <h2 className="af-class-lkv-result-list-heading-agent">
                          {map(proxies['af-agent-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalittaja/profiili/[agent]" target="_blank" {...{...props, className: `af-class-link-6 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Matti Meikäläinen</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        </h2>
                      </div>
                      {map(proxies['af-agent-title'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-agent-title ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Toimitusjohtaja, LKV, LVV</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      <div className="af-class-lkv-result-list-rating-wrapper-agent w-clearfix">
                        {map(proxies['af-stars-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-star-full ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div>
                        {map(proxies['af-description-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                          <a href="/kiinteistonvalitys/[city]/[company]/[agent]" className="af-class-lkv-result-p-link">Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet...</a>
                        </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                      </div>
                    </div>
                    <div className="af-class-lkv-result-button-wrapper w-clearfix">
                      {map(proxies['af-link-agent'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalittaja/profiili/[agent]" target="_blank" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-top w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Katso välittäjän tiedot<span className="af-class-arrow-right af-class-arrow-button-medium"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {map(proxies['af-estimation-waiting'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-margin-initial af-class-offer-confirmation-button-bottom w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Alustavaa hinta-arviota <br />ei annettu</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    </div>
                  </div>
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                {map(proxies['af-agency-card'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  <div className="af-class-lkv-result-wrapper w-clearfix">
                    <div className="af-class-lkv-result-logo-wrapper">
                      {map(proxies['af-logo-image'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-86 ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    </div>
                    <div className="af-class-lkv-result-content-wrapper w-clearfix">{map(proxies['af-top-100'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="images/top100.svg" width={41} alt="top-100" {...{...props, className: `af-class-image-6 ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </img>
                ))}
                      <h2 className="af-class-lkv-result-list-heading">
                        {map(proxies['af-marketing-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[city]/[company]" target="_blank" {...{...props, className: `af-class-link-6 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kiinteistönvälitys Matti Meikäläinen Oy</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      </h2>
                      {map(proxies['af-address'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-address-orginal ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Töölönkatu 3 A, 00260 Helsinki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      <div className="af-class-lkv-result-list-rating-wrapper w-clearfix">
                        {map(proxies['af-stars'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-star-full ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div>
                        {map(proxies['af-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                          <a href="/kiinteistonvalitys/[city]/[company]" target="_blank" className="af-class-lkv-result-p-link">Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet...</a>
                        </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                      </div>
                    </div>
                    <div className="af-class-lkv-result-button-wrapper w-clearfix">
                      {map(proxies['af-company-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[city]/[company]" target="_blank" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-top w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Katso yrityksen tiedot<span className="af-class-arrow-right af-class-arrow-button-medium"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {map(proxies['af-price-est'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-medium af-class-static-button af-class-result-button-margin-initial w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Välittäjän alustava <br />hinta-arvio: 200.000 €</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    </div>
                  </div>
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                {map(proxies['af-placeholder-card'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  <div className="af-class-lkv-result-wrapper w-clearfix">
                    <div className="af-class-lkv-result-logo-wrapper">
                      {map(proxies['af-logo-image'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-86 ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    </div>
                    <div className="af-class-lkv-result-content-wrapper">
                      <h2 className="af-class-lkv-result-list-heading-waiting">Odotetaan välittäjän vastausta...</h2>
                    </div>
                  </div>
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfOfferConfirmationView

/* eslint-enable */