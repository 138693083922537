/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfComponentNavDarkView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfComponentNavDarkController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfComponentNavDarkView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfComponentNavDarkView ? transformProxies(this.props.children) : {
      'af-admin-nav-wrapper': [],
      'af-link-logout': [],
      'af-link-reviews': [],
      'af-link-settings': [],
      'af-link-company': [],
      'af-link-offer-requests': [],
      'af-link-management': [],
      'af-auth-page-info': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-bodyremove">
            <div className="af-class-section-11 af-class-eogihn">
              <div data-collapse="medium" data-animation="default" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="af-class-navbar-color af-class-navbar-color-alt w-nav">
                <div className="w-container">
                  <div className="af-class-menu-button w-nav-button">
                    <div className="af-class-menu-dark-hamburger w-icon-nav-menu" />
                  </div>
                </div>
                <a href="/" className="af-class-brand w-nav-brand"><img src="images/asuntoarvio-logo-white-2020.svg" width={220} alt="Asuntoarvio logo white" height={53} className="af-class-asuntoarvio-logo" /></a>
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <a href="/kilpailutuspalvelu" className="af-class-nav-link-color af-class-nav-link-alt-2 w-nav-link">Kilpailuta kiinteistönvälitys</a>
                  <a href="/kiinteistonvalitys" className="af-class-nav-link-color af-class-nav-link-alt-2 w-nav-link">Etsi kiinteistönvälittäjä</a>
                  <a href="https://asuntoarvio.fi/mika-on-asuntoarvio" className="af-class-nav-link-color af-class-nav-link-alt-2 w-nav-link">Mikä on Asuntoarvio?</a>
                  <a href="https://asuntoarvio.fi/kiinteistonvalittajille" className="af-class-nav-link-color af-class-nav-link-alt-2 af-class-nav-link-alt-last w-nav-link">Kiinteistönvälittäjille</a>
                </nav>
              </div>
              {map(proxies['af-admin-nav-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-secondary-navi w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                {map(proxies['af-link-logout'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/ulos" {...{...props, className: `af-class-secondary-nav-link w-inline-block ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                  <div className="af-class-secondary-navi-link">Kirjaudu ulos</div>
                </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-link-reviews'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]/asiakasarviot/[status]" {...{...props, className: `af-class-secondary-nav-link w-inline-block ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                  <div className="af-class-secondary-navi-link">Asiakasarviot</div>
                </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-link-settings'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/asetukset" {...{...props, className: `af-class-secondary-nav-link w-inline-block ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                  <div className="af-class-secondary-navi-link">Omat tiedot</div>
                </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-link-company'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]" {...{...props, className: `af-class-secondary-nav-link w-inline-block ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                  <div className="af-class-secondary-navi-link">Yrityksen tiedot </div>
                </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-link-offer-requests'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]/tarjouspyynnot/avoimet" {...{...props, className: `af-class-secondary-nav-link w-inline-block ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                  <div className="af-class-secondary-navi-link">Tarjouspyynnöt</div>
                </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-link-management'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/toimipisteet" {...{...props, className: `af-class-secondary-nav-link w-inline-block ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                  <div className="af-class-secondary-navi-link">Etusivu</div>
                </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
              {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            </div>
            <div className="af-class-admin-company-name-text-container">
              {map(proxies['af-auth-page-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-admin-company-name-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Helsingin kiinteistönvälitys LKV</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfComponentNavDarkView

/* eslint-enable */