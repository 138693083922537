import Api from '../lib/api';

export class ExportService extends Api {
    constructor() {
        super('export');
    }

    createExport() {
        return this.child('create-export');
    }
}
