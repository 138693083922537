import { ActionType } from 'typesafe-actions';
import { Company } from '../../company/Company.interface';
import * as actions from './actions';
import { SET, CLEAR } from './constants';

export type CompanysAction = ActionType<typeof actions>;

export interface CompanyState {
    data: Company|null;
}

const initialSate: CompanyState = { data: null };

export default function companyReducer(state = initialSate, action: actions.CompanyActionTypes): CompanyState {
    switch (action.type) {
        case SET:
            return { data: action.payload };
        case CLEAR:
            return { ...initialSate };
        default:
            return state;
    }
}
