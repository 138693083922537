/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAdminUserFormView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAdminUserFormController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAdminUserFormView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAdminUserFormView ? transformProxies(this.props.children) : {
      'af-form-user': [],
      'af-user-list-wrapper': [],
      'af-form-user-search': [],
      'af-input-user-search': [],
      'af-user-search-btn': [],
      'af-user-row': [],
      'af-user-row-id': [],
      'af-user-row-email': [],
      'af-user-company-link': [],
      'af-user-row-company': [],
      'af-user-accept': [],
      'af-user-reject': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-section-12">
              <div className="af-class-container-6 w-container">
                <div className="af-class-div-block-33">
                  {map(proxies['af-form-user'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `w-form ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                    <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" data-wf-page-id="5d9c6c5957629d694083f5b7" data-wf-element-id="0961a802-b69f-d456-a584-168f697f3128">
                      <div className="af-class-div-block-32 w-clearfix">
                        <div className="af-class-admin-id-tag">ID:&nbsp;12345</div><select id="User-Status" name="User-Status" data-name="User Status" className="af-class-admin-user-status w-select">
                          <option value>Hyväksytty</option>
                          <option value="First">Hyväksymätön</option>
                        </select>
                        <div className="af-class-text-link af-class-alt-position">Poista käyttäjä</div>
                      </div>
                      <div className="af-class-div-block-32">
                        <div className="af-class-heading-3">Pääkäyttäjän tiedot:</div>
                      </div><label htmlFor="Firstname" className="af-class-field-label-3">Etunimi</label><input className="af-class-input-field-admin-user-form w-input" maxLength={256} name="Firstname" data-name="Firstname" placeholder type="text" id="Firstname" required /><label htmlFor="Lastname" className="af-class-field-label-3">Sukunimi</label><input className="af-class-input-field-admin-user-form w-input" maxLength={256} name="Lastname" data-name="Lastname" placeholder type="text" id="Lastname" required /><label htmlFor="Email-2" className="af-class-field-label-3">Sähköposti</label><input className="af-class-input-field-admin-user-form w-input" maxLength={256} name="Email" data-name="Email" placeholder type="email" id="Email" required /><label htmlFor="Phone" className="af-class-field-label-3">Puhelinnumero</label><input className="af-class-input-field-admin-user-form w-input" maxLength={256} name="Phone" data-name="Phone" placeholder type="tel" id="Phone" required /><label htmlFor="Password" className="af-class-field-label-3">Salasana</label><input className="af-class-input-field-admin-user-form w-input" maxLength={256} name="Password" data-name="Password" placeholder type="password" id="Password" required /><label htmlFor="Password-2" className="af-class-field-label-3">Vahvista salasana</label><input className="af-class-input-field-admin-user-form w-input" maxLength={256} name="Password-2" data-name="Password 2" placeholder type="password" id="Password-2" required /><input type="submit" data-wait="Please wait..." className="af-class-button af-class-alt-button w-button" value="Tallenna" />
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                </div>
              </div>
              {map(proxies['af-user-list-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-container-3 w-container ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                <div className="w-form">
                  {map(proxies['af-form-user-search'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-form-user-search" name="wf-form-form-user-search" data-name="form-user-search" method="get" data-wf-page-id="5d9c6c5957629d694083f5b7" data-wf-element-id="bf03234b-068f-8362-7579-d7eba87e6945" {...{...props, className: `w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="Company-Id-Input" className="af-class-field-label-3 af-class-alt-margin">Etsi käyttäjä</label>{map(proxies['af-input-user-search'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="Company-Id-Input" data-name="Company-Id-Input" placeholder type="text" id="Company-Id-Input" {...{...props, className: `af-class-input-field af-class-input-field-alt-width w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{map(proxies['af-user-search-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Etsi" {...{...props, className: `af-class-button af-class-alt-button af-class-alt-button-position w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Etsi')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
                {map(proxies['af-user-row'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-34 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  <div className="w-clearfix">
                    {map(proxies['af-user-row-id'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-admin-id-tag ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>ID:&nbsp;12345</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    {map(proxies['af-user-row-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/admin/kayttajat/[user]" {...{...props, className: `af-class-text-link af-class-alt-2-position ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Yritys X</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  </div>
                  {map(proxies['af-user-company-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-133 w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-user-row-company'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[city]/[company]" {...{...props, className: `af-class-text-link af-class-alt-2-position ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Yritys X</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-user-accept'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-link af-class-alt-2-position ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Hyväksy</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    {map(proxies['af-user-reject'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-link af-class-alt-2-position ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Hylkää</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
              {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            </div>
            {/*  Google Tag Manager (noscript)  */}

            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAdminUserFormView

/* eslint-enable */
