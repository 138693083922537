import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BaseComponent, BaseState } from '../components/BaseComponent';
import { CompanyManagerService } from '../services/company-manager';
import { Review, ReviewCount } from './Review.interface';
import { AfAdminReviewsController } from '../controllers/AfAdminReviewsController';
import { ReviewStatus, StringSignatureString } from '../shared/types';
import { OfferRequest } from '../offer-request/offer-request';
interface TParams {
  companyId: string;
}

interface State extends BaseState {
  reviews: Review[];
  page: number;
  pageCount: number;
  status: ReviewStatus;
  emailInvite: string;
  nameInvite: string;
  errorName: boolean;
  errorEmail: boolean;
  reviewCounts: ReviewCount[];
}

const emptyPlaceholders: StringSignatureString = {
  [ReviewStatus.open]: 'Yrityksellä ei ole avoimia asiakasarvioita',
  [ReviewStatus.accepted]: 'Yrityksellä ei ole hyväksyttyjä asiakasarvioita',
  [ReviewStatus.rejected]: 'Yrityksellä ei ole hylättyjä asiakasarvioita',
};

export class CompanyCustomerReviews extends BaseComponent<RouteComponentProps<TParams>, State> {
  state = {
    reviews: [],
    page: 1,
    pageCount: 1,
    status: ReviewStatus.open,
    emailInvite: '',
    nameInvite: '',
    errorEmail: false,
    errorName: false,
    reviewCounts: [],
  } as State;

  componentDidMount() {
    super.componentDidMount();
    this.load();
  }

  get api(): any {
    return new CompanyManagerService(parseInt(this.props.match.params.companyId, 10)).review();
  }

  onSelectStatus = (status: ReviewStatus) => {
    this.load(status, 1);
  }

  onLoadPage = (page: number) => {
    this.load(this.state.status, page);
  }

  updateReviewCounts(count: number, status: string) {
    const reviewCounts = [...this.state.reviewCounts].map(rev => {
      if (rev.status === status) {
        const newCount = parseInt(rev.count, 10) + count;
        return { ...rev, count: newCount.toString() };
      }
      return rev;
    });
    this.setState({
      reviewCounts,
    });
  }

  reviewActionAccept() {
    const currentStatus = this.state.status;
    currentStatus === ReviewStatus.open
    ? this.updateReviewCounts(-1, ReviewStatus.open)
    : this.updateReviewCounts(-1, ReviewStatus.rejected);
    this.updateReviewCounts(1, ReviewStatus.accepted);
  }
  reviewActionReject() {
    const currentStatus = this.state.status;
    currentStatus === ReviewStatus.open
    ? this.updateReviewCounts(-1, ReviewStatus.open)
    : this.updateReviewCounts(-1, ReviewStatus.accepted);
    this.updateReviewCounts(1, ReviewStatus.rejected);
  }

  acceptReview = async (id: number) => {
    try {
      await this.api.child(id).put();
      this.removeFromList(id);
      this.reviewActionAccept();
      toast.success('Arvestelu lisätty julkiseksi');
    } catch (error) {
      toast.error('Arvostelun julkaisu epäonnistui');
    }
  }

  rejectReview = async (id: number) => {
    try {
      await this.api.child(id).del();
      this.removeFromList(id);
      this.reviewActionReject();
      toast.success('Arvostelupyyntö lähetetty onnistuneesti.');
    } catch (error) {
      toast.error('Arvostelupyynnön lähetys epäonnistui. ');
    }
  }

  viewOffer = async (companyId: number, companyOfferRequestId: number) => {
    const service = new CompanyManagerService(companyId);
    const offer = await service.offerRequest().child(`${companyOfferRequestId}/cor`).get() as unknown as OfferRequest;
    this.props.history.push(`/hallinta/${companyId}/tarjouspyynto/${offer.id}`);
  }

  changeMail = (emailInvite: string) =>
    this.setState({
      emailInvite,
    })

  changeNameInvite = (nameInvite: string) =>
    this.setState({
      nameInvite,
    })

  sendReviewMail = async () => {
    const { emailInvite, nameInvite } = this.state;
    const resInviteName = nameInvite.trim();
    const resEmailInvite = emailInvite.trim();
    this.setState({
      errorName: resInviteName === '' ? true : false,
      errorEmail: resEmailInvite === '' ? true : false,
    });
    if (nameInvite === '' || emailInvite === '') {
      toast.error('Arvostelupyynnön lähetys epäonnistui.');
      return;
    }
    try {
      await this.api.child('invite').post({
        sendEmail: resEmailInvite,
        nameInvite: resInviteName,
      });
      this.setState({
        nameInvite: '',
        emailInvite: '',
      });
      toast.success('Arvostelupyyntö lähetetty onnistuneesti.');
    } catch (error) {
      toast.error('Arvostelupyynnön lähetys epäonnistui. ');
    }

  }

  removeFromList(id: number) {
    this.setState({ reviews: this.state.reviews.filter(r => r.id !== id) });
  }

  async load(status = this.state.status, page = this.state.page) {
    try {
      this.startLoadingData('data');
      const { items, meta: { totalPages: pageCount } } = await this.api.query({ status, page }).get();
      const reviewCounts = await this.api.child('counts').get();
      this.endLoadingData('data', { reviewCounts, reviews: items, page, pageCount, status });
    } catch (error) {
      toast.error('Asiakasarvioiden haku epäonnistui.');
    }
  }

  render() {
    const { reviews, page, pageCount, status, errorName, errorEmail, nameInvite, emailInvite, reviewCounts } = this.state;
    const loading = this.renderLoading('data') || (reviews && !reviews.length ? emptyPlaceholders[status] : null);

    return (
      <AfAdminReviewsController
        onChangeEmail={this.changeMail}
        onChangeNameInvite={this.changeNameInvite}
        onRejectReview={this.rejectReview}
        onAccepReview={this.acceptReview}
        onViewOffer={this.viewOffer}
        onSelectStatus={this.onSelectStatus}
        onLoadPage={this.onLoadPage}
        onSendReviewMail={this.sendReviewMail}
        reviewCounts={reviewCounts}
        nameInvite={nameInvite}
        emailInvite={emailInvite}
        errorName={errorName}
        errorEmail={errorEmail}
        status={status}
        reviews={reviews}
        page={page}
        pageCount={pageCount}
        loader={loading}
        {...this.props}
      />
    );
  }
}
