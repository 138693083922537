import Api from '../lib/api';

export class OfferRequestService extends Api {
    constructor() {
        super('offer-request');
    }

    offerRequest() {
        return new OfferRequestService();
    }

    stats() {
        return this.child('stats');
    }
}
