/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAgencyPageView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAgencyPageController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAgencyPageView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAgencyPageView ? transformProxies(this.props.children) : {
      'af-breadcrumb-link-search': [],
      'af-breadcrumb-company': [],
      'af-header': [],
      'af-logo-image': [],
      'af-header-marketing-name': [],
      'af-top-100': [],
      'af-marketing-name': [],
      'af-address': [],
      'af-stars-average': [],
      'af-stars': [],
      'af-link-reviews': [],
      'af-review-agency-button': [],
      'af-description': [],
      'af-phone': [],
      'af-www': [],
      'af-info': [],
      'af-territories-wrap': [],
      'af-territory-city': [],
      'af-territory-city-link': [],
      'af-territory-zip-code-area': [],
      'af-territory-zip-code-area-link': [],
      'af-takeover-wrap': [],
      'af-take-over-company-btn': [],
      'af-admin-wrap': [],
      'af-admin-link': [],
      'af-admin-offer-request-link': [],
      'af-costs-wrapper': [],
      'af-commissions': [],
      'af-commission-of-housing-cooperative-wrapper': [],
      'af-commission-of-housing-cooperative': [],
      'af-commission-of-property-wrapper': [],
      'af-commission-of-property': [],
      'af-min-commissions': [],
      'af-min-commission-of-housing-cooperative-wrapper': [],
      'af-min-commission-of-housing-cooperative': [],
      'af-min-commission-of-property-wrapper': [],
      'af-min-commission-of-property': [],
      'af-base-costs': [],
      'af-base-costs-of-housing-cooperative-wrapper': [],
      'af-base-costs-of-housing-cooperative': [],
      'af-base-costs-of-property-wrapper': [],
      'af-base-costs-of-property': [],
      'af-offer-request-link-2': [],
      'af-extra-costs': [],
      'af-data-wrapper': [],
      'af-average-response-time': [],
      'af-asking-price-ratio': [],
      'af-would-recommend': [],
      'af-services-wrapper': [],
      'af-services-title': [],
      'af-services': [],
      'af-advertising-title': [],
      'af-advertising': [],
      'af-reviews-wrapper': [],
      'af-review': [],
      'af-review-stars': [],
      'af-review-author': [],
      'af-review-date': [],
      'af-recommend-company': [],
      'af-review-description': [],
      'af-review-show-more': [],
      'af-map': [],
      'af-offer-request-link': [],
      'af-social-media-wrapper': [],
      'af-facebook': [],
      'af-linkedin': [],
      'af-twitter': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-view-wrapper">
              <div className="af-class-container-4 w-container">
                <div className="af-class-breadcrumb-wrapper">
                  <div className="af-class-breadcrumb-link">
                    <a href="/" className="af-class-breadcrumb-link">Asuntoarvio.fi</a>
                  </div>
                  <div className="af-class-breadcrumb-arrow-right">-</div>
                  <div className="af-class-breadcrumb-link">
                    {map(proxies['af-breadcrumb-link-search'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[search]" {...{...props, className: `af-class-breadcrumb-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Etsi kiinteistönvälitys</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  </div>
                  <div className="af-class-breadcrumb-arrow-right">-</div>
                  {map(proxies['af-breadcrumb-company'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-breadcrumb-link af-class-breadcrumb-link-active ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kiinteistömaailma Kamppi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                </div>
              </div>
              <div className="af-class-container-5 w-container">
                {map(proxies['af-header'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-header ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  {map(proxies['af-logo-image'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-86-agencypage af-class-lkv-placeholder ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {map(proxies['af-header-marketing-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h1 {...{...props, className: `af-class-heading-3 af-class-for-heading ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Helsingin kiinteistönvälitys LKV</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </h1>
                ))}
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                <div className="af-class-div-block-33" />
                <div className="af-class-agency-left-content-wrapper">
                  <div className="af-class-agency-info-wrapper af-class-alt-padding w-clearfix">{map(proxies['af-top-100'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="images/top100.svg" width={53} alt="top-100" {...{...props, className: `af-class-image-5 ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </img>
                ))}
                    {map(proxies['af-marketing-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h1 {...{...props, className: `af-class-heading-3 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Helsingin kiinteistönvälitys LKV</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </h1>
                ))}
                    {map(proxies['af-address'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h2 {...{...props, className: `af-class-agency-page-adress ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kampinkatu 1, 00100 Helsinki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </h2>
                ))}
                    <div className="af-class-agency-page-rating-wrapper w-clearfix">
                      {map(proxies['af-stars-average'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-rating-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>4.6</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      {map(proxies['af-stars'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-star-full ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      {map(proxies['af-link-reviews'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#reviews" {...{...props, className: `af-class-text-link-small-review-anchor ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Lue arvosteluja</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    </div>
                    {map(proxies['af-review-agency-button'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[city]/[company]/arvostele" {...{...props, className: `af-class-button-medium af-class-static-button af-class-no-float af-class-review-button-topmargin w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Arvostele kiinteistönvälittäjä tästä<span className="af-class-arrow-right af-class-arrow-button-medium af-class-arrow-padding-left"> </span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                    <div className="af-class-div-block-82 w-clearfix">
                      {map(proxies['af-phone'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-lkv-result-list-contact ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-agency-info-icon-2"></span>020 123 4567</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                      <p className="af-class-lkv-result-list-www">
                        {map(proxies['af-www'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="http://[www]" rel="nofollow" target="_blank" {...{...props, className: `af-class-lkv-result-list-www ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-agency-info-icon-2"></span>www.kiinteistonvalitys.fi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      </p>
                    </div>
                    {map(proxies['af-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Aukioloajat: Ma-to 10-16.30, pe 10-14<br />Perustettu: 1983<br />Y-tunnus: 123456-7<br />Työntekijöiden lukumäärä:&nbsp;12<br /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                    {map(proxies['af-territories-wrap'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <p className="af-class-p2 af-class-p2-margin">Välitysliikkeen toimialueet</p>
                      {map(proxies['af-territory-city'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h3 {...{...props, className: `af-class-postcode-tag af-class-postcode-tag-small af-class-postcode-tag-dark ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        {map(proxies['af-territory-city-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[city]" {...{...props, className: `af-class-postcode-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Helsinki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </h3>
                ))}
                      {map(proxies['af-territory-zip-code-area'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <h3 {...{...props, className: `af-class-postcode-tag ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        {map(proxies['af-territory-zip-code-area-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalitys/[postcode]" {...{...props, className: `af-class-postcode-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>00100 Helsinki Keskusta</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </h3>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  </div>
                  {map(proxies['af-takeover-wrap'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <p className="af-class-p2">Onko yritys sinun? Rekisteröidy ja lisää tietoja sivulle.</p>
                    <p className="af-class-p">Maksuton rekisteröityminen auttaa sinua ja välitysliikettäsi löytämään uusia asiakkaita ja saat arviopyyntöjä toimialueesi asunnon myyntiä suunnittelevilta. Rekisteröityneenä käyttäjänä pääset muokkaamaan yrityksesi tietoja ja kasvatat myyntiä.<br /><br />Asuntoarvio.fi:n tavoitteena on edistää asiantuntevien kiinteistönvälitysliikkeiden liiketoimintaa tuomalla kiinteistönvälityksen laatuvertailun ja kuluttajien vertaisarviot osaksi välittäjän valintaprosessia. Lisää tietoa kiinteistönvälittäjille löydät <a href="/kiinteistonvalittajille" target="_blank" className="af-class-small-link-register-company">tästä linkistä</a>.</p>
                    {map(proxies['af-take-over-company-btn'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/rekisteroidy/kiinteistonvalitys/[city]/[company]" {...{...props, className: `af-class-button-medium af-class-static-button af-class-no-float w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Ota yrityksesi profiili haltuun tästä<span className="af-class-arrow-right af-class-arrow-button-medium af-class-arrow-padding-left"> </span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-admin-wrap'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-admin-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]" {...{...props, className: `af-class-button-medium af-class-static-button af-class-no-float af-class-admin-button w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Muokkaa yrityksen tietoja<span className="af-class-arrow-right af-class-arrow-button-medium"> </span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-admin-offer-request-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/hallinta/[companyId]/tarjouspyynnot/avoimet" {...{...props, className: `af-class-button-medium af-class-static-button af-class-no-float af-class-admin-button w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Avoimet tarjouspyynnöt<span className="af-class-arrow-right af-class-arrow-button-medium"> </span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-costs-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-commissions'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-details-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <p className="af-class-p2">Välityspalkkio</p>
                      {map(proxies['af-commission-of-housing-cooperative-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-postcode-tag af-class-green-tag ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Asunto-osake {map(proxies['af-commission-of-housing-cooperative'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-green-pros ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>3,5 %</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      {map(proxies['af-commission-of-property-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-postcode-tag af-class-green-tag ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Kiinteistö {map(proxies['af-commission-of-property'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-green-pros ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>4,5 %</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    {map(proxies['af-min-commissions'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-details-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <p className="af-class-p2">Minimipalkkio</p>
                      {map(proxies['af-min-commission-of-housing-cooperative-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-postcode-tag af-class-green-tag ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Asunto-osake {map(proxies['af-min-commission-of-housing-cooperative'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-green-pros ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>4500 €</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      {map(proxies['af-min-commission-of-property-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-postcode-tag af-class-green-tag ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Kiinteistö {map(proxies['af-min-commission-of-property'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-green-pros ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>5000 €</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    {map(proxies['af-base-costs'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-details-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <p className="af-class-p2">Perushinta</p>
                      {map(proxies['af-base-costs-of-housing-cooperative-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-postcode-tag af-class-green-tag ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Asunto-osake {map(proxies['af-base-costs-of-housing-cooperative'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-green-pros ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>400 €</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      {map(proxies['af-base-costs-of-property-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-postcode-tag af-class-green-tag ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Kiinteistö {map(proxies['af-base-costs-of-property'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-green-pros ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>700 €</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    {map(proxies['af-offer-request-link-2'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kilpailutuspalvelu/[company]" {...{...props, className: `af-class-button-medium-reward-block af-class-agency-offer-width w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Pyydä tarjous välityspalkkiosta <span className="af-class-arrow-right af-class-arrow-button-medium af-class-arrow-no-margin"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-extra-costs'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p af-class-margin-4 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><strong>Asiakirjakulut</strong>: kyllä, ei<br /><strong>Markkinointikulut</strong>: kyllä, ei<br /><strong>Palkkion laskentaperuste</strong>: kauppahinnasta, velattomasta kauppahinnasta<em className="af-class-italic-text" /><br /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                    <p className="af-class-p af-class-margin-4"><em className="af-class-italic-text">Hinnat sisältävät alv 24 %. Palkkiotiedot ovat kiinteistönvälitysliikkeen julkisia tietoja tai yhtiön itse palveluun ilmoittamia. Kuluttajansuojalaki, 20.1.1978/38, 2 luku; asetus kulutushyödykkeen hinnan ilmoittamisesta markkinoinnissa 11.7.2013/553. Asuntoarvio.fi ei vastaa tietojen oikeellisuudesta tai virheettömyydestä.</em><br /></p>
                    <div className="af-class-div-block-50" />
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-data-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-agency-info-wrapper-extra-margin w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-div-block-58">
                      <div className="af-class-agency-statistic-ball">
                        {map(proxies['af-average-response-time'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-statistic-big-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>2</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-agency-statistic-text">Päivää</div>
                      </div>
                      <div className="af-class-agency-statistic-label">Vastausnopeus <br />tarjouspyyntöön <br />keskimäärin</div>
                    </div>
                    <div className="af-class-div-block-58">
                      <div className="af-class-agency-statistic-ball">
                        {map(proxies['af-asking-price-ratio'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-statistic-big-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>31</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-agency-statistic-text">Prosenttisesti</div>
                      </div>
                      <div className="af-class-agency-statistic-label">Hintapyyntö<br />toteutunut<br />keskimäärin</div>
                    </div>
                    <div className="af-class-div-block-58">
                      <div className="af-class-agency-statistic-ball">
                        {map(proxies['af-would-recommend'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-statistic-big-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>16</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        <div className="af-class-agency-statistic-text">Kpl</div>
                      </div>
                      <div className="af-class-agency-statistic-label">Käyttäjää <br />suosittelee <br />yritystä </div>
                    </div>
                    <div className="af-class-div-block-57" />
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-services-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-div-block-61">
                      {map(proxies['af-services-title'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Lisäpalvelut</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                      {map(proxies['af-services'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-lkv-result-list-address af-class-alt-line ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-check"></span>Ammattivalokuvaaja<br /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                    </div>
                    <div className="af-class-div-block-61">
                      {map(proxies['af-advertising-title'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Mainonta ja näkyvyys</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                      {map(proxies['af-advertising'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-lkv-result-list-address af-class-alt-line ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-check"></span>Etuovi<br /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                    </div>
                    <div className="af-class-div-block-50" />
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-reviews-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div id="reviews" className="af-class-h4">Arvostelut</div>
                    {map(proxies['af-review'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <div className="af-class-div-block-60" />
                      <div className="af-class-div-block-59 w-clearfix">
                        {map(proxies['af-review-stars'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-star-full ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-103">
                        <div className="af-class-div-block-resultpage-reviewer-name w-clearfix">
                          {map(proxies['af-review-author'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-rating-number af-class-alt-size ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Etunimi Sukunimi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        </div>
                        {map(proxies['af-review-date'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-date ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>24.8.2019</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      {map(proxies['af-recommend-company'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-user-recommendations ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Suosittelee yritystä <span className="af-class-thumb"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      {map(proxies['af-review-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-lkv-result-list-address ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  <div className="af-class-div-block-76">
                    {map(proxies['af-review-show-more'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-medium-show-more af-class-static-button af-class-no-float w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Näytä lisää arvosteluita (12/32)</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                  </div>
                </div>
                <div className="af-class-agency-right-content-wrapper">
                  {map(proxies['af-map'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-53 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                    <div className="af-class-map-2 w-widget w-widget-map" data-widget-style="roadmap" data-widget-latlng="60.1683731,24.9301702" data-enable-scroll="true" role title data-enable-touch="true" data-widget-zoom={12} data-widget-tooltip />
                  </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  <div className="af-class-div-block-54">
                    <div className="w-clearfix">
                      <div className="af-class-text-block-24"></div>
                      <p className="af-class-lkv-result-list-address">Välittäjävertailu on 100 % maksuton palvelu asunnon myyjille ja myyntiä suunnitteleville.</p>
                    </div>
                    <div className="w-clearfix">
                      <div className="af-class-text-block-24"></div>
                      <p className="af-class-lkv-result-list-address">Luotettava valinta elämäsi tärkeimpään kauppaan. Paikkakuntasi parhaat kiinteistönvälittäjät nopeasti ja vaivattomasti.</p>
                    </div>
                    <div className="w-clearfix">
                      <div className="af-class-text-block-24"></div>
                      <p className="af-class-lkv-result-list-address">Palvelun käyttö ei sido sinua mihinkään. Voit vapaasti valita sopivimman välittäjän asuntosi kauppaan.</p>
                    </div>
                    {map(proxies['af-offer-request-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kilpailutuspalvelu/[company]" {...{...props, className: `af-class-button-medium w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Pyydä tarjous yritykseltä<span className="af-class-arrow-right af-class-arrow-button-medium af-class-arrow-no-margin"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                    {map(proxies['af-social-media-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <p className="af-class-lkv-result-list-address af-class-margin-top">Jaa yrityksen sivu sosiaalisessa mediassa</p>
                      <div className="af-class-footer-some-icons af-class-share">{map(proxies['af-facebook'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-footer-some-icons af-class-small ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}{map(proxies['af-linkedin'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-footer-some-icons af-class-small ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}{map(proxies['af-twitter'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-footer-some-icons af-class-small ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}</div>
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  </div>
                </div>
              </div>
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAgencyPageView

/* eslint-enable */