import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AfAdminCompanyListView from '../views/AfAdminCompanyListView';
import { BaseController } from './BaseController';
import { Company } from '../company/Company.interface';
import { UserCompany } from '../store/user/reducer';
import { CompanyStatus } from '../shared/types';

interface Props {
  isAdmin: boolean;
  companies: Company[] | null;
  userCompanies: UserCompany[] | null;
  onLoad: (approved: boolean) => void;
  approved: boolean;
}

export class AfAdminCompanyListController extends BaseController<RouteComponentProps & Props> {
  renderUserCompanyRow = (userCompany: UserCompany) => {
    return this.renderCompanyRow(userCompany.company, userCompany);
  }

  scrollToAgencyCard = (ref: any) => {
    if (ref && ref.scrollIntoView) {
      ref.scrollIntoView({ behavior: 'smooth' });
    }
  }

  renderCompanyRow = (company: Company, userCompany: UserCompany|null = null) => {
    const { location: { state } } = this.props;
    const {
      id,
      marketingName,
      address,
      zipCode,
      city,
      description,
      logoImage,
      status,
    } = company;

    let accepted = true;
    let isMainUser = true;
    if (userCompany) {
      accepted = !!userCompany.acceptedAt;
      isMainUser = userCompany.isMainUser;
    }

    const isApproved = status === CompanyStatus.approved || status === CompanyStatus.noOffers;

    return (
      <af-agency-card key={id} ref={state && (state as any).claimed === id ? this.scrollToAgencyCard : null}>
        <af-marketing-name>{marketingName}</af-marketing-name>
        <af-address>{`${address}, ${zipCode}, ${city}`}</af-address>
        <af-description>{description}</af-description>
        {accepted && isApproved && this.afLink('offer-requests-link', { companyId: id })}
        <af-logo-image style={{ backgroundImage: logoImage ? `url(${logoImage})` : undefined }} />
        {((isMainUser && accepted) || (isMainUser && !isApproved)) && this.afLink('edit-info-link', { companyId: id })}
        {(!accepted || !isApproved) && <af-pending onClick={(e: any) => e.preventDefault()} />}
      </af-agency-card>
    );
  }

  renderAdmin() {
    const { companies, onLoad, history, approved } = this.props;

    const adminLinks = [
      ['Hyväksymättömät yritykset', () => onLoad(false), () => approved === false ? 'af-class-button-company-form-tabs-active' : undefined],
      // ['Hyväksytyt yritykset', () => onLoad(true), () => approved === true ? 'af-class-button-company-form-tabs-active' : undefined],
      ['Avoimet tarjouspyynnöt', () => history.push('/admin/tarjouspyynnot'), () => undefined],
      ['Käyttäjien hyväksyntä', () => history.push('/admin/kayttajat'), () => undefined],
      ['Export-työkalut', () => history.push('/admin/export'), () => undefined],
    ];

    return (
      <AfAdminCompanyListView>
        <af-admin-wrapper>
          {adminLinks.map((link: any) => (
            <af-admin-button key={link[0]}
              onClick={(e: any) => { e.preventDefault(); link[1](); }}
              className={link[2]()}
            >
            {link[0]}
            </af-admin-button>
          ))}
        </af-admin-wrapper>
        {companies && companies.length && companies.map((company: Company) => this.renderCompanyRow(company))}
        {(!companies || !companies.length) && (
          <af-agency-card>
            <af-marketing-name>{approved ? 'Hyväksyttyjä yrityksiä ei löytynyt' : 'Hyväksymättömiä yrityksiä ei löytynyt'}</af-marketing-name>
          </af-agency-card>
        )}
        {this.props.children}
      </AfAdminCompanyListView>
    );
  }

  render() {
    const { userCompanies, isAdmin } = this.props;
    const hasMainUserCompanies = userCompanies ? !!userCompanies.find(c => c.isMainUser) : false;

    if (isAdmin) {
      return this.renderAdmin();
    }

    return (
      <AfAdminCompanyListView>
        {(!userCompanies || !userCompanies.length) && <af-info-text />}
        {(userCompanies && userCompanies.length && hasMainUserCompanies) && <af-info-text2 />}
        {(userCompanies && userCompanies.length && !hasMainUserCompanies) && <af-info-text3 />}
        {userCompanies && userCompanies.map(this.renderUserCompanyRow)}
        {this.props.children}
      </AfAdminCompanyListView>
    );
  }
}
