import Api from '../lib/api';

export class AgentService extends Api {
    constructor(agentSlug: string|null = null) {
        const path = ['agent'];
        if (agentSlug) {
            path.push(agentSlug.toString());
        }
        super(path.join('/'));
    }

    review() {
        return this.child('review');
    }
}
