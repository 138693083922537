import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

const ScrollToTop: React.FC<RouteComponentProps> = (props) => {
  const { location: { pathname, state } } = props;
  useEffect(() => {
    if (!state || !(state as any).disableScrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [pathname, state]);

  return null;
};

export default withRouter(ScrollToTop);
