import { get, truncate, isString, isEmpty } from 'lodash';
import { Company } from '../company/Company.interface';
import { UserProfile } from '../store/user/reducer';

const DEFAULT_META_VALUES = {
    title: 'Asuntoarvio - Vertaa ja löydä alueesi paras kiinteistönvälitys',
    description: 'Asuntoarvion avulla vertaat kokemuksia ja löydät parhaat kiinteistönvälittäjät. Kilpailuta välittäjä ja valitse asunnon kauppaan paras ammattilainen. Välittäjien vertailu on nopeaa ja aina ilmaista!',
    referrer: 'no-referrer-when-downgrade',
    robots: 'all',
    'og:title': 'Asuntoarvio - Vertaa ja löydä alueesi paras kiinteistönvälitys',
    'og:description': 'Asuntoarvion avulla vertaat kokemuksia ja löydät parhaat kiinteistönvälittäjät. Kilpailuta välittäjä ja valitse asunnon kauppaan paras ammattilainen. Välittäjien vertailu on nopeaa ja aina ilmaista!',
    'og:type': 'website',
    'og:site_name': 'Asuntoarvio',
    'og:locale': 'fi_FI',
    'og:image': 'https://uploads-ssl.webflow.com/5ce67e065375cacafd942078/5dc54df8cedf1a7ca77bcdc5_AA-facebook-og-image-1200-630.jpg',
    'og:image:width': '1200',
    'og:image:height': '630',
};

export interface MetaTags {
    [key: string]: string;
}

export const getDefaultDescriptionForAgency = (name: string, city: string) => `Kiinteistönvälitys ${name} kiinteistönvälittäjä ${city}. Vertaa kokemuksia ja arvosteluita Asuntoarvio.fi -palvelussa. Kiinteistönvälittäjien kilpailuttaminen on ilmaista ja kannattaa aina`;
export const getDefaultDescriptionForAgent = (name: string, company: string, city: string) => `Kiinteistönvälittäjä ${name} (${company} / ${city}). Vertaa kokemuksia ja arvosteluita Asuntoarvio.fi -palvelussa. Kiinteistönvälittäjien kilpailuttaminen on ilmaista ja kannattaa aina`;

export class MetaTagsService {
    constructor() {
        this.resetMetaElements();
    }

    setOfferRequestFormTags() {
        this.setMetaTags({
            title: 'Kilpailutuspalvelu - Tavoita alueesi parhaat kiinteistönvälittäjät ja pyydä ilmainen asunnon hinta-arvio | Asuntoarvio',
            description: 'Kilpailuta kiinteistönvälitys ilmaiseksi Asuntoarvio.fi:n avulla! Kilpailutuspalvelu on 100 % maksuton palvelu asunnon myyjille ja myyntiä suunnitteleville. Luotettava valinta elämäsi tärkeimpään kauppaan. Paikkakuntasi parhaat kiinteistönvälittäjät nopeasti ja vaivattomasti.',
        });
    }

    setCompanySearchTags() {
        this.setMetaTags({
            title: 'Etsi kiinteistönvälitys - Kokemuksia, arvostelut ja kaikki Suomen kiinteistönvälittäjät | Asuntoarvio',
            description: 'Kiinteistönvälityshaun avulla löydät lähes kaikki Suomen kiinteistönvälittäjät. Vertaa kokemuksia, arvostelut ja löydä asunnolle paras välittäjä. Vertailu asunnon myynnissä on ilmaista ja kannattaa aina!',
        });
    }

    setAgentSearchTags() {
        this.setMetaTags({
            title: 'Etsi kiinteistönvälittäjä - Kokemuksia, arvostelut ja kaikki Suomen kiinteistönvälittäjät | Asuntoarvio',
            description: 'Kiinteistönvälittäjähaun avulla löydät lähes kaikki Suomen kiinteistönvälittäjät. Vertaa kokemuksia, arvostelut ja löydä asunnolle paras välittäjä. Vertailu asunnon myynnissä on ilmaista ja kannattaa aina!',
        });
    }

    setCompanyCityTags(city: string) {
        this.setMetaTags({
            title: `Kiinteistönvälitys ${city} - Kokemuksia ja arvostelut | Asuntoarvio`,
            description: `Löydä ${city} kiinteistönvälittäjät. Vertaa kokemuksia, arvostelut ja löydä asunnolle paras välittäjä. Vertailu asunnon myynnissä on ilmaista ja kannattaa aina!`,
        });
    }

    setAgentCityTags(city: string) {
        this.setMetaTags({
            title: `Kiinteistönvälittäjä ${city} kokemuksia ja arvostelut | Asuntoarvio`,
            description: `Löydä ${city} kiinteistönvälittäjät. Vertaa kokemuksia, arvostelut ja löydä asunnolle paras välittäjä. Vertailu asunnon myynnissä on ilmaista ja kannattaa aina!`,
        });
    }

    setCompanyTags(company: Company) {
        const name = get(company, 'marketingName') || get(company, 'name');
        const city = get(company, 'city');
        if (isEmpty(name) || isEmpty(city)) {
            this.resetMetaElements();
            return;
        }

        const title = `${name} - ${city} - Kokemuksia ja arvostelut | Asuntoarvio`;

        let description = get(company, 'description') || getDefaultDescriptionForAgency(name, city);
        description = truncate(description, { length: 295, omission: '...' });
        this.setMetaTags({ title, description });
    }

    setAgentTags(agent: UserProfile) {
        const firstName = get(agent, 'firstName');
        const lastName = get(agent, 'lastName');
        const company = get(agent, 'companies.0.company.marketingName');
        const city = get(agent, 'companies.0.company.city');
        if (isEmpty(firstName) || isEmpty(lastName) || isEmpty(company) || isEmpty(city)) {
            this.resetMetaElements();
            return;
        }

        const title = `${firstName} ${lastName} - ${company} - ${city} - Kokemuksia ja arvostelut | Asuntoarvio`;

        let description = get(agent, 'description') || getDefaultDescriptionForAgent(`${firstName} ${lastName}`, company, city);
        description = truncate(description, { length: 295, omission: '...' });
        this.setMetaTags({ title, description });
    }

    private setMetaTags(data: MetaTags) {
        const tags = [
            'description',
            'referrer',
            'robots',
        ];
        const ogTags = [
            'og:title',
            'og:description',
            'og:type',
            'og:site_name',
            'og:locale',
            'og:image',
            'og:image:width',
            'og:image:height',
        ];
        document.title = get(data, 'title') || DEFAULT_META_VALUES.title;
        data['og:title'] = get(data, 'title') || DEFAULT_META_VALUES['og:title'];
        data['og:description'] = get(data, 'description') || DEFAULT_META_VALUES['og:description'];
        tags.forEach(tag => this.setMetaElementValue({
            name: tag,
            content: get(data, tag) || get(DEFAULT_META_VALUES, tag) || '',
        }));
        ogTags.forEach(tag => this.setMetaElementValue({
            property: tag,
            content: get(data, tag) || get(DEFAULT_META_VALUES, tag) || '',
        }));
    }

    private setMetaElementValue(data: { name?: string, property?: string, content: string }) {
        const isGeneric = isString(data.name) && !isEmpty(data.name);
        let elem = isGeneric
            ? document.querySelector(`head meta[name='${data.name}']`)
            : document.querySelector(`head meta[property='${data.property}']`);
        const isNewTag = elem === null;
        if (isNewTag) {
            elem = document.createElement('meta');
        }
        if (!elem) {
            return;
        }
        if (isGeneric) {
            elem.setAttribute('name', get(data, 'name') || '');
            elem.setAttribute('content', data.content);
        } else {
            elem.setAttribute('property', get(data, 'property') || '');
            elem.setAttribute('content', data.content);
        }
        if (isNewTag) {
            document.getElementsByTagName('head')[0].appendChild(elem);
        }
    }

    private resetMetaElements() {
        this.setMetaTags({
            title: DEFAULT_META_VALUES.title,
            description: DEFAULT_META_VALUES.description,
            image: DEFAULT_META_VALUES['og:image'],
        });
    }
}
