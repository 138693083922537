import React from 'react';
import Moment from 'react-moment';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import AfAgencyPageView from '../views/AfAgencyPageView';
import { Company } from '../company/Company.interface';
import { Review } from '../company/Review.interface';
import { GoogleMap } from '../components/GoogleMap';
import { BaseController } from './BaseController';
import { formatNumber } from '../lib/helpers';
import icons from '../icons.json';

interface Props {
  company: Company;
  reviews: Review[];
  reviewPage: number;
  reviewPageCount: number;
  reviewsCount: number;
  loadReviewsNextPage: () => void;
  onTakeOver: (e: any) => void;
  onReviewClick: (e: any) => void;
  isAdmin: boolean;
}

export class AfAgencyPageController extends BaseController<RouteComponentProps & Props, {}> {
  componentDidMount() {
    super.componentDidMount();
    this.metaTagService.setCompanyTags(get(this.props, 'company'));
    this.structuredDataService.setCompanySD(get(this.props, 'company'), get(this.props, 'reviews'));
  }

  renderReviews() {
    const { reviews, loadReviewsNextPage, reviewPageCount, reviewPage } = this.props;

    if (!reviews.length) {
      return null;
    }

    let ref: any = null;

    const go = (event: any) => {
      event.preventDefault();

      if (!ref) {
        return;
      }

      ref.scrollIntoView();
    };

    const parts = [
      <af-link-reviews key='reviews-link' onClick={go} />,
      <af-reviews-wrapper key='reviews-list' ref={(r: any) => { ref = r; }}>
        {reviews.map(({
          id,
          customerName,
          numberOfStars,
          createdAt,
          description,
          wouldRecommend,
        }) => (
            <af-review key={id}>
              {this.renderStars('review-stars', numberOfStars)}
              <af-review-author>{customerName}</af-review-author>
              <af-review-date><Moment local date={createdAt} /></af-review-date>
              {wouldRecommend && <af-recommend-company />}
              <af-review-description>{description}</af-review-description>
            </af-review>
          ))}
      </af-reviews-wrapper>,
    ];

    if (reviewPageCount > reviewPage) {
      parts.push((
        <af-review-show-more key='reviews-show-more' onClick={loadReviewsNextPage}>
          Näytä lisää arvosteluita ({reviewPage}/{reviewPageCount})
        </af-review-show-more>
      ));
    }

    return parts;
  }

  renderCosts() {
    const { company } = this.props;
    const {
      commissionOfHousingCooperative,
      commissionOfProperty,
      minCommissionOfHousingCooperative,
      minCommissionOfProperty,
      baseCostsOfHousingCooperative,
      baseCostsOfProperty,
      documentCharges,
      marketingExpenses,
      useDeptFreePrice,
      slug,
    } = company;

    if (
      !commissionOfHousingCooperative &&
      !commissionOfProperty &&
      !minCommissionOfHousingCooperative &&
      !minCommissionOfProperty &&
      !baseCostsOfHousingCooperative &&
      !baseCostsOfProperty
    ) {
      return null;
    }

    const extraCosts = [{
      label: 'Asiakirjakulut',
      value: documentCharges !== null ? (documentCharges ? 'Kyllä' : 'Ei') : null,
    }, {
      label: 'Markkinointikulut',
      value: marketingExpenses !== null ? (marketingExpenses ? 'Kyllä' : 'Ei') : null,
    }, {
      label: 'Palkkion laskentaperuste',
      value: useDeptFreePrice ? 'velattomasta kauppahinnasta' : 'kauppahinnasta',
    }];

    return (
      <af-costs-wrapper>
        {this.afLink('offer-request-link-2', { company: slug })}
        {(commissionOfHousingCooperative || commissionOfProperty) && (
          <af-commissions>
            {commissionOfHousingCooperative && (
              <af-commission-of-housing-cooperative-wrapper>
                <af-commission-of-housing-cooperative>
                  {formatNumber(commissionOfHousingCooperative)} %
                </af-commission-of-housing-cooperative>
              </af-commission-of-housing-cooperative-wrapper>
            )}
            {commissionOfProperty && (
              <af-commission-of-property-wrapper>
                <af-commission-of-property>
                  {formatNumber(commissionOfProperty)} %
                </af-commission-of-property>
              </af-commission-of-property-wrapper>
            )}
          </af-commissions>
        )}
        {(minCommissionOfHousingCooperative || minCommissionOfProperty) && (
          <af-min-commissions>
            {minCommissionOfHousingCooperative && (
              <af-min-commission-of-housing-cooperative-wrapper>
                <af-min-commission-of-housing-cooperative>
                  {formatNumber(minCommissionOfHousingCooperative)} €
                </af-min-commission-of-housing-cooperative>
              </af-min-commission-of-housing-cooperative-wrapper>
            )}
            {minCommissionOfProperty && (
              <af-min-commission-of-property-wrapper>
                <af-min-commission-of-property>
                  {formatNumber(minCommissionOfProperty)} €
                  </af-min-commission-of-property>
              </af-min-commission-of-property-wrapper>
            )}
          </af-min-commissions>
        )}
        {(baseCostsOfHousingCooperative || baseCostsOfProperty) && (
          <af-base-costs>
            {baseCostsOfHousingCooperative && (
              <af-base-costs-of-housing-cooperative-wrapper>
                <af-base-costs-of-housing-cooperative>
                  {formatNumber(baseCostsOfHousingCooperative)} €
                </af-base-costs-of-housing-cooperative>
              </af-base-costs-of-housing-cooperative-wrapper>
            )}
            {baseCostsOfProperty && (
              <af-base-costs-of-property-wrapper>
                <af-base-costs-of-property>
                  {formatNumber(baseCostsOfProperty)} €
                </af-base-costs-of-property>
              </af-base-costs-of-property-wrapper>
            )}
          </af-base-costs>
        )}
        <af-extra-costs>
          {extraCosts.filter(({ value }) => value).map(({ label, value }) => (<span key={label}><b>{label}:</b> {value}<br /></span>))}
        </af-extra-costs>
      </af-costs-wrapper>
    );
  }

  renderServices() {
    const { company } = this.props;

    const {
      advertising,
      services,
    } = company;

    if (!advertising.length && !services.length) {
      return null;
    }

    return (
      <af-services-wrapper>
        {services.length && <af-services-title />}
        {
          services.map(({ value, label }) => (
            <af-services key={value}>
              <span className='af-class-check'>{icons.check}</span>
              {label}
            </af-services>
          ))
        }

        {advertising.length && <af-advertising-title />}
        {
          advertising.map(({ value, label }) => (
            <af-advertising key={value}>
              <span className='af-class-check'>{icons.check}</span>
              {label}
            </af-advertising>
          ))
        }
      </af-services-wrapper>
    );
  }

  renderTerritories() {
    const { company } = this.props;
    const { territoryCities, territoryZipCodeAreas } = company;

    if (!territoryCities.length && !territoryZipCodeAreas.length) {
      return null;
    }

    return (
      <af-territories-wrap>
        {territoryCities.map(({ value, label }) => (
          <af-territory-city key={value}>
            {this.afLink('territory-city-link', { city: label }, label)}
          </af-territory-city>
        ))}
        {territoryZipCodeAreas.map(({ value, label, data }) => (
          <af-territory-zip-code-area key={value}>
            {this.afLink('territory-zip-code-area-link', { postcode: data.zipCode }, label)}
          </af-territory-zip-code-area>
        ))}
      </af-territories-wrap>
    );
  }

  render() {
    const { company, onTakeOver, isAdmin, onReviewClick } = this.props;

    const {
      marketingName,
      description,
      location,
      logoImage,
      backgroundImage,
      address,
      city,
      zipCode,
      stats,
      phone,
      www,
      visitingHours,
      founded,
      businessId,
      numberOfEmployees,
      slug,
      hasMainUser,
      id,
    } = company;

    const {
      numberOfStarsAvg,
      responseDays,
      askingPriceRatio,
      wouldRecommendCount,
    } = stats;

    return (
      <AfAgencyPageView>
        {this.afLink('breadcrumb-link-search', { search: city })}
        <af-breadcrumb-company>{marketingName}</af-breadcrumb-company>

        {this.afLink('offer-request-link', { company: slug })}
        <af-map><GoogleMap coordinates={location} /></af-map>

        <af-header style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none' }}>
          {!backgroundImage && <af-header-marketing-name>{marketingName}</af-header-marketing-name>}
          <af-logo-image style={{ backgroundImage: logoImage ? `url(${logoImage})` : undefined }} />
        </af-header>
        <af-marketing-name>{marketingName}</af-marketing-name>
        <af-address>{`${address}, ${zipCode}, ${city}`}</af-address>
        {numberOfStarsAvg && <af-stars-average>{formatNumber(numberOfStarsAvg, 2)}</af-stars-average>}
        {this.afLink('review-agency-button', { city, company: slug }, null , { onClick: onReviewClick })}
        {this.renderStars('stars', numberOfStarsAvg)}
        {description && description
          .split('\n')
          .filter(p => p)
          .map((p, i) => <af-description key={`description-${i}`} style={{ marginTop: i > 0 ? '10px' : 0 }}>{p}</af-description>)}
        {phone && <af-phone><span className='af-class-agency-info-icon-2'>{icons.phone}</span>{phone}</af-phone>}
        {www && this.afLink('www', www, (
          <span>
            <span className='af-class-agency-info-icon-2'>{icons.home}</span>
            {www.replace(/^http(s|):\/\//, '')}
          </span>
        ))}
        {
          [
            visitingHours ? `Aukioloajat: ${visitingHours}` : undefined,
            founded ? `Perustettu: ${founded}` : undefined,
            businessId ? `Y-tunnus: ${businessId}` : undefined,
            numberOfEmployees ? `Työntekijöiden lukumäärä: ${numberOfEmployees}` : undefined,
          ].filter(v => v).map((v, i) => <af-info key={`info-${i}`}>{v}</af-info>)
        }

        {this.renderTerritories()}

        {!hasMainUser && (
          <af-takeover-wrap>
            {this.afLink('register-link', { city, company: slug })}
            {this.afLink('take-over-company-btn', { city, company: slug }, null, { onClick: onTakeOver })}
          </af-takeover-wrap>
        )}

        {isAdmin && (
          <af-admin-wrap>
            {this.afLink('admin-link', { companyId: id })}
            {this.afLink('admin-offer-request-link', { companyId: id })}
          </af-admin-wrap>
        )}

        {this.renderCosts()}

        {this.renderServices()}

        {responseDays && askingPriceRatio && (
          <af-data-wrapper>
            <af-average-response-time>{responseDays}</af-average-response-time>
            <af-asking-price-ratio>{askingPriceRatio}</af-asking-price-ratio>
            <af-would-recommend>{wouldRecommendCount}</af-would-recommend>
          </af-data-wrapper>
        )}

        {this.renderReviews()}
      </AfAgencyPageView>
    );
  }
}
