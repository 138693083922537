/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfOfferRequestView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfOfferRequestController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfOfferRequestView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfOfferRequestView ? transformProxies(this.props.children) : {
      'af-section1': [],
      'af-form-section1': [],
      'af-input-address': [],
      'af-input-address-extra': [],
      'af-input-zip-code': [],
      'af-input-city': [],
      'af-btn-next-section1': [],
      'af-section2': [],
      'af-form-section2': [],
      'af-schedule-box': [],
      'af-condition-box-selected': [],
      'af-input-size': [],
      'af-input-type': [],
      'af-btn-next-section2': [],
      'af-section3': [],
      'af-form-section3': [],
      'af-input-name': [],
      'af-input-email': [],
      'af-input-phone': [],
      'af-ask-offers-from-any-company-checkbox-wrapper': [],
      'af-ask-offers-from-any-company-checkbox': [],
      'af-btn-next-section3': [],
      'af-btn-prev-section3': [],
      'af-section4': [],
      'af-form-section4': [],
      'af-done-all': [],
      'af-confirm-email': [],
      'af-done-company': [],
      'af-confirm-email': [],
      'af-not-selling': [],
      'af-important-feature-box': [],
      'af-btn-next-section4': [],
      'af-section5': [],
      'af-form-section5': [],
      'af-input-year-of-construction': [],
      'af-input-number-of-rooms': [],
      'af-input-floor-number': [],
      'af-input-number-of-floors-in-building': [],
      'af-btn-next-section5': [],
      'af-btn-prev-section5': [],
      'af-section6': [],
      'af-form-section6': [],
      'af-has-extras-box': [],
      'af-has-recently-renovated-box': [],
      'af-btn-next-section6': [],
      'af-btn-prev-section6': [],
      'af-section7': [],
      'af-form-section7': [],
      'af-condition-box': [],
      'af-condition-box-selected': [],
      'af-input-price-estimate': [],
      'af-input-extra-info': [],
      'af-btn-next-section7': [],
      'af-btn-prev-section7': [],
      'af-section8': [],
      'af-form-section8': [],
      'af-btn-next-section8': [],
      'af-section9': [],
      'af-form-section9': [],
      'af-locality-unacceptable': [],
      'af-error-wrapper': [],
      'af-error': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-section-12">
              <div className="w-container">
                <div className="af-class-breadcrumb-wrapper af-class-breac-crumb-alt">
                  <div className="af-class-breadcrumb-link w-clearfix"><span className="af-class-arrow-back af-class-arrow-back-alt"></span>
                    <a href="/" className="af-class-offer-request-backlink">Palaa etusivulle</a>
                  </div>
                </div>
              </div>
              <div className="w-container">
                <div className="af-class-div-block-33" />
                <div className="af-class-agency-left-content-wrapper af-class-no-top-margin-form">
                  {map(proxies['af-section1'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-b-margin ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="w-form">
                      <div className="af-class-text-block-4 af-class-float-left af-class-lkv-big-text">Kilpailuta kiinteistönvälittäjä</div>
                      <p className="af-class-lkv-result-list-address af-class-margin-1">Kilpailuta kiinteistövälitys ilmaiseksi Asuntoarvio.fi:n avulla! Aloita antamalla myyntikohteen osoitetiedot.</p>
                      {map(proxies['af-form-section1'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section1" name="wf-form-af-form-section1" data-name="af-form-section1" method="get" data-wf-page-id="5d9c6c5957629d222083f5b2" data-wf-element-id="0abbdae8-8cbd-67c8-443a-0461e8319dc6" {...{...props, className: `af-class-form w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <div className="af-class-div-block-67"><label htmlFor="address" className="af-class-field-label-3 af-class-bold-label">Katuosoite *</label>{map(proxies['af-input-address'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="address" data-name="address" placeholder="esim. Annankatu 1" type="text" id="address" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-67 af-class-alt-paddign-input-wrapper"><label htmlFor="addressExtra" className="af-class-field-label-3 af-class-bold-label">Rappu ja asunnon numero</label>{map(proxies['af-input-address-extra'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="addressExtra" data-name="addressExtra" placeholder="esim. B 20" type="text" id="addressExtra" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-67 af-class-alt-paddign-input-wrapper"><label htmlFor="zipCode" className="af-class-field-label-3 af-class-bold-label">Postinumero *</label>{map(proxies['af-input-zip-code'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={5} name="zipCode" data-name="zipCode" pattern="[0-9]{5}" data-focus-type="number" placeholder="esim. 00100 " minLength={5} type="text" id="zipCode" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-67"><label htmlFor="city" className="af-class-field-label-3 af-class-bold-label">Paikkakunta *</label>{map(proxies['af-input-city'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="city" data-name="city" placeholder="esim. Helsinki" type="text" id="city" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div />
                        <div />
                        <div className="af-class-div-block-79 w-clearfix">
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-78">{map(proxies['af-btn-next-section1'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Seuraava" {...{...props, className: `af-class-button af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Seuraava')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                        </div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-section2'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-b-margin ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="w-form">
                      {map(proxies['af-form-section2'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section2" name="wf-form-af-form-section2-3" data-name="af-form-section2" method="get" data-wf-page-id="5d9c6c5957629d222083f5b2" data-wf-element-id="f1792b66-d5fa-9950-db38-2833cfff550a" {...{...props, className: `w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <div className="af-class-text-block-4 af-class-float-left af-class-lkv-big-text">Kilpailuta kiinteistönvälittäjä</div>
                        <p className="af-class-lkv-result-list-address af-class-margin-1">Millä aikataululla olet myymässä asuntoasi?</p>
                        <div className="af-class-div-block-offer-request-multiselect-button">
                          {map(proxies['af-schedule-box'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-32 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Heti</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                          {map(proxies['af-condition-box-selected'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-32 af-class-required-example ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Required</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        </div>
                        <div className="af-class-div-block-67 af-class-alt-paddign-input-wrapper"><label htmlFor="size" className="af-class-field-label-3 af-class-bold-label">Asunnon pinta-ala (m2) *</label>{map(proxies['af-input-size'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="size" data-name="size" pattern="[0-9]{1,5}([,.][0-9]{1,2})?" data-focus-type="number" placeholder="esim. 120" type="text" id="size" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-67-bottom w-clearfix"><label htmlFor="type" className="af-class-field-label-3 af-class-bold-label">Asunnon tyyppi *</label>{map(proxies['af-input-type'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="type" name="type" data-name="type" required {...{...props, className: `af-class-select-field w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                            <option value="Valitse">Valitse</option>
                            <option value="Kerrostalo">Kerrostalo</option>
                            <option value="Rivitalo">Rivitalo</option>
                            <option value="Omakotitalo">Omakotitalo</option>
                            <option value="Paritalo">Paritalo</option>
                            <option value="Erillistalo">Erillistalo</option>
                            <option value="Loma-asunto">Loma-asunto</option>
                          </React.Fragment>)}
                  </select>
                ))}</div>
                        <div className="af-class-div-block-79 w-clearfix">
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-78">{map(proxies['af-btn-next-section2'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Seuraava" {...{...props, className: `af-class-button af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Seuraava')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                        </div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-section3'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-b-margin ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="w-form">
                      <div className="af-class-text-block-4 af-class-float-left af-class-lkv-big-text">Kilpailuta kiinteistönvälittäjä</div>
                      <p className="af-class-lkv-result-list-address af-class-margin-1">Anna yhteystiedot, joiden avulla välittäjä voi ottaa sinuun yhteyttä. Sinä päätät kenet välittäjistä tapaat ja koska. Täysin ilman sitoumuksia.</p>
                      {map(proxies['af-form-section3'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section3" name="wf-form-af-form-section3-4" data-name="af-form-section3" method="get" data-wf-page-id="5d9c6c5957629d222083f5b2" data-wf-element-id="bcc0741f-bbb3-7144-1b51-a4ce8007b59d" {...{...props, className: `af-class-form w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <div className="af-class-div-block-67"><label htmlFor="name-3" className="af-class-field-label-3 af-class-bold-label">Nimi *</label>{map(proxies['af-input-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="name" data-name="name" placeholder="esim. Matti Meikäläinen" type="text" id="name" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-67 af-class-alt-paddign-input-wrapper"><label htmlFor="email" className="af-class-field-label-3 af-class-bold-label">Sähköposti *</label>{map(proxies['af-input-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="email" data-name="email" placeholder="esim. matti@gmail.com" type="email" id="email" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-67"><label htmlFor="phone" className="af-class-field-label-3 af-class-bold-label">Puhelinnumero *</label>{map(proxies['af-input-phone'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="phone" data-name="phone" placeholder="esim. 040 1234 567" type="tel" id="phone" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        {map(proxies['af-ask-offers-from-any-company-checkbox-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-68 af-class-margin-2 w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                          {map(proxies['af-ask-offers-from-any-company-checkbox'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-69 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-checked"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                          <div className="af-class-lkv-result-list-address af-class-margin-form af-class-margin-form-2">Haluan tarjouksia myös muilta alueen välitysliikkeiltä</div>
                        {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                        <div className="af-class-div-block-68 af-class-margin-2 af-class-margin-3" />
                        <div className="af-class-div-block-83 w-clearfix">
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-78">{map(proxies['af-btn-next-section3'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Lähetä" {...{...props, className: `af-class-button af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Lähetä')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-77">{map(proxies['af-btn-prev-section3'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Edellinen" {...{...props, className: `af-class-button af-class-alt-button af-class-alt-button-2 w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Edellinen')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                        </div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                    <div className="af-class-lkv-result-list-address af-class-margin-form af-class-margin-form-2 af-class-terms-block-2022">Käyttäessäsi palvelua hyväksyt <a href="/app/documents/Asuntoarvio-fi-kayttoehdot_230101.pdf" target="_blank"><span className="af-class-offer-request-terms">käyttöehdot</span></a> ja <a href="/app/documents/Asuntoarvio-fi-tietosuojakaytanto_230101.pdf" target="_blank"><span className="af-class-offer-request-terms">tietosuojakäytännön</span></a>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-section4'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-b-margin ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="w-form">
                      {map(proxies['af-form-section4'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section4" name="wf-form-af-form-section4" data-name="af-form-section4" method="get" data-wf-page-id="5d9c6c5957629d222083f5b2" data-wf-element-id="1220e620-884a-30de-2873-f71e92a0a40c" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <div className="af-class-text-block-4 af-class-float-left af-class-lkv-big-text af-class-more-line-height">Kiitos, tiedot on lähetetty onnistuneesti.</div>
                        {map(proxies['af-done-all'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-lkv-result-list-address af-class-margin-1 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Olemme toimittaneet tietosi paikkakunnan parhaille välittäjille ja lähetimme vahvistuksen kilpailutuksesta osoitteeseen {map(proxies['af-confirm-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...props}>
                    {loader || (props.children ? props.children : <React.Fragment>etunimi.sukunimi@tunnus.fi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}.<br /><br />Saat yhteydenottoja välittäjiltä <strong>n. 1 arkipäivän</strong> kuluessa.<br /><br /><em>Antamalla tarkemmat tiedot myyntikohteesta autat välittäjiä myyntihinnan arvioinnissa ja saat tarkempia tarjouksia sekä parhaimmat välittäjät kilpailemaan asuntosi myynnistä.</em><em><br /></em>{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </p>
                ))}
                        {map(proxies['af-done-company'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-lkv-result-list-address af-class-margin-1 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Olemme toimittaneet tietosi valitsemallesi yritykselle ja lähetimme vahvistuksen kilpailutuksesta osoitteeseen {map(proxies['af-confirm-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...props}>
                    {loader || (props.children ? props.children : <React.Fragment>etunimi.sukunimi@tunnus.fi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}.<br /><br />Saat yhteydenottoja välittäjiltä <strong>n. 1 arkipäivän</strong> kuluessa.<br /><br /><em>Tarkemmat tiedot myyntikohteesta auttavat välittäjää asuntosi arvioinnissa. Kertoisitko vielä seuraavia asioita.</em>{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </p>
                ))}
                        {map(proxies['af-not-selling'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-lkv-result-list-address af-class-margin-1 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Asuntoarvio.fi -palvelu on tarkoitettu ensisijaisesti asunnon myyjille. Pyrimme löytämään parhaan kiinteistönvälittäjän tulevaa myyntiä ajatellen.<br /><br />Olet antanut asunnon&nbsp;myyntiaikatauluksi "En ole myymässä". <br /><br />Jos asuntosi myynti on kuitenkin ajankohtainen <strong>seuraavan 12 kuukauden </strong>sisällä, voit lähettää meille sähköpostia osoitteeseen <a href="mailto:asiakaspalvelu@asuntoarvio.fi?subject=Asunnon%20myyntiaikataulun%20tarkennus">asiakaspalvelu@asuntoarvio.fi</a> ja käynnistämme kilpailutuksen.<br /><br />Kattavan tietopaketin asunnon hinta-arviosta löydät osoitteesta <a href="https://asuntoarvio.fi/asunnon-hinta-arvio-hintatesti-ja-hintalaskuri">https://asuntoarvio.fi/asunnon-hinta-arvio-hintatesti-ja-hintalaskuri</a>.</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                        <div className="af-class-h4-lift-offer-request">Mikä seuraavista on sinulle tärkeintä kiinteistönvälittäjän valinnassa?</div>
                        <div className="af-class-div-block-offer-request-multiselect-button">
                          {map(proxies['af-important-feature-box'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-32 af-class-important-feature-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Paras hinta asunnolle</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        </div>
                        <div className="w-clearfix">
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-78">{map(proxies['af-btn-next-section4'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Seuraava" {...{...props, className: `af-class-button af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Seuraava')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                        </div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-section5'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-b-margin ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="w-form">
                      {map(proxies['af-form-section5'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section5" name="wf-form-af-form-section5-6" data-name="af-form-section5" method="get" data-wf-page-id="5d9c6c5957629d222083f5b2" data-wf-element-id="f591f938-8584-30da-398f-2d6919c3e935" {...{...props, className: `af-class-form w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <div className="af-class-text-block-4 af-class-float-left af-class-lkv-big-text">Kilpailuta kiinteistönvälittäjä</div>
                        <p className="af-class-lkv-result-list-address af-class-margin-1">Tarkemmat tiedot auttavat arvioimaan kohteen hinnan paremmin.</p>
                        <div className="af-class-h4-lift-offer-request">Asunnon tiedot</div>
                        <div className="af-class-div-block-67"><label htmlFor="yearOfConstruction" className="af-class-field-label-3 af-class-bold-label">Rakennusvuosi</label>{map(proxies['af-input-year-of-construction'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input max={2030} maxLength={256} name="yearOfConstruction" data-name="yearOfConstruction" pattern="\d+" min={1800} placeholder="esim. 1980" type="number" id="yearOfConstruction" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-67 af-class-alt-paddign-input-wrapper"><label htmlFor="numberOfRooms" className="af-class-field-label-3 af-class-bold-label">Huoneluku</label>{map(proxies['af-input-number-of-rooms'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input max={100} maxLength={256} name="numberOfRooms" data-name="numberOfRooms" min={1} placeholder="esim. 3" type="number" id="numberOfRooms" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-67"><label htmlFor="floorNumber" className="af-class-field-label-3 af-class-bold-label">Asunnon kerros</label>{map(proxies['af-input-floor-number'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input max={100} maxLength={256} name="floorNumber" data-name="floorNumber" pattern="\d+" min={-5} placeholder="esim. 4" type="number" id="floorNumber" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-67-bottom-padding af-class-alt-paddign-input-wrapper"><label htmlFor="numberOfFloorsInBuilding" className="af-class-field-label-3 af-class-bold-label">Talon kerrosten lukumäärä</label>{map(proxies['af-input-number-of-floors-in-building'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input max={200} maxLength={256} name="numberOfFloorsInBuilding" data-name="numberOfFloorsInBuilding" pattern="\d+" min={1} placeholder="esim. 6" type="number" id="numberOfFloorsInBuilding" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div />
                        <div className="af-class-div-block-149 w-clearfix">
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-78">{map(proxies['af-btn-next-section5'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Seuraava" {...{...props, className: `af-class-button af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Seuraava')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-77">{map(proxies['af-btn-prev-section5'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Edellinen" {...{...props, className: `af-class-button af-class-alt-button af-class-alt-button-2 w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Edellinen')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                        </div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-section6'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-b-margin ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="w-form">
                      {map(proxies['af-form-section6'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section6" name="wf-form-af-form-section6-7" data-name="af-form-section6" method="get" data-wf-page-id="5d9c6c5957629d222083f5b2" data-wf-element-id="158ba91c-6817-e1f1-5d80-ddf967e203b3" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <div className="af-class-text-block-4 af-class-float-left af-class-lkv-big-text">Kilpailuta kiinteistönvälittäjä</div>
                        <p className="af-class-lkv-result-list-address af-class-margin-1">Lisätiedot asunnosta ovat tärkeitä myyntihinnan määrittämiseksi.</p>
                        <div className="af-class-h4-lift-offer-request">Lisätiedot asunnosta</div>
                        <div className="af-class-div-block-offer-request-multiselect-button">
                          {map(proxies['af-has-extras-box'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-32 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Parveke</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        </div>
                        <div className="af-class-h4-lift-offer-request">Tehdyt remontit</div>
                        <div className="af-class-div-block-offer-request-multiselect-button">
                          {map(proxies['af-has-recently-renovated-box'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-32 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Julkisivu</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        </div>
                        <div className="w-clearfix">
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-78">{map(proxies['af-btn-next-section6'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Seuraava" {...{...props, className: `af-class-button af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Seuraava')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-77">{map(proxies['af-btn-prev-section6'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Edellinen" {...{...props, className: `af-class-button af-class-alt-button af-class-alt-button-2 w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Edellinen')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                        </div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-section7'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-b-margin ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="w-form">
                      {map(proxies['af-form-section7'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section7" name="wf-form-af-form-section7-8" data-name="af-form-section7" method="get" data-wf-page-id="5d9c6c5957629d222083f5b2" data-wf-element-id="d7494015-7f57-9816-83c5-fc996e5c7740" {...{...props, className: `w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <div className="af-class-text-block-4 af-class-float-left af-class-lkv-big-text">Kilpailuta kiinteistönvälittäjä</div>
                        <p className="af-class-lkv-result-list-address af-class-margin-1" />
                        <div className="af-class-h4-lift-offer-request">Valitse asunnon kunto</div>
                        <div className="af-class-div-block-offer-request-multiselect-button">
                          {map(proxies['af-condition-box'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-32 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Uusi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                          {map(proxies['af-condition-box-selected'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-text-block-32 af-class-multiselect-example ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Selected</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                        </div>
                        <div className="af-class-h4-lift-offer-request">Oma arvio asunnon arvosta / tavoitehinta (€)</div>
                        <div className="af-class-div-block-67-wide"><label htmlFor="priceEstimate" className="af-class-field-label-3 af-class-bold-label">Hinta-arvio ei ole pakollinen tieto, mutta se auttaa välittäjää työssään.</label>{map(proxies['af-input-price-estimate'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="priceEstimate" step={1} data-name="priceEstimate" pattern="\d+" data-focus-type="number" min={0} placeholder="esim. 240000" type="text" id="priceEstimate" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-67-wide">
                          <div className="af-class-h4-lift-offer-request">Lisätiedot asunnostasi ja myntitilanteesta</div><label htmlFor="extraInfo" className="af-class-field-label-3-extra-info af-class-bold-label">Voit kertoa tarkempia tietoja asunnostasi sekä siitä missä tilanteessa olet myyntiin ryhtymässä. Kaikki lisätieto on tarpeellista. Asunnon myynnissä onnistutaan yhteistyöllä!</label>{map(proxies['af-input-extra-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <textarea maxLength={5000} name="extraInfo" data-name="extraInfo" placeholder="esim. Olen myymässä asuntoani 2 kuukauden sisällä. Perheemme etsii isompaa asuntoa samalta alueelta tai lähiseudulta. Toivon välittäjältä hyvää yhteistyötä." id="extraInfo" {...{...props, className: `af-class-offer-request-text-area-extra-info w-input ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </textarea>
                ))}
                        </div>
                        <div className="af-class-h4-lift-offer-request" />
                        <div className="w-clearfix">
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-78">{map(proxies['af-btn-next-section7'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Lähetä" {...{...props, className: `af-class-button af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Lähetä')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                          <div className="af-class-div-block-71">
                            <div className="af-class-div-block-77">{map(proxies['af-btn-prev-section7'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Edellinen" {...{...props, className: `af-class-button af-class-alt-button af-class-alt-button-2 w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Edellinen')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                          </div>
                        </div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-section8'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-b-margin ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="w-form">
                      {map(proxies['af-form-section8'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section8" name="wf-form-af-form-section8-9" data-name="af-form-section8" method="get" data-wf-page-id="5d9c6c5957629d222083f5b2" data-wf-element-id="8c86c25f-c895-03c8-414f-1142484b995e" {...{...props, className: `w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <div className="af-class-text-block-4 af-class-float-left af-class-lkv-big-text af-class-more-line-height">Kiitos, tiedot on lähetetty onnistuneesti.</div>
                        <p className="af-class-lkv-result-list-address af-class-margin-1">Antamasi tiedot on välitetty paikkakunnan parhaille välittäjille. Tulemme huomioimaan valinnassa antamasi tiedot asunnostasi sekä sen miten välittäjän ovat aikaisemmin onnistuneet vastaavissa myyntikohteissa.<br /><br />Saat yhteydenottoja välittäjiltä n. 1 arkipäivän kuluessa. Jos tässä välissä sinulle tulee kysyttävää, voit olla yhteydessä sähköpostilla: asiakaspalvelu@asuntoarvio.fi. </p>
                        <div className="af-class-div-block-71">{map(proxies['af-btn-next-section8'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Valmis" {...{...props, className: `af-class-button af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Valmis')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {map(proxies['af-section9'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-agency-info-wrapper af-class-b-margin ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="w-form">
                      {map(proxies['af-form-section9'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section9" name="wf-form-af-form-section9-10" data-name="af-form-section9" method="get" data-wf-page-id="5d9c6c5957629d222083f5b2" data-wf-element-id="d407f78c-8be0-8a02-f7d1-a71fd74d15bb" {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <div className="af-class-text-block-4 af-class-float-left af-class-lkv-big-text af-class-more-line-height">Paikkakuntaa ei löydy palvelusta</div>
                        {map(proxies['af-locality-unacceptable'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-lkv-result-list-address af-class-margin-1 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Annettujen osoitetietojen perusteella emme valitettavasti löydä palvelusta aktiivisia kiinteistönvälittäjiä. Asuntoarvio.fi -palvelu keskittyy suurimpien kaupunkien ja paikkakuntien kiinteistönvälittäjien vertailuun ja kilpailutukseen. <br /><br />Jos paikkakunnalla ei ole riittävästi kilpailua, emme pysty tarjoamaan luotettavaa vertailua.<br /><br />Pyydämme ystävällisesti olemaan itse yhteydessä alueesi kiinteistönvälittäjiin.<br /><br />Kattavan tietopaketin asunnon hinta-arviosta löydät osoitteesta <a href="https://asuntoarvio.fi/asunnon-hinta-arvio-hintatesti-ja-hintalaskuri">https://asuntoarvio.fi/asunnon-hinta-arvio-hintatesti-ja-hintalaskuri</a>.</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                </div>
                <div className="af-class-agency-right-content-wrapper af-class-no-top-margin af-class-offer-request-margin-top">
                  <div className="af-class-div-block-53" />
                  {map(proxies['af-error-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-error-container af-class-no-margin-peukut ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-text-block-36">Ole hyvä ja tarkista lomakkeen tiedot:</div>
                    <div>
                      {map(proxies['af-error'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-141 w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>
                        <div className="af-class-exclamation"></div>
                        <p className="af-class-offer-request-error-message">Tarkista Katuosoite</p>
                      </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                    </div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  <div className="af-class-div-block-54 af-class-no-margin-peukut af-class-offer-request-top-margin">
                    <div className="af-class-div-block-142 w-clearfix">
                      <div className="af-class-text-block-24"></div>
                      <p className="af-class-lkv-result-list-address">Välittäjävertailu on 100 % maksuton palvelu asunnon myyjille ja myyntiä suunnitteleville.</p>
                    </div>
                    <div className="af-class-div-block-142 w-clearfix">
                      <div className="af-class-text-block-24"></div>
                      <p className="af-class-lkv-result-list-address">Luotettava valinta elämäsi tärkeimpään kauppaan. Paikkakuntasi parhaat kiinteistönvälittäjät nopeasti ja vaivattomasti.</p>
                    </div>
                    <div className="af-class-div-block-142 w-clearfix">
                      <div className="af-class-text-block-24"></div>
                      <p className="af-class-lkv-result-list-address">Palvelun käyttö ei sido sinua mihinkään. Voit vapaasti valita sopivimman välittäjän asuntosi kauppaan.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-frontpage-lift-logo-offer-request w-container">
              <h2 className="af-class-h2-frontpage-logo-text">Luotettavimmat kiinteistönvälittäjät mukana palvelussa</h2>
              <div className="af-class-div-block-frontpage-logo-container w-clearfix">
                <div className="af-class-div-block-frontpage-logo"><img src="images/Remax-kiinteistonvalitys.jpg" loading="lazy" alt="Remax kiinteistönvälitys" className="af-class-image-9" />
                  <h4 className="af-class-h4-lift-frontpage-logo-text">RE/MAX</h4>
                </div>
                <div className="af-class-div-block-frontpage-logo"><img src="images/Kiinteistomaailma-kiinteistonvalitys.jpg" loading="lazy" alt="Kiinteistömaailma kiinteistönvälitys" className="af-class-image-9" />
                  <h4 className="af-class-h4-lift-frontpage-logo-text">Kiinteistömaailma</h4>
                </div>
                <div className="af-class-div-block-frontpage-logo"><img src="images/Muutos-LKV.jpg" loading="lazy" alt="Muutos LKV" className="af-class-image-9" />
                  <h4 className="af-class-h4-lift-frontpage-logo-text">Muutos LKV</h4>
                </div>
                <div className="af-class-div-block-frontpage-logo"><img src="images/Viva-LKV-kiinteistonvalitys.jpg" loading="lazy" alt="Viva LKV kiinteistönvälitys" className="af-class-image-9" />
                  <h4 className="af-class-h4-lift-frontpage-logo-text">Viva LKV</h4>
                </div>
                <div className="af-class-div-block-frontpage-logo"><img src="images/Roof-kiinteistonvalitys.png" loading="lazy" alt="Roof kiinteistönvälitys" className="af-class-image-9" />
                  <h4 className="af-class-h4-lift-frontpage-logo-text">Roof LKV</h4>
                </div>
                <div className="af-class-div-block-frontpage-logo"><img src="images/Aninkainen-kiinteistonvalitys.jpg" loading="lazy" alt="Aninkainen kiinteistönvälitys" className="af-class-image-9" />
                  <h4 className="af-class-h4-lift-frontpage-logo-text">Aninkainen</h4>
                </div>
                <div className="af-class-div-block-frontpage-logo"><img src="images/Sp-koti-kiinteistonvalitys.jpg" loading="lazy" alt="Sp-Koti kiinteistönvälitys" className="af-class-image-9" />
                  <h4 className="af-class-h4-lift-frontpage-logo-text">Sp-Koti</h4>
                </div>
                <div className="af-class-div-block-frontpage-logo"><img src="images/vendo-kiinteistonvalitys.png" loading="lazy" alt="Vendo kiinteistönvälitys" className="af-class-image-9" />
                  <h4 className="af-class-h4-lift-frontpage-logo-text">Vendo</h4>
                </div>
              </div>
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfOfferRequestView

/* eslint-enable */