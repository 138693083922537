export class ApiError extends Error {
    statusCode = 500;

    response = {};

    json = {};

    constructor(response) {
        super(response.statusText);
        this.response = response;
        this.statusCode = response.status;
        this.json = response.json;
    }

    isNotFound() {
        return this.statusCode === 404;
    }
}
