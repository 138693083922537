/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAdminAgentFormView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAdminAgentFormController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAdminAgentFormView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAdminAgentFormView ? transformProxies(this.props.children) : {
      'af-select-section': [],
      'af-show-agent-info': [],
      'af-show-images-info': [],
      'af-public-link-wrapper': [],
      'af-public-link': [],
      'af-agent-info': [],
      'af-form-agent-info': [],
      'af-input-first-name': [],
      'af-input-last-name': [],
      'af-input-description': [],
      'af-input-email': [],
      'af-input-facebook': [],
      'af-input-instagram': [],
      'af-input-linkedin': [],
      'af-input-years-of-experience': [],
      'af-search-select-territory-cities': [],
      'af-search-select-territory-zip-code-areas': [],
      'af-search-select-languages': [],
      'af-search-select-specialities': [],
      'af-search-select-titles': [],
      'af-agent-info-submit': [],
      'af-images-info': [],
      'af-linked-users': [],
      'af-form-images-info': [],
      'af-input-logo-image': [],
      'af-logo-image-preview': [],
      'af-input-background-image': [],
      'af-background-image-preview': [],
      'af-images-info-submit': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            {map(proxies['af-select-section'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-container-6 w-container ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-div-block-95">
                {map(proxies['af-show-agent-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-company-form-tabs w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Välittäjän tiedot</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-show-images-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-company-form-tabs w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Logot &amp; kuvat</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-public-link-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-130 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                  <div className="af-class-text-block-40">Katso välittäjän julkinen sivu:</div>
                  {map(proxies['af-public-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="/kiinteistonvalittaja/profiili/[agent]" target="_blank" {...{...props, className: `af-class-admin-company-public-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>www.asuntoarvio.fi/kiinteistonvalittaja/profiili/matti.meikalainen</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-agent-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-section-12 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-6 w-container">
                <div className="af-class-div-block-33">
                  <div id="company-form" className="w-form">
                    {map(proxies['af-form-agent-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-agent-info" name="wf-form-af-form-agent-info" data-name="af-form-agent-info" method="get" data-wf-page-id="5d9c6c5957629d33c283f5b8" data-wf-element-id="923e55bf-c5d0-32ba-b1d0-99983d639162" {...{...props, className: `w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <div className="af-class-div-block-32">
                        <div className="af-class-heading-3">Välittäjän tiedot:</div>
                      </div>
                      <div className="w-clearfix">
                        <div className="af-class-div-block-87"><label htmlFor="firstName-3" className="af-class-field-label-3">Etunimi</label>{map(proxies['af-input-first-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="firstName" data-name="firstName" placeholder="esim. Matti" type="text" id="firstName" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="lastName" className="af-class-field-label-3">Sukunimi</label>{map(proxies['af-input-last-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="lastName" data-name="lastName" placeholder="esim. Meikäläinen" type="text" id="lastName" required {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87 af-class-full-width"><label htmlFor="description" className="af-class-field-label-4">Välittäjän henkilökohtainen kuvaus</label>{map(proxies['af-input-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <textarea maxLength={5000} name="description" data-name="Description" placeholder="Vapaa kuvaus välittäjän toiminnasta ja palveluista. Kirjoita mahdollisimman myyvä kuvaus asiakkaita ajatellen." id="description" required {...{...props, className: `af-class-input-field-company-form-textarea-2 w-input ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </textarea>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="email-3" className="af-class-field-label-3">Sähköposti</label>{map(proxies['af-input-email'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="email" data-name="email" placeholder="esim. matti.meikalainen@yritys.fi" type="email" id="email" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="facebook" className="af-class-field-label-3">Facebook-sivun osoite (<em>myös osoitteen alku: https://</em>)</label>{map(proxies['af-input-facebook'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="facebook" data-name="facebook" pattern="https?://.+\..{2,}" placeholder="esim. https://www.facebook.com/asuntoarvio.fi/" type="text" id="facebook" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="instagram-2" className="af-class-field-label-3">Instagram-sivun osoite (<em>myös osoitteen alku: https://</em>)</label>{map(proxies['af-input-instagram'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="instagram" data-name="instagram" pattern="https?://.+\..{2,}" placeholder="esim. https://www.instagram.com/asuntoarvio_fi/" type="text" id="instagram" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="LinkedIn" className="af-class-field-label-3">LinkedIn-sivun osoite (<em>myös osoitteen alku: https://</em>)</label>{map(proxies['af-input-linkedin'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="LinkedIn" data-name="LinkedIn" pattern="https?://.+\..{2,}" placeholder="esim. https://www.linkedin.com/in/mattimeikalainen/" type="text" id="LinkedIn" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                        <div className="af-class-div-block-87"><label htmlFor="yearsOfExperience" className="af-class-field-label-3">Kokemusvuodet</label>{map(proxies['af-input-years-of-experience'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="yearsOfExperience" data-name="yearsOfExperience" pattern="[0-9]{1,2}" data-focus-type="number" min={0} placeholder="esim. 6" type="text" id="yearsOfExperience" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}</div>
                      </div>
                      <div className="af-class-div-block-87"><label htmlFor="territoryCities" className="af-class-field-label-3">Toimialueet <br /><em>(valitse max. 5 kpl kuntaa/kaupunkia)</em></label>
                        {map(proxies['af-search-select-territory-cities'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment><input className="af-class-input-field w-input" maxLength={256} name="territoryCities" data-name="territoryCities" placeholder="Etsi paikkakunta" type="text" id="territoryCities" /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-87"><label htmlFor="territory-zip-code-area" className="af-class-field-label-3">Toimialueet <br /><em>(valitse max. 20 kpl postinumeroaluetta)</em></label>
                        {map(proxies['af-search-select-territory-zip-code-areas'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment><input className="af-class-input-field w-input" maxLength={256} name="territoryZipCodeArea" data-name="territoryZipCodeArea" placeholder="Etsi postinumero tai postinumeroalue" type="text" id="territoryZipCodeArea" /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-87"><label htmlFor="services-4" className="af-class-field-label-3">Kielitaito<br /><em>(valitse max. 5 kpl kieliä)</em><br /></label>
                        {map(proxies['af-search-select-languages'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment><input className="af-class-input-field w-input" maxLength={256} name="languages" data-name="languages" placeholder="Valitse puhutut kielet" type="text" id="languages" /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-87"><label htmlFor="advertising-4" className="af-class-field-label-3">Erityisosaaminen<br /><em>(valitse max. 5 kpl osaamisalueita)</em></label>
                        {map(proxies['af-search-select-specialities'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment><input className="af-class-input-field w-input" maxLength={256} name="specialities" data-name="specialities" placeholder="Valitse erityisosaaminen" type="text" id="specialities" /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-87"><label htmlFor="advertising" className="af-class-field-label-3">Tittelit<br /><em>(valitse max. 5 kpl titteleitä)</em></label>
                        {map(proxies['af-search-select-titles'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment><input className="af-class-input-field w-input" maxLength={256} name="examinations" data-name="examinations" placeholder="Valitse tutkinnot" type="text" id="examinations" /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      </div>
                      <div className="af-class-div-block-68 af-class-margin-2" />
                      <div className="af-class-div-block-102">
                        <div>{map(proxies['af-agent-info-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Tallenna tiedot" {...{...props, className: `af-class-button af-class-alt-button w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Tallenna tiedot')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                      </div>
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-images-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-section-20 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-3 w-container">
                {map(proxies['af-linked-users'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {loader || (props.children ? props.children : <React.Fragment>
                  <div className="af-class-heading-3">Omat kuvat:</div>
                </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                <div className="w-form">
                  {map(proxies['af-form-images-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-images-info" name="wf-form-af-form-users-info" data-name="af-form-users-info" method="get" data-wf-page-id="5d9c6c5957629d33c283f5b8" data-wf-element-id="f5d569a8-e20b-82d6-ceb6-7091d1efb2c0" {...{...props, className: `w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-div-block-87"><label htmlFor="logoImage" className="af-class-field-label-3 af-class-alt-margin af-class-image-upload-label">Profiilikuva</label><label htmlFor="logoImage-2" className="af-class-field-label-3 af-class-alt-margin af-class-image-type-info">Koko 300x300 pikseliä (tiedostomuoto: .png, .jpg tai .gif)</label>{map(proxies['af-input-logo-image'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="logoImage" data-name="logoImage" placeholder="Valitse kuva tietokoneelta" type="text" id="logoImage" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{map(proxies['af-logo-image-preview'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="images/user-silhouette-2.svg" width={165} height={165} alt {...props}>{props.children}
                  </img>
                ))}</div>
                    <div className="af-class-div-block-87"><label htmlFor="backgroundImage" className="af-class-field-label-3 af-class-alt-margin af-class-image-upload-label">Profiilisivun pääkuva</label><label htmlFor="backgroundImage-2" className="af-class-field-label-3 af-class-alt-margin af-class-image-type-info">Koko 1880x500 pikseliä (tiedostomuoto: .png, .jpg tai .gif)</label>{map(proxies['af-input-background-image'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="backgroundImage" data-name="backgroundImage" placeholder="Valitse kuva tietokoneelta" type="text" id="backgroundImage" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}<label htmlFor="backgroundImage-2" className="af-class-field-label-3 af-class-alt-margin af-class-image-type-info"><em>Voit jättää pääkuvan lataamatta. Silloin palvelu käyttää vakiokuvia profiilisivun taustalla.</em></label>{map(proxies['af-background-image-preview'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <img src="https://d3e54v103j8qbb.cloudfront.net/plugins/Image/assets/placeholder.60f9b1840c.svg" alt {...props}>{props.children}
                  </img>
                ))}</div>
                    <div className="af-class-div-block-131 w-clearfix">{map(proxies['af-images-info-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Lähetä" {...{...props, className: `af-class-button af-class-alt-button af-class-alt-button-position w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Lähetä')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAdminAgentFormView

/* eslint-enable */