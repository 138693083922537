import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';

import history from '../history';

import rootReducer from './rootReducer';

const disableLogs = process.env.NODE_ENV === 'production' || process.env.REACT_APP_NO_LOGGING;

const appliedMiddleware = disableLogs
  ? [
    routerMiddleware(history),
    thunk,
  ]
  : [
    routerMiddleware(history),
    thunk,
    logger,
  ];

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...appliedMiddleware as any),
  ),
);

export default store;
