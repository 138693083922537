import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { set, kebabCase, isArray, isString, isEmpty, flatten, uniq } from 'lodash';
import moment from 'moment';
import Select, { components } from 'react-select';
import icons from '../icons.json';
import { logger } from '../lib/debug';
import { StringSignatureAny } from '../shared/types';
import { BaseComponent, BaseState } from './BaseComponent';

import './styles.scss';

const log = logger('Form');

declare global {
  interface Window { dataLayer: any; }
}

const input = document.createElement('input');
const testValue = 'a';
input.setAttribute('type', 'date');
input.setAttribute('value', testValue);
const isDateSupported = (input.value !== testValue);
const supportedDateFormats = ['D.M.YYYY', 'YYYY-MM-DD'];
const generatePreviewForTypes = new Set(['image/png', 'image/jpeg']);

export type valueType = string | number | string[] | boolean | null;

export interface FormState extends BaseState {
  section: number | string;
  errors: StringSignatureAny;
  formData: any;
  savedSections: string[];
  inputTypes: StringSignatureAny;
}

export const FormInitialState = {
  section: 1,
  errors: {},
  formData: {},
  savedSections: [],
  inputTypes: {},
} as FormState;

export class Form<T extends {} = {}, S extends FormState = FormState>
  extends BaseComponent<RouteComponentProps & T, S> {
  form: any;
  inputRefs: StringSignatureAny = {};
  boxSelectionRequiredFields = new Set<string>();
  boxSelectionErrorMessages = new Map<string, string>();
  boxSelectionInvalidFields = new Set<string>();

  get formName() {
    return 'Form';
  }

  get sectionName() {
    return this.state.section.toString();
  }

  // change handler factory
  getHandler = (field: string, value: valueType | undefined = undefined): React.FormEventHandler => {
    return this.handleChangeEvent.bind(this, field, value);
  }

  getElemName(elem: any): string {
    return elem.name;
  }

  getCustomValidators(elemName: string|null = null) {
    const validators = {
      sample: () => {
        return ['Not implemented'];
      },
    } as StringSignatureAny;

    return elemName ? validators[elemName] : validators;
  }

  depensValidators = {
  } as StringSignatureAny;

  normalizeValue(_: string, target: any, value: valueType) {
    let newValue = value;

    const types = [];
    if (target && target.type) {
      types.push(target.type);
    }

    const canGetAttribute = target && typeof target.getAttribute === 'function';
    if (canGetAttribute) {
        types.push(target.getAttribute('data-focus-type'));
        types.push(target.getAttribute('data-blur-type'));
        types.push(target.getAttribute('data-db-type'));
    }

    if (types.includes('number') && newValue) {
      const pattern = canGetAttribute ? target.getAttribute('pattern') : null;
      if (pattern && pattern.toString().includes('.')) {
        newValue = newValue.toString().replace(/,/, '.');
        newValue = newValue.toString().replace(/[^\d.]/, '');
      } else {
        newValue = newValue.toString().replace(/[^\d]/, '');
      }
    }

    return newValue;
  }

  handleChangeEvent(fieldName: string, predefinedValue: valueType | undefined, event: React.ChangeEvent<HTMLInputElement>) {
    this.handleChange(fieldName, predefinedValue, event);
  }

  handleChange(fieldName: string, predefinedValue: valueType | undefined, event: any = null) {
    const field = `formData.${fieldName}`;

    const { target } = (event === null ? { target: {
      value: predefinedValue as valueType,
      type: 'custom',
      files: null,
      name: fieldName,
    } } : event);

    let newValue: valueType;
    if (predefinedValue !== undefined) {
      newValue = predefinedValue as valueType;
    } else {
      newValue = target.value;
    }

    const newState = Object.assign({}, this.state);

    newValue = this.normalizeValue(field, target, newValue);

    if (newValue === 'true') {
      newValue = true;
    }

    if (newValue === 'false') {
      newValue = false;
    }

    if (newValue === '' || newValue === 'null') {
      newValue = null;
    }

    if (newValue && target.type === 'file' && target.files && target.files[0]) {
      const reader = new FileReader();
      const file = target.files[0];
      const generatePreview = file?.type && generatePreviewForTypes.has(file.type);
      reader.onload = (e: any) => {
        if (e && e.target && e.target) {
          const imagesState = Object.assign({}, this.state);
          set(imagesState, field, file);
          if (generatePreview) {
            set(imagesState, `${field}Preview`, e.target.result);
          }
          this.setState(imagesState);
        }
      };
      // read the image file as a data URL.
      reader.readAsDataURL(target.files[0]);
      return;
    }

    log('set', field, ' : ', newValue);

    this.setState(set(newState, field, newValue), () => {
      this.validateElem(target.name ? target : { name: fieldName });
      if (this.boxSelectionRequiredFields.has(fieldName)) {
        this.validateBoxSelections();
      }
    });
  }

  validateElem(elem: any, errorsObject: StringSignatureAny|null = null) {
    const errors = errorsObject !== null ? errorsObject : this.state.errors;

    const elemErrors = this.getElementErrors(elem);
    const isValid = !elemErrors.length;
    if (isValid && errors && errors[elem.name]) {
      delete errors[elem.name];
    } else if (!isValid) {
      errors[elem.name] = elemErrors;
    }

    if (this.depensValidators[elem.name]) {
      this.depensValidators[elem.name].forEach((elemName: string) => {
        errors[elemName] = this.getElementErrors(this.inputRefs[elemName] || { name: elemName });
        if (!errors[elemName].length) {
          delete errors[elemName];
        }
      });
    }

    if (errorsObject === null) {
      this.setState({ errors });
    }
  }

  validateBoxSelections(errorsObject: StringSignatureAny|null = null) {
    const { formData } = this.state;
    let unselectedFields: string[] = [];

    if (this.boxSelectionRequiredFields.size > 0) {
      unselectedFields = Array.from(this.boxSelectionRequiredFields)
        .filter(field => !isString(formData[field]) || isEmpty(formData[field]));
      unselectedFields.forEach(field => {
        this.boxSelectionInvalidFields.add(field);
        if (errorsObject) {
          errorsObject[field] = this.boxSelectionErrorMessages.get(field);
        }
      });
    }

    if (unselectedFields.length === 0) {
      this.boxSelectionInvalidFields.clear();
    }
  }

  async saveformData(): Promise<boolean> {
    throw new Error('Save method not implemented');
  }

  getElementErrors = (elemToValidate: any): string[] => {
    let elem = elemToValidate;

    if (!elem.name) {
      return [];
    }

    const elemErrors: string[] = [];
    const label = this.getElemName(elem);

    if (elem.tagName === 'INPUT' && elem.getAttribute('data-custom-type-date') === 'true') {
      const date = moment(elem.value, supportedDateFormats, true);
      if (!date.isValid()) {
        elemErrors.push(`${label} päivämäärä on virheellinen`);
      }
    }

    const customValidator = this.getCustomValidators(elem.name);
    if (!elem.checkValidity) {
      elem = customValidator;
    }

    if (!elem || !elem.checkValidity || (elem.checkValidity() && (!customValidator || customValidator.checkValidity()))) {
      return elemErrors;
    }

    elemErrors.push(...(customValidator && customValidator.getErrors ? customValidator.getErrors() : []));

    if (elem.validity === null || elem.validity.valid === true) {
      return elemErrors;
    }

    const {
      valueMissing,
      patternMismatch,
      tooLong,
      tooShort,
      rangeOverflow,
      rangeUnderflow,
      badInput,
    } = elem.validity;

    if (valueMissing) {
      let missingMessage = `${label} on täyttämättä`;
      if (elem.tagName.toUpperCase() === 'SELECT') {
        missingMessage = `${label} ei ole valittuna`;
      }
      elemErrors.push(missingMessage);
    }

    const patternError = elem.getAttribute('data-pattern-error');
    if ((patternMismatch || badInput) && patternError) {
      elemErrors.push(patternError);
    }

    if (tooLong) {
      elemErrors.push(`${label} pituus saa olla enintään ${elem.getAttribute('maxLength')} merkkiä`);
    }

    if (tooShort) {
      elemErrors.push(`${label} pituus pitää olla vähintään ${elem.getAttribute('minLength')} merkkiä`);
    }

    const min = elem.getAttribute('min');
    if (rangeUnderflow && min !== undefined) {
      elemErrors.push(`${label} pitää olla vähintään ${min}`);
    }

    const max = elem.getAttribute('max');
    if (rangeOverflow && max !== undefined) {
      elemErrors.push(`${label} saa olla enintään ${max}`);
    }

    if (!elemErrors.length) {
      elemErrors.push(`${label} on virheellinen`);
    }

    return elemErrors;
  }

  onSubmitError() {
    // placeholder
  }

  isSectionSaving() {
    return this.isLoadingData(this.sectionName);
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();

    const { elements } = this.form;

    const customValidators = this.getCustomValidators();
    const elementsToValidate = Object.values(elements)
      .concat(this.section()
        .map((elem: any) => elem.key && customValidators[elem.key] ? customValidators[elem.key] : null)
        .filter((elem: any) => elem),
      );

    const errors = {} as StringSignatureAny;
    elementsToValidate.forEach((elem: any) => {
      this.validateElem(elem, errors);
    });

    this.validateBoxSelections(errors);
    this.setState({ errors });

    if (Object.values(errors).filter(elemErrors => elemErrors.length).length) {
      this.onSubmitError();
      return;
    }

    const sectionName = this.sectionName;
    this.startLoadingData(sectionName);
    const saved = await this.saveformData();
    this.endLoadingData(sectionName, saved ? { savedSections: [ ...this.state.savedSections, sectionName ] } : {});

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'formSubmissionSuccess',
      formId: `${this.formName}-${sectionName}`,
    });
  }

  onInputFocus = (event: any) => {
    const { target } = event;
    const focusType = target.getAttribute('data-focus-type');
    if (!focusType) {
      return;
    }

    if (!target.getAttribute('data-origin-type')) {
      target.setAttribute('data-origin-type', target.getAttribute('type'));
    }

    target.isFocusing = true;
    this.setState({
      inputTypes: { ...this.state.inputTypes, [target.name]: focusType },
    }, () => {
      setTimeout(() => { target.isFocusing = false; }, 10);
    });
  }

  onInputBlur = (event: any) => {
    const { target } = event;
    const originType = target.getAttribute('data-origin-type');
    const currentType = this.state.inputTypes[target.name];
    const isFocusing = target.isFocusing;
    target.isFocusing = false;

    this.validateElem(target);
    this.validateBoxSelections();

    if (isFocusing) {
      return;
    }

    target.dispatchEvent(new Event('input', { bubbles: true }));

    if (originType && originType !== currentType) {
      this.setState({ inputTypes: { ...this.state.inputTypes, [target.name]: originType } }, () => {
        this.validateElem(target);
        this.validateBoxSelections();
      });
    }
  }

  registerRequiredBoxSelection(fieldName: string, errorMessage: string) {
    this.boxSelectionRequiredFields.add(fieldName);
    this.boxSelectionErrorMessages.set(fieldName, errorMessage);
  }

  setInputRef(name: string) {
    return (el: any) => {
      this.inputRefs[name] = el;
    };
  }

  renderDateInput(name: string, children: JSX.Element|null = null, props: any = {}) {
    const customProps = { ...props, type: isDateSupported ? 'date' : 'text', pattern: '^[0-9]{1,2}.[0-9]{1,2}.[0-9]{4}$' };
    customProps['data-custom-type-date'] = 'true';
    return this.renderInput(name, children, customProps);
  }

  renderTextInput(name: string, children: JSX.Element|null = null, props: any = {}) {
    return this.renderInput(name, children, { ...props, type: 'text' });
  }

  renderNumberInput(name: string, children: JSX.Element|null = null, props: any = {}) {
    return this.renderInput(name, children, { ...props, type: 'number' });
  }

  renderSelect(name: string, children: JSX.Element|null = null, props: any = {}, attributeNameOverride?: string) {
    return this.renderInput(name, children, { ...props, type: 'select' }, attributeNameOverride);
  }

  renderInput(name: string, children: JSX.Element|null = null, props: any = {}, attributeNameOverride?: string) {
    const { formData, errors } = this.state;
    const InputTag = `af-input-${kebabCase(attributeNameOverride || name)}` as unknown as React.ComponentType<any>;

    let value = formData[name];
    if (['text', 'number'].includes(props.type)) {
      value = value === true || value === null || value === false ? '' : value;
    }

    if (props.type === 'select') {
      delete props.type;
      if (value === true) {
        value = 'true';
      }
      if (value === null) {
        value = 'null';
      }
      if (value === false) {
        value = 'false';
      }
    }

    if (props['data-custom-type-date'] === 'true' && value && moment(value, [...supportedDateFormats, moment.ISO_8601], true).isValid()) {
      value = moment(value, supportedDateFormats).format(props.type === 'date' ? 'YYYY-MM-DD' : 'D.M.YYYY');
    }

    if (this.state.inputTypes[name]) {
      props.type = this.state.inputTypes[name];
    }

    return (
      <InputTag
        key={name}
        onChange={this.getHandler(name)}
        value={!props.type || props.type !== 'file' ? (value || '') : undefined}
        onFocus={this.onInputFocus}
        onBlur={this.onInputBlur}
        className={errors && errors[name] ? 'error-input' : undefined}
        ref={this.setInputRef(name)}
        {...props}
      >
        {children}
      </InputTag>
    );
  }

  onPreviewClickImage = (name: string) => () => {
    const { formData } = this.state;

    if (formData[`${name}Preview`]) {
      formData[`${name}Preview`] = false;
    } else if (formData[name]) {
      formData[name] = false;
    }

    this.setState({ formData });
  }

  renderImageInput(name: string) {
    const { formData } = this.state;

    const PreviewTag = `af-${kebabCase(name)}-preview` as unknown as React.ComponentType<any>;
    const preview = formData[`${name}Preview`] || formData[name] || undefined;

    const data = [
      this.renderInput(name, null, {
        type: 'file',
      }),
    ];

    if (preview) {
      data.push(<PreviewTag alt='' src={preview} key={`${name}-preview`} onClick={this.onPreviewClickImage(name)} />);
    }

    return data;
  }

  renderFileUpload(name: string) {
    return this.renderInput(name, null, { type: 'file' });
  }

  renderCheckbox(name: string, props: any = {}) {
    const { formData, errors } = this.state;
    const CheckboxTag = `af-${kebabCase(name)}-checkbox` as unknown as React.ComponentType<any>;

    return (
      <CheckboxTag
        key={name}
        onClick={this.getHandler(name, !formData[name])}
        className={errors && errors[name] ? 'error-input' : undefined}
        ref={this.setInputRef(name)}
        {...props}
      >
        {!formData[name] && <span />}
      </CheckboxTag>
    );
  }

  renderBoxSelection(field: string) {
    const { formData } = this.state;
    interface Options {
      label: string;
      value: valueType;
    }
    return (option: string | Options, index: valueType | undefined = undefined) => {
      const label = isString(option) ? option : option.label;
      const value = isString(option) ? index : option.value;
      const isChecked = isArray(formData[field]) ? formData[field].includes(value) : formData[field] === value;
      const isInvalid = this.boxSelectionInvalidFields.has(kebabCase(field));

      const Boxtag = `af-${kebabCase(field)}-box` as unknown as React.ComponentType<any>;
      const classNames = [];
      if (isChecked) {
        classNames.push('af-class-multiselect-example');
      }
      if (isInvalid) {
        classNames.push('af-class-multiselect-invalid');
      }

      return (
        <Boxtag
          onClick={this.getHandler(field, value)}
          className={classNames.join(' ')}
        >
          {label}
        </Boxtag>
      );
    };
  }

  renderSearchSelection(field: string, options: any, props: any = {}) {
    const { formData } = this.state;

    const Wrappertag = `af-search-select-${kebabCase(field)}` as unknown as React.ComponentType<any>;
    const values = formData[field];
    const { max } = props;
    if (typeof max !== 'undefined') {
      delete props.max;
    }

    const Menu = (p: any) => {
      const value = p.getValue();
      const optionSelectedLength = value && value.length ? value.length : 0;
      return (
        <components.Menu {...p}>
          {!max || optionSelectedLength < max ? (
            p.children
          ) : (
            <div style={{ padding: '5px' }}>Maksimimäärä valittuna</div>
          )}
        </components.Menu>
      );
    };

    const isValidNewOption = (inputValue: any, selectValue: any) =>
      inputValue.length > 0 && selectValue.length < 5;

    return (
      <Wrappertag key={field}>
        <Select
          key={`${field}Select`}
          isMulti
          value={values}
          onChange={(items: any) => this.handleChange(field, items, null)}
          options={max && values && max <= values.length ? values : options}
          placeholder='Valitse...'
          components={{ Menu }}
          isValidNewOption={isValidNewOption}
          {...props}
        />
      </Wrappertag>
    );
  }

  section() {
    return [
      this.renderInput('sample'),
    ];
  }

  getAllErrors() {
    const { errors } = this.state;
    return uniq(flatten(Object.values(errors)));
  }

  hasErrors() {
    const { errors } = this.state;
    return errors && Object.keys(errors).length > 0 && this.getAllErrors().length;
  }

  renderSectionChildren(): any {
    return null;
  }

  renderFormChildren(): any {
    return null;
  }

  renderErrors(perSection = false) {
    if (!this.hasErrors()) {
      return null;
    }

    let errorTag = 'af-error';
    if (perSection) {
      errorTag += `-${this.sectionName}`;
    }

    const ErrorTag = errorTag as unknown as React.ComponentType<any>;
    const ErrorWrapper = `${errorTag}-wrapper` as unknown as React.ComponentType<any>;

    return (
      <ErrorWrapper>
        {this.getAllErrors().map((error: string) => (
          <ErrorTag key={error}>
            <span className='af-class-exclamation'>{icons.exclamation}</span> {error}
          </ErrorTag>
        ))}
      </ErrorWrapper>
    );
  }

  renderForm(sectionClassName = '') {
    this.boxSelectionRequiredFields.clear();
    this.boxSelectionErrorMessages.clear();
    const Section = `af-${this.sectionName}` as unknown as React.ComponentType<any>;
    const FormElement = `af-form-${this.sectionName}` as unknown as React.ComponentType<any>;
    const inputs = this.section();
    return (
      <Section className={sectionClassName}>
        <FormElement onSubmit={(e: any) => e.preventDefault()} noValidate ref={(ref: any) => { this.form = ref; }}>
          {inputs.map((a: any, x: number) => ({
            ...a,
            key: `${this.sectionName}${x}`,
          }))}
          {this.renderFormChildren()}
        </FormElement>
        {this.renderSectionChildren()}
      </Section>
    );
  }
}
