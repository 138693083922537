import React from 'react';
import { get, debounce } from 'lodash';

declare const google: any;

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&region=FI&language=fi`;
const DEFAULT_COORDINATES = { lat: 60.167850, lng: 24.952965 };

interface Props {
  address?: string;
  coordinates?: any;
}

function getLatLngFromCoordinates(coordinates: any) {
  if (!coordinates) {
    return { lat: null, lng: null };
  }

  let { lat, lng } = coordinates;
  if (coordinates.type === 'Point') {
    ([lat, lng] = coordinates.coordinates);
  }

  return { lat, lng };
}

function isValidCoordinates(coordinates: any) {
  const coords = getLatLngFromCoordinates(coordinates);
  return coords !== null && coords.lat && coords.lng;
}

export class GoogleMap extends React.Component<Props> {
  map: google.maps.Map | null = null;
  marker: google.maps.Marker | null = null;

  async componentDidMount() {
    this.map = await this.addMapsScript();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.hasPropertyCoordinates() && prevProps.coordinates !== this.props.coordinates) {
      this.setMarkerToCoordinates(this.props.coordinates);
    } else if (!this.props.coordinates && this.props.address && prevProps.address !== this.props.address) {
      this.setMarkerToAddress(this.props.address);
    }
  }

  hasPropertyCoordinates() {
    const { coordinates } = this.props;
    return isValidCoordinates(coordinates);
  }

  initMap(): google.maps.Map {
    const map = new google.maps.Map(document.getElementById('map'), {
      center: DEFAULT_COORDINATES,
      zoom: 9,
    });

    this.marker = new google.maps.Marker({
      position: DEFAULT_COORDINATES,
      map: this.map,
      draggable: true,
    });

    if (this.marker) {
      this.marker.setMap(map);
    }

    if (this.hasPropertyCoordinates()) {
      this.setMarkerToCoordinates(this.props.coordinates);
    } else if (this.props.address) {
      this.setMarkerToAddress(this.props.address);
    }

    return map;
  }

  addMapsScript(): Promise<google.maps.Map> {
    return new Promise(resolve => {
      if (!document.querySelectorAll(`[src='${GOOGLE_MAPS_URL}']`).length) {
        document.body.appendChild(Object.assign(
        document.createElement('script'), {
          type: 'text/javascript',
          src: GOOGLE_MAPS_URL,
          onload: () => resolve(this.initMap()),
        }));
      } else {
        resolve(this.initMap());
      }
    });
  }

  getPosition() {
    return this.marker ? this.marker.getPosition() : null;
  }

  setMarkerToAddress = debounce(async (rawAddress: string) => {
    const address = encodeURIComponent(rawAddress);
    // TODO: refactor hard coded 'Finland' to a localization variable
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}+Finland&key=${GOOGLE_API_KEY}`);
    const coordinates = get(await response.json(), 'results[0].geometry.location');
    if (isValidCoordinates(coordinates)) {
      this.setMarkerToCoordinates(coordinates);
    }
  }, 500);

  setMarkerToCoordinates = debounce(async (coordinates: any) => {
    if (!isValidCoordinates(coordinates) || !this.marker || !this.map) {
      return;
    }

    const { lat, lng } = getLatLngFromCoordinates(coordinates);
    const latLng = new google.maps.LatLng(lat, lng);

    this.marker.setPosition(latLng);
    this.map.setCenter(latLng);
    if (this.map.getZoom() < 14) {
      this.map.setZoom(14);
    }
  }, 500);

  render() {
    return (
      <div id='map'></div>
    );
  }
}
