import { RouteComponentProps } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { MetaTagsService } from '../services/meta-tags';
import { HelperComponent } from '../components/HelperComponent';
import { StructuredDataService } from '../services/structured-datas';

declare global {
    interface Window { Webflow: any; }
}

const reloadWebflow = debounce(() => {
  // Asynchronous initialization
  const Webflow = window.Webflow || [];
  Webflow.push(() => window.Webflow.ready());
}, 100);

export class BaseController<T extends {} = {}, S extends {} = {}> extends HelperComponent<RouteComponentProps & T, S> {
  protected metaTagService = new MetaTagsService();
  protected structuredDataService = new StructuredDataService();

  componentDidUpdate(_: any) {
    reloadWebflow();
  }

  componentDidMount() {
    reloadWebflow();
  }
}
