import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import set from 'lodash/set';
import {
  OfferRequestStatus,
  OfferRequestWithCompany,
  CompanyOfferRequestUser,
  maskContactData,
  offerRequestClosedTypes,
  offerRequestLeadIntegrationTypes,
} from './offer-request';
import { userSelectors } from '../store/user';
import { companySelectors } from '../store/company';
import { UserProfile } from '../store/user/reducer';
import { OfferRequestDetailsBase, OfferRequestDetailsState, OfferRequestDetailsTParams } from './OfferRequestDetailsBase';
import { OfferRequestAction } from './OfferRequestAction';
import { CompanyManagerService } from '../services/company-manager';
import { Company } from '../company/Company.interface';

import './CompanyOfferRequestDetails.scss';

const MAX_AGENT_LINK = 2;

interface TParams extends OfferRequestDetailsTParams {
  companyId: string;
}

interface State extends OfferRequestDetailsState {
  offerRequest: OfferRequestWithCompany;
  unmaskedOfferRequest: OfferRequestWithCompany;
  users: UserProfile[];
  linking?: number | null;
  isOfferRequestAvailable: boolean;
}

interface Props {
  company: Company;
  currentUser: UserProfile;
  isAdmin: boolean;
  isCompanyMainUser: (companyId: number) => boolean;
}

export class CompanyOfferRequestDetails extends OfferRequestDetailsBase<Props & RouteComponentProps<TParams>, State> {
  setStateAfterLoad(state: any, cb: any = null) {
    const { offerRequest, isOfferRequestAvailable } = state;
    if (!offerRequest) {
      super.setStateAfterLoad({
        offerRequest: null,
        unmaskedOfferRequest: null,
        isOfferRequestAvailable,
      }, cb);
      return;
    }
    super.setStateAfterLoad({
      offerRequest: maskContactData(offerRequest),
      unmaskedOfferRequest: offerRequest,
      isOfferRequestAvailable,
    }, cb);
  }

  async componentDidMount() {
    await super.componentDidMount();

    const { isAdmin, isCompanyMainUser } = this.props;

    if (!isAdmin && !isCompanyMainUser(this.companyId)) {
      return;
    }

    const service = new CompanyManagerService(this.companyId);
    const users = await service.child('users').get();

    this.setState({ users });
  }

  get companyId(): number {
    const { match } = this.props;
    return parseInt(match.params.companyId, 10);
  }

  get api(): any {
    const service = new CompanyManagerService(this.companyId);
    return service.offerRequest().child(this.offerId);
  }

  get myyntiPro(): any {
    const { offerRequest } = this.state;

    const hasMyyntiPro = get(offerRequest, 'companyOfferRequest.company.lead.hasMyyntiPro');
    const sent = get(offerRequest, 'companyOfferRequest.lead.hasMyyntiProSent');
    const enabled = hasMyyntiPro && offerRequestLeadIntegrationTypes.includes(offerRequest.companyOfferRequest.status);

    return {
      sent,
      enabled,
    };
  }

  getLinkedAgentsCount(): number {
    const { offerRequest } = this.state;
    const { companyOfferRequest } = offerRequest;
    const { users } = companyOfferRequest;

    return users.length;
  }

  onStatusUpdated = async (_: OfferRequestWithCompany, newStatus: OfferRequestStatus, extraData = {}) => {
    await super.loadOfferRequest();
    const {
      unmaskedOfferRequest: offerRequest,
      offerRequest: {
        companyOfferRequest: { users },
      },
    } = this.state;

    offerRequest.companyOfferRequest = {
      ...offerRequest.companyOfferRequest,
      priceEstimation: this.state.offerRequest.companyOfferRequest.priceEstimation,
      status: newStatus,
      ...extraData,
      users,
    };

    this.setState({ offerRequest, unmaskedOfferRequest: offerRequest });
  }

  renderAction = (): null | JSX.Element => {
    const { offerRequest, users } = this.state;
    const { history, currentUser, company } = this.props;

    return OfferRequestAction.renderActionButtons({
      history,
      company,
      offerRequest,
      getActionRef: () => this.actionRef,
      users,
      currentUser
    });
  }

  async attachUser(user: UserProfile) {
    if (this.state.linking) {
      return;
    }

    try {
      this.setState({ linking: user.id });
      const { offerRequest } = this.state;
      const { companyOfferRequest } = offerRequest;
      const result = await this.api.child('users').post({ userId: user.id });
      this.setState({
        linking: null,
        offerRequest: {
          ...offerRequest,
          companyOfferRequest: { ...companyOfferRequest, users: [...companyOfferRequest.users, result] },
        },
      });
      toast.success('Linkitys onnistui');
    } catch (error) {
      this.setState({ linking: null });
      toast.error('Linkitys epäonnistui');
    }
  }

  async deattachUser(user: UserProfile) {
    if (this.state.linking) {
      return;
    }

    try {
      this.setState({ linking: user.id });
      const { offerRequest } = this.state;
      const { companyOfferRequest } = offerRequest;
      await this.api.child('users').child(user.id).del();
      this.setState({
        linking: null,
        offerRequest: {
          ...offerRequest,
          companyOfferRequest: { ...companyOfferRequest, users: companyOfferRequest.users.filter(u => u.userId !== user.id) },
        },
      });
      toast.success('Linkitys poistettu');
    } catch (error) {
      this.setState({ linking: null });
      toast.error('Linkityksen poisto epäonnistui');
    }
  }

  renderAgentName(user: UserProfile) {
    const { firstName, lastName, email, id } = user;

    const name = firstName && lastName ? `${firstName} ${lastName} (${email})` : email;

    return <a href={`/hallinta/${this.companyId}/kayttajat/${id}`} target='_blank' rel='noopener noreferrer'>{name}</a>;
  }

  renderUserLink(user: UserProfile) {
    const { isAdmin } = this.props;
    const { offerRequest, linking } = this.state;
    const { companyOfferRequest } = offerRequest;
    const { users, status } = companyOfferRequest;

    const isLinked = users.find((u: CompanyOfferRequestUser) => u.userId === user.id) && linking !== user.id;
    const allowLinking = !offerRequestClosedTypes.includes(status) || isAdmin;
    const maxLinked = this.getLinkedAgentsCount() >= MAX_AGENT_LINK && !isAdmin;

    return (
      <div key={user.id} className='company-link-suggestion'>
        <div>
          {this.renderAgentName(user)}
        </div>
        {(isLinked && allowLinking) && (
          <button className='af-class-button-offer-page w-clearfix w-button' onClick={() => this.deattachUser(user)} disabled={!!linking}>
            {linking !== user.id && 'Poista linkitys'}
            {linking === user.id && <div className='ld ld-ring ld-spin'></div>}
          </button>
        )}
        {(!isLinked && allowLinking && !maxLinked) && (
          <button className='af-class-button-offer-page w-clearfix w-button' onClick={() => this.attachUser(user)} disabled={!!linking}>
            {linking !== user.id && 'Linkitä'}
            {linking === user.id && <div className='ld ld-ring ld-spin'></div>}
          </button>
        )}
        {(!isLinked && allowLinking && maxLinked) && (
          <div>
            {`Sallittu määrä ${MAX_AGENT_LINK} saavutettu. Poista linkitys toiselta välittäjältä linkittääksesi tämän.`}
          </div>
        )}
      </div>
    );
  }

  renderCompanies = (): null | JSX.Element => {
    const { users } = this.state;

    if (!users) {
      return null;
    }

    return (
      <div>
        {
          users.map((user: UserProfile) => this.renderUserLink(user))
        }
      </div>
    );
  }

  onSubmitEstimation = async () => {
    const { offerRequest } = this.state;
    const { companyOfferRequest } = offerRequest;
    try {
      await this.api.child('priceEst').put({
        priceEstimation: companyOfferRequest.priceEstimation,
        status: companyOfferRequest.status,
      });
      toast.success('Hinta-arvio muutettu onnistuneesti.');
    } catch (error) {
      toast.error('Hinta-arvion muuttaminen epäonnistui.');
    }
  }

  onChangeEstimation = (estVal: number | null = null) => {
    const { offerRequest } = this.state;
    const { companyOfferRequest } = offerRequest;
    this.setState({
      offerRequest: {
        ...offerRequest,
        companyOfferRequest: { ...companyOfferRequest, priceEstimation: estVal },
      },
    });
  }

  onSendMyyntiPro = async () => {
    let { offerRequest } = this.state;

    if (!this.myyntiPro.enabled) {
      toast.error('Myynti Pro ei ole enabloitu.');
      return;
    }

    if (this.myyntiPro.sent) {
      toast.warn('Myynti Pro tiedot on jo lähetetty.');
      return;
    }

    const update = (key: string, value: any) => set(offerRequest, `companyOfferRequest.lead.${key}`, value);

    try {
      const result = await this.api.child('myyntipro').put({});
      offerRequest = update('hasMyyntiProSent', result.hasMyyntiProSent);
      this.setState({ offerRequest });

      toast.success('Myynti Pro tiedot lähetetty onnistuneesti.');
    } catch (error) {
      this.setState({ offerRequest });
      toast.error('Myynti Pro tietojen lähetys epäonnistui.');
    }
  }

  render() {
    const { offerRequest, isOfferRequestAvailable } = this.state;
    const isAnonymized = offerRequest?.isAnonymized;

    if (!isOfferRequestAvailable) {
      return (
        <div className="af-offer-request-not-available-wrapper">
          <h2 className="af-offer-request-not-available-header">Tämä vinkki on poistunut välitysliikkeeltä.</h2>
          <p className="af-offer-request-not-available-content">Nopea vastaaminen asunnon myyjän yhteydenottoon nostaa merkittävästi mahdollisuutta toimeksiannon toteutumiseen.</p>
        </div>
      );
    } else if (isAnonymized) {
      return (
        <div className="af-offer-request-not-available-wrapper">
          <h2 className="af-offer-request-not-available-header">Hakemaasi tarjouspyyntöä ei löydy.</h2>
          <p className="af-offer-request-not-available-content">Tarjouspyyntö on vanhentunut, linkki on väärin tai tarjouspyyntö ei ole enää voimassa. Jos uskot tämän olevan virhe, ota yhteyttä asiakaspalveluun.</p>
        </div>
      );
    }

    return (
      <>
        {super.render()}
        <OfferRequestAction
          {...this.props}
          ref={(ref: OfferRequestAction) => this.actionRef = ref}
          onStatusUpdated={this.onStatusUpdated}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  company: companySelectors.getCompany(state),
  currentUser: userSelectors.getProfile(state),
  isAdmin: userSelectors.isAdmin(state),
  isCompanyMainUser: userSelectors.isCompanyMainUser(state),
});

export default connect(
  mapStateToProps,
  {
  },
)(CompanyOfferRequestDetails);
