/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfComponentFooterWhiteView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfComponentFooterWhiteController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfComponentFooterWhiteView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfComponentFooterWhiteView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-footer-section-white-2">
              <div className="af-class-div-block-74 w-clearfix">
                <div className="af-class-footer-top-logo-wrapper">
                  <a href="/" className="af-class-div-block-99 w-inline-block"><img src="images/asuntoarvio-logo-org-2020.svg" width={234} alt="Asuntoarvio logo dark" height={57} className="af-class-image-2" /></a>
                </div>
                <div className="af-class-footer-col-wrapper">
                  <p className="af-class-p">Asuntoarvio on ilmainen, luotettava ja nopea tapa vertailla ja kilpailuttaa asuinalueesi välittäjät.</p>
                  <p className="af-class-p"><br />3314300-8<br />‍Asuntoarvio.fi © 2023</p>
                  <div className="af-class-footer-some-icon-wrapper">
                    <a href="https://www.instagram.com/asuntoarvio_fi/" target="_blank" className="af-class-footer-some-icons"></a>
                    <a href="https://twitter.com/asuntoarvio" target="_blank" className="af-class-footer-some-icons"></a>
                    <a href="https://www.facebook.com/asuntoarvio.fi" target="_blank" className="af-class-footer-some-icons"></a>
                  </div>
                </div>
                <div className="af-class-footer-col-wrapper">
                  <div className="af-class-div-block-73" />
                  <p className="af-class-p">Kampinkuja 2<br />00100 Helsinki</p>
                  <p className="af-class-p-footer-email">
                    <a href="mailto:asiakaspalvelu@asuntoarvio.fi?subject=Yhteydenotto%20Asuntoarvio.fi-sivustolta" className="af-class-link-footer-no-hover">asiakaspalvelu@asuntoarvio.fi</a><br />
                  </p>
                </div>
                <div className="af-class-footer-col-wrapper">
                  <div className="af-class-div-block-73" />
                  <p className="af-class-p">
                    <a href="/kilpailutuspalvelu" className="af-class-footer-link-default-style-grey">Kilpailuta kiinteistönvälitys</a><br />
                    <a href="/kiinteistonvalitys" className="af-class-footer-link-default-style-grey">Etsi kiinteistönvälittäjä</a><br />
                    <a href="/mika-on-asuntoarvio" className="af-class-footer-link-default-style-grey">Mikä on Asuntoarvio?</a><br />
                    <a href="/kiinteistonvalittajille" className="af-class-footer-link-default-style-grey">Kiinteistönvälittäjille</a><br />
                    <a href="/app/documents/Asuntoarvio-fi-kayttoehdot_230101.pdf" target="_blank" className="af-class-footer-link-default-style-grey">Palvelun käyttöehdot</a><br />
                    <a href="/app/documents/Asuntoarvio-fi-tietosuojakaytanto_230101.pdf" target="_blank" className="af-class-footer-link-default-style-grey">Tietosuojakäytäntö</a>
                  </p>
                </div>
                <div className="af-class-footer-col-wrapper">
                  <div className="af-class-div-block-73" />
                  <p className="af-class-p">
                    <a href="/kiinteistonvalitys/helsinki" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Helsinki</a><br />
                    <a href="/kiinteistonvalitys/espoo" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Espoo</a><br />
                    <a href="/kiinteistonvalitys/vantaa" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Vantaa</a><br />
                    <a href="/kiinteistonvalitys/tampere" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Tampere<br /></a>
                    <a href="/kiinteistonvalitys/turku" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Turku<br /></a>
                    <a href="/kiinteistonvalitys/oulu" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Oulu</a><br />
                    <a href="/kiinteistonvalitys/vaasa" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Vaasa</a><br />
                    <a href="/kiinteistonvalitys/jyväskylä" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Jyväskylä</a><br />
                    <a href="/kiinteistonvalitys/lahti" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Lahti</a><br />
                    <a href="/kiinteistonvalitys/kuopio" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Kuopio</a><br />
                    <a href="/kiinteistonvalitys/pori" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Pori</a><br />
                    <a href="/kiinteistonvalitys/kouvola" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Kouvola</a><br />
                    <a href="/kiinteistonvalitys/lappeenranta" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Lappeenranta</a><br />
                    <a href="/kiinteistonvalitys/hämeenlinna" className="af-class-footer-link-default-style-grey">Kiinteistönvälitys Hämeenlinna</a>
                  </p>
                </div>
              </div>
            </div>
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfComponentFooterWhiteView

/* eslint-enable */