import React from 'react';
import AfAdminModalsView from '../views/AfAdminModalsView';

export class AfAdminModalsController extends React.Component {

  render() {
    return (
      <AfAdminModalsView>
        {this.props.children}
      </AfAdminModalsView>
    );
  }
}
