import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AfAdminReviewsView from '../views/AfAdminReviewsView';
import Moment from 'react-moment';
import { BaseController } from './BaseController';
import { Review, ReviewCount } from '../company/Review.interface';
import { ReviewStatus, StringSignatureString } from '../shared/types';
import './styles.scss';

interface Props {
  onRejectReview: (id: number) => void;
  onAccepReview: (id: number) => void;
  onViewOffer: (companyId: number, companyOfferRequestId: number) => void;
  onSelectStatus: (status: ReviewStatus) => void;
  onSendReviewMail: () => void;
  onChangeEmail: (mailAddress: string) => void;
  onChangeNameInvite: (emailInvite: string) => void;
  onLoadPage: (id: number) => void;
  reviews: Review[];
  page: number;
  pageCount: number;
  status: ReviewStatus;
  loader: any;
  errorEmail: boolean;
  errorName: boolean;
  nameInvite: string;
  emailInvite: string;
  reviewCounts: ReviewCount[];
}

const buttonPlaceholders: StringSignatureString = {
  [ReviewStatus.open]: 'Avoimet asiakasarviot',
  [ReviewStatus.accepted]: 'Julkiset asiakasarviot',
  [ReviewStatus.rejected]: 'Piilotetut asiakasarviot',
};

export class AfAdminReviewsController extends BaseController<RouteComponentProps & Props> {
  accept = (id: number) => () => this.props.onAccepReview(id);

  reject = (id: number) => () => this.props.onRejectReview(id);

  viewOffer = (companyId: number, companyOfferRequestId: number) => {
    this.props.onViewOffer(companyId, companyOfferRequestId);
  }

  renderReview = (review: Review) => {
    const { id, createdAt, customerName, description, acceptedAt, rejectedAt, companyOfferRequestId, companyId } = review;

    return (
      <af-review key={id}>
        <af-date><Moment local date={createdAt} /></af-date>
        <af-name>{customerName}</af-name>
        <af-description>{description}</af-description>
        {!acceptedAt && <af-action-btn-accept onClick={this.accept(id)} />}
        {!rejectedAt && <af-action-btn-reject onClick={this.reject(id)} />}
        {companyOfferRequestId && <af-action-btn-offer-request onClick={(e: any) => {
          e.preventDefault();
          this.viewOffer(companyId, companyOfferRequestId);
        }} />}
      </af-review>
    );
  }

  onSendReviewMail = (e: any) => {
    e.preventDefault();
    this.props.onSendReviewMail();
  }

  getCountNumber(status: string) {
    const {reviewCounts } = this.props;
    const review = reviewCounts.find(rev => rev.status === status);
    if (!!review) {
      return `(${review.count})`;
    }
    return '';
  }

  render() {
    const { onSelectStatus, reviews, page, pageCount, onLoadPage, status, loader, errorEmail, errorName } = this.props;
    const open = `${buttonPlaceholders[ReviewStatus.open]} ${this.getCountNumber(ReviewStatus.open)}`;
    const accepted = `${buttonPlaceholders[ReviewStatus.accepted]} ${this.getCountNumber(ReviewStatus.accepted)}`;
    const rejected =  `${buttonPlaceholders[ReviewStatus.rejected]} ${this.getCountNumber(ReviewStatus.rejected)}`;

    return (
      <AfAdminReviewsView>
        <af-link-open
          onClick={() => onSelectStatus(ReviewStatus.open)}
          className={status === ReviewStatus.open ? 'w--current' : undefined}
        >
          {open}
        </af-link-open>
        <af-link-rejected
          onClick={() => onSelectStatus(ReviewStatus.rejected)}
          className={status === ReviewStatus.rejected ? 'w--current' : undefined}
        >
          {rejected}
        </af-link-rejected>
        <af-link-accepted
          onClick={() => onSelectStatus(ReviewStatus.accepted)}
          className={status === ReviewStatus.accepted ? 'w--current' : undefined}
        >
          {accepted}
          </af-link-accepted>
        {this.renderPagination(page, pageCount, onLoadPage)}
        <af-data-wrapper data-loader={loader}>
          {reviews.map(this.renderReview)}
        </af-data-wrapper>
        {this.props.children}
        <af-form-section-review-email>
          <af-customer-name-form className={errorName ? 'error-input' : ''}
            value={this.props.nameInvite}
            onChange={(e: any) => this.props.onChangeNameInvite(e.target.value)} />
          <af-customer-email-form className={errorEmail ? 'error-input' : ''}
            value={this.props.emailInvite}
            onChange={(e: any) => this.props.onChangeEmail(e.target.value)} />
          <af-customer-email-submit onClick={this.onSendReviewMail} />
        </af-form-section-review-email>
      </AfAdminReviewsView>
    );
  }
}
