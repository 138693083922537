import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { isEmpty, isString } from 'lodash';
import AfOfferConfirmationController from '../controllers/AfOfferConfirmationController';
import { BaseComponent, BaseState } from '../components/BaseComponent';
import { OfferRequestService } from '../services/offer-request';
import {
  IOfferRequestConfirmation,
  OfferRequest,
} from './offer-request';

interface State extends BaseState {
  renderResult: IOfferRequestConfirmation[];
  offerRequest: OfferRequest;
}

interface TParams {
  offerSlug: string;
}

export class OfferRequestConfirmation extends BaseComponent<RouteComponentProps<TParams>, State> {
  state = {
    renderResult: [{ content: {} , type: 'expired' }],
  } as State;

  get offerSlug(): string {
    const { match } = this.props;
    return match.params.offerSlug;
  }

  get api(): any {
    const service = new OfferRequestService();
    return service.child(this.offerSlug);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.startLoadingData('data');

    let renderResult: IOfferRequestConfirmation[] = [];
    try {
      const offerRequest = await this.api.child('getOfferList').get();
      if (offerRequest.companyOfferRequests.length !== 0) {
        offerRequest.companyOfferRequests.forEach((companyOfferRequest: any) => {
          if (!companyOfferRequest.contactedAt) {
            return;
          }
          // check if linked to user
          if (companyOfferRequest.companyOfferRequestUser) {
            const userOfOffer = companyOfferRequest.companyOfferRequestUser.user;
            // check if userMainCompany
            if (userOfOffer.userMainCompany.id === companyOfferRequest.company.id) {
              // check that user profile exists
              const { firstName, lastName } = userOfOffer;
              if (isString(firstName) && isString(lastName) && !isEmpty(firstName) && !isEmpty(lastName)) {
                userOfOffer.priceEstimation = companyOfferRequest.priceEstimation;
                renderResult = [...renderResult, { content: userOfOffer, type: 'user' }];
                return;
              }
            }
          }
          companyOfferRequest.company.priceEstimation = companyOfferRequest.priceEstimation;
          renderResult = [...renderResult, { content: companyOfferRequest.company, type: 'company' }];
          return;
        });
      }
      this.endLoadingData('data', this.setState({
        renderResult,
        offerRequest,
      }));
    } catch (error: any) {
      if (error.json && error.json.statusCode === 405 && error.json.message === 'Expired') {
        this.endLoadingData('data', this.setState({
          renderResult: [{ content: {} , type: 'expired' }],
          offerRequest: {},
        }));
      }
    }
  }

  render() {
    const { renderResult, offerRequest } = this.state;

    return (
      <AfOfferConfirmationController
        dataLoader={this.renderLoading('data')}
        pageLoading={this.isLoadingData('page')}
        renderResult={renderResult}
        offerRequest={offerRequest}
        {...this.props}
      />
    );
  }
}
