// import React from 'react';
// import Modal from 'react-modal';
import { connect } from 'react-redux';
import { userSelectors } from '../store/user';
import { UserProfile } from '../store/user/reducer';
import { Form, FormState, FormInitialState } from '../components/Form';
// import { AfAdminModalsController } from '../controllers/AfAdminModalsController';
import { AuthHelper } from '../AuthProvider';
// import GoogleLogin from 'react-google-login';
// @ts-ignore
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

interface Props {
    isOpen: boolean;
    isAuthenticated: boolean;
    profile: UserProfile | null;
    onSocialLogin: (e: any) => void;
    onCloseModalLogin: () => void;
}

export class ReviewLogin extends Form<Props & AuthHelper, FormState> {
    state = {
        ...FormInitialState,
        section: 'authenticated',
    } as FormState;

    section() {
        return [
            this.renderCheckbox('acceptTerms'),
        ];
    }

    // loginFacebook(e: any) {
    //     e.preventDefault();
    //     const element: HTMLElement = document.getElementById('facebookLoginBtn') as HTMLElement;
    //     element.click();
    // }

    // loginGoogle(e: any) {
    //     e.preventDefault();
    //     const element: HTMLElement = document.getElementById('googleLoginBtn') as HTMLElement;
    //     element.click();
    // }

    render() {
        return null;
        // const { onSocialLogin, isOpen, onCloseModalLogin } = this.props;

        // return (
        //     <Modal
        //         key='modal'
        //         isOpen={isOpen}
        //         onRequestClose={() => onCloseModalLogin()}
        //     >
        //         {/*<FacebookLogin
        //             appId={`${process.env.REACT_APP_FACEBOOK_CLIENT_ID}`}
        //             fields='name,email'
        //             callback={(res: any) => { onSocialLogin(res); }}
        //             render={(renderProps: any) => (
        //                 <button
        //                     style={{ display: 'none' }}
        //                     id='facebookLoginBtn'
        //                     onClick={renderProps.onClick}>
        //                 </button>
        //             )}
        //         />*/}
        //         <GoogleLogin
        //             clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
        //             onSuccess={(res) => { onSocialLogin(res); }}
        //             render={(renderProps: any) =>
        //                 <button
        //                     style={{ display: 'none' }}
        //                     id='googleLoginBtn'
        //                     onClick={renderProps.onClick}>
        //                 </button>
        //             }
        //         />
        //         <AfAdminModalsController>
        //             <af-modal-review-login>
        //                 <af-form-reviews-login>
        //                     {/*<af-modal-login-facebook-wrapper>
        //                         <af-modal-login-faceook onClick={this.loginFacebook} />
        //                     </af-modal-login-facebook-wrapper>*/}
        //                     <af-modal-login-google-wrapper>
        //                         <af-modal-login-google onClick={this.loginGoogle} />
        //                     </af-modal-login-google-wrapper>
        //                     <af-exit-some-modal onClick={() => onCloseModalLogin()} />
        //                 </af-form-reviews-login>
        //             </af-modal-review-login>
        //         </AfAdminModalsController>
        //     </Modal>
        // );
    }
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: userSelectors.isAuthenticated(state),
    profile: userSelectors.getProfile(state),
});

export default connect(
    mapStateToProps,
    {
    },
)(ReviewLogin);
