import React from 'react';
import Moment from 'react-moment';
import { get, random } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import AfAgentPageView from '../views/AfAgentPageView';
import { UserProfile } from '../store/user/reducer';
import { Review } from '../company/Review.interface';
import { GoogleMap } from '../components/GoogleMap';
import { BaseController } from './BaseController';
import { formatNumber } from '../lib/helpers';

interface Props {
  agent: UserProfile;
  reviews: Review[];
  reviewPage: number;
  reviewPageCount: number;
  reviewsCount: number;
  loadReviewsNextPage: () => void;
  onReviewClick: (e: any) => void;
  isAdmin: boolean;
}

const randomBg = () => `images/agent-bg-${random(1, 4)}.jpg`;

export class AfAgentPageController extends BaseController<RouteComponentProps & Props, {}> {
  componentDidMount() {
    super.componentDidMount();
    this.metaTagService.setAgentTags(get(this.props, 'agent'));
    this.structuredDataService.setAgentSD(get(this.props, 'agent'), get(this.props, 'reviews'));
  }

  renderReviews() {
    const { reviews, loadReviewsNextPage, reviewPageCount, reviewPage } = this.props;

    if (!reviews || !reviews.length) {
      return null;
    }

    let ref: any = null;

    const go = (event: any) => {
      event.preventDefault();

      if (!ref) {
        return;
      }

      ref.scrollIntoView();
    };

    const parts = [
      <af-link-reviews key='reviews-link' onClick={go} />,
      <af-reviews-wrapper key='reviews-list' ref={(r: any) => { ref = r; }}>
        {reviews.map(({
          id,
          customerName,
          numberOfStars,
          createdAt,
          description,
          wouldRecommend,
        }) => (
            <af-review key={id}>
              {this.renderStars('review-stars', numberOfStars)}
              <af-review-author>{customerName}</af-review-author>
              <af-review-date><Moment local date={createdAt} /></af-review-date>
              {wouldRecommend && <af-recommend-agent />}
              <af-review-description>{description}</af-review-description>
            </af-review>
          ))}
        {reviewPageCount > reviewPage && (
          <af-review-show-more onClick={loadReviewsNextPage}>
            Näytä lisää arvosteluita ({reviewPage}/{reviewPageCount})
          </af-review-show-more>
        )}
      </af-reviews-wrapper>,
    ];

    if (reviewPageCount > reviewPage) {
      parts.push((
        <af-review-show-more key='reviews-show-more' onClick={loadReviewsNextPage}>
          Näytä lisää arvosteluita ({reviewPage}/{reviewPageCount})
        </af-review-show-more>
      ));
    }

    return parts;
  }

  renderTerritories() {
    const { agent } = this.props;
    const {
      territoryCities,
      territoryZipCodeAreas,
    } = agent;

    if (!territoryCities.length && !territoryZipCodeAreas.length) {
      return null;
    }

    return (
      <af-territories-wrap-agent>
        {territoryCities.map(({ value, label }) => (
          <af-territory-city-agent key={value}>
            {this.afLink('territory-city-agent-link', { city: label }, label)}
          </af-territory-city-agent>
        ))}
        {territoryZipCodeAreas.map(({ value, label, data }) => (
          <af-territory-zip-code-area-agent key={value}>
            {this.afLink('territory-zip-code-area-agent-link', { postcode: data.zipCode }, label)}
          </af-territory-zip-code-area-agent>
        ))}
      </af-territories-wrap-agent>
    );
  }

  render() {
    const { agent, onReviewClick } = this.props;

    const {
      stats,
      companies,
      slug: agentSlug,
      firstName,
      lastName,
      description,
      titles,
      yearsOfExperience,
      languages,
      specialities,
      facebook,
      instagram,
      linkedin,
      backgroundImage,
      logoImage,
    } = agent;

    const {
      numberOfStarsAvg,
      responseDays,
      askingPriceRatio,
      wouldRecommendCount,
    } = stats;

    return (
      <AfAgentPageView>
        <af-breadcrumb-agent>{firstName} {lastName}</af-breadcrumb-agent>

        {this.afLink('offer-request-link', { agent: agentSlug })}
        <af-map-agent><GoogleMap coordinates={companies[0].company.location} /></af-map-agent>

        <af-header-agent style={{ backgroundImage: `url(${backgroundImage || randomBg()})` }}>
          <af-logo-image-agent style={{ backgroundImage: logoImage ? `url(${logoImage})` : undefined }} />
        </af-header-agent>
        <af-agent-name>{firstName} {lastName}</af-agent-name>
        {titles && titles.length && (<af-agent-title>{titles.map(({ label }) => label).join(', ')}</af-agent-title>)}
        {companies.map(({ company }) => this.afLink('marketing-name-agent',
          {
            city: company.city,
            company: company.slug,
          }, company.marketingName, { key: company.id }))
        }
        {numberOfStarsAvg && <af-stars-average-agent>{formatNumber(numberOfStarsAvg, 2)}</af-stars-average-agent>}
        {this.renderStars('stars-agent', numberOfStarsAvg)}
        {this.afLink('review-agent-button', {agent: agentSlug}, null, {onClick: onReviewClick})}
        {description && description
          .split('\n')
          .filter(p => p)
          .map((p, i) => <af-description-agent key={`description-${i}`} style={{ marginTop: i > 0 ? '10px' : 0 }}>{p}</af-description-agent>)}
        {facebook && this.afLink('facebook-agent', facebook)}
        {instagram && this.afLink('instagram-agent', instagram)}
        {linkedin && this.afLink('linkedin-agent', linkedin)}
        {
          [
            yearsOfExperience ? `Kokemusvuodet: ${yearsOfExperience}` : undefined,
            languages && languages.length ? `Kielitaito: ${languages.map(({ label }) => label).join(', ')}` : undefined,
            specialities && specialities.length ? `Erityisosaaminen: ${specialities.map(({ label }) => label).join(', ')}` : undefined,
          ].filter(v => v).map((v, i) => <af-info-agent key={`info-${i}`}>{v}</af-info-agent>)
        }

        {this.renderTerritories()}

        {responseDays && askingPriceRatio && (
          <af-data-wrapper>
            <af-average-response-time>{responseDays}</af-average-response-time>
            <af-asking-price-ratio>{askingPriceRatio}</af-asking-price-ratio>
            <af-would-recommend>{wouldRecommendCount}</af-would-recommend>
          </af-data-wrapper>
        )}

        {this.renderReviews()}
      </AfAgentPageView>
    );
  }
}
