import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { BaseController } from './BaseController';
import AfComponentNavDarkView from '../views/AfComponentNavDarkView';
import { getSlugByStatus, OfferRequestStatus } from '../offer-request/offer-request';
import { companySelectors } from '../store/company';
import { Company } from '../company/Company.interface';
import { userSelectors } from '../store/user';
import { UserProfile } from '../store/user/reducer';
import { CompanyStatus } from '../shared/types';

interface Props {
  privateRoute: boolean;
  adminRoute: boolean;
  company: Company | null;
  profile: UserProfile | null;
  isAdmin: boolean;
  isCompanyMainUser: (companyId: number) => boolean;
}

interface TParams {
  companyId: string;
}

export class AfComponentNavDarkControllerBase extends BaseController<RouteComponentProps<TParams> & Props> {
  render() {
    const { privateRoute, match, company, location, profile, isAdmin, isCompanyMainUser } = this.props;
    const companyId = match.params.companyId ? parseInt(match.params.companyId, 10) : null;

    const adminLinks = ['management', 'logout', 'settings'];
    const isMainUser = isAdmin ? true : (companyId ? isCompanyMainUser(companyId) : false);

    if (companyId && isMainUser) {
      adminLinks.push('company');
    }

    const isApproved = [CompanyStatus.approved, CompanyStatus.noOffers].includes(company?.status as any);

    if (companyId && company && isApproved) {
      adminLinks.push('offer-requests');
      if (isMainUser) {
        adminLinks.push('reviews');
      }
    }

    let authPageInfo = null;
    if (company && company.id === companyId) {
      authPageInfo = company.marketingName;
      if (profile && profile.email) {
        authPageInfo += ` - ${profile.email}`;
      }
    } else if (profile && profile.email) {
      authPageInfo = `Olet kirjautuneena tunnuksella ${profile.email}`;
    }

    if (isAdmin) {
      authPageInfo += ' | SUPER ADMIN';
    }

    return (
      <AfComponentNavDarkView>
        {(privateRoute) && (
          <af-admin-nav-wrapper>
            {adminLinks.map((link) => this.afLink(`link-${link}`, {
              companyId,
              status: getSlugByStatus(OfferRequestStatus.open),
            }, null, {
              key: link,
              activeClassName: 'af-class-secondary-nav-link-active',
              isActive(href: string, props: any) {
                if (props.key === 'offer-requests' || props.key === 'reviews') {
                  const pat = /\/[^/]+$/;
                  return href.replace(pat, '') === location.pathname.replace(pat, '');
                }

                return href === location.pathname;
              },
            }))}
          </af-admin-nav-wrapper>
        )}
        {privateRoute && authPageInfo && <af-auth-page-info>{authPageInfo}</af-auth-page-info>}
      </AfComponentNavDarkView>
    );
  }
}

const mapStateToProps = (state: any) => ({
  company: companySelectors.getCompany(state),
  isCompanyMainUser: userSelectors.isCompanyMainUser(state),
  profile: userSelectors.getProfile(state),
  isAdmin: userSelectors.isAdmin(state),
});

export const AfComponentNavDarkController = connect(
  mapStateToProps,
  {},
)(AfComponentNavDarkControllerBase);
