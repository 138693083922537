import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CompanyManagerService } from '../services/company-manager';
import { UserService } from '../services/user';
import { UserProfile, UserCompany } from '../store/user/reducer';
import { logger } from '../lib/debug';
import { AfAdminUserFormController } from '../controllers/AfAdminUserFormController';
import { BaseComponent, BaseState } from '../components/BaseComponent';

const log = logger('AdminUserList');

interface State extends BaseState {
  users: UserProfile[];
}

export class AdminUserList extends BaseComponent<RouteComponentProps, State> {
  constructor(props: any) {
    super(props);
    this.state = { users: [] } as State;
  }

  componentDidMount() {
    super.componentDidMount();
    this.load();
  }

  load = async (searchValue: string|null = null) => {
    try {
      const service = new UserService();
      if (searchValue) {
        service.query({ email: searchValue });
      }

      const users = await service.get();

      this.setState({
        users,
      });
    } catch (error) {
      toast.error('Haku epäonnistui');
    }
  }

  modifyLinkState(userId: number, companyId: number, acceptedAt: Date|null) {
    const { users } = this.state;
    const user = users.find(({ id }) => Number(id) === Number(userId));
    if (!user) {
      log('user not found in state', userId);
      return;
    }

    if (acceptedAt) {
      const company = user.companies.find(c => Number(c.company.id) === Number(companyId));
      if (!company) {
        log('company link not found in state', user, companyId);
        return;
      }

      company.acceptedAt = acceptedAt;
      log('update acceptedAt', company.acceptedAt);
    } else {
      user.companies = user.companies
        .filter(c => Number(c.company.id) !== Number(companyId)) as [UserCompany];
    }

    this.setState({ users });
  }

  async acceptLink(userId: number, companyId: number) {
    try {
      const service = new CompanyManagerService(companyId).child('users');
      await service.post({ userId });
      toast.success('Käyttäjän ja yrityksen linkitys hyväksytty onnistuneesti!');
      this.modifyLinkState(userId, companyId, new Date());
    } catch (e) {
      toast.error('Käyttäjän ja yrityksen linkitys epäonnistui!');
    }
  }

  async rejectLink(userId: number, companyId: number) {
    try {
      await new CompanyManagerService(companyId).child('users').child(userId).del();
      toast.success('Käyttäjän ja yrityksen linkitys poistettu onnistuneesti!');
      this.modifyLinkState(userId, companyId, null);
    } catch (e) {
      toast.error('Käyttäjän ja yrityksen linkityksen poisto epäonnistui!');
    }
  }

  render() {
    const { users } = this.state;

    const onActionLink = (accept: boolean, userId: number, companyId: number) => {
      accept ? this.acceptLink(userId, companyId) : this.rejectLink(userId, companyId);
    };

    return (
      <AfAdminUserFormController
        users={users}
        onActionLink={onActionLink}
        onSearch={this.load}
        {...this.props}
      />
    );
  }
}
