/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfAdminOfferInfoTableView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfAdminOfferInfoTableController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfAdminOfferInfoTableView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfAdminOfferInfoTableView ? transformProxies(this.props.children) : {
      'af-state-open': [],
      'af-state-text': [],
      'af-state-contacted': [],
      'af-state-text': [],
      'af-state-contract': [],
      'af-state-text': [],
      'af-state-fulfilled': [],
      'af-state-text': [],
      'af-state-not-fulfilled': [],
      'af-state-text': [],
      'af-state-reclamation': [],
      'af-state-text': [],
      'af-admin-tools': [],
      'af-data-wrapper': [],
      'af-row': [],
      'af-date': [],
      'af-name': [],
      'af-contact': [],
      'af-address': [],
      'af-info-button': [],
      'af-commission-receipt-link': [],
      'af-custom-status-selector': [],
      'af-info': [],
      'af-action-wrapper': [],
      'af-action-button-wrapper': [],
      'af-action-btn-primary': [],
      'af-action-btn-secondary': [],
      'af-action-btn-third': [],
      'af-admin-form-notes': [],
      'af-input-notes-status': [],
      'af-input-notes-textarea': [],
      'af-action-info-wrapper': [],
      'af-action-info': [],
      'af-link-prev': [],
      'af-link-page': [],
      'af-link-next': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-section-2">
              <div data-current data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-tabs w-tabs">
                <div className="af-class-tabs-menu w-tab-menu">
                  {map(proxies['af-state-open'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a data-w-tab="Tab 1" {...{...props, className: `af-class-tab01 w-inline-block w-tab-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-state-text'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-tab-link-text-1 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Avoimet tarjouspyynnöt (14)</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </a>
                ))}
                  {map(proxies['af-state-contacted'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a data-w-tab="Tab 2" {...{...props, className: `af-class-tab02 w-inline-block w-tab-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-state-text'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-tab-link-text-2 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Avoimet asiakkaat (5)</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </a>
                ))}
                  {map(proxies['af-state-contract'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a data-w-tab="Tab 3" {...{...props, className: `af-class-tab03 w-inline-block w-tab-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-state-text'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-tab-link-text-3 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Toimeksiantosopimukset (3)</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </a>
                ))}
                  {map(proxies['af-state-fulfilled'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a data-w-tab="Tab 4" {...{...props, className: `af-class-tab04 w-inline-block w-tab-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-state-text'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-tab-link-text-4 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Toteutuneet kaupat</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </a>
                ))}
                  {map(proxies['af-state-not-fulfilled'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a data-w-tab="Tab 5" {...{...props, className: `af-class-tab05 w-inline-block w-tab-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-state-text'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-tab-link-text-5 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Ei toteutuneet</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </a>
                ))}
                  {map(proxies['af-state-reclamation'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a data-w-tab="Tab 6" {...{...props, className: `af-class-tab06 w-inline-block w-tab-link ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    {map(proxies['af-state-text'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-tab-link-text-6 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Reklamaatiot</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </a>
                ))}
                </div>
              </div>
              {map(proxies['af-admin-tools'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
              {map(proxies['af-data-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-134 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                <div className="af-class-scrollable-data-row">
                  <div className="af-class-div-block-7 w-clearfix">
                    <div className="af-class-div-block-6">
                      <div className="af-class-div-block-132">
                        <div className="af-class-table-header">Päivämäärä</div>
                      </div>
                    </div>
                    <div className="af-class-div-block-6">
                      <div>
                        <div className="af-class-table-header">Nimi</div>
                      </div>
                    </div>
                    <div className="af-class-div-block-6">
                      <div>
                        <div className="af-class-table-header">Yhteystiedot</div>
                      </div>
                    </div>
                    <div className="af-class-div-block-6">
                      <div>
                        <div className="af-class-table-header">kohde</div>
                      </div>
                    </div>
                    <div className="af-class-div-block-6">
                      <div>
                        <div className="af-class-table-header">Lisätiedot</div>
                      </div>
                    </div>
                  </div>
                  {map(proxies['af-row'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-data-row ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                    <div className="af-class-scrollable-data-row">
                      <div className="af-class-feed-wrapper-offer-info-table w-clearfix">
                        <div className="af-class-div-block-6">
                          <div>
                            {map(proxies['af-date'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-table-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>21.05.2019</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                          </div>
                        </div>
                        <div className="af-class-div-block-6">
                          <div>
                            {map(proxies['af-name'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-table-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Etunimi Sukunimi</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                          </div>
                        </div>
                        <div className="af-class-div-block-6">
                          <div>
                            {map(proxies['af-contact'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-table-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>etunimi@tunnus.fi<br />040 123 4567</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                          </div>
                        </div>
                        <div className="af-class-div-block-6">
                          <div>
                            {map(proxies['af-address'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-table-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Kerrostalo, 4h<br />Töölönkatu 3 A 20<br />00100, Helsinki</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                          </div>
                        </div>
                        <div className="af-class-div-block-6">
                          <div>
                            {map(proxies['af-info-button'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-forward w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Katso lisätiedot<span className="af-class-arrow-forward"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                            {map(proxies['af-commission-receipt-link'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                            {map(proxies['af-custom-status-selector'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                            {map(proxies['af-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-table-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Myyntiaikataulu 3 kk</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {map(proxies['af-action-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-action-wrapper w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      {map(proxies['af-action-button-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-feed-wrapper-button-container ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        <div className="af-class-div-block-6-buttons af-class-div-block-145">
                          <div className="af-class-div-block-144">
                            {map(proxies['af-action-btn-primary'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-3 af-class-button-admin-top-wide-info-table w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Näytä yhteystiedot</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                          </div>
                          <div className="af-class-div-block-144">
                            {map(proxies['af-action-btn-secondary'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-admin-bottom-info-table w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Hylkää tarjouspyyntö</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                          </div>
                          {map(proxies['af-action-btn-third'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-button-admin-bottom-info-table w-clearfix w-button ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>En ole kiinnostunut kohteesta</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                        </div>
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      <div className="af-class-div-block-6-buttons af-class-div-block-notes-form">
                        <div className="w-form">
                          {map(proxies['af-admin-form-notes'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-admin-notes" name="wf-form-af-form-admin-notes" data-name="af-form-admin-notes" method="get" data-wf-page-id="5d9c6c5957629d7b2283f5b4" data-wf-element-id="1f719ed2-749b-df18-ee89-5f72d9569804" {...{...props, className: `af-class-form-4 ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="notesStatus" className="af-class-field-label">Valitse yhteydenoton tila:</label>{map(proxies['af-input-notes-status'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <select id="notesStatus" name="notesStatus" data-name="notesStatus" {...{...props, className: `af-class-input-field w-select ${ldLoader || ''} ${props.className || ''}`}}>
                    {(props.children ? props.children : <React.Fragment>
                              <option value>Ei kontaktoitu</option>
                            </React.Fragment>)}
                  </select>
                ))}{map(proxies['af-input-notes-textarea'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <textarea maxLength={5000} name="notesTextarea" data-name="notesTextarea" placeholder="Muistiinpanot kontaktointiin" id="notesTextarea" {...{...props, className: `af-class-textarea w-input ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </textarea>
                ))}<input type="submit" data-wait="Please wait..." af-admin="af-notes-fulfilled-confirm-btn" className="af-class-button-admin-notes w-button" value="Tallenna" />{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                          <div className="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                          </div>
                          <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                          </div>
                        </div>
                      </div>
                      {map(proxies['af-action-info-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-admin-info-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                        {map(proxies['af-action-info'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-admin-table-info-text ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Yhteystietojen katsominen muodostaa sopimussuhteen Asuntoarvion kanssa myyntikohteesta ja siirtää sen Kontaktoidut-statukseen. </React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                      {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                  {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                </div>
              {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
              <div className="af-class-div-block-84 w-clearfix">
                {map(proxies['af-link-prev'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-div-block-8 af-class-alt-width ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Edellinen</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-link-page'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-div-block-8 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>1</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
                {map(proxies['af-link-next'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-div-block-8 af-class-alt-width ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>Seuraava</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </a>
                ))}
              </div>
            </div>
            {/*  Google Tag Manager (noscript)  */}

            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfAdminOfferInfoTableView

/* eslint-enable */
