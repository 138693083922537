import Api from '../lib/api';

export class UserService extends Api {
    constructor() {
        super('user');
    }

    me() {
        return this.child('me');
    }

    companyTakeOver(companyId: number) {
        return this.me().child('company').post({
            companyId,
        });
    }
}
