/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class AfReviewsLandingView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AfReviewsLandingController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AfReviewsLandingView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {

  }

  render() {
    const proxies = Controller !== AfReviewsLandingView ? transformProxies(this.props.children) : {
      'af-section-review': [],
      'af-section-review-children': [],
      'af-face-wrapper': [],
      'af-face-icon': [],
      'af-review-text-wrapper': [],
      'af-review-text': [],
      'af-form-section-review': [],
      'af-would-recommend-checkbox': [],
      'af-input-description': [],
      'af-section-submit': [],
      'af-section-review-not-fulfilled': [],
      'af-section-review-children': [],
      'af-face-wrapper': [],
      'af-face-icon': [],
      'af-review-text-wrapper': [],
      'af-review-text': [],
      'af-form-section-review-not-fulfilled': [],
      'af-input-description': [],
      'af-section-submit': [],
      'af-section-fix-data': [],
      'af-section-fix-data-children': [],
      'af-date': [],
      'af-selling-price': [],
      'af-commission': [],
      'af-description': [],
      'af-form-section-fix-data': [],
      'af-input-fulfilled-at': [],
      'af-input-selling-price': [],
      'af-input-commission': [],
      'af-section-submit': [],
      'af-section-review-open': [],
      'af-section-review-children': [],
      'af-face-wrapper': [],
      'af-face-icon': [],
      'af-review-text-wrapper': [],
      'af-review-text': [],
      'af-form-section-review-open': [],
      'af-would-recommend-checkbox': [],
      'af-input-description': [],
      'af-section-submit-cancel': [],
      'af-section-submit': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `

        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            {map(proxies['af-section-review'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-view-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-5 w-container">
                <div className="af-class-agency-review-content-wrapper">
                  <div className="af-class-agency-review-wrapper af-class-alt-padding">
                    {map(proxies['af-section-review-children'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <h1 className="af-class-heading-3-agency-review">Kiitos arvostelustasi!</h1>
                      <div className="af-class-div-block-agency-review-buttons">
                        {map(proxies['af-face-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-div-block-customer-review-icon w-inline-block w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                          <p className="af-class-review-landing-smiley-container">{map(proxies['af-face-icon'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-review-icon ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}</p>
                        {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </a>
                ))}
                      </div>
                      <div className="af-class-lkv-review-rating-wrapper w-clearfix">
                        {map(proxies['af-review-text-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-rating-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Arvostelusi kiinteistönvälittäjästä: {map(proxies['af-review-text'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <em {...props}>
                    {loader || (props.children ? props.children : <React.Fragment>Huono, Kohtalainen, Hyvä, Erinomainen, Loistava</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </em>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      </div>
                      <p className="af-class-agency-review-small-text">Klikkaa yllä olevia painikkeita valitaksesi kokemuksesi mukaisen arvostelun.</p>
                      <p className="af-class-p-agency-review">Muista jättää myös vapaa tekstiarvio välittäjästä ja palvelukokemuksesta kokonaisuutena. Sanalliset perustelut auttavat kiinteistönvälittäjiä omassa työssään ja toimivat palkintona hyvästä ja asiantuntevasta asunnon myynnistä.</p>
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    <div>
                      <div className="af-class-form-block-review-agency w-form">
                        {map(proxies['af-form-section-review'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form data-name="Agency Review Form" id="Agency-Review-Form" name="wf-form-Agency-Review-Form" method="get" data-wf-page-id="5d9c6c5957629d670783f5e3" data-wf-element-id="c68de494-f273-face-520a-3c5bfd9044a2" {...{...props, className: `af-class-form w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                          <div className="af-class-div-block-agency-recommended w-clearfix">
                            {map(proxies['af-would-recommend-checkbox'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-69 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-checked"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                            <div className="af-class-lkv-result-list-address af-class-margin-form af-class-margin-form-2">Suosittelen yritystä myös muille palvelun käyttäjille!</div>
                          </div>
                          <div className="af-class-div-block-review-agency-textarea"><label htmlFor="description" className="af-class-field-label-review-tell-more">Kertoisitko hieman lisää kokemuksestasi?</label>{map(proxies['af-input-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <textarea maxLength={5000} name="description" data-name="description" placeholder="Oliko välittäjäsi asiantunteva? Osasiko hän hinnoitella asuntosi oikein? Oliko välittän kanssa mukava tehdä yhteistyötä? Palkitse välittäjä ansaituilla kehuilla tai anna risuja, jos yhteistyössä on parannettavaa." id="description" required {...{...props, className: `af-class-input-field-review-agency w-input ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </textarea>
                ))}</div>
                          <div className="af-class-div-block-68" />
                          <div className="af-class-div-block-agency-review-button-wrapper">{map(proxies['af-section-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." id="Review-Agency-Button" value="Lähetä arvostelu" {...{...props, className: `af-class-button-agency-contract-button-large w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Lähetä arvostelu')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                        {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                    <p className="af-class-agency-review-small-text">Lähettämällä arvostelun hyväksyt arvostelun julkaisun sekä palvelun <a href="/app/documents/Asuntoarvio-fi-kayttoehdot_230101.pdf" target="_blank" className="af-class-text-link-small-review-page">käyttöehdot</a> ja <a href="/app/documents/Asuntoarvio-fi-tietosuojakaytanto_230101.pdf" target="_blank" className="af-class-text-link-small-review-page">tietosuojakäytännön</a>.</p>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-section-review-not-fulfilled'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-view-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-5 w-container">
                <div className="af-class-agency-review-content-wrapper">
                  <div className="af-class-agency-review-wrapper af-class-alt-padding">
                    {map(proxies['af-section-review-children'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <h1 className="af-class-heading-3-agency-review">Kiitos arvostelustasi!</h1>
                      <div className="af-class-div-block-agency-review-buttons">
                        {map(proxies['af-face-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-div-block-customer-review-icon w-inline-block w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                          <p className="af-class-review-landing-smiley-container">{map(proxies['af-face-icon'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-review-icon ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}</p>
                        {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </a>
                ))}
                      </div>
                      <div className="af-class-lkv-review-rating-wrapper w-clearfix">
                        {map(proxies['af-review-text-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-rating-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Arvostelusi kiinteistönvälittäjästä: {map(proxies['af-review-text'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <em {...props}>
                    {loader || (props.children ? props.children : <React.Fragment>Huono, Kohtalainen, Hyvä, Erinomainen, Loistava</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </em>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      </div>
                      <p className="af-class-agency-review-small-text">Klikkaa yllä olevia painikkeita valitaksesi kokemuksesi mukaisen arvostelun.</p>
                      <p className="af-class-p-agency-review">Kertoisitko ystävällisesti syyn toimeksiannon päättymiselle. Vastauksesi on tärkeä ja auttaa meitä suosittelemaan parempia välittäjiä jatkossa. Kiitos!</p>
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    <div>
                      <div className="af-class-form-block-review-agency w-form">
                        {map(proxies['af-form-section-review-not-fulfilled'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form data-name="Agency Review Form No Deal" id="wf-form-Agency-Review-Form-No-Deal" name="wf-form-Agency-Review-Form-No-Deal" method="get" data-wf-page-id="5d9c6c5957629d670783f5e3" data-wf-element-id="e61ac2c1-7ab0-7b25-332f-920ce721d83e" {...{...props, className: `af-class-form w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                          <div className="af-class-div-block-review-agency-textarea"><label htmlFor="description-2" className="af-class-field-label-review-tell-more">Kertoisitko hieman syistä, jotka johtivat toimeksiannon peruuntumiseen?</label>{map(proxies['af-input-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <textarea maxLength={5000} name="description" data-name="description" placeholder="Löysitkö toisen paremman välittäjän? Eikö tieto päättymisestä pidä paikkaansa? Kerro meille tarkemmin tapahtumista." id="description-2" required {...{...props, className: `af-class-input-field-review-agency w-input ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </textarea>
                ))}</div>
                          <div className="af-class-div-block-68" />
                          <div className="af-class-div-block-agency-review-button-wrapper">{map(proxies['af-section-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." id="Review-Agency-Button-No-Deal" value="Lähetä arvostelu" {...{...props, className: `af-class-button-agency-contract-button-large w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Lähetä arvostelu')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                        {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-section-fix-data'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-view-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-5 w-container">
                <div className="af-class-agency-review-content-wrapper">
                  <div className="af-class-agency-contract-wrapper af-class-alt-padding">
                    {map(proxies['af-section-fix-data-children'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <h1 className="af-class-heading-3-agency-contract">Onnittelut asunnon kaupasta!</h1>
                      <div className="af-class-lkv-review-rating-wrapper w-clearfix">
                        <div className="af-class-lkv-result-list-rating-number">Olemme saaneet kiinteistönvälittäjältäsi seuraavat tiedot:</div>
                      </div>
                      <p className="af-class-p-agency-contract-details">Asunnon kaupan päivämärää: {map(proxies['af-date'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...{...props, className: `af-class-agency-contract-detail-large ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>12.10.2019</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}<br /><strong /></p>
                      <p className="af-class-p-agency-contract-details"><strong />Asunnon toteutunut velaton kauppahinta: {map(proxies['af-selling-price'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...{...props, className: `af-class-agency-contract-detail-large ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>230.000 euroa<br /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}<strong /></p>
                      <p className="af-class-p-agency-contract-details"><strong />Kiinteistönvälittäjän toteutunut välityspalkkio: {map(proxies['af-commission'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <strong {...{...props, className: `af-class-agency-contract-detail-large ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment>7.200 euroa</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </strong>
                ))}</p>
                      {map(proxies['af-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <p {...{...props, className: `af-class-p-agency-contract-info ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><strong />Jos annetut tiedot ovat virheellisen, korjaisitko ystävällisesti oikeat tiedot alla oleviin lomakkeisiin. Tämä auttaa palvelua arvioimaan kiinteistönvälittäjän suoriutumista ja sijoitusta palvelussa. Kiitos!<strong /></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </p>
                ))}
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    <div className="w-form">
                      {map(proxies['af-form-section-fix-data'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form id="wf-form-af-form-section-fix-data" name="wf-form-af-form-section-fix-data" data-name="af-form-section-fix-data" method="get" data-wf-page-id="5d9c6c5957629d670783f5e3" data-wf-element-id="6c4452b8-bd92-7358-8f0b-43d020d635ed" {...{...props, className: `af-class-agency-contract-form ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment><label htmlFor="fulfilledAt" className="af-class-field-label">Asunnon kaupan päivämäärä</label>{map(proxies['af-input-fulfilled-at'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="fulfilledAt" data-name="fulfilledAt" placeholder="esim. 24.10.2019" type="number" id="fulfilledAt" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}<label htmlFor="sellingPrice" className="af-class-field-label">Toteutunut velaton kauppahinta (€)</label>{map(proxies['af-input-selling-price'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="sellingPrice" step={1} data-name="sellingPrice" pattern="\d+" data-focus-type="number" min={0} placeholder="esim. 230000" type="text" id="sellingPrice" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}<label htmlFor="commission" className="af-class-field-label">Toteutunut välityspalkkio (€)</label>{map(proxies['af-input-commission'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <input maxLength={256} name="commission" step={1} data-name="commission" pattern="\d+" data-focus-type="number" min={0} placeholder="esim. 7500" type="text" id="commission" {...{...props, className: `af-class-input-field w-input ${ldLoader || ''} ${props.className || ''}`}}>{props.children}
                  </input>
                ))}{map(proxies['af-section-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." value="Vahvista tiedot" {...{...props, className: `af-class-button-agency-contract-button-large w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Vahvista tiedot')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                    <p className="af-class-agency-contract-small-text">Asuntoarvio.fi käyttää toteutuneita kauppahintoja ja välityspalkkioita vain palvelun tuottamiseen ja kehittämiseen.</p>
                    <p className="af-class-agency-contract-small-text">Yksilöidyt hintatiedot eivät ole julkista tietoa, eikä niitä luovuteta kolmansille osapuolille. Palvelun käyttöehtoihin voit tutustua tarkemmin <a href="/app/documents/Asuntoarvio-fi-kayttoehdot_230101.pdf" target="_blank" className="af-class-text-link-small-review-page">täältä</a>.</p>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {map(proxies['af-section-review-open'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-view-wrapper ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
              <div className="af-class-container-5 w-container">
                <div className="af-class-agency-review-content-wrapper">
                  <div className="af-class-agency-review-wrapper af-class-alt-padding">
                    {map(proxies['af-section-review-children'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...props}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                      <h1 className="af-class-heading-3-agency-review">Arvostele kiinteistönvälittäjä</h1>
                      <div className="af-class-div-block-agency-review-buttons">
                        {map(proxies['af-face-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <a href="#" {...{...props, className: `af-class-div-block-customer-review-icon w-inline-block w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                          <p className="af-class-review-landing-smiley-container">{map(proxies['af-face-icon'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <span {...{...props, className: `af-class-review-icon ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </span>
                ))}</p>
                        {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </a>
                ))}
                      </div>
                      <div className="af-class-lkv-review-rating-wrapper w-clearfix">
                        {map(proxies['af-review-text-wrapper'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-lkv-result-list-rating-number ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>Arvostelusi kiinteistönvälittäjästä: {map(proxies['af-review-text'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <em {...props}>
                    {loader || (props.children ? props.children : <React.Fragment>Huono, Kohtalainen, Hyvä, Erinomainen, Loistava</React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </em>
                ))}{ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                      </div>
                      <p className="af-class-agency-review-small-text">Klikkaa yllä olevia painikkeita valitaksesi kokemuksesi mukaisen arvostelun.</p>
                      <p className="af-class-p-agency-review">Muista jättää myös vapaa tekstiarvio välittäjästä ja palvelukokemuksesta kokonaisuutena. Sanalliset perustelut auttavat kiinteistönvälittäjiä omassa työssään ja toimivat palkintona hyvästä ja asiantuntevasta asunnon myynnistä.</p>
                    {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
                    <div>
                      <div className="af-class-form-block-review-agency w-form">
                        {map(proxies['af-form-section-review-open'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <form data-name="Agency Review Form" id="Agency-Review-Form" name="wf-form-Agency-Review-Form" method="get" data-wf-page-id="5d9c6c5957629d670783f5e3" data-wf-element-id="9c46c1a4-6397-bf4b-c60d-57f47aa9cbee" {...{...props, className: `af-class-form w-clearfix ${ldLoader || ''} ${props.className || ''}`}}>
                    {createScope(props.children, proxies => loader || <React.Fragment>
                          <div className="af-class-div-block-agency-recommended w-clearfix">
                            {map(proxies['af-would-recommend-checkbox'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <div {...{...props, className: `af-class-div-block-69 ${ldLoader || ''} ${props.className || ''}`}}>
                    {loader || (props.children ? props.children : <React.Fragment><span className="af-class-checked"></span></React.Fragment>)}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </div>
                ))}
                            <div className="af-class-lkv-result-list-address af-class-margin-form af-class-margin-form-2">Suosittelen yritystä myös muille palvelun käyttäjille!</div>
                          </div>
                          <div className="af-class-div-block-review-agency-textarea"><label htmlFor="description-4" className="af-class-field-label-review-tell-more">Kertoisitko hieman lisää kokemuksestasi?</label>{map(proxies['af-input-description'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <textarea maxLength={5000} name="description-3" data-name="Description 3" placeholder="Oliko välittäjäsi asiantunteva? Osasiko hän hinnoitella asuntosi oikein? Oliko välittän kanssa mukava tehdä yhteistyötä? Palkitse välittäjä ansaituilla kehuilla tai anna risuja, jos yhteistyössä on parannettavaa." id="description-3" required {...{...props, className: `af-class-input-field-review-agency w-input ${ldLoader || ''} ${props.className || ''}`}}>{loader || props.children}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </textarea>
                ))}</div>
                          <div className="af-class-div-block-68" />
                          <div className="af-class-div-block-agency-review-button-wrapper">{map(proxies['af-section-submit-cancel'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." id="Review-Agency-Button" value="Peruuta" {...{...props, className: `af-class-button-agency-contract-button-large w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Peruuta')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}{map(proxies['af-section-submit'], ({ 'data-loader': loader, 'data-ld-loader': ldLoader, ...props }) => (
                  <button type="submit" data-wait="Please wait..." id="Review-Agency-Button" value="Lähetä arvostelu" {...{...props, className: `af-class-button-agency-contract-button-large-positon-right w-button ${ldLoader || ''} ${props.className || ''}`}}>{loader || (props.children ? props.children : 'Lähetä arvostelu')}{ldLoader && <div className="ld ld-ring ld-spin"></div>}
                  </button>
                ))}</div>
                        {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </form>
                ))}
                        <div className="w-form-done">
                          <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                          <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                      </div>
                    </div>
                    <p className="af-class-agency-review-small-text">Lähettämällä arvostelun hyväksyt arvostelun julkaisun sekä palvelun <a href="/app/documents/Asuntoarvio-fi-kayttoehdot_230101.pdf" target="_blank" className="af-class-text-link-small-review-page">käyttöehdot</a> ja <a href="/app/documents/Asuntoarvio-fi-tietosuojakaytanto_230101.pdf" target="_blank" className="af-class-text-link-small-review-page">tietosuojakäytännön</a>.</p>
                  </div>
                </div>
              </div>
            {ldLoader && <div className="ld ld-ring ld-spin"></div>}</React.Fragment>)}
                  </div>
                ))}
            {/*  Google Tag Manager (noscript)  */}
            
            {/*  End Google Tag Manager (noscript)  */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n  .af-view .w-webflow-badge {  display:none !important; position: static !important; visibility: hidden !important; z-index: -100 !important;  }\n" }} />
          </div>
        </span>
      </span>
    )
  }
}

export default AfReviewsLandingView

/* eslint-enable */