import { set } from 'lodash';
import { format } from 'date-fns';

export * from '../shared/helpers';

export const loadingProps = (isLoading: boolean) => ({
  'data-ld-loader': `ld-over-inverse ${isLoading ? 'running' : ''}`,
  disabled: isLoading,
});

export const setPrerenderStatus = (status: boolean, context = window) =>
  set(context, 'prerenderStatus', status);

export const formatDate = (input: any, formatStr = 'd.M.yyyy'): string => {
  if (input instanceof Date) {
    return format(input, formatStr);
  } else if (typeof input === 'string') {
    return format(new Date(input), formatStr);
  } else {
    return '-';
  }
};
