import React from 'react';
import AfAdminOfferInfoTableView from '../views/AfAdminOfferInfoTableView';
import { BaseController } from './BaseController';

export class AfAdminOfferInfoTableController extends BaseController {
  render() {
    return (
      <AfAdminOfferInfoTableView>
        {this.props.children}
      </AfAdminOfferInfoTableView>
    );
  }
}
